import React from "react";
import { useTranslation } from "react-i18next";
import changeCase from "change-case";
import { showDate } from "../../function/showValueHelper";
import { getDataFromSingleCache } from "../../function/cache";

type PrintHeaderProps = {
    title: string;
    companyInfo: any;
    supplier?: any;
    customer?: any;
    key?: string;
    no?: string;
    dtCreated: string;
    customAddress?: string;
};

function getData(companyInfo: any, key: string) {
    let obj = companyInfo.find((val: any) => val.key === key);
    return obj ? obj.value : "";
}

const PrintHeader = React.memo(
    ({ title, companyInfo, supplier, customer, no, dtCreated, customAddress }: PrintHeaderProps) => {
        const { t } = useTranslation();
        const imgData = getDataFromSingleCache("setting/logo");
        let to = supplier != null ? supplier : customer;
        let headerElm = imgData ? (
            <tr>
                <th className="docTitle" style={{ width: "60%" }}>
                    <img src={imgData} height="80px" className="logo" alt="logo" />
                </th>
                <th className="docTitle" style={{ textAlign: "left" }}>
                    <span style={{ verticalAlign: "bottom" }}>{changeCase.upper(title)}</span>
                </th>
            </tr>
        ) : (
            <tr>
                <th colSpan={2} className="docTitle" style={{ verticalAlign: "bottom" }}>
                    {changeCase.upper(title)}
                </th>
            </tr>
        );

        let accountEmail = getData(companyInfo, "email");
        let accountPhone = getData(companyInfo, "no-telepon");
        let accountHasEmail = accountEmail !== "" && accountEmail !== null && accountEmail !== "-";
        let recipientHasEmail = to.email !== "" && to.email !== null && to.email !== "-";
        let accountHasPhone = accountPhone !== "" && accountPhone !== null && accountPhone !== "-";
        let recipientHasPhone = to.phone !== "" && to.email !== null && to.phone !== "-";

        let emailRow;
        if (accountHasEmail && recipientHasEmail) {
            emailRow = (
                <>
                    <th>
                        {t("common.email")}: {accountEmail}
                    </th>
                    <th>
                        {t("common.email")}: {to.email ? to.email : ""}
                    </th>
                </>
            );
        } else if (accountHasEmail && !recipientHasEmail) {
            emailRow = (
                <>
                    <th colSpan={2}>
                        {t("common.email")}: {accountEmail}
                    </th>
                </>
            );
        } else if (!accountHasEmail && recipientHasEmail) {
            emailRow = (
                <>
                    <th />
                    <th>
                        {t("common.email")}: {to.email ? to.email : ""}
                    </th>
                </>
            );
        }

        let phoneRow;
        if (accountHasPhone && recipientHasPhone) {
            phoneRow = (
                <>
                    <th>
                        {t("common.telephoneNo")}: {accountPhone}
                    </th>
                    <th>
                        {t("common.telephoneNo")}: {to.phone ? to.phone : ""}
                    </th>
                </>
            );
        } else if (accountHasPhone && !recipientHasPhone) {
            phoneRow = (
                <>
                    <th colSpan={2}>
                        {t("common.telephoneNo")}: {accountPhone}
                    </th>
                </>
            );
        } else if (!accountHasPhone && recipientHasPhone) {
            phoneRow = (
                <>
                    <th />
                    <th>
                        {t("common.telephoneNo")}: {to.phone ? to.phone : ""}
                    </th>
                </>
            );
        }

        return (
            <thead>
                {headerElm}
                <tr style={{ marginBottom: "10px" }}>
                    <th style={{ width: "60%" }}>
                        <b>
                            {t("common.documentNo")}
                            <br />
                            {no}
                        </b>
                    </th>
                    <th>
                        <b>
                            {t("common.createDate")}
                            <br />
                            {showDate(dtCreated)}
                        </b>
                    </th>
                </tr>
                <tr>
                    <th />
                    <th>{t("common.recipient")}:</th>
                </tr>
                <tr>
                    <th>{getData(companyInfo, "nama-perusahaan")}</th>
                    <th>{to.name}</th>
                </tr>
                <tr>
                    <th>
                        {getData(companyInfo, "alamat-perusahaan")}
                        {getData(companyInfo, "kota") ? ", " + getData(companyInfo, "kota") + "." : ""}
                    </th>
                    <th>
                        {customAddress != null
                            ? customAddress
                            : to.address.replace(/\n+$/, "") + (to.city ? ", " + to.city + "." : "")}
                        {/* {to.address}
                    {to.city ? ", " + to.city + "." : ""} */}
                    </th>
                </tr>
                <tr>
                    <th>
                        {getData(companyInfo, "status-badan-usaha") === "PKP"
                            ? t("common.taxIdentificationNo") + ": " + getData(companyInfo, "no-npwp")
                            : ""}
                    </th>
                    <th>
                        {to.taxIdentificationNo !== "" ? (
                            <>
                                {t("common.taxIdentificationNo")}: {to.taxIdentificationNo}
                            </>
                        ) : (
                            <></>
                        )}
                    </th>
                </tr>
                <tr>{emailRow}</tr>
                <tr>{phoneRow}</tr>
            </thead>
        );
    }
);

export default PrintHeader;

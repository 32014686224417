import React, { useState } from "react";
import ClassNames from "classnames";
import { UncontrolledTooltip, FormGroup, Label, Col } from "reactstrap";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import DatePicker from "react-datepicker";

const backendFormat = "YYYY/MM"; //format that will be sent to backend

//else dayjs can't parse custom date
dayjs.extend(customParseFormat);

type InputMonthType = BaseInputType & {
    dateFormat?: string;
    maxDate?: any;
    minDate?: any;
    isClearable?: boolean;
};

/**
 * There are 2 (3) format used here
 * 1. backEndFormat: the date format used by backend (as v0.1.0 we use YYYY/MM/DD) <-- we use this format as state saved by redux-form.
 *    so, we can send the value directly to backend without any conversion.
 * 2. dateFormat: the format displayed by DatePicker component
 * 3. dayjsFormat: converting from state into displayed value (but dayjs need all uppercase, so we convert it)
 */
export const InputMonth = ({
    input,
    meta: { touched, error },
    className = "",
    label = "",
    // onChange,
    disabled,
    readOnly,
    horizontalInput,
    isRequired,
    isClearable,
    dateFormat = "MM/yyyy", //"yyyy/MM/dd", //format shown to user (format of DatePicker) //TO-DO: read from setting
    placeholder = dateFormat.toUpperCase(),
    minDate,
    maxDate,
    noInfo,
}: InputMonthType) => {
    const { t } = useTranslation();
    const dayjsFormat = dateFormat.toUpperCase(); //format needed by dayjs
    const [id] = useState(("input" + input.name).replace(/[^\w]+/g, ""));

    // console.log(dayjs().subtract(50, "year").toISOString());
    return (
        <FormGroup row={horizontalInput} className={className}>
            {label && (
                <Label for={id} /*sm={horizontalInput ? 2 : false}*/>
                    {t(label)} {isRequired ? <small>*</small> : ""}
                </Label>
            )}
            <Col sm={horizontalInput ? 10 : false} style={{ padding: "0" }}>
                <DatePicker
                    {...input}
                    className={ClassNames({ "has-danger": touched && error }, "form-control")}
                    value={
                        input.value
                            ? dayjs(input.value, backendFormat).format(dayjsFormat)
                            : null /*value is the textbox (shown to user)*/
                    }
                    selected={
                        input.value
                            ? dayjs(input.value, backendFormat).toDate()
                            : null /*selected is the highlight in calendar*/
                    }
                    dateFormat={dateFormat} //this and value handle the format shown to user (not the state value)
                    placeholderText={disabled ? "" : t(placeholder)}
                    disabled={disabled}
                    readOnly={readOnly}
                    minDate={
                        minDate
                            ? minDate
                            : dayjs()
                                  .subtract(50, "year")
                                  .toDate()
                    }
                    maxDate={maxDate}
                    showMonthYearPicker
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    onChange={(val) => input.onChange(val ? dayjs(val).format(backendFormat) : null)} //this is the format needed by backend (YYYY/MM/DD) (state value that will be sent by redux-form)
                    onSelect={() => null} //avoid duplicated value change
                    onBlur={() => null} //avoid duplicated value change
                    id={id}
                    isClearable={isClearable}
                />
            </Col>
            {noInfo !== true && (
                <UncontrolledTooltip
                    className={ClassNames({ "force-hidden": !(touched && error) })}
                    placement="right"
                    target={id}
                    flip={false} //https://github.com/reactstrap/reactstrap/issues/1488
                >
                    {t(error)}
                </UncontrolledTooltip>
            )}
        </FormGroup>
    );
};

import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { withTranslation } from "react-i18next";
import { slcGlobalSuccess, slcGlobalError, slcGlobalLoading } from "../../redux/globalAPINotification";
import { slcSettingToastDuration } from "../../../Setting/redux/setting";

class GlobalAPINotification extends PureComponent<any, any> {
    constructor(props: any) {
        super(props);

        this.state = {
            loadingToastId: null,
        };
    }
    componentDidUpdate(prevProps: any) {
        const { t, toastDuration } = this.props;
        
        if (
            this.props.globalError !== prevProps.globalError &&
            Object.keys(this.props.globalError).length > 0 &&
            this.props.globalError.reAuth !== true
        ) {
            //if the error is not reauth error
            toast.error(
                <div>
                    <small>
                        <b>{t("notification.error")}</b>
                    </small>
                    <br />
                    <small>
                        {t("common.message")}:{" "}
                        {this.props.globalError.message == null ? t("common.timeout") : this.props.globalError.message}{" "}
                        {/*can't use t() as it cause problems with ':' in error message*/}
                    </small>
                    <br />
                    <small>
                        <small>
                            {t("common.source")}: {this.props.globalError.source}
                        </small>
                    </small>
                </div>,
                //@ts-ignore
                { containerId: "SOE", autoClose:(toastDuration?.value ?? 5) * 1000  }
            );
        }

        if (this.props.globalSuccess !== prevProps.globalSuccess && Object.keys(this.props.globalSuccess).length > 0) {
            toast.success(
                <div>
                    <small>
                        <b>{t("common.success")}</b>
                    </small>
                    <br />
                    <small>
                        {t("common.message")}: {this.props.globalSuccess.message}
                        {/*can't use t() as it cause problems with ':' in message*/}
                    </small>
                    <br />
                    <small>
                        <small>
                            {t("common.source")}: {this.props.globalSuccess.source}
                        </small>
                    </small>
                </div>,
                //@ts-ignore
                { containerId: "SOE", autoClose:(toastDuration?.value ?? 5) * 1000 }
            );
        }

        if (this.props.globalLoading !== prevProps.globalLoading) {
            if (this.props.globalLoading.length > 0) {
                if (this.state.loadingToastId != null) {
                    //update
                    toast.update(this.state.loadingToastId, {
                        render: (
                            <div>
                                <small>{this.props.globalLoading.length + " " + t("common.ongoingProcess")}</small>
                            </div>
                        ),
                        //@ts-ignore
                        containerId: "Loading",
                        autoClose:(toastDuration?.value ?? 5) * 1000
                    });
                } else {
                    //new
                    const newId = toast(
                        <div>
                            <small>{this.props.globalLoading.length + " " + t("common.ongoingProcess")}</small>
                        </div>,
                        //@ts-ignore
                        { containerId: "Loading", autoClose: false, closeButton: false }
                    );
                    this.setState({ loadingToastId: newId });
                }
            } else {
                if (this.state.loadingToastId != null) {
                    setTimeout(() => {
                        //sometimes the loading time is too fast, that toast try to dismiss before finish rendering
                        toast.dismiss(this.state.loadingToastId);
                        this.setState({ loadingToastId: null });
                    });
                }
            }
        }
    }

    render() {
        return (
            <>
                {/* success or error
            //@ts-ignore */}
                <ToastContainer enableMultiContainer containerId={"SOE"} position={toast.POSITION.TOP_RIGHT} />
                {/* 
            //@ts-ignore */}
                <ToastContainer enableMultiContainer containerId={"Loading"} position={toast.POSITION.BOTTOM_RIGHT} />
            </>
        );
    }
}

function mapStateToProps(state: any) {
    return {
        globalSuccess: slcGlobalSuccess(state),
        globalError: slcGlobalError(state),
        globalLoading: slcGlobalLoading(state),
        toastDuration: slcSettingToastDuration(state),
    };
}

export default connect(mapStateToProps)(withTranslation()(GlobalAPINotification));

import React, { PureComponent } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Table } from "reactstrap";
import PrintArea from "../../../common/component/PrintArea";
import PrintFooter from "../../../common/component/PrintFooter";
import PrintHeaderProduction from "../../../common/component/PrintHeaderProduction";
import { SafeBig } from "../../../common/function/safeBig";
import { showCurrency, showQty } from "../../../common/function/showValueHelper";

type JobOrderPrintState = {
    data: any;
    companyInfo: any;
};

class JobOrderPrint extends PureComponent<WithTranslation, JobOrderPrintState> {
    constructor(props: any) {
        super(props);

        //load data from localStorage
        const stringData = localStorage.getItem("jobOrder-print");
        const jsonData = stringData != null ? JSON.parse(stringData) : null;
        // remove data from localStorage
        // localStorage.removeItem("jobOrder-print");

        let stateObj = {
            data: jsonData && jsonData.data ? jsonData.data : null,
            companyInfo: jsonData && jsonData.companyInfo ? jsonData.companyInfo : null,
        };

        this.state = stateObj;
    }

    render() {
        const { t } = this.props;
        const { companyInfo, data } = this.state;
        if (data == null) return <p>No Data</p>;

        let finalQty = SafeBig(data.outputQty).times(data.productionQty);

        const materialColumn = data.detailList
            .filter((val: any) => val.type === "material")
            .map((val: any, i: number) => {
                return (
                    <tr key={i}>
                        <td className="rowNo">{i + 1}</td>
                        <td>{`${val.product.key} - ${val.product.name}`}</td>
                        <td>{`${showQty(val.qty)} ${val.product.unit.name}`}</td>
                    </tr>
                );
            });

        const expenseColumn = data.detailList
            .filter((val: any) => val.type === "expense")
            .map((val: any, i: number) => {
                return (
                    <tr key={i}>
                        <td className="rowNo">{i + 1}</td>
                        <td>{`${val.coa.coaNo} - ${val.coa.name}`}</td>
                        <td>{`${showCurrency(val.amount)}`}</td>
                    </tr>
                );
            });

        return (
            //@ts-ignore
            <PrintArea>
                <Table className="ra-table-print">
                    <PrintHeaderProduction
                        title={t("common.jobOrder")}
                        no={data.key}
                        dtCreated={data.documentDate}
                        companyInfo={companyInfo}
                    />
                    <tbody>
                        <tr>
                            <td colSpan={2}>
                                <Table className="ra-table-detail-print">
                                    <colgroup>
                                        <col width={"3%"} />
                                        <col width={"70%"} />
                                        <col width={"27%"} />
                                    </colgroup>
                                    <tbody>
                                        <tr>
                                            <th colSpan={3}>{t("common.material")}</th>
                                        </tr>
                                        <tr>
                                            <th className="rowNo">#</th>
                                            <th>{t("common.product")}</th>
                                            <th className="qty">{t("common.qty")}</th>
                                        </tr>
                                        {materialColumn}
                                        <tr>
                                            <th colSpan={3}>{t("common.expense")}</th>
                                        </tr>
                                        <tr>
                                            <th className="rowNo">#</th>
                                            <th>{t("common.expense")}</th>
                                            <th className="qty">{t("common.amount")}</th>
                                        </tr>
                                        {expenseColumn}
                                        <tr>
                                            <th colSpan={2}>{t("common.outputProduct")}</th>
                                            <td>{`${data.outputProduct.name}`}</td>
                                        </tr>
                                        <tr>
                                            <th colSpan={2}>{t("common.productionQty")}</th>
                                            <td>{`${showQty(data.productionQty)} Lot @${showQty(
                                                data.outputQty
                                            )} = ${showQty(finalQty)} ${data.outputProduct.unit.name}`}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={3} className="description">
                                                {t("common.description")}: <br /> {data.description}
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                <PrintFooter footerType="jobOrder" />
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </PrintArea>
        );
    }
}

export default withTranslation()(JobOrderPrint);

import { PureComponent } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Table } from "reactstrap";
import { showCurrency, showDate } from "../../../common/function/showValueHelper";

type PayableCardProps = {
    data: any;
};

type PayableCardState = {};

class PayableCard extends PureComponent<WithTranslation & PayableCardProps, PayableCardState> {
    constructor(props: any) {
        super(props);
        let stateObj = {};
        this.state = stateObj;
    }

    render() {
        const { data, t } = this.props;
        let result = data.listData.map((val: any, i: number) => {
            let txElements = (
                <Table className="ra-table-detail-print" key={`payCard-${i}`}>
                    <thead>
                        <tr>
                            <th>{t("common.date")}</th>
                            <th>{t("common.key")}</th>
                            <th>{t("common.description")}</th>
                            <th>{t("common.increase")}</th>
                            <th>{t("common.decrease")}</th>
                            <th>{t("common.balance")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colSpan={2}></td>
                            <td>{t("common.beginningBalance")}</td>
                            <td colSpan={2}></td>
                            <td>{showCurrency(val.initialBalance.balance)}</td>
                        </tr>
                        {val.list.map((transaction: any, i: number) => {
                            return (
                                <tr key={`payCard-tx-${i}`}>
                                    <td>{showDate(transaction.date)}</td>
                                    <td>{transaction.key}</td>
                                    <td>{transaction.description}</td>
                                    <td>{showCurrency(transaction.increase ?? 0)}</td>
                                    <td>{showCurrency(transaction.decrease ?? 0)}</td>
                                    <td>{showCurrency(transaction.balance)}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            );
            return (
                <>
                    <tr>
                        <td>{t("common.supplier")}</td>
                        <td>: {`${val.supplier.key} - ${val.supplier.name}`}</td>
                    </tr>
                    <tr>
                        <td colSpan={2}>{txElements}</td>
                    </tr>
                </>
            );
        });
        return (
            <div>
                <style>
                    {`
                        @page {
                            size: A4 landscape;
                        }

                        .ra-table-detail-print {
                            margin-bottom: 1em !important;
                        }
                    `}
                </style>
                <Table className="ra-table-print">
                    <colgroup>
                        <col style={{ width: "20%" }}></col>
                        <col style={{ width: "70%" }}></col>
                    </colgroup>
                    <tbody>{result}</tbody>
                </Table>
            </div>
        );
    }
}

export default withTranslation()(PayableCard);

import { PureComponent } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Table } from "reactstrap";
import { showCurrency, showDate, showQty } from "../../../common/function/showValueHelper";

type PurchaseSummaryProps = {
    data: any;
};

type PurchaseSummaryState = {};

class PurchaseSummary extends PureComponent<WithTranslation & PurchaseSummaryProps, PurchaseSummaryState> {
    constructor(props: any) {
        super(props);
        let stateObj = {};
        this.state = stateObj;
    }

    rowWalker(data: any, i: number) {
        let keys;
        let result = [];
        for (let j = i; j < data.length; j++) {
            let val = data[j];
            keys = Object.keys(val);
            if (keys.length === 11 && typeof val.pricePerUnit.value === "string") {
                // is header
                result.push(
                    <tr key={j}>
                        <td>{val.productName.value}</td>
                        <td>{val.qty.value}</td>
                        <td>{val.totQty.value}</td>
                        <td>{val.pricePerUnit.value}</td>
                        <td>{val.priceAfterDiscount.value}</td>
                        <td>{val.taxBase.value}</td>
                        <td>{val.taxTotal.value}</td>
                        <td>{val.subtotal.value}</td>
                        <td>{val.hpp.value}</td>
                        <td>{val.labaKotor.value}</td>
                    </tr>
                );
            } else if (keys.length === 11 && typeof val.pricePerUnit.value !== "string") {
                // is data
                result.push(
                    <tr key={j}>
                        <td>{val.productName.value}</td>
                        <td>{val.qty.value}</td>
                        <td>{val.totQty.value}</td>
                        <td>{showCurrency(val.pricePerUnit.value)}</td>
                        <td>{showCurrency(val.priceAfterDiscount.value)}</td>
                        <td>{showCurrency(val.taxBase.value)}</td>
                        <td>{showCurrency(val.taxTotal.value)}</td>
                        <td>{showCurrency(val.subtotal.value)}</td>
                        <td>{showCurrency(val.hpp.value)}</td>
                        <td>{showCurrency(val.labaKotor.value)}</td>
                    </tr>
                );
            } else if (keys.length === 4) {
                // is summary
                result.push(
                    <tr key={i}>
                        <td colSpan={7}>TOTAL</td>
                        <td>{showCurrency(val.subtotal.value)}</td>
                        <td>{showCurrency(val.hpp.value)}</td>
                        <td>{showCurrency(val.labaKotor.value)}</td>
                    </tr>
                );
            } else {
                // exit from map
                break;
            }
        }
        return result;
    }

    render() {
        const { data, t } = this.props;
        let result = data.listData.map((val: any, i: number) => {
            let txElements = val.list.map((transaction: any) => {
                let txElement: any[] = [];
                txElement.push(
                    <tr>
                        <td>{t("common.date")}</td>
                        <td>: {showDate(transaction.documentDate)}</td>
                    </tr>
                );
                txElement.push(
                    <tr>
                        <td>{t("common.key")}</td>
                        <td>: {transaction.key}</td>
                    </tr>
                );
                if (transaction.transactionList.length > 0) {
                    const { transactionList } = transaction;
                    txElement.push(
                        <tr>
                            <td colSpan={2}>
                                <Table className="ra-table-detail-print" key={`purchaseSummary-${i}`}>
                                    <colgroup>
                                        <col style={{ width: "25%" }} />
                                        <col style={{ width: "12%" }} />
                                        <col style={{ width: "8%" }} />
                                        <col style={{ width: "8%" }} />
                                        <col style={{ width: "8%" }} />
                                        <col style={{ width: "15%" }} />
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th>{t("common.product")}</th>
                                            <th>{t("common.qty")}</th>
                                            <th>{t("common.price")}</th>
                                            <th>{t("common.taxBase")}</th>
                                            <th>{t("common.tax")}</th>
                                            <th>{t("common.subtotal")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {transactionList.map((transaction: any, i: number) => {
                                            if (transaction.product) {
                                                return (
                                                    <tr key={`purchaseSummary-tx-${i}`}>
                                                        <td>{`${transaction.product.key} - ${transaction.product.name}`}</td>
                                                        <td>{`${showQty(transaction.qty)} ${
                                                            transaction.unit.name
                                                        }`}</td>
                                                        <td>{showCurrency(transaction.priceAfterDiscount ?? 0)}</td>
                                                        <td>{showCurrency(transaction.taxBase ?? 0)}</td>
                                                        <td>{showCurrency(transaction.taxTotal)}</td>
                                                        <td>{showCurrency(transaction.subtotal)}</td>
                                                    </tr>
                                                );
                                            } else {
                                                return (
                                                    <tr>
                                                        <td colSpan={4}></td>
                                                        <td>{t("common.total")}</td>
                                                        <td>{showCurrency(transaction.subtotalPerPurchase)}</td>
                                                    </tr>
                                                );
                                            }
                                        })}
                                    </tbody>
                                </Table>
                            </td>
                        </tr>
                    );
                }
                return txElement;
            });
            return (
                <>
                    <tr>
                        <td>{t("common.supplier")}</td>
                        <td>: {`${val.supplier.key} - ${val.supplier.name}`}</td>
                    </tr>
                    {txElements}
                </>
            );
        });
        return (
            <div>
                <style>
                    {`
                        @page {
                            size: A4 landscape;
                        }

                        .ra-table-detail-print {
                            margin-bottom: 1em !important;
                        }
                    `}
                </style>
                <Table className="ra-table-print">
                    <colgroup>
                        <col style={{ width: "20%" }}></col>
                        <col style={{ width: "70%" }}></col>
                    </colgroup>
                    <tbody>{result}</tbody>
                </Table>
            </div>
        );
    }
}

export default withTranslation()(PurchaseSummary);

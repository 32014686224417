import React from "react";
import { useTranslation } from "react-i18next";
import changeCase from "change-case";
import { showDate } from "../../function/showValueHelper";
import { getDataFromSingleCache } from "../../function/cache";

type PrintHeaderProductionProps = {
    title: string;
    no: string;
    dtCreated: string;
    companyInfo: any;
};

function getData(companyInfo: any, key: string) {
    let obj = companyInfo.find((val: any) => val.key === key);
    return obj ? obj.value : "";
}

const PrintHeaderProduction = React.memo(({ title, companyInfo, no, dtCreated }: PrintHeaderProductionProps) => {
    const { t } = useTranslation();
    const imgData = getDataFromSingleCache("setting/logo");
    let headerElm = imgData ? (
        <tr>
            <th className="docTitle" style={{ width: "60%" }}>
                <img src={imgData} height="80px" className="logo" alt="logo" />
            </th>
            <th className="docTitle" style={{ textAlign: "left" }}>
                <span style={{ verticalAlign: "bottom" }}>{changeCase.upper(title)}</span>
            </th>
        </tr>
    ) : (
        <tr>
            <th colSpan={2} className="docTitle" style={{ verticalAlign: "bottom" }}>
                {changeCase.upper(title)}
            </th>
        </tr>
    );
    let accountEmail = getData(companyInfo, "email");
    let accountPhone = getData(companyInfo, "no-telepon");
    return (
        <thead>
            {headerElm}
            <tr>
                <th style={{ width: "60%", paddingBottom: "15px" }}>
                    <b>
                        {t("common.documentNo")}:&nbsp; {no}
                    </b>
                </th>
                <th>
                    <b>
                        {t("common.createDate")}:&nbsp; {showDate(dtCreated)}
                    </b>
                </th>
            </tr>
            <tr>
                <th colSpan={2}>{getData(companyInfo, "nama-perusahaan")}</th>
            </tr>
            <tr>
                <th colSpan={2} style={{ paddingBottom: "15px" }}>
                    {getData(companyInfo, "alamat-perusahaan")}
                    {getData(companyInfo, "kota") ? ", " + getData(companyInfo, "kota") + "." : ""}
                </th>
            </tr>
            <tr>
                <th colSpan={2}>
                    {t("common.email")}: {accountEmail}
                </th>
            </tr>
            <tr>
                <th colSpan={2}>
                    {t("common.telephoneNo")}: {accountPhone}
                </th>
            </tr>
        </thead>
    );
});

export default PrintHeaderProduction;

import React, { useState } from "react";
import ClassNames from "classnames";
import { UncontrolledTooltip, FormGroup, Label, Col, CustomInput } from "reactstrap";
import { useTranslation } from "react-i18next";

type InputCheckGroupType = {
    label?: string;
    className?:string;
    horizontalInput?: boolean;
    children:any;
    isRequired?:boolean;
};

export const InputCheckGroup = ({
    label = "",
    className,
    horizontalInput,
    children,
    isRequired
}: InputCheckGroupType) => {
    const { t } = useTranslation();
    return (
        <FormGroup row={horizontalInput} className={className}>
            <Label /*sm={horizontalInput ? 2 : false}*/>
                {t(label)} {isRequired ? <small>*</small> : ""}
            </Label>
            <Col sm={horizontalInput ? 10 : false}>
                {children}
            </Col>
        </FormGroup>
    );
};

type InputCheckType = {
    input: any;
    meta: { touched: boolean; error: string };
    className?:string;
    label?: string;
    disabled?: boolean;
    // defaultChecked?:boolean;
    isRequired?: boolean;
    noInfo?: boolean;
};

export const InputCheck = React.memo(({
    input,
    meta: { touched, error },
    className="",
    label = "",
    disabled,
    // defaultChecked,
    isRequired,
    noInfo,
}: InputCheckType) => {
    const { t } = useTranslation();
    const [id] = useState(("input" + input.name).replace(/[^\w]+/g,""));
    // console.log(input.value);
    return (
        <FormGroup className={className}>
            {/* <input
                type="checkbox"
                {...input}
                className={ClassNames({ "has-danger": touched && error })}
                disabled={disabled}
                checked={input.value}
                // defaultChecked={defaultChecked} //default check should use redux-form instead of props
                id={id}
            /> */}
            <CustomInput
                type="checkbox"
                {...input}
                className={ClassNames({ "has-danger": touched && error })}
                disabled={disabled}
                checked={input.value}
                value={input.value==="0"?false:input.value==="1"?true:input.value}
                // defaultChecked={input.value} //default check should use redux-form instead of props
                id={id}
                label={t(label)+" "+(isRequired ? <small>*</small> : "")}
            />
            

            {noInfo!==true && <UncontrolledTooltip
                className={ClassNames({ "force-hidden": !(touched && error) })}
                placement="right"
                target={id}
                flip={false} //https://github.com/reactstrap/reactstrap/issues/1488
            >
                {t(error)}
            </UncontrolledTooltip>}
        </FormGroup>
    );
});

import reducerRegistry from "../../common/reduxHandler/reducerRegistry";
import { reqGet, reqDelete } from "../../common/function/request";
import { setFetchWithPaginationParam } from "../../common/function/requestParamHelper";
import { saveIntoSingleCache, getDataFromSingleCache } from "../../common/function/cache";

//initial state
const initialState: CommonReduxState = {
    loading: false,
    data: null,
    error: null,
};

//action
const createActionName = (name: string) => `notification/${name}`;
const READ_NEW_NOTIFICATION_DONE = createActionName("READ_NEW_NOTIFICATION_DONE");
const FETCH_NEW_NOTIFICATION = createActionName("FETCH_NEW_NOTIFICATION");
const FETCH_NEW_NOTIFICATION_DONE = createActionName("FETCH_NEW_NOTIFICATION_DONE");
const FETCH_NEW_NOTIFICATION_FAILED = createActionName("FETCH_NEW_NOTIFICATION_FAILED");
const FETCH_NOTIFICATION_LIST = createActionName("FETCH_NOTIFICATION_LIST");
const FETCH_NOTIFICATION_LIST_DONE = createActionName("FETCH_NOTIFICATION_LIST_DONE");
const FETCH_NOTIFICATION_LIST_FAILED = createActionName("FETCH_NOTIFICATION_LIST_FAILED");
// const CREATE_NOTIFICATION = createActionName("CREATE_NOTIFICATION");
// const CREATE_NOTIFICATION_DONE = createActionName("CREATE_NOTIFICATION_DONE");
// const CREATE_NOTIFICATION_FAILED = createActionName("CREATE_NOTIFICATION_FAILED");
// const UPDATE_NOTIFICATION = createActionName("UPDATE_NOTIFICATION");
// const UPDATE_NOTIFICATION_DONE = createActionName("UPDATE_NOTIFICATION_DONE");
// const UPDATE_NOTIFICATION_FAILED = createActionName("UPDATE_NOTIFICATION_FAILED");
const FETCH_NOTIFICATION = createActionName("FETCH_NOTIFICATION");
const FETCH_NOTIFICATION_DONE = createActionName("FETCH_NOTIFICATION_DONE");
const FETCH_NOTIFICATION_FAILED = createActionName("FETCH_NOTIFICATION_FAILED");
const DELETE_NOTIFICATION = createActionName("DELETE_NOTIFICATION");
const DELETE_NOTIFICATION_DONE = createActionName("DELETE_NOTIFICATION_DONE");
const DELETE_NOTIFICATION_FAILED = createActionName("DELETE_NOTIFICATION_FAILED");

//reducer
function newNotification(
    state = { loading: false, data: [], error: null, newCount: 0 },
    action: DispatchedAction<APIBaseDataResponse>
) {
    // console.log(action);
    switch (action.type) {
        case READ_NEW_NOTIFICATION_DONE:
            //when user click view new notification - retrieve saved notification if exists
            let savedData = getDataFromSingleCache("newNotification");
            return { ...state, data: savedData != null ? savedData : [], newCount: 0 };
        case FETCH_NEW_NOTIFICATION:
            return { ...state, error: null, loading: true };
        case FETCH_NEW_NOTIFICATION_DONE:
            //cache used for cross-tab notification
            //new data comes, update the cache
            let oldData = getDataFromSingleCache("newNotification");
            if (oldData == null) oldData = [];

            let newData = [...oldData, ...action.payload.data];

            //refresh cache if new notification found
            if (action.payload.data.length > 0) saveIntoSingleCache("newNotification", newData, 43200); //12h

            return { ...state, data: newData, newCount: state.newCount + action.payload.data.length, loading: false };
        case FETCH_NEW_NOTIFICATION_FAILED:
            return { ...state, error: action.error, loading: false };
        default:
            return state;
    }
}

function notificationList(state = initialState, action: DispatchedAction<APIListResponse>) {
    switch (action.type) {
        case FETCH_NOTIFICATION_LIST:
            return { ...initialState, page: null, totalPage: null, loading: true };
        case FETCH_NOTIFICATION_LIST_DONE:
            return {
                ...state,
                data: action.payload.data.list,
                page: action.payload.data.paginator.page,
                totalPage: action.payload.data.paginator.totalPage,
                total: action.payload.data.paginator?.total,
                loading: false,
            };
        case FETCH_NOTIFICATION_LIST_FAILED:
            return { ...state, error: action.error, loading: false };
        default:
            return state;
    }
}

// function createNotification(state = initialState, action: DispatchedAction<APIBaseDataResponse>) {
//     switch (action.type) {
//         case CREATE_NOTIFICATION:
//             return { ...initialState, loading: true };
//         case CREATE_NOTIFICATION_DONE:
//             return { ...state, data: action.payload.data, loading: false };
//         case CREATE_NOTIFICATION_FAILED:
//             return { ...state, error: action.error, loading: false };
//         default:
//             return state;
//     }
// }

function getActiveNotification(state = initialState, action: DispatchedAction<APIBaseDataResponse>) {
    switch (action.type) {
        case FETCH_NOTIFICATION:
            return { ...initialState, loading: true };
        case FETCH_NOTIFICATION_DONE:
            return { ...state, data: action.payload.data, loading: false };
        case FETCH_NOTIFICATION_FAILED:
            return { ...state, error: action.error, loading: false };
        default:
            return state;
    }
}

// function updateNotification(state = initialState, action: DispatchedAction<APIBaseDataResponse>) {
//     switch (action.type) {
//         case UPDATE_NOTIFICATION:
//             return { ...initialState, loading: true };
//         case UPDATE_NOTIFICATION_DONE:
//             return { ...state, data: action.payload.data, loading: false };
//         case UPDATE_NOTIFICATION_FAILED:
//             return { ...state, error: action.error, loading: false };
//         default:
//             return state;
//     }
// }

function deleteNotification(state = initialState, action: DispatchedAction<APIBaseDataResponse>) {
    switch (action.type) {
        case DELETE_NOTIFICATION:
            return { ...initialState, loading: true };
        case DELETE_NOTIFICATION_DONE:
            return { ...state, data: action.payload, loading: false };
        case DELETE_NOTIFICATION_FAILED:
            return { ...state, error: action.error, loading: false };
        default:
            return state;
    }
}

//register reducer
reducerRegistry.register("newNotification", newNotification);
reducerRegistry.register("notificationList", notificationList);
// reducerRegistry.register("createNotification", createNotification);
reducerRegistry.register("getActiveNotification", getActiveNotification);
// reducerRegistry.register("updateNotification", updateNotification);
reducerRegistry.register("deleteNotification", deleteNotification);

//selector
export const slcNewNotification = (state: any): CommonReduxState => state["newNotification"];
export const slcNotificationList = (state: any): CommonReduxState => state["notificationList"];
// export const slcCreateNotification = (state: any): CommonReduxState => state["createNotification"];
export const slcFetchActiveNotification = (state: any): CommonReduxState => state["getActiveNotification"];
// export const slcUpdateNotification = (state: any): CommonReduxState => state["updateNotification"];
export const slcDeleteNotification = (state: any): CommonReduxState => state["deleteNotification"];

//action creator
const urlPath = "notification";

/**
 * fetch current tenant notification
 */
export function actFetchNewNotification() {
    const request = reqGet(`${urlPath}/check`, null);

    return (dispatch: any) => {
        dispatch({
            type: FETCH_NEW_NOTIFICATION,
        });
        request
            .then((payload) => {
                dispatch({
                    type: FETCH_NEW_NOTIFICATION_DONE,
                    payload: payload.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: FETCH_NEW_NOTIFICATION_FAILED,
                    error,
                });
            });
    };
}

/**
 * Action for Master Notification pagination
 *
 * @param {*} param
 */
export function actFetchNotificationList(param: APIGetParameter) {
    param = setFetchWithPaginationParam(param);
    const request = reqGet(urlPath, param);

    return (dispatch: any) => {
        dispatch({
            type: FETCH_NOTIFICATION_LIST,
        });
        request
            .then((payload) => {
                dispatch({
                    type: FETCH_NOTIFICATION_LIST_DONE,
                    payload: payload.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: FETCH_NOTIFICATION_LIST_FAILED,
                    error,
                });
            });
    };
}

/**
 * Create new Notification
 *
 * @param Object valueList
 */
// export function actCreateNotification(valueList: any) {
//     let param = setCreateEditParam(valueList);

//     const request = reqPost(urlPath, param);

//     return (dispatch: any) => {
//         dispatch({
//             type: CREATE_NOTIFICATION,
//         });
//         request
//             .then((payload) => {
//                 //clear cache
//                 deleteCacheCollection(urlPath);
//                 dispatch({
//                     type: CREATE_NOTIFICATION_DONE,
//                     payload: payload.data,
//                 });
//             })
//             .catch((error) => {
//                 dispatch({
//                     type: CREATE_NOTIFICATION_FAILED,
//                     error,
//                 });
//             });
//     };
// }

/**
 * Edit notification data
 *
 * @param integer id
 * @param object valueList
 */
// export function actEditNotification(id: number, valueList: any) {
//     let param = setCreateEditParam(valueList);

//     const request = reqPut(`${urlPath}/${id}`, param);

//     return (dispatch: any) => {
//         dispatch({
//             type: UPDATE_NOTIFICATION,
//         });
//         request
//             .then((payload) => {
//                 //clear cache
//                 // deleteCacheCollection(urlPath);
//                 dispatch({
//                     type: UPDATE_NOTIFICATION_DONE,
//                     payload: payload.data,
//                 });
//             })
//             .catch((error) => {
//                 dispatch({
//                     type: UPDATE_NOTIFICATION_FAILED,
//                     error,
//                 });
//             });
//     };
// }

/**
 * Get a Notification data. Used mainly for view/edit
 *
 * @param integer id
 */
export function actFetchActiveNotification(id: number) {
    const request = reqGet(`${urlPath}/${id}`, null);

    return (dispatch: any) => {
        dispatch({
            type: FETCH_NOTIFICATION,
            addToLoading: true,
        });
        request
            .then((payload) => {
                dispatch({
                    type: FETCH_NOTIFICATION_DONE,
                    payload: payload.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: FETCH_NOTIFICATION_FAILED,
                    error,
                });
            });
    };
}

/**
 * Delete Notification from DB
 *
 * @param {number} id
 */
export function actDeleteNotification(id: number) {
    const request = reqDelete(`${urlPath}/${id}`);

    return (dispatch: any) => {
        dispatch({
            type: DELETE_NOTIFICATION,
        });
        request
            .then((response) => {
                //clear cache
                // deleteCacheCollection(urlPath);
                dispatch({
                    type: DELETE_NOTIFICATION_DONE,
                    payload: true,
                });
            })
            .catch((error) => {
                dispatch({
                    type: DELETE_NOTIFICATION_FAILED,
                    error,
                });
            });
    };
}

export function actReadNotification() {
    return {
        type: READ_NEW_NOTIFICATION_DONE,
    };
}

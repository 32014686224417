import React, { PureComponent } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Table } from "reactstrap";
import PrintFooter from "../../../common/component/PrintFooter";
import PrintHeader from "../../../common/component/PrintHeader";
import { showCurrency, showQty } from "../../../common/function/showValueHelper";

type POPrintBerkatProps = {
    data: any;
    companyInfo: any;
    showAmount: boolean;
    colSpan: number;
};

class POPrintBerkat extends PureComponent<WithTranslation & POPrintBerkatProps> {
    render() {
        const { companyInfo, data, showAmount, t } = this.props;
        if (data == null) return <p>No Data</p>;
        let columns = data.detailList.map((obj: any, index: number) => {
            return (
                <tr key={"detail-" + index}>
                    <td className="rowNo">{index + 1}</td>
                    <td>{obj.product.name}</td>
                    <td className="qty">
                        {obj.unit == null ? (
                            <table className="printQtyTable">
                                <tbody>
                                    <tr key={"detail-1-" + index}>
                                        <td>{showQty(obj.qty) + " "}</td>
                                        <td>{obj.product?.unit?.name}</td>
                                    </tr>
                                </tbody>
                            </table>
                        ) : (
                            <table className="printQtyTable">
                                <tbody>
                                    <tr key={"detail-1-" + index}>
                                        <td>{showQty(obj.qty) + " "}</td>
                                        <td>{obj.unit?.name}</td>
                                    </tr>
                                </tbody>
                            </table>
                        )}
                    </td>
                    <td className="price">{showCurrency(obj.price, 2)}</td>
                    <td className="price">{showCurrency(obj.disc1Amount, 2)}</td>
                    <td className="subTotal">{showCurrency(obj.subtotal, 2)}</td>
                </tr>
            );
        });

        let summaryRow = showAmount ? (
            <Table className="ra-summary-table">
                <tbody>
                    <tr>
                        <td colSpan={this.props.colSpan}>
                            <b>{t("common.calculatedPrice")}: </b> <br /> {data.say} Rupiah
                        </td>
                        <td className="disc" style={{ textAlign: "right" }}>
                            {t("common.discount")}
                        </td>
                        <td className="subTotal">{showCurrency(data.disc2AmountTotal)}</td>
                    </tr>
                    <tr>
                        <td rowSpan={5} colSpan={this.props.colSpan}>
                            {t("common.notes")}: {data.description}
                        </td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: "right" }}>{t("common.taxBase")}</td>
                        <td className="subTotal">{showCurrency(data.subtotal, 2)}</td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: "right" }}>{t("common.tax")}</td>
                        <td className="subTotal">{showCurrency(data.vat, 2)}</td>
                    </tr>
                    {data.modifierCoa != null && (
                        <tr>
                            <td style={{ textAlign: "right" }}>{data.modifierCoa.name}</td>
                            <td className="subTotal">{showCurrency(data.modifierAmount, 2)}</td>
                        </tr>
                    )}
                    <tr>
                        <td style={{ textAlign: "right" }}>{t("common.grandTotal")}</td>
                        <td className="subTotal">{showCurrency(data.grandTotal, 2)}</td>
                    </tr>
                </tbody>
            </Table>
        ) : (
            <Table className="ra-summary-table">
                <tbody>
                    <tr>
                        <td rowSpan={5} colSpan={this.props.colSpan}>
                            {t("common.notes")}: {data.description}
                        </td>
                    </tr>
                </tbody>
            </Table>
        );

        return (
            //@ts-ignore
            <Table className="ra-table-print">
                <PrintHeader
                    title={t("common.purchaseOrder")}
                    companyInfo={companyInfo}
                    supplier={data.supplier}
                    no={data.key}
                    dtCreated={data.dtCreated}
                />
                <tbody>
                    <tr>
                        <td colSpan={2}>
                            <Table className="ra-table-detail-print">
                                <thead>
                                    <tr>
                                        <th className="rowNo">#</th>
                                        <th>{t("common.product")}</th>
                                        <th className="qty">{t("common.qty")}</th>
                                        {showAmount && (
                                            <>
                                                {this.props.colSpan === 5 ? (
                                                    <th className="price">{t("common.price@")}</th>
                                                ) : (
                                                    ""
                                                )}
                                                <th className="price">{t("common.price")}</th>
                                                <th className="disc">{t("common.discount")}</th>
                                                <th className="subTotal">{t("common.subTotal")}</th>
                                            </>
                                        )}
                                    </tr>
                                </thead>
                                <tbody>{columns}</tbody>
                            </Table>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>{summaryRow}</td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            <PrintFooter footerType="purchaseOrder" status={data.status} />
                        </td>
                    </tr>
                </tbody>
            </Table>
        );
    }
}

export default withTranslation()(POPrintBerkat);

/**
 * Save token and access data.
 */
export function saveAuthResponseData(response: APILoginResponse) {
    //APIListResponse is dummy
    if (!response.data.token || !response.data.access) return;

    localStorage.setItem("hpT", response.data.token);

    //save indifinitely
    const jsonAccess = JSON.stringify(response.data.access);

    localStorage.setItem("access", jsonAccess);
}

/**
 * Get saved token data
 */
export function getUserToken() {
    return localStorage.getItem("hpT");
}

/**
 * Get saved access data
 */
export function getUserAccess() {
    const json = localStorage.getItem("access");
    if (json == null) return null;

    try {
        const access = JSON.parse(json);
        return access;
    } catch (err) {
        return null;
    }
}

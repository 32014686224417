import React, { PureComponent } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Table } from "reactstrap";
import HTMLPrintHeader from "../../../common/component/HTMLPrintHeader";
import PrintArea from "../../../common/component/PrintArea";
import { showCurrency } from "../../../common/function/showValueHelper";

type CustomerDepositSummaryPrintState = {
    data: any;
    param: any;
    // date: any;
};

class CustomerDepositSummaryPrint extends PureComponent<WithTranslation, CustomerDepositSummaryPrintState> {
    constructor(props: any) {
        super(props);

        //load data from localStorage
        const stringData = localStorage.getItem("customer-deposit-summary-print");
        const jsonData = stringData != null ? JSON.parse(stringData) : null;

        // remove data from localStorage
        localStorage.removeItem("customer-deposit-summary-print");

        let stateObj = {
            data: jsonData?.data ?? null,
            param: jsonData?.param ?? {},
            // date
        }

        this.state = stateObj;
    }

    render() {
        const { t } = this.props;
        const { param, data } = this.state;
        if (data == null) return <p>No Data</p>;

        let columns = data.map((obj: any, index: number) => {
            return (
                <tr key={"detail-" + index}>
                    <td className="rowNo">{index + 1}</td>
                    <td>{obj.customer.key} - {obj.customer.name}</td>
                    <td style={{ textAlign: "right" }}>{showCurrency(obj.startBalance, 2)}</td>
                    <td style={{ textAlign: "right" }}>{showCurrency(obj.amountIn, 2)}</td>
                    <td style={{ textAlign: "right" }}>{showCurrency(obj.amountOut, 2)}</td>
                    <td style={{ textAlign: "right" }}>{showCurrency(obj.endBalance, 2)}</td>
                </tr>
            );
        });

        return (
            <PrintArea>
                <Table className="ra-table-print">
                    <HTMLPrintHeader
                        param={param}
                    />
                    <tbody>
                        <tr>
                            <td colSpan={3}>
                                <Table className="ra-table-detail-print">
                                    <thead>
                                        <tr>
                                            <th className="rowNo">#</th>
                                            <th>{t("common.customer")}</th>
                                            <th className="price">{t("common.startingBalance")}</th>
                                            <th className="price">{t("common.amountIn")}</th>
                                            <th className="price">{t("common.amountOut")}</th>
                                            <th className="price">{t("common.endingBalance")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {columns}
                                    </tbody>
                                </Table>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </PrintArea>
        );
    }
}

export default withTranslation()(CustomerDepositSummaryPrint);

import changeCase from "change-case";
import dayjs from "dayjs";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { ControlledStateOverrideProps } from "react-table";
import { formValueSelector } from "redux-form";
import { getDataFromCachedCollection, saveIntoCacheCollection } from "../../function/cache";
import PanelSearchFilter from "../PanelSearchFilter";
import ServerSideTable from "../ServerSideTable";
import SimpleBox from "../SimpleBox";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    slcSettingExpandSubrow,
    slcSettingShowUpdateDateInTable,
    slcSettingDefaultFilterByDate,
    slcSettingShowDeletedData,
} from "../../../Setting/redux/setting";
import { showDate } from "../../function/showValueHelper";
import { withTranslation } from "react-i18next";

type ListTableWithSearchProps = {
    title: string;
    formName: string;
    searchFieldList: SearchFieldProps[];
    // fetchData:(state:any, instance:any)=>(void);
    fetchDataAction: Function;
    reduxListState: CommonReduxState;
    reduxDeleteState?: CommonReduxState;
    reduxEditState?: CommonReduxState;
    monitorReduxState?: CommonReduxState;
    refetchOnReduxChange?: CommonReduxState[];
    // children?:any;
    sideLink?: LinkDefinition | LinkDefinition[];
    columnDefinition: any;
    moduleName: string | string[];
    externalField?: string[]; //fields that need to be joined into body, not q & key
    dateRangeField?: string[]; //fields that separated into 2 input range (xStart, xEnd), and need
    monthPickerField?: string[]; //fields that returns YYYY/MM and require modification into two YYYY/MM/DD values
    multiSelectField?: string[]; //fields that joins data into string,string and using "IN" for search
    mapLikeField?: string[]; //fields that joins data into string,string and using "mapLike" for search (e.g. tag)
    equalField?: string[]; //fields that don't use `id`, but need `=` for the query
    subComponent?: any; //reactTable's subComponent
    sideComponent?: React.ReactElement; //component to be placed before beside button
    addOnComponent?: React.ReactElement; //component to be placed between panel and table
    disableClearSearch?: boolean;
    showPagination?: boolean;
    enableShowUpdateDate?: boolean; //enable/disable optional updateDate in listTable
    //sometimes parent need to know what the filter param is -> e.g. cash-mutation to fetch filtered balance
    filterParamListener?: (filtered: any[], externalParam: any, operatorList: any[], mode: string) => void;
    disableMode?: boolean;
    formValues?: any;
    defaultOrder?: { id: string; desc?: boolean }[]; //ReactTable v6 defaultSorted
    //props from setting
    settingExpandSubrow?: boolean;
    settingShowUpdateDateInTable?: boolean;
    settingDefaultFilterByDate?: number;
    settingShowDeletedData?: boolean;
    allowSoftDeleted?: boolean; //allow soft-deleted data if setting true
    t?: any;
    customListColumn?: string[];
    className?: string;
};

type ListTableWithSearchState = {
    filtered: any[];
    externalParam: any;
    operatorList: any[];
    mode: string;
    key: number; //used to force rerender
    lastVisitData: any; //save page, pagesize, and sort. so we can return to the same page
    totalPage: number;
    columnDefinition: any;
    processedSearchFieldList: SearchFieldProps[];
};

/**
 * Table containing list of data, with search ability.
 *
 * Used as the listTable-page of master & register modules
 *
 * To move searchField outside q/searchKey/operator, use `externalField={["fieldName"]}`
 */
class ListTableWithSearch extends PureComponent<ListTableWithSearchProps, ListTableWithSearchState> {
    table: any;
    constructor(props: any) {
        super(props);

        //ability to load last visited page
        const lastVisitData = getDataFromCachedCollection("list-table", { key: this.props.formName });

        //check for active filter
        let previousFilter = lastVisitData?.filter;

        //set states depending on initialValue of fields if available
        let processedSearchFieldList = this.props.searchFieldList;
        let initialValues: any = {};
        for (let i = 0; i < this.props.searchFieldList.length; i++) {
            const val = this.props.searchFieldList[i];

            //check default filter
            let settingDefaultFilterByDate = props.settingDefaultFilterByDate;

            //known bug -> after first setting fetch, this code will not work, need to be refreshed 1 time
            if (settingDefaultFilterByDate != null && settingDefaultFilterByDate > 0 && val.name === "documentDate") {
                let substract = settingDefaultFilterByDate - 1; //because show-today-only is sub(0) not 1
                let now = dayjs();
                // for inputfield?
                initialValues[val.name] = `${now.subtract(substract, "day").format("DD/MM/YYYY")}-${now.format(
                    "DD/MM/YYYY"
                )}`;

                // for doSearch?
                initialValues[`${val.name}@start`] = `${now.subtract(substract, "day").format("YYYY/MM/DD")}`;
                initialValues[`${val.name}@end`] = `${now.format("YYYY/MM/DD")}`;
                //send to field's initialValue
                processedSearchFieldList[i].initialValue = initialValues[val.name];
            } else if (val.initialValue != null) {
                initialValues[val.name] = val.initialValue;
            } else if (previousFilter != null && previousFilter[val.name] != null) {
                initialValues[val.name] = previousFilter[val.name];
                //send to field's initialValue
                processedSearchFieldList[i].initialValue = previousFilter[val.name]; //not working because of overriden by cDU
            }
        }

        let { filtered, externalParam, operatorList, mode } = this.doSearch(initialValues, true);

        //if redux-form values exists, replace initial doSearch
        //NOTES! Only affect redux-store values (tab-specific), not using localStorage
        if (this.props.formValues != null && Object.keys(this.props.formValues).length > 0) {
            let searchQuery = this.doSearch(this.props.formValues, true);

            filtered = searchQuery.filtered;
            externalParam = searchQuery.externalParam;
            operatorList = searchQuery.operatorList;
            mode = searchQuery.mode;
        }

        //customize list column using setting
        let columnDefinition = props.columnDefinition;
        if (props.customListColumn?.length > 0) {
            let newColumnDefinition: any[] = [];

            for (let column of props.customListColumn) {
                //search
                let exist = props.columnDefinition.find(
                    (val: any) => val.key === column || val.id === column || val.accessor === column
                );

                if (exist) {
                    newColumnDefinition.push(exist);
                }
            }

            //replace
            columnDefinition = newColumnDefinition;
        }

        //check show/hide updateDate
        if (props.enableShowUpdateDate === true && props.settingShowUpdateDateInTable === true) {
            columnDefinition.splice(columnDefinition.length - 1, 0, {
                accessor: (row: any) => showDate(row.updateDate, "DD/MM/YYYY HH:mm:ss"),
                id: "updateDate",
                Header: props.t("common.updateDate"),
            });
        }

        this.state = {
            filtered,
            externalParam,
            operatorList,
            mode,
            key: 0,
            lastVisitData: lastVisitData ? lastVisitData : {}, //rt page starts from 0
            totalPage: 1,
            columnDefinition,
            processedSearchFieldList,
        };
    }

    componentDidMount() {
        window.addEventListener("beforeunload", this.saveCache);
    }

    componentWillUnmount() {
        this.saveCache();
        window.removeEventListener("beforeunload", this.saveCache);
    }

    /**
     * Save several pagination config to cache. So when user return to this list-table, previous config still persist
     */
    saveCache = () => {
        saveIntoCacheCollection("list-table", { key: this.props.formName }, this.state.lastVisitData, 3600); //only save for 1 hour
    };

    componentDidUpdate(prevProps: any) {
        if (
            (this.props.reduxDeleteState !== prevProps.reduxDeleteState &&
                this.props.reduxDeleteState &&
                this.props.reduxDeleteState.data != null) ||
            (this.props.reduxEditState !== prevProps.reduxEditState &&
                this.props.reduxEditState &&
                this.props.reduxEditState.data != null) ||
            (this.props.monitorReduxState !== prevProps.monitorReduxState &&
                this.props.monitorReduxState &&
                this.props.monitorReduxState.data != null)
        ) {
            //force table rerender
            // this.setState({ key: this.state.key > 100 ? 0 : this.state.key + 1 });
            this.table.fireFetchData();
        }

        if (this.props.refetchOnReduxChange != null && this.props.refetchOnReduxChange.length > 0) {
            let anyChanged = false;
            for (let i = 0; i < this.props.refetchOnReduxChange.length; i++) {
                let cur = this.props.refetchOnReduxChange[i];
                let prev = prevProps.refetchOnReduxChange[i];
                if (cur !== prev && cur != null && cur.data != null) {
                    anyChanged = true;
                    break;
                }
            }
            if (anyChanged) {
                this.table.fireFetchData();
            }
        }

        //avoid totalpage 'blinking'
        if (this.props.reduxListState.data && this.props.reduxListState !== prevProps.reduxListState) {
            this.setState({ totalPage: this.props.reduxListState.totalPage });
        }

        //change fieldList on change
        if (this.props.searchFieldList != null && this.props.searchFieldList !== prevProps.searchFieldList) {
            //check for active filter
            let previousFilter = this.state.lastVisitData.filter;

            //set states depending on initialValue of fields if available
            let processedSearchFieldList = this.props.searchFieldList;
            let initialValues: any = {};
            for (let i = 0; i < this.props.searchFieldList.length; i++) {
                const val = this.props.searchFieldList[i];

                //check default filter
                let settingDefaultFilterByDate = this.props.settingDefaultFilterByDate;

                //known bug -> after first setting fetch, this code will not work, need to be refreshed 1 time
                if (
                    settingDefaultFilterByDate != null &&
                    settingDefaultFilterByDate > 0 &&
                    val.name === "documentDate"
                ) {
                    //send to field's initialValue
                    processedSearchFieldList[i].initialValue = initialValues[val.name];
                } else if (previousFilter != null && previousFilter[val.name] != null) {
                    initialValues[val.name] = previousFilter[val.name];
                    //send to field's initialValue
                    processedSearchFieldList[i].initialValue = previousFilter[val.name];
                }
            }

            this.setState({ processedSearchFieldList });
        }
    }

    doRefresh = () => {
        this.table.fireFetchData();
    };

    /**To avoid redux-thunk caught by doSearch */
    doSearchWrapper = (values: any) => {
        this.doSearch(values, false);
    };

    /**
     * Process to allow `alias$column` as indexing key
     *
     * Will be changed to `alias.column` in backend
     */
    processKey = (value: string) => {
        let output = value;
        if (value.includes("$")) {
            output = "";
            let split = value.split("$");
            for (let i = 0; i < split.length; i++) {
                let data = split[i];
                output += changeCase.camel(data);

                if (i < split.length - 1) {
                    output += "$";
                }
            }
        }

        return output;
    };

    /**
     * Using redux-form submitted values to set state which will be used by react-table fetch-data function
     */
    doSearch = (values: any, returnAsObject: boolean = false) => {
        let tmpOperators = [];
        let tmpFiltered = [];
        let tmpExternal: any = {};

        this.setState({
            lastVisitData: { ...(this.state?.lastVisitData ?? {}), filter: values },
        });

        let usedKey: string[] = [];
        for (let key in values) {
            if (key === "mode") continue;

            if (values[key] != null) {
                if (this.props.dateRangeField != null) {
                    if (key.indexOf("@end") > -1) {
                        //if it's dateend, check the existance of datestart
                        const accompanyingStart = key.slice(0, key.indexOf("@"));

                        if (`${accompanyingStart}@start` in values) continue; //if start exists, skip dateRange-End fields
                    }

                    let indexAt = key.lastIndexOf("@");
                    const trimmedKey = indexAt > -1 ? key.slice(0, indexAt) : key; //documentDate@start into documentDate
                    const dateRangeIndex = this.props.dateRangeField.indexOf(trimmedKey);

                    //avoid duplicated q
                    if (usedKey.includes(trimmedKey)) continue;
                    usedKey.push(trimmedKey);

                    if (dateRangeIndex > -1) {
                        let dateStart: any;
                        let dateEnd: any;
                        //dateRange-Start. need 'between' not 'like'
                        dateStart = values[trimmedKey + "@start"]; //default to 1900/01/01
                        dateEnd = values[trimmedKey + "@end"]; //default to today

                        if (!this.props.externalField?.includes(trimmedKey)) {
                            tmpFiltered.push({
                                id: trimmedKey,
                                value: `${dateStart ? dateStart : "1900/01/01"}-${
                                    dateEnd ? dateEnd : dayjs().format("YYYY/MM/DD")
                                } 23:59:59`,
                            });
                            tmpOperators.push("between");
                        } else {
                            //if used as external
                            tmpExternal[trimmedKey] = `${dateStart ? dateStart : "1900/01/01"}-${
                                dateEnd ? dateEnd : dayjs().format("YYYY/MM/DD")
                            } 23:59:59`;
                        }
                        continue;
                    }
                }

                if (this.props.monthPickerField != null && this.props.monthPickerField.indexOf(key) > -1) {
                    //month, so need to be modified
                    let month = dayjs(values[key], { format: "YYYY/MM" });

                    let dateStart = month.date(1).format("YYYY/MM/DD");
                    let dateEnd = month.endOf("month").format("YYYY/MM/DD");

                    tmpFiltered.push({
                        id: this.processKey(key),
                        value: `${dateStart}-${dateEnd} 23:59:59`,
                    });
                    tmpOperators.push("between");
                    continue;
                }

                if (this.props.externalField != null && this.props.externalField.indexOf(key) > -1) {
                    //add to external param instead of q & searchKey
                    tmpExternal[key] = values[key];
                    continue;
                }

                if (this.props.multiSelectField != null && this.props.multiSelectField.indexOf(key) > -1) {
                    let joined = values[key].join(",");

                    tmpFiltered.push({
                        id: this.processKey(key),
                        value: joined,
                    });
                    tmpOperators.push("in");
                    continue;
                }

                if (this.props.mapLikeField != null && this.props.mapLikeField.indexOf(key) > -1) {
                    let joined = values[key].join(",");

                    tmpFiltered.push({
                        id: this.processKey(key),
                        value: joined,
                    });
                    tmpOperators.push("mapLike");
                    continue;
                }

                tmpFiltered.push({
                    id: this.processKey(key),
                    value: values[key],
                });
                if (key.toLowerCase().indexOf("id") > -1 || this.props.equalField?.includes(key))
                    tmpOperators.push("=");
                //any id matching use = not like
                else tmpOperators.push("like");
            }
        }

        if (this.props.filterParamListener != null) {
            this.props.filterParamListener(tmpFiltered, tmpExternal, tmpOperators, values.mode);
        }

        if (returnAsObject === false) {
            this.setState({
                filtered: tmpFiltered,
                externalParam: tmpExternal,
                operatorList: tmpOperators,
                mode: values.mode,
            });

            return {
                filtered: [],
                externalParam: [],
                operatorList: [],
                mode: "and",
            };
        } else {
            return {
                filtered: tmpFiltered,
                externalParam: tmpExternal,
                operatorList: tmpOperators,
                mode: values.mode,
            };
        }
    };

    /**
     * Clear this component's state
     */
    clearSearch = () => {
        this.setState({
            filtered: [],
            operatorList: [],
            mode: "and",
            lastVisitData: { ...this.state.lastVisitData, filter: {} },
        });
        this.saveCache();
        if (this.props.filterParamListener != null) {
            this.props.filterParamListener([], {}, [], "and");
        }
    };

    /**
     * This function will be called by react-table.
     *
     * Build API Get parameter using default/submitted filter. The parameter will be passed to an actionCreator to call GET request to backend.
     *
     * Notes 20200331: Not sure why, but react-table state retains filtered status even if we view a data. That data is not saved in lastVisitData
     */
    fetchData = (state: ControlledStateOverrideProps, instance: any) => {
        // we don't have redux for these data, so we use localStorage to help
        this.setState({
            lastVisitData: {
                ...this.state.lastVisitData,
                page: state.page,
                pageSize: state.pageSize,
                sorted: state.sorted,
            },
        });
        // Call action creator
        let inputs: APIGetParameter = {
            page: state.page,
            size: state.pageSize,
            ...this.state.externalParam, //any special value that need separate param (not q & searchKey)
            withDeleted: this.props.settingShowDeletedData && this.props.allowSoftDeleted,
        };

        if (state.sorted != null && state.sorted[0] != null) {
            inputs.orderBy = state.sorted[0].id;
            inputs.orderDir = state.sorted[0].desc === false ? "ASC" : "DESC";
        }
        if (state.filtered != null) {
            let valueArray: string[] = [];
            let keyArray: string[] = [];
            state.filtered.map((obj, key) => {
                if (obj.value !== "all") {
                    valueArray.push(obj.value);
                    keyArray.push(this.processKey(obj.id));
                }
                return true;
            });
            inputs.q = valueArray;
            inputs.searchKey = keyArray;
        }

        if (this.state.operatorList != null) {
            inputs.operator = this.state.operatorList;
        }
        inputs.mode = this.state.mode;
        this.props.fetchDataAction(inputs);
    };

    render() {
        const {
            title,
            sideLink,
            formName,
            // searchFieldList,
            reduxListState,
            // columnDefinition,
            moduleName,
            subComponent,
            addOnComponent,
            disableClearSearch,
            showPagination = true,
            disableMode,
            settingExpandSubrow,
            sideComponent,
        } = this.props;
        const { columnDefinition, processedSearchFieldList } = this.state;

        return (
            <SimpleBox
                title={title}
                sideLink={sideLink}
                sideComponent={
                    <>
                        {sideComponent ?? <span />}
                        <Button color="primary" style={{ marginLeft: "8px" }} onClick={this.doRefresh}>
                            <FontAwesomeIcon icon="redo" />
                        </Button>
                    </>
                }
                moduleName={moduleName}
            >
                {processedSearchFieldList.length > 0 && (
                    <PanelSearchFilter
                        formName={formName}
                        doSearch={this.doSearchWrapper}
                        clearSearch={this.clearSearch}
                        fieldList={processedSearchFieldList}
                        filterOn={this.state.filtered.length > 0}
                        disableClearSearch={disableClearSearch}
                        disableMode={disableMode}
                        sideText={reduxListState?.total}
                    />
                )}
                {addOnComponent}
                <ServerSideTable
                    // key={this.state.key}
                    passedRef={(instance: any) => (this.table = instance)}
                    columns={columnDefinition}
                    data={reduxListState.data}
                    loading={reduxListState.loading}
                    totalPage={this.state.totalPage}
                    fetchData={this.fetchData}
                    filtered={this.state.filtered}
                    subComponent={subComponent}
                    overridePage={this.state.lastVisitData?.page}
                    showPagination={showPagination}
                    defaultSorted={
                        this.state.lastVisitData?.sorted?.length > 0
                            ? this.state.lastVisitData?.sorted
                            : this.props.defaultOrder
                    }
                    //if there's no pagination, pagesize is data length
                    pageSize={
                        this.state.lastVisitData
                            ? this.state.lastVisitData.pageSize
                            : !showPagination && reduxListState.data
                            ? reduxListState.data.length
                            : undefined
                    }
                    expandAllSubrow={settingExpandSubrow}
                    className={this.props.className ?? ""}
                />
            </SimpleBox>
        );
    }
}

function mapStateToProps(state: any, ownProps: ListTableWithSearchProps) {
    let fieldName: string[] = [];
    for (let i = 0; i < ownProps.searchFieldList.length; i++) {
        const val = ownProps.searchFieldList[i];
        fieldName.push(val.name);
    }

    const selector = formValueSelector(ownProps.formName);

    const settingExpandSubrow = slcSettingExpandSubrow(state);
    const settingShowUpdateDateInTable = slcSettingShowUpdateDateInTable(state);
    const settingDefaultFilterByDate = slcSettingDefaultFilterByDate(state);
    const settingShowDeletedData = slcSettingShowDeletedData(state);

    return fieldName.length > 0
        ? {
              formValues: selector(state, ...fieldName),
              settingExpandSubrow,
              settingShowUpdateDateInTable,
              settingDefaultFilterByDate,
              settingShowDeletedData,
          }
        : {};
}

// export default ListTableWithSearch;

export default connect(
    mapStateToProps,
    null
    //@ts-ignore
)(withTranslation()(ListTableWithSearch));

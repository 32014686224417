import React, { PureComponent } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import {
    actInitConfig,
    slcSettingFeaturePrintFontType_Value,
    slcSettingFeaturePrintPaperType_Value,
} from "../../../Setting/redux/setting";

/**
 * Component used to render html/pdf inside #print-area with button to trigger to browser's print.
 *
 * Intended to be used in new page not modal.
 */
// const PrintArea = React.memo((props: any) => {
//     const { t } = useTranslation();
//     return (
//         <div>
//             <div style={{ padding: "20px", background:"#eeeeee" }} id="print-menu">
//                 <Button color="primary" onClick={() => window.print()}>
//                     Print
//                 </Button>

//                 <label style={{ marginLeft: "20px" }}>{t("common.printResultMayDiffer")}</label>
//             </div>
//             <div id="print-area">{props.children}</div>
//         </div>
//     );
// });

/**
 * Component used to render html/pdf inside #print-area with button to trigger to browser's print.
 *
 * Intended to be used in new page not modal.
 *
 * - Load common print-settings data here instead of parent
 * - Module-specific settings need to be loaded by parent (e.g. sales' print template)
 */
class PrintArea extends PureComponent<any, any> {
    componentDidMount() {
        //load setting
        this.props.actInitConfig();
    }

    render() {
        const { t, children, pageSize, fontFamily, customComponent } = this.props;
        return (
            <div>
                <style>
                    {`@media only print {
                        @page {
                            size: ${pageSize};
                        }

                        html, body, p, th, td, * {
                            font-family: ${fontFamily} !important;
                        }
                    }`}
                </style>
                <div style={{ padding: "20px", background: "#eeeeee" }} id="print-menu">
                    <Button color="primary" onClick={() => window.print()}>
                        Print
                    </Button>
                    <span style={{ marginLeft: "20px" }}>{customComponent}</span>
                    <label style={{ marginLeft: "20px" }}>{t("common.printResultMayDiffer")}</label>
                </div>
                <div id="print-area">{children}</div>
            </div>
        );
    }
}

function mapStateToProps(state: any) {
    return {
        pageSize: slcSettingFeaturePrintPaperType_Value(state),
        fontFamily: slcSettingFeaturePrintFontType_Value(state),
        // printTemplate: slcSettingFeaturePrintTemplate_Value(state),
    };
}

export default connect<any>(
    mapStateToProps,
    { actInitConfig }
)(withTranslation()(PrintArea));

import dayjs from "dayjs";
import { reqAdminPost, reqAdminGet } from "../../common/function/adminRequest";
import { clearAllCache } from "../../common/function/cache";
import jwtTokenReader from "../../common/function/jwtTokenReader";
import { getUserAccess, getUserToken, saveAuthResponseData } from "../../common/function/userAuthSession";
import reducerRegistry from "../../common/reduxHandler/reducerRegistry";
import { reqGet } from "../../common/function/request";


//initial state
const initialState: CommonReduxState = {
    loading: false,
    token: null,
    data: null,
    error: null,
    access: null,
    isLoggedIn: false,
    name: null,
};

//action
const createActionName = (name: string) => `login/${name}`;
const USER_LOGIN = createActionName("USER_LOGIN");
const USER_LOGIN_DONE = createActionName("USER_LOGIN_DONE");
const USER_LOGIN_FROM_VALIDATION_DONE = createActionName("USER_LOGIN_FROM_VALIDATION_DONE");
const USER_LOGIN_FAILED = createActionName("USER_LOGIN_FAILED");
const SET_REDIRECT_URL = createActionName("SET_REDIRECT_URL");
const CHANGE_TENANT = createActionName("CHANGE_TENANT");
const CHANGE_TENANT_DONE = createActionName("CHANGE_TENANT_DONE");
const CHANGE_TENANT_FAILED = createActionName("CHANGE_TENANT_FAILED");

//reducer
function userLogin(state = initialState, action: DispatchedAction<APILoginResponse>) {
    switch (action.type) {
        case USER_LOGIN:
            return { ...initialState, loading: true  };
        case USER_LOGIN_DONE:
            //save token and access
            saveAuthResponseData(action.payload);
            let newState= {
                ...state,
                loading: false,
                token: action.payload.data.token,
                isLoggedIn: true,
                access: action.payload.data.access,
                name: action.payload.data.name,
                languageCode: action.payload.data.languageCode,

                uid: jwtTokenReader()?.uid,
                tid: jwtTokenReader()?.tid,
            };
            return newState;
        case USER_LOGIN_FROM_VALIDATION_DONE:
            return {
                ...state,
                loading: false,
                token: action.payload.data.token,
                isLoggedIn: true,
                access: action.payload.data.access,
                name: "",
                
                uid: jwtTokenReader()?.uid,
                tid: jwtTokenReader()?.tid,
            };
        case USER_LOGIN_FAILED:
            return { ...state, loading: false, error: action.error, isLoggedIn: false, access: null };
        default:
            return state;
    }
}

function reAuthNeeded(state = false, action: any) {
    if (
        action.error != null &&
        action.error.response &&
        action.error.response.data &&
        action.error.response.data.data &&
        action.error.response.data.data.reAuth === true
    ) {
        return true;
    }

    return false;
}

function loginRedirectURL(state = "", action: any) {
    if (action.type === SET_REDIRECT_URL) return action.payload;

    return state;
}

function changeTenant(state = initialState, action: DispatchedAction<APILoginResponse>) {
    switch (action.type) {
        case CHANGE_TENANT:
            return { ...initialState, loading: true  };
        case CHANGE_TENANT_DONE:
            //save token and access
            saveAuthResponseData(action.payload);
            let newState= {
                ...state,
                loading: false,
                token: action.payload.data.token,
                isLoggedIn: true,
                access: action.payload.data.access,
                name: action.payload.data.name,
                languageCode: action.payload.data.languageCode,

                uid: jwtTokenReader()?.uid,
                tid: jwtTokenReader()?.tid,
            };
            return newState;
        case CHANGE_TENANT_FAILED:
            return { ...state, loading: false, error: action.error };
        default:
            return state;
    }
}

//register reducer
reducerRegistry.register("userLogin", userLogin);
reducerRegistry.register("reAuthNeeded", reAuthNeeded);
reducerRegistry.register("loginRedirectURL", loginRedirectURL);
reducerRegistry.register("changeTenant", changeTenant);

//selector
export const slcUserLogin = (state: any): CommonReduxState => state["userLogin"];
export const slcReAuthNeeded = (state: any): CommonReduxState => state["reAuthNeeded"];
export const slcLoginRedirectURL = (state: any): string => state["loginRedirectURL"];
export const slcChangeTenant = (state: any): CommonReduxState => state["changeTenant"];

//action creator

/**
 * Function used for login authentication with backend
 * Returns token to be saved in localStorage
 *
 * @param {*} username
 * @param {*} password
 */
export function actUserLogin(username: string, password: string, recaptcha:string) {
    const request = reqAdminPost(`sign-in`, { username, password, recaptcha });

    return async (dispatch: any) => {
        dispatch({
            type: USER_LOGIN,
        });

        try {
            let payload = await request;

            const accessPayload = await reqGet(`access`, {}, {
                headers: {
                    Authorization: `Bearer ${payload.data.data.token}`
                },
            });
            const accessPayloadAdmin = await reqAdminGet(`access`, {}, {
                headers: {
                    Authorization: `Bearer ${payload.data.data.token}`
                },
            });
            dispatch({
                type: USER_LOGIN_DONE,
                payload:{meta:payload.data.meta, data:{...payload.data.data, access:{...accessPayload.data.data, ...accessPayloadAdmin.data.data}}},
            });
        } catch (error){
            dispatch({
                type: USER_LOGIN_FAILED,
                error,
            });
        }
    };
}

/**
 * If the token still exists, assume that user is logged-in (Expiry will be told by server when user send any request)
 */
export function actValidateUserLogin() {
    return (dispatch: any) => {
        const token = getUserToken();
        const access = getUserAccess();
        if (token != null && access != null) {
            const tokenData = jwtTokenReader();
            
            //jwt's timestamp is sec not ms
            const expiryTime = dayjs((tokenData?.exp??0) *1000);
            // console.log(tokenData, dayjs(tokenData.exp*1000).format("DD/MM/YYYY HH:mm:ss"));

            if (expiryTime.isBefore(dayjs())){ //ask for relog
                dispatch({
                    type: USER_LOGIN_FAILED,
                    payload: {
                        data: {
                            reAuth:true
                        },
                    },
                });
            } else {
                dispatch({
                    type: USER_LOGIN_FROM_VALIDATION_DONE,
                    payload: {
                        data: {
                            token,
                            access,
                        },
                    },
                });
            }
        }
    };
}

export function actResetUserLogin(skipDispatch=false) {
    //clear important Cache (keep language as it's important)
    localStorage.removeItem("access");
    localStorage.removeItem("hpT");
    localStorage.removeItem("newNotification");
    //clear cache
    clearAllCache();

    //clear all cookie
    document.cookie.split(";").forEach(function(c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });

    if (!skipDispatch){
        return (dispatch: any) => {
            dispatch({
                type: USER_LOGIN_FAILED,
            });
        };
    }
}

export function actSetRedirectURL(url: string) {
    return {
        type: SET_REDIRECT_URL,
        payload: url,
    };
}


/**
 * Change current-admin's tenant
 */
 export function actChangeTenant(customTenantUUID:string) {
    const request = reqAdminPost(`change-tenant`, { customTenantUUID });

    return async (dispatch: any) => {
        dispatch({
            type: CHANGE_TENANT,
        });

        try {
            let payload = await request;

            //success, clear some cache
            localStorage.removeItem("newNotification");
            //clear cache
            clearAllCache();

            const accessPayload = await reqGet(`access`, {}, {
                headers: {
                    Authorization: `Bearer ${payload.data.data.token}`
                },
            });
            const accessPayloadAdmin = await reqAdminGet(`access`, {}, {
                headers: {
                    Authorization: `Bearer ${payload.data.data.token}`
                },
            });
            dispatch({
                type: CHANGE_TENANT_DONE,
                payload:{meta:payload.data.meta, data:{...payload.data.data, access:{...accessPayload.data.data, ...accessPayloadAdmin.data.data}}},
            });

            //force refresh
            setTimeout(() => {
                window.location.reload();    
            });
        } catch (error){
            dispatch({
                type: CHANGE_TENANT_FAILED,
                error,
            });
        }
    };
}
import financePosition from "./financePosition";
import inventoryCardAccounting from "./inventoryCardAccounting";
import inventoryCardLimited from "./inventoryCardLimited";
import inventoryMutationAccounting from "./inventoryMutationAccounting";
import inventoryMutationLimited from "./inventoryMutationLimited";
import ledger from "./ledger";
import payableCard from "./payableCard";
import profitDeficit from "./profitDeficit";
import purchaseSummary from "./purchaseSummary";
import receivableCard from "./receivableCard";
import salesGrossProfit from "./salesGrossProfit";

let SalesGrossProfit = salesGrossProfit;
let ReceivableCard = receivableCard;
let InventoryCardAcc = inventoryCardAccounting;
let PayableCard = payableCard;
let PurchaseSummary = purchaseSummary;
let InventoryCardLimited = inventoryCardLimited;
let ProfitDeficit = profitDeficit;
let FinancePosition = financePosition;
let InventoryMutationLimited = inventoryMutationLimited;
let InventoryMutationAcc = inventoryMutationAccounting;
let Ledger = ledger;

export {
    SalesGrossProfit,
    ReceivableCard,
    InventoryCardAcc,
    PayableCard,
    PurchaseSummary,
    InventoryCardLimited,
    ProfitDeficit,
    FinancePosition,
    InventoryMutationLimited,
    InventoryMutationAcc,
    Ledger,
};

import React, { PureComponent, Suspense } from "react";
import { connect } from "react-redux";
import { Route, Switch, Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";
import cloneDeep from "clone-deep";
import changeCase from "change-case";

import MainNavbar from "../component/MainNavbar";
import Sidebar from "../component/Sidebar";
import GlobalAPINotification from "../component/GlobalAPINotification";
import { boolAuthChecker } from "../../common/component/AuthCheck";
import MyAccountPage from "./MyAccountPage";
import NotificationPage from "./NotificationPage";

import { slcUserLogin, actSetRedirectURL } from "../redux/login";
import {
    actInitConfig,
    slcSettingDeliveryOrderDraft,
    slcSettingInventoryAdjustmentDraft,
    slcSettingUnfulfilledSalesOrder,
} from "../../Setting/redux/setting";
import { actFetchMyAccount, slcFetchMyAccount } from "../redux/myAccount";
import Modules from "../../moduleConfig";
import { trackPage } from "../../common/function/googleAnalytics";
import { actFetchPeriodDateConstraint, slcPeriodDateConstraint } from "../../Setting/redux/periodLock";
import { actFetchSettingLogo, actFetchSettingStamp } from "../../Setting/redux/settingDB";
import reduxStore from "../../common/reduxHandler/createStore";

type EnsureLoginPageState = {
    isSidebarOpen: boolean;
    backgroundColor: string;
    activeColor: string;
    routeList: any[];
    sidebarLink: any[];
    helmet: JSX.Element | null;
};

/**
 * Page for logged-in user.
 * Need to check first-mount access eligibility.
 */
class EnsureLoginPage extends PureComponent<any, EnsureLoginPageState> {
    listener: any = null; //react router listener
    constructor(props: any) {
        super(props);

        //load all modules defined inside ModuleConfig
        let routeList: any = []; //put all components i
        let moduleList = cloneDeep(Modules); //clone
        let index = 0;
        while (moduleList.length > 0) {
            //dfs
            let currentModule = moduleList[0];
            moduleList.shift();

            const TargetPage = currentModule.component;
            let allowed = true;
            if (currentModule.authorityModule) {
                if (
                    //allowed
                    boolAuthChecker(
                        currentModule.authorityModule,
                        currentModule.authorityType != null ? currentModule.authorityType : [],
                        currentModule.authorityType == null ? true : false
                    )
                ) {
                    allowed = true;
                }
            } else {
                allowed = true;
            }

            //conditional | this code cause bug (refresh redirected to /home instead of staying the same link)
            // if (currentModule.renderFlagByState) {
            //     if (
            //         currentModule.renderFlagByState.selector(reduxStore.getState()) !==
            //         currentModule.renderFlagByState.value
            //     ) {
            //         //it's conditional render by state and the value did not match
            //         allowed = false;
            //     }
            // }

            if (allowed) {
                if (currentModule.children != null) {
                    //not leaf node, push them but don't create new route
                    moduleList.push(...currentModule.children);
                } else {
                    //leaf node, add a Route for it so it will be accessible
                    routeList.push(<Route key={index++} path={currentModule.path} component={TargetPage} />);
                }
            }
        }
        const sidebarState = localStorage.getItem("sidebarState");
        this.state = {
            isSidebarOpen: sidebarState === "true" ? true : false,
            backgroundColor: "black",
            activeColor: "info",
            routeList,
            sidebarLink: Modules, //pass Modules to be processed by sidebar to create Links
            helmet: null,
        };
    }

    componentDidMount() {
        document.body.style.backgroundColor = "#f4f6f9";
        document.body.classList.add("sidebar-mini");
        document.body.classList.add("bg-adminlte-gray");

        const sidebarState = localStorage.getItem("sidebarState");
        if (sidebarState === "true") {
            document.body.classList.add("sidebar-open");
        } else {
            document.body.classList.add("sidebar-collapse");
        }

        if (this.props.userLogin.isLoggedIn === false) {
            this.props.actSetRedirectURL(this.props.location.pathname);
        } else {
            //We can initialize here (not outside if to avoid duplicated call)
            //happens on app-loginpage-app -> twice app didMount

            this.props.actInitConfig();
            this.props.actFetchSettingLogo();
            this.props.actFetchSettingStamp();
            // fetch account

            this.props.actFetchMyAccount();

            // fetch InputDate constraint (only need to fetch here, on period lock, on period unlock)
            // not for refine-inventory
            if (process.env.REACT_APP_IS_INVENTORY !== "true") {
                this.props.actFetchPeriodDateConstraint();
            }

            //Google Analytics
            //set current path as first listen
            if (process.env.REACT_APP_USE_ANALYTICS === "true") {
                trackPage(`${this.props.location.pathname}${this.props.location.search}`);

                //listen to react-router history
                this.listener = this.props.history.listen((location: any, action: any) => {
                    trackPage(`${location.pathname}${location.search}`);
                });
            }
        }

        //set app title
        let name = process.env.REACT_APP_IS_INVENTORY === "true" ? "Refine Inventory" : "Refine Accounting";
        //title
        let title = changeCase.title(this.props.match.url.replace(/\//g, " ")) + `  - ${name}`;
        this.setState({
            helmet: (
                <Helmet>
                    <title>{title}</title>
                </Helmet>
            ),
        });
    }

    componentWillUnmount() {
        if (this.listener != null) this.listener(); //unlisten
    }

    componentDidUpdate(prevProps: any) {
        if (this.props.match.url !== prevProps.match.url) {
            //set title etc if needed
            let name = process.env.REACT_APP_IS_INVENTORY === "true" ? "Refine Inventory" : "Refine Accounting";
            let title = changeCase.title(this.props.match.url.replace(/\//g, " ")) + `  - ${name}`;
            this.setState({
                helmet: (
                    <Helmet>
                        <title>{title}</title>
                    </Helmet>
                ),
            });
        }

        //reload route on setting change
        if (
            this.props.settingDeliveryOrderDraft !== prevProps.settingDeliveryOrderDraft ||
            this.props.settingInventoryAdjustmentDraft !== prevProps.settingInventoryAdjustmentDraft ||
            this.props.settingUnfulfilledSalesOrder !== prevProps.settingUnfulfilledSalesOrder
        ) {
            //load all modules defined inside ModuleConfig
            let routeList: any = []; //put all components i
            let moduleList = cloneDeep(Modules); //clone
            let index = 0;
            while (moduleList.length > 0) {
                //dfs
                let currentModule = moduleList[0];
                moduleList.shift();

                const TargetPage = currentModule.component;
                let allowed = true;
                if (currentModule.authorityModule) {
                    if (
                        //allowed
                        boolAuthChecker(
                            currentModule.authorityModule,
                            currentModule.authorityType != null ? currentModule.authorityType : [],
                            currentModule.authorityType == null ? true : false
                        )
                    ) {
                        allowed = true;
                    }
                } else {
                    allowed = true;
                }

                //conditional
                if (currentModule.renderFlagByState) {
                    if (
                        currentModule.renderFlagByState.selector(reduxStore.getState()) !==
                        currentModule.renderFlagByState.value
                    ) {
                        //it's conditional render by state and the value did not match
                        allowed = false;
                    }
                }

                if (allowed) {
                    if (currentModule.children != null) {
                        //not leaf node, push them but don't create new route
                        moduleList.push(...currentModule.children);
                    } else {
                        //leaf node, add a Route for it so it will be accessible
                        routeList.push(<Route key={index++} path={currentModule.path} component={TargetPage} />);
                    }
                }
            }
            this.setState({ routeList });
        }
    }

    toggleSidebar = () => {
        localStorage.setItem("sidebarState", (!this.state.isSidebarOpen).toString());
        if (!this.state.isSidebarOpen) {
            document.body.classList.add("sidebar-open");
            document.body.classList.remove("sidebar-collapse");
        } else {
            document.body.classList.add("sidebar-collapse");
            document.body.classList.remove("sidebar-open");
        }

        this.setState({ isSidebarOpen: !this.state.isSidebarOpen });
    };

    render() {
        const { isLoggedIn } = this.props.userLogin;
        if (isLoggedIn === false) {
            return <Redirect to={"/login"} />;
        } else {
            return (
                <>
                    {this.state.helmet}
                    <GlobalAPINotification />
                    <MainNavbar toggleSidebar={this.toggleSidebar} />
                    <Sidebar routeArr={this.state.sidebarLink} location={this.props.location} />
                    <div id="sidebar-overlay" onClick={this.toggleSidebar} />
                    <div className="content-wrapper">
                        {/* <SimpleBox title="Test title" collapsible icon="fa-search">
                        <p>body here</p>
                    </SimpleBox> */}
                        {/* <ListTableWithSearch
                        formName="test"
                        title="Test box"
                        fetchDataAction={(values:any) => console.log(values)}
                        reduxStoreData={{ loading: false, data: [], error: null }}
                        searchFieldList={FieldList}
                        columnDefinition={columnDef}
                    /> */}
                        <Suspense fallback={<p>Loading...</p>}>
                            <Switch>
                                {this.state.routeList}
                                <Route path="/my-account" component={MyAccountPage} />
                                <Route path="/notifications" component={NotificationPage} />
                                <Redirect from="*" to="/home" />
                            </Switch>
                        </Suspense>
                    </div>
                </>
            );
        }
    }
}

function mapStateToProps(state: any) {
    return {
        userLogin: slcUserLogin(state),
        myAccount: slcFetchMyAccount(state),
        periodDateConstraint: slcPeriodDateConstraint(state),

        //need to rerender
        settingDeliveryOrderDraft: slcSettingDeliveryOrderDraft(state),
        settingInventoryAdjustmentDraft: slcSettingInventoryAdjustmentDraft(state),
        settingUnfulfilledSalesOrder: slcSettingUnfulfilledSalesOrder(state),
    };
}

export default connect(
    mapStateToProps,
    {
        actSetRedirectURL,
        actInitConfig,
        actFetchMyAccount,
        actFetchPeriodDateConstraint,
        actFetchSettingLogo,
        actFetchSettingStamp,
    }
)(EnsureLoginPage);

import { PureComponent } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Table } from "reactstrap";
import { showQty } from "../../../common/function/showValueHelper";
import { SafeBig } from "../../../common/function/safeBig";

type InventoryMutationAccProps = {
    data: any;
};

type InventoryMutationAccState = {};

class InventoryMutationAcc extends PureComponent<
    WithTranslation & InventoryMutationAccProps,
    InventoryMutationAccState
> {
    constructor(props: any) {
        super(props);
        let stateObj = {};
        this.state = stateObj;
    }

    render() {
        const { data, t } = this.props;
        let sumInitialQty = SafeBig(0);
        let sumPurchaseQty = SafeBig(0);
        let sumDebitQty = SafeBig(0);
        let sumTransferInQty = SafeBig(0);
        let sumSalesQty = SafeBig(0);
        let sumCreditQty = SafeBig(0);
        let sumTransferOutQty = SafeBig(0);
        let sumAdjustmentQty = SafeBig(0);
        let sumEndingQty = SafeBig(0);

        let sumInitialAmount = SafeBig(0);
        let sumPurchaseAmount = SafeBig(0);
        let sumDebitAmount = SafeBig(0);
        let sumTransferInAmount = SafeBig(0);
        let sumSalesAmount = SafeBig(0);
        let sumCreditAmount = SafeBig(0);
        let sumTransferOutAmount = SafeBig(0);
        let sumAdjustmentAmount = SafeBig(0);
        let sumEndingAmount = SafeBig(0);

        let result = data.listData.map((val: any, i: number) => {
            if (i === 0) return <></>;
            sumInitialQty = sumInitialQty.add(val.initialQty.value);
            sumPurchaseQty = sumPurchaseQty.add(val.purchaseQty.value);
            sumDebitQty = sumDebitQty.add(val.debitMemoQty.value);
            sumTransferInQty = sumTransferInQty.add(val.inventoryTransferInQty.value);
            sumSalesQty = sumSalesQty.add(val.salesQty.value);
            sumCreditQty = sumCreditQty.add(val.creditMemoQty.value);
            sumTransferOutQty = sumTransferOutQty.add(val.inventoryTransferOutQty.value);
            sumAdjustmentQty = sumAdjustmentQty.add(val.inventoryAdjustmentQty.value);
            sumEndingQty = sumEndingQty.add(val.endingQty.value);

            sumInitialAmount = sumInitialAmount.add(val.initialAmount.value);
            sumPurchaseAmount = sumPurchaseAmount.add(val.purchaseAmount.value);
            sumDebitAmount = sumDebitAmount.add(val.debitMemoAmount.value);
            sumTransferInAmount = sumTransferInAmount.add(val.inventoryTransferInAmount.value);
            sumSalesAmount = sumSalesAmount.add(val.salesAmount.value);
            sumCreditAmount = sumCreditAmount.add(val.creditMemoAmount.value);
            sumTransferOutAmount = sumTransferOutAmount.add(val.inventoryTransferOutAmount.value);
            sumAdjustmentAmount = sumAdjustmentAmount.add(val.inventoryAdjustmentAmount.value);
            sumEndingAmount = sumEndingAmount.add(val.endingAmount.value);

            return (
                <tr key={`lim-inv-mutation=${i}`}>
                    <td>{`${val.productKey.value} - ${val.productName.value}`}</td>
                    <td>{showQty(val.initialQty.value)}</td>
                    <td>{showQty(val.initialAmount.value)}</td>
                    <td>{showQty(val.purchaseQty.value)}</td>
                    <td>{showQty(val.purchaseAmount.value)}</td>
                    <td>{showQty(val.debitMemoQty.value)}</td>
                    <td>{showQty(val.debitMemoAmount.value)}</td>
                    <td>{showQty(val.inventoryTransferInQty.value)}</td>
                    <td>{showQty(val.inventoryTransferInAmount.value)}</td>
                    <td>{showQty(val.salesQty.value)}</td>
                    <td>{showQty(val.salesAmount.value)}</td>
                    <td>{showQty(val.creditMemoQty.value)}</td>
                    <td>{showQty(val.creditMemoAmount.value)}</td>
                    <td>{showQty(val.inventoryTransferOutQty.value)}</td>
                    <td>{showQty(val.inventoryTransferOutAmount.value)}</td>
                    <td>{showQty(val.inventoryAdjustmentQty.value)}</td>
                    <td>{showQty(val.inventoryAdjustmentAmount.value)}</td>
                    <td>{showQty(val.endingQty.value)}</td>
                    <td>{showQty(val.endingAmount.value)}</td>
                    <td>{showQty(val.productDescription.value)}</td>
                </tr>
            );
        });
        result.push(
            <tr key={`sum-inv-acc`}>
                <td>{t("common.total")}</td>
                <td>{showQty(sumInitialQty)}</td>
                <td>{showQty(sumInitialAmount)}</td>
                <td>{showQty(sumPurchaseQty)}</td>
                <td>{showQty(sumPurchaseAmount)}</td>
                <td>{showQty(sumDebitQty)}</td>
                <td>{showQty(sumDebitAmount)}</td>
                <td>{showQty(sumTransferInQty)}</td>
                <td>{showQty(sumTransferInAmount)}</td>
                <td>{showQty(sumSalesQty)}</td>
                <td>{showQty(sumSalesAmount)}</td>
                <td>{showQty(sumCreditQty)}</td>
                <td>{showQty(sumCreditAmount)}</td>
                <td>{showQty(sumTransferOutQty)}</td>
                <td>{showQty(sumTransferOutAmount)}</td>
                <td>{showQty(sumAdjustmentQty)}</td>
                <td>{showQty(sumAdjustmentAmount)}</td>
                <td>{showQty(sumEndingQty)}</td>
                <td>{showQty(sumEndingAmount)}</td>
                <td></td>
            </tr>
        );
        return (
            <div className="table-responsive">
                <style>
                    {`
                        @page {
                            size: A4 landscape;
                        }

                        .ra-table-detail-print {
                            margin-bottom: 1em !important;
                        }
                    `}
                </style>
                <Table className="ra-table-detail-print overflow">
                    <thead>
                        <tr>
                            <td rowSpan={2}>{t("common.product")}</td>
                            <td colSpan={2}>{t("common.beginningBalance")}</td>
                            <td colSpan={2}>{t("common.purchase")}</td>
                            <td colSpan={2}>{t("common.debitMemo")}</td>
                            <td colSpan={2}>{t("common.transferIn")}</td>
                            <td colSpan={2}>{t("common.sales")}</td>
                            <td colSpan={2}>{t("common.creditMemo")}</td>
                            <td colSpan={2}>{t("common.transferOut")}</td>
                            <td colSpan={2}>{t("common.inventoryAdjustment")}</td>
                            <td colSpan={2}>{t("common.balance")}</td>
                            <td rowSpan={2}>{t("common.description")}</td>
                        </tr>
                        <tr>
                            <td>{t("common.qty")}</td>
                            <td>{t("sign.currency")}</td>
                            <td>{t("common.qty")}</td>
                            <td>{t("sign.currency")}</td>
                            <td>{t("common.qty")}</td>
                            <td>{t("sign.currency")}</td>
                            <td>{t("common.qty")}</td>
                            <td>{t("sign.currency")}</td>
                            <td>{t("common.qty")}</td>
                            <td>{t("sign.currency")}</td>
                            <td>{t("common.qty")}</td>
                            <td>{t("sign.currency")}</td>
                            <td>{t("common.qty")}</td>
                            <td>{t("sign.currency")}</td>
                            <td>{t("common.qty")}</td>
                            <td>{t("sign.currency")}</td>
                            <td>{t("common.qty")}</td>
                            <td>{t("sign.currency")}</td>
                        </tr>
                    </thead>
                    <tbody>{result}</tbody>
                </Table>
            </div>
        );
    }
}

export default withTranslation()(InventoryMutationAcc);

import React, { PureComponent } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Col, Row } from "reactstrap";
import { Field, InjectedFormProps, reduxForm } from "redux-form";
import { ConstrainedInputDate, InputCreatableSelect, InputText, InputTextArea } from "../../../common/component/Input";
import SimpleFormBox from "../../../common/component/SimpleFormBox";
import { basicFieldLoader, scrBasicFieldLoader } from "../../../common/sharedClassRoutine";
import {
    slcSettingTempDataForCreditMemo,
    slcSettingTempDataForDebitMemo,
    slcSettingTempDataForPurchase,
    slcSettingTempDataForPurchaseOrder,
    slcSettingTempDataForSales,
    slcSettingTempDataForSalesOrder,
} from "../../../Setting/redux/setting";
import {
    actEditInfo,
    actFetchActiveInfo,
    actOptionInfoTag,
    slcFetchActiveInfo,
    slcOptionInfoTag,
    slcUpdateInfo,
} from "../redux/updateInfo";
import { titleCase, camelCase } from "change-case";
import { boolAuthChecker } from "../../../common/component/AuthCheck";

type IMapStateToProps = {
    activeInfo: CommonReduxState;
    updateInfo: CommonReduxState;
    optionInfoTag: any[];
    tempDataForPurchaseOrder: string[];
    tempDataForPurchase: string[];
    tempDataForDebitMemo: string[];
    tempDataForSalesOrder: string[];
    tempDataForSales: string[];
    tempDataForCreditMemo: string[];
};

type IMapDispatchToProps = {
    actFetchActiveInfo: Function;
    actEditInfo: Function;
    actOptionInfoTag: Function;
};

type InfoFormProps = {} & InjectedFormProps &
    ReactRouterProps &
    IMapStateToProps &
    IMapDispatchToProps &
    WithTranslation;
type InfoFormState = {
    module: string;
    originalPath: string;
    tempDataKey: string[];
};

//react-router path
const formName = "updateInfoForm";

//remapping retrieved value to field name
const fieldLoadMapper: FieldLoadMapper = { key: "key", tag: "tag" };

class UpdateInfoForm extends PureComponent<InfoFormProps, InfoFormState> {
    fieldLoader: scrBasicFieldLoader;
    constructor(props: any) {
        super(props);
        this.state = {
            module: "",
            originalPath: "",
            tempDataKey: [],
        };

        this.fieldLoader = basicFieldLoader.bind(this);
    }

    componentDidMount() {
        let paramKey = null;
        let originalPath :any = null;
        let module :any = null;
        if (this.props.match.params != null) {
            paramKey = this.props.match.params.key;
            originalPath = this.props.match.params.module;
        }

        if (paramKey != null && originalPath != null) {
            //to accept non-standard path
            switch (originalPath) {
                case "receive-report":
                    module = "purchase";
                    break;
            case "debit-memo-delivery":
                    module = "debit-memo";
                    break;
            case "delivery-report":
                    module = "sales";
                    break;
            case "credit-memo-receive":
                    module = "credit-memo";
                    break;
                default:
                    module = originalPath;
            }

            //check auth
            if (!boolAuthChecker(module, ["update"])) {
                setTimeout(() => {
                    this.props.history.push(`/${originalPath}`); //disallow further edit
                });
            }

            this.props.actFetchActiveInfo(module, paramKey);

            this.props.actOptionInfoTag(module);

            this.setState({ module, originalPath });

            setTimeout(() => {
                this.parseModule();   
            });
        }
    }

    componentDidUpdate(prevProps: InfoFormProps) {
        //reset certain state on create/update
        if (this.props.updateInfo !== prevProps.updateInfo && this.props.updateInfo.data != null) {
            this.props.actOptionInfoTag(this.state.module);
        }

        //load field values
        this.fieldLoader(this.props.activeInfo, prevProps.activeInfo, {
            mapper: fieldLoadMapper,
            modifier: this.fieldLoadModifier,
        });

        //check only 1 key is enough as they come from the same data
        if (
            this.props.tempDataForPurchase != null &&
            this.props.tempDataForPurchase !== prevProps.tempDataForPurchase
        ) {
            this.parseModule();
        }
    }

    parseModule = ()=>{
        let tempDataKey: string[] = [];
        switch (this.state.module) {
            case "purchase-order":
                tempDataKey = this.props.tempDataForPurchaseOrder;
                break;
            case "purchase":
                tempDataKey = this.props.tempDataForPurchase;
                break;
            case "debit-memo":
                tempDataKey = this.props.tempDataForDebitMemo;
                break;
            case "sales-order":
                tempDataKey = this.props.tempDataForSalesOrder;
                break;
            case "sales":
                tempDataKey = this.props.tempDataForSales;
                break;
            case "credit-memo":
                tempDataKey = this.props.tempDataForCreditMemo;
                break;
        }

        this.setState({ tempDataKey });
    }
    /**Modify ActiveData before inserting into Fields */
    fieldLoadModifier = (values: any) => {
        let newValues = { ...values };

        return newValues;
    };

    /**
     * OnSubmit (Create/Edit)
     * Handle form submit
     *
     * Do parameter modifications here
     */
    onSubmit = (values: any) => {
        //check tempData for deletion (old vs new is enough)
        let tempData = values.tempData ?? {};
        if (this.props.activeInfo.data != null) {
            for (let key in this.props.activeInfo.data.tempData) {
                if (values.tempData == null || values.tempData[key] == null) {
                    tempData[key] = null;
                }
            }
        }

        // clone to another obj to make sure we are not change the values's value
        let param: any = {
            description: values.description,
            tag: values.tag ?? null,
            tempData,
        };

        this.props.actEditInfo(this.state.module, this.props.activeInfo.data.id, param);
    };

    render() {
        const { handleSubmit, updateInfo, optionInfoTag, match, t } = this.props;

        return (
            <SimpleFormBox
                title={`${t("common.infoForm")} | ${t(`common.${camelCase(this.state.module)}`)}`}
                onSubmit={handleSubmit(this.onSubmit)}
                submitting={updateInfo.loading}
                path={`/${this.state.originalPath}`}
                match={match}
                formName={formName}
            >
                <Row>
                    <Col xs="12">
                        <Row>
                            <Col xs="12" sm="6">
                                <Field label="common.documentNo" name="key" component={InputText} disabled />
                            </Col>
                            <Col xs="12" sm="6">
                                <Field
                                    label="common.date"
                                    name="documentDate"
                                    component={ConstrainedInputDate}
                                    showTimeInput={true}
                                    disabled
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col xs="12">
                        <Row>
                            <Col xs="12" sm="6">
                                <Field
                                    name={"tag"}
                                    label={"common.tag"}
                                    component={InputCreatableSelect}
                                    options={optionInfoTag}
                                    isClearable={true}
                                    isCreatable={true}
                                    multi={true}
                                />
                            </Col>
                            <Col xs="12" sm="6">
                                <Field label="common.description" name="description" component={InputTextArea} rows={5}/>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs="12">
                        <Row>
                            {this.state.tempDataKey.length > 0 && (
                                <Col xs="12" sm="6">
                                    <h4>
                                        <u>{t("common.otherInfo")}</u>
                                    </h4>
                                </Col>
                            )}
                            {this.state.tempDataKey.map((val: any, i) => (
                                <Col xs="12" sm="6" key={i}>
                                    <Field label={titleCase(val)} name={`tempData.${val}`} component={InputText} />
                                </Col>
                            ))}
                        </Row>
                    </Col>
                </Row>
            </SimpleFormBox>
        );
    }
}

function validate(values: any, props: any) {
    let errors: any = {};
    // const errorMsg = "validation.pleaseEnterAValue";

    return { ...errors };
}

function mapStateToProps(state: any) {
    return {
        optionInfoTag: slcOptionInfoTag(state),
        activeInfo: slcFetchActiveInfo(state),
        updateInfo: slcUpdateInfo(state),
        tempDataForPurchaseOrder: slcSettingTempDataForPurchaseOrder(state),
        tempDataForPurchase: slcSettingTempDataForPurchase(state),
        tempDataForDebitMemo: slcSettingTempDataForDebitMemo(state),
        tempDataForSalesOrder: slcSettingTempDataForSalesOrder(state),
        tempDataForSales: slcSettingTempDataForSales(state),
        tempDataForCreditMemo: slcSettingTempDataForCreditMemo(state),
    };
}

export default connect(
    mapStateToProps,
    {
        actFetchActiveInfo,
        actEditInfo,
        actOptionInfoTag,
    }
)(reduxForm<any, any>({ form: formName, validate })(withTranslation()(UpdateInfoForm)));

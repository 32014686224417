import React, { PureComponent } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Table } from "reactstrap";
import HTMLPrintHeader from "../../../common/component/HTMLPrintHeader";
import PrintArea from "../../../common/component/PrintArea";
import { SafeBig } from "../../../common/function/safeBig";

type EstimatedInventoryPrintState = {
    data: any;
    param: any;
    // date: any;
};

class EstimatedInventoryPrint extends PureComponent<WithTranslation, EstimatedInventoryPrintState> {
    constructor(props: any) {
        super(props);

        //load data from localStorage
        const stringData = localStorage.getItem("estimated-inventory-print");
        const jsonData = stringData != null ? JSON.parse(stringData) : null;

        // remove data from localStorage
        // localStorage.removeItem("estimated-inventory-print");

        let stateObj = {
            data: jsonData?.data ?? null,
            param: jsonData?.param ?? {},
            // date
        };

        this.state = stateObj;
    }

    renderQtyTable({
        qty,
        altUnitList,
        product,
        warningColor,
    }: {
        qty: string;
        altUnitList: any;
        product: any;
        warningColor?: boolean;
    }) {
        let tmpQty = SafeBig(qty);
        // fill default unit into altUnitList
        if (!altUnitList.find((value: any) => value.unit.id === product.unit.id)) {
            altUnitList.push({ qty: 1, unit: product.unit });
        }
        return (
            <table
                className="printQtyTable"
                style={{ backgroundColor: warningColor ? "red" : "", color: warningColor ? "white" : "" }}
            >
                <tbody>
                    <tr key={"est-qty-table"}>
                        {altUnitList.map((val: any, i: number) => {
                            let qtyToPrint = SafeBig(tmpQty).div(val.qty).round(0, 0);
                            tmpQty = SafeBig(tmpQty).mod(val.qty);
                            if (val.unit != null) return <td key={i}>{`${qtyToPrint} ${val.unit.name}`}</td>;
                            else return <td key={i}></td>;
                        })}
                    </tr>
                </tbody>
            </table>
        );
    }

    render() {
        const { t } = this.props;
        const { param, data } = this.state;
        if (data == null) return <p>No Data</p>;

        let columns = data.map((obj: any, index: number) => {
            const altUnitList = obj.altUnitList
                .filter((a: any) => a.deleteDate === null)
                .sort((a: any, b: any) => b.qty - a.qty);
            return (
                <tr key={"detail-" + index}>
                    <td className="rowNo">{index + 1}</td>
                    <td>{obj.product.name}</td>
                    <td className="qty">
                        {this.renderQtyTable({ qty: obj.soQty, altUnitList, product: obj.product })}
                    </td>
                    <td className="qty">
                        {this.renderQtyTable({ qty: obj.existingQty, altUnitList, product: obj.product })}
                    </td>
                    <td className="qty">
                        {this.renderQtyTable({ qty: obj.poQty, altUnitList, product: obj.product })}
                    </td>
                    <td className="qty">
                        {this.renderQtyTable({
                            qty: obj.finalQty,
                            altUnitList,
                            product: obj.product,
                            warningColor: obj.finalQty < 0,
                        })}
                    </td>
                </tr>
            );
        });

        return (
            <PrintArea>
                <Table className="ra-table-print">
                    <HTMLPrintHeader param={param} />
                    <tbody>
                        <tr key={`estimated-inv-print`}>
                            <td colSpan={3}>
                                <Table className="ra-table-detail-print">
                                    <thead>
                                        <tr key={`estimated-inv-detail-print`}>
                                            <th className="rowNo">#</th>
                                            <th style={{ width: "37%" }} className="product">
                                                {t("common.product")}
                                            </th>
                                            <th style={{ width: "15%" }} className="qty">
                                                {t("common.salesOrder")} (Q)
                                            </th>
                                            <th style={{ width: "15%" }} className="qty">
                                                {t("common.warehouse")} (Q)
                                            </th>
                                            <th style={{ width: "15%" }} className="qty">
                                                {t("common.depositWarehouse")} (Q)
                                            </th>
                                            <th style={{ width: "15%" }} className="qty">
                                                {t("common.balance")} (Q)
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>{columns}</tbody>
                                </Table>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </PrintArea>
        );
    }
}

export default withTranslation()(EstimatedInventoryPrint);

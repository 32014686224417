import React, { PureComponent } from "react";
import {
    Navbar,
    NavItem,
    Nav,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";
import { connect } from "react-redux";
import { actResetUserLogin } from "../../redux/login";
import { withTranslation } from "react-i18next";
import { clearAllCache } from "../../../common/function/cache";
import { slcFetchMyAccount } from "../../../Core/redux/myAccount";
import { Link } from "react-router-dom";
import NotificationDropdown from "../NotificationDropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TutorialModal from "../../../common/component/TutorialModal";
import ChangeTenantNavbar from "../ChangeTenantNavbar";
import { AuthCheck } from "../../../common/component/AuthCheck";

// type MainNavbarProps = {
//     toggleSidebar: any;
// };
function clearAllCacheAndRefresh(event: any) {
    clearAllCache();
    window.location.reload();
}

class MainNavbar extends PureComponent<any, any> {
    onLogout = () => {
        this.props.actResetUserLogin();
    };
    render() {
        let { myAccount, t } = this.props;
        return (
            <>
                <Navbar
                    className="main-header bg-white navbar-light navbar-expand border-bottom"
                    style={{ overflow: "show" }}
                >
                    <Nav className="navbar-nav">
                        <NavItem>
                            <NavLink onClick={this.props.toggleSidebar}>
                                <FontAwesomeIcon icon="bars" />
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            {process.env.REACT_APP_IS_INVENTORY === "true" && (
                                <NavLink
                                    href="https://refineinventory.com/category/tutorial-menggunakan-refine-inventory/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <FontAwesomeIcon icon="book" style={{ marginRight: "8px" }} />
                                    {t("common.documentation")}
                                </NavLink>
                            )}
                        </NavItem>
                    </Nav>
                    <Nav className="navbar-nav ml-auto">
                        <AuthCheck moduleName="tenant" allowedPermission={["create", "update"]}>
                            <ChangeTenantNavbar />
                        </AuthCheck>
                        {/* Tutorial modal and button */}
                        <NavItem>
                            <TutorialModal />
                        </NavItem>
                        <NotificationDropdown />
                        <UncontrolledDropdown nav inNavbar>
                            <DropdownToggle nav>
                                <FontAwesomeIcon icon="user" />
                            </DropdownToggle>
                            <DropdownMenu right>
                                <DropdownItem>
                                    {this.props.t("common.adminNavWelcome")},<br />{" "}
                                    {myAccount.data ? myAccount.data.name : ""}
                                </DropdownItem>{" "}
                                <hr />
                                <Link to="/my-account">
                                    <DropdownItem>{this.props.t("common.myAccount")}</DropdownItem>
                                </Link>
                                <DropdownItem onClick={clearAllCacheAndRefresh}>
                                    {this.props.t("common.clearCache")}
                                </DropdownItem>
                                <DropdownItem onClick={this.onLogout}>{this.props.t("common.logout")}</DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </Nav>
                </Navbar>
            </>
        );
    }
}

function mapStateToProps(state: any) {
    return {
        myAccount: slcFetchMyAccount(state),
    };
}

export default connect(
    mapStateToProps,
    { actResetUserLogin }
)(withTranslation()(MainNavbar));

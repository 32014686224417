import React, { PureComponent } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import PrintArea from "../../../common/component/PrintArea";
import SOPrintDefault from "../SalesOrderPrintTemplate/tplDefault";
import SOPrintAnj from "../SalesOrderPrintTemplate/tplAnj";
import SOPrintBerkat from "../SalesOrderPrintTemplate/tplBerkat";

type SalesOrderPrintState = {
    data: any;
    companyInfo: any;
    colSpan: number;
    settingHargaSatuan: boolean;
    printTemplateSetting: string;
};

class SalesOrderPrint extends PureComponent<WithTranslation, SalesOrderPrintState> {
    constructor(props: any) {
        super(props);

        //load data from localStorage
        const stringData = localStorage.getItem("salesOrder-print");
        const jsonData = stringData != null ? JSON.parse(stringData) : null;

        // remove data from localStorage
        // localStorage.removeItem("salesOrder-print");

        // console.log(jsonData);

        let stateObj = {
            data: jsonData && jsonData.data ? jsonData.data : null,
            colSpan: 4,
            companyInfo: jsonData && jsonData.companyInfo ? jsonData.companyInfo : null,
            settingHargaSatuan: jsonData?.setting?.settingHargaSatuan ? jsonData.setting.settingHargaSatuan : null,
            printTemplateSetting: jsonData?.setting?.printTemplateSetting ?? "STANDARD",
        };

        this.state = stateObj;
    }

    render() {
        const { companyInfo, data } = this.state;
        if (data == null) return <p>No Data</p>;

        let elmPrint = null;
        switch (this.state.printTemplateSetting) {
            case "ANJ":
                elmPrint = <SOPrintAnj data={data} companyInfo={companyInfo} />;
                break;

            case "BERKAT":
                elmPrint = (
                    <SOPrintBerkat
                        data={data}
                        companyInfo={companyInfo}
                        settingHargaSatuan={this.state.settingHargaSatuan}
                    />
                );
                break;

            default:
                elmPrint = (
                    <SOPrintDefault
                        data={data}
                        companyInfo={companyInfo}
                        settingHargaSatuan={this.state.settingHargaSatuan}
                        colSpan={this.state.colSpan}
                    />
                );
                break;
        }

        return <PrintArea>{elmPrint}</PrintArea>;
    }
}

export default withTranslation()(SalesOrderPrint);

import React, { PureComponent } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import PrintArea from "../../../common/component/PrintArea";
import ReceiveReportPrintDefault from "../ReceiveReportPrintTemplate/tplDefault";
import ReceiveReportPrintAnj from "../ReceiveReportPrintTemplate/tplAnj";
import ReceiveReportPrintBerkat from "../ReceiveReportPrintTemplate/tplBerkat";

type ReceiveReportPrintState = {
    data: any;
    companyInfo: any;
    printTemplateSetting: string;
};

class ReceiveReportPrint extends PureComponent<WithTranslation, ReceiveReportPrintState> {
    constructor(props: any) {
        super(props);

        //load data from localStorage
        const stringData = localStorage.getItem("receiveReport-print");
        const jsonData = stringData != null ? JSON.parse(stringData) : null;

        // remove data from localStorage
        // localStorage.removeItem("receivereport-print");

        // console.log(jsonData);

        this.state = {
            data: jsonData && jsonData.data ? jsonData.data : null,
            companyInfo: jsonData && jsonData.companyInfo ? jsonData.companyInfo : null,
            printTemplateSetting: jsonData.setting?.printTemplateSetting ?? "STANDARD"
        };
    }

    render() {
        const { companyInfo, data } = this.state;
        if (data == null) return <p>No Data</p>;

        let elmPrint = null;
        switch (this.state.printTemplateSetting) {
            case "BERKAT":
                elmPrint = <ReceiveReportPrintBerkat data={data} companyInfo={companyInfo} />
                break;
                
            case "ANJ":
                elmPrint = <ReceiveReportPrintAnj data={data} companyInfo={companyInfo} />
                break;
                
            default:
                elmPrint = <ReceiveReportPrintDefault data={data} companyInfo={companyInfo} />
                break;
        }

        return (
            <PrintArea>{ elmPrint }</PrintArea>
        );
    }
}

export default withTranslation()(ReceiveReportPrint);

import { reqGet, reqPost } from "../../../common/function/request";
import reducerRegistry from "../../../common/reduxHandler/reducerRegistry";
import i18n from "../../../../i18n";

//initial state
const initialState: CommonReduxState = {
    loading: false,
    data: null,
    error: null,
};

//action
const createActionName = (name: string) => `hybridReport/${name}`;
const FETCH_HYBRID_REPORT = createActionName("FETCH_HYBRID_REPORT");
const FETCH_HYBRID_REPORT_DONE = createActionName("FETCH_HYBRID_REPORT_DONE");
const FETCH_HYBRID_REPORT_FAILED = createActionName("FETCH_HYBRID_REPORT_FAILED");
const PRINT_CREATED_REPORT = createActionName("PRINT_CREATED_REPORT"); //no reducer
const PRINT_CREATED_REPORT_DONE = createActionName("PRINT_CREATED_REPORT_DONE");
const PRINT_CREATED_REPORT_FAILED = createActionName("PRINT_CREATED_REPORT_FAILED");

//reducer
/** Only used to remove loading. The dl link added in actionCreator */
function hybridReport(state = initialState, action: DispatchedAction<APIBaseDataResponse>) {
    switch (action.type) {
        case FETCH_HYBRID_REPORT:
            return { ...initialState, loading: true };
        case FETCH_HYBRID_REPORT_DONE:
            return {
                ...state,
                data: action.payload.data,
                loading: false,
            };
        case FETCH_HYBRID_REPORT_FAILED:
            return { ...state, error: action.error, loading: false };
        default:
            return state;
    }
}
function printReport(state = initialState, action: DispatchedAction<APIBaseDataResponse>) {
    switch (action.type) {
        case PRINT_CREATED_REPORT:
            return { ...initialState, loading: true };
        case PRINT_CREATED_REPORT_DONE:
            return {
                ...state,
                data: action.payload.data,
                loading: false,
            };
        case PRINT_CREATED_REPORT_FAILED:
            return { ...state, error: action.error, loading: false };
        default:
            return state;
    }
}

//register reducer
reducerRegistry.register("hybridReport", hybridReport);
reducerRegistry.register("printReport", printReport);

//selector
export const slcHybridReport = (state: any): CommonReduxState => state["hybridReport"];
export const slcPrintReport = (state: any): CommonReduxState => state["printReport"];

//action creator
const urlPath = "hybrid-report";

export function actFetchHybridReport(moduleName: string, param?: any) {
    const request = reqPost(`${urlPath}/${moduleName}`, param);

    return (dispatch: any) => {
        dispatch({
            type: FETCH_HYBRID_REPORT,
            addToLoading: true,
        });
        request
            .then((payload: any) => {
                dispatch({
                    type: FETCH_HYBRID_REPORT_DONE,
                    payload: {
                        data: {
                            data: payload.data.data,
                            meta: {
                                ...payload.data.meta,
                                //merge param to meta
                                moduleName,
                                param,
                            },
                        },
                    },
                    successNotification: i18n.t("common.addedToQueue"),
                });
            })
            .catch((error) => {
                dispatch({
                    type: FETCH_HYBRID_REPORT_FAILED,
                    error,
                });
            });
    };
}

/**
 * Localstorage are limited to 10MB
 *
 * Period Report might have more than that, so we can't use localStorage to communicate between tabs.
 *
 * Solution: Get the json data in the new tab instead
 *
 * This function is used to send which link to be downloaded by CreatedReportPrint
 */
export function actPrintCreatedReport(id: number) {
    return (dispatch: any) => {
        localStorage.setItem(`print-created-report`, id.toString());
        window.open(`/print-created-report`);
        dispatch({
            type: PRINT_CREATED_REPORT_DONE,
            payload: {},
        });
    };

    //old code below
    // const request = reqGet(`created-report/${id}/json`, null);

    // return (dispatch: any) => {
    //     dispatch({
    //         type: PRINT_CREATED_REPORT,
    //     });
    //     request
    //         .then((payload: any) => {
    //             //directly open a new window with the data
    //             localStorage.setItem(`print-created-report`, JSON.stringify(payload.data));

    //             // console.log(moduleName);
    //             window.open(`/print-created-report`);
    //             dispatch({
    //                 type: PRINT_CREATED_REPORT_DONE,
    //                 payload: payload.data,
    //             });
    //         })
    //         .catch((error) => {
    //             dispatch({
    //                 type: PRINT_CREATED_REPORT_FAILED,
    //                 error,
    //             });
    //         });
    // };
}

/**
 * Localstorage are limited to 10MB
 *
 * Period Report might have more than that, so we can't use localStorage to communicate between tabs.
 *
 * Solution: Get the json data in the new tab instead
 *
 * This function will be called in CreatedReportPrint to download the data (make sure it's async) -> Use componentDidMount
 */
export async function asyncGetCreatedReport(id: number) {
    const request = await reqGet(`created-report/${id}/json`, null);

    return request.data;
}

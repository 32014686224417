import React, { useState } from "react";
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Collapse,
    Button,
    UncontrolledButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import ClassNames from "classnames";
import { AuthCheck } from "../AuthCheck";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type SimpleBoxProps = {
    title?: string;
    titleComponent?: any;
    rightTitle?: string | null;
    className?: string;
    color?: "primary" | "secondary" | "success" | "info" | "warning" | "danger" | "none";
    children?: any;
    footerComponent?: any;
    sideLink?: LinkDefinition | LinkDefinition[]; //tied to "create" auth
    moduleName?: string | string[];
    collapsible?: boolean;
    initialCollapse?: boolean;
    icon?: any;
    headerTag?: any;
    sideComponent?: any; //component to be rendered in sideLink position
};

/**
 * Simple Box Container. Used mainly as "main focus" inside a page.
 *
 * Notes: `sideLink` is tied to `create` auth
 */
const SimpleBox = React.memo(
    ({
        title,
        rightTitle,
        className = "",
        color = "none",
        children,
        footerComponent,
        sideLink,
        sideComponent,
        moduleName,
        collapsible,
        initialCollapse = false,
        icon,
        titleComponent,
        headerTag = "h4",
        ...props
    }: SimpleBoxProps) => {
        const { t } = useTranslation();
        const [collapse, setCollapse] = useState(initialCollapse);

        let header = null,
            body = null,
            footer = null,
            sideButton = null;

        if (sideLink) {
            if (Array.isArray(sideLink)) {
                sideButton = (
                    <UncontrolledButtonDropdown>
                        <Link to={sideLink[0].path}>
                            <Button color="secondary">{t(sideLink[0].label)}</Button>
                        </Link>
                        <DropdownToggle caret color="secondary" />
                        <DropdownMenu>
                            {sideLink.map((val, index) =>
                                index === 0 ? null : (
                                    <Link key={index} to={val.path}>
                                        <DropdownItem>{t(val.label)}</DropdownItem>
                                    </Link>
                                )
                            )}
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                );
            } else {
                sideButton = (
                    <Link to={sideLink.path}>
                        <Button color="secondary">{t(sideLink.label)}</Button>
                    </Link>
                );
            }
        }

        if (title && title !== "") {
            header = (
                <CardHeader
                    tag={headerTag}
                    onClick={() => setCollapse(!collapse)}
                    className={ClassNames({ hoverable: collapsible })}
                >
                    <div className="title">
                        {icon && <FontAwesomeIcon icon={icon} style={{ marginRight: "10px" }} />}
                        {t(title)}
                    </div>
                    <div className="action">
                        {titleComponent != null ? titleComponent : <></>}
                        {moduleName != null ? (
                            <AuthCheck moduleName={moduleName} allowedPermission={["create"]}>
                                <span className="float-right" style={{ marginLeft: "8px" }}>
                                    {sideButton}
                                </span>
                            </AuthCheck>
                        ) : (
                            <span className="float-right" style={{ marginLeft: "8px" }}>
                                {sideButton}
                            </span>
                        )}
                        {/* SideComponent - No auth check */}
                        {sideComponent != null ? <span className="float-right">{sideComponent}</span> : <></>}
                        {rightTitle != null && <strong className="float-right">{t(rightTitle)}</strong>}
                    </div>
                </CardHeader>
            );
        }
        if (footerComponent) {
            footer = <CardFooter>{footerComponent}</CardFooter>;
        }
        if (collapsible === true) {
            //add collapse component
            body = (
                <Collapse isOpen={!collapse}>
                    <CardBody>{children}</CardBody>
                    {footer}
                </Collapse>
            );
        } else {
            //render directly
            body = (
                <>
                    <CardBody>{children}</CardBody>
                    {footer}
                </>
            );
        }

        return (
            <Card
                {...props}
                className={ClassNames(
                    className,
                    "card-outline",
                    { "card-primary": color === "primary" },
                    { "card-secondary": color === "secondary" },
                    { "card-success": color === "success" },
                    { "card-info": color === "info" },
                    { "card-warning": color === "warning" },
                    { "card-danger": color === "danger" }
                )}
            >
                {header}
                {body}
            </Card>
        );
    }
);

export default SimpleBox;

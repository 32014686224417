import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { WithTranslation, withTranslation } from "react-i18next";
import dayjs from "dayjs";

import ListTableWithSearch from "../../common/component/ListTableWithSearch";
import { INPUT_TYPE } from "../../common/component/PanelSearchFilter";
import TableAction from "../../common/component/TableAction";
import {
    slcDeleteNotification,
    slcNotificationList,
    actFetchNotificationList,
    actDeleteNotification,
} from "../redux/notification";
import { boolAuthChecker } from "../../common/component/AuthCheck";
import { showDate } from "../../common/function/showValueHelper";

type NotificationPageProps = CommonTablePageProps & {
    notificationList: CommonReduxState;
    deleteNotification: CommonReduxState;
    actFetchNotificationList: Function;
    actDeleteNotification: Function;
};

// const path = "/notifications";

class NotificationPage extends PureComponent<NotificationPageProps & WithTranslation, any> {
    constructor(props: any) {
        super(props);
        const { t } = this.props;
        this.state = {
            searchFieldList: [
                {
                    label: "common.type",
                    name: "name",
                    type: INPUT_TYPE.SELECT,
                    options: [
                        {
                            value: "notification.dueDate",
                            label: t("notification.dueDate"),
                        },
                    ],
                },
                { label: "common.documentNo", name: "key", type: INPUT_TYPE.TEXT },
                {
                    label: "common.source",
                    name: "foreignTable",
                    type: INPUT_TYPE.SELECT,
                    options: [
                        {
                            value: "giro-payable",
                            label: t("common.giroPayable"),
                        },
                    ],
                },
                { label: "common.notificationDate", name: "documentDate", type: INPUT_TYPE.DATE_RANGE },
            ],
            columnDef: [
                {
                    accessor: (row: any) => showDate(row.documentDate),
                    id: "documentDate",
                    Header: t("common.notificationDate"),
                    sortable: true,
                },
                {
                    accessor: (row: any) => t(row.type),
                    id: "type",
                    Header: t("common.type"),
                    sortable: true,
                },
                {
                    accessor: "key",
                    Header: t("common.documentNo"),
                    sortable: true,
                },
                {
                    accessor: (obj: any) => {
                        return (
                            <div>
                                <p>{t(`desc-${obj.type}`)}</p>
                                <p>{this.setNotificationDescription(obj.type, obj.description)}</p>
                            </div>
                        );
                    },
                    id: "description",
                    Header: t("common.description"),
                    sortable: false,
                },
                {
                    id: "action",
                    Header: t("common.action"),
                    width: 85,
                    sortable: false,
                    Cell: (row: any) => (
                        <TableAction
                            moduleName="admin"
                            onClickDelete={this.onClickDelete}
                            originalData={row.original}
                            disableDelete={
                                !boolAuthChecker("admin", ["delete"]) //only admin allowed to delete notification
                            }
                            disableEdit={true}
                            disablePreview={true}
                            customAction={this.setNotificationLink(row.original)}
                        />
                    ),
                },
            ],
        };
    }

    /**
     * We have several notification type, with further data placed in 'description'.
     */
    setNotificationDescription = (type: string, description: string) => {
        switch (type) {
            case "notification.dueDate":
                return dayjs(description).format("DD/MM/YYYY");
            default:
        }

        return description;
    };

    /**
     * We have several notification type, each have custom url for user to view its detail
     */
    setNotificationLink = (data: any) => {
        switch (data.foreignTable) {
            case "purchase":
            case "sales":
                return [
                    {
                        label: "common.view",
                        to: data.foreignTable.replace(/register-|master-/g, "") + "/show/" + data.foreignId,
                    },
                ];
            case "giro-payable":
            case "giro-receivable":
                return [{ label: "common.view", to: data.foreignTable.replace(/register-|master-/g, "") }];
            default:
                return null;
        }
    };

    onClickDelete = (id: any) => {
        if (id != null) this.props.actDeleteNotification(id);
    };

    render() {
        return (
            <ListTableWithSearch
                moduleName="setting"
                title="common.notificationList"
                formName="notificationSearch"
                fetchDataAction={this.props.actFetchNotificationList}
                reduxListState={this.props.notificationList}
                reduxDeleteState={this.props.deleteNotification}
                searchFieldList={this.state.searchFieldList}
                columnDefinition={this.state.columnDef}
            />
        );
    }
}

function mapStateToProps(state: any) {
    return {
        notificationList: slcNotificationList(state),
        deleteNotification: slcDeleteNotification(state),
    };
}

export default connect<any>(
    mapStateToProps,
    { actFetchNotificationList, actDeleteNotification }
)(withTranslation()(NotificationPage));

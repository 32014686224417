import React, { PureComponent } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Table } from "reactstrap";
import HTMLPrintHeader from "../../../common/component/HTMLPrintHeader";
import PrintArea from "../../../common/component/PrintArea";
import { SafeBig } from "../../../common/function/safeBig";
import { showCurrency } from "../../../common/function/showValueHelper";

type EstimatedInventoryPrintState = {
    data: any;
    param: any;
    // date: any;
};

class EstimatedInventoryPrint extends PureComponent<WithTranslation, EstimatedInventoryPrintState> {
    constructor(props: any) {
        super(props);

        //load data from localStorage
        const stringData = localStorage.getItem("inventory-mutation-print");
        const jsonData = stringData != null ? JSON.parse(stringData) : null;

        // remove data from localStorage
        // localStorage.removeItem("estimated-inventory-print");

        let stateObj = {
            data: jsonData?.data ?? null,
            param: jsonData?.param ?? {},
            // date
        };

        this.state = stateObj;
    }

    renderQtyTable(qty: string, altUnitList: any, product: any) {
        let tmpQty = SafeBig(qty);
        // fill default unit into altUnitList
        if (!altUnitList.find((value: any) => value.unit.id === product.unit.id)) {
            altUnitList.push({ qty: 1, unit: product.unit });
        }
        return (
            <table className="printQtyTable">
                <tbody>
                    <tr>
                        {altUnitList.map((val: any, i: number) => {
                            let qtyToPrint = SafeBig(tmpQty).div(val.qty).round(0, 0);
                            tmpQty = SafeBig(tmpQty).mod(val.qty);
                            if (val.unit != null) return <td key={i}>{`${qtyToPrint} ${val.unit.name}`}</td>;
                            else return <td key={i}></td>;
                        })}
                    </tr>
                </tbody>
            </table>
        );
    }

    renderCogsTable(cogs: any, altUnitList: any, product: any) {
        // fill default unit into altUnitList
        if (!altUnitList.find((value: any) => value.unit.id === product.unit.id)) {
            altUnitList.push({ qty: 1, unit: product.unit.name });
        }
        const cogsAmount = cogs;
        return (
            <table className="printQtyTable">
                <tbody>
                    <tr>
                        {altUnitList.map((val: any, i: number) => {
                            if (val.unit != null)
                                return <td key={i}>{`${showCurrency(SafeBig(cogsAmount).times(val.qty))}`}</td>;
                            else return <td key={i}></td>;
                        })}
                    </tr>
                </tbody>
            </table>
        );
    }

    render() {
        const { t } = this.props;
        const { param, data } = this.state;
        if (data == null) return <p>No Data</p>;

        let { category, product } = data;

        let uncategorizedProduct = product.filter((prod: any) => {
            let { product } = prod;
            return product.categoryList.length === 0;
        });

        let uncategorizedProductResults = null;
        let sumUncategorized = SafeBig(0);
        if (uncategorizedProduct.length > 0) {
            uncategorizedProductResults = uncategorizedProduct.map((obj: any, index: number) => {
                const altUnitList = obj.altUnitList
                    .filter((a: any) => a.deleteDate === null)
                    .sort((a: any, b: any) => b.qty - a.qty);
                sumUncategorized = sumUncategorized.plus(obj.amount);
                return (
                    <tr key={"detail-" + index}>
                        <td className="rowNo">{index + 1}</td>
                        <td>{obj.product.name}</td>
                        <td className="qty">{this.renderQtyTable(obj.qty, altUnitList, obj.product)}</td>
                        <td className="qty">{this.renderCogsTable(obj.cogs, altUnitList, obj.product)}</td>
                        <td>{showCurrency(obj.amount)}</td>
                    </tr>
                );
            });
        }

        let results = category.map((cat: any, i: number) => {
            let { category, amount } = cat;
            // filter product where category equal to this category
            let filteredProduct = product.filter((prod: any) => {
                let { product } = prod;
                return product.categoryList?.find((obj: any) => obj.id === category.id) ?? false;
            });
            if (filteredProduct.length <= 0) return <></>;
            let columns = filteredProduct.map((obj: any, index: number) => {
                const altUnitList = obj.altUnitList
                    .filter((a: any) => a.deleteDate === null)
                    .sort((a: any, b: any) => b.qty - a.qty);
                return (
                    <tr key={"detail-" + index}>
                        <td className="rowNo">{index + 1}</td>
                        <td>{obj.product.name}</td>
                        <td className="qty">{this.renderQtyTable(obj.qty, altUnitList, obj.product)}</td>
                        <td className="qty">{this.renderCogsTable(obj.cogs, altUnitList, obj.product)}</td>
                        <td>{showCurrency(obj.amount)}</td>
                    </tr>
                );
            });
            return (
                <>
                    <tr>
                        <td>
                            <h4>{t("common.category")}</h4>
                        </td>
                        <td colSpan={2}>
                            <h4>:{category.name}</h4>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={3}>
                            <Table className="ra-table-detail-print">
                                <thead>
                                    <tr>
                                        <th className="rowNo">#</th>
                                        <th className="product">{t("common.product")}</th>
                                        <th className="qty">{t("common.balance")} (Q)</th>
                                        <th className="qty">{t("common.cogs")} (Rp)</th>
                                        <th className="qty">{t("common.amount")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {columns}
                                    <tr>
                                        <td colSpan={4} style={{ textAlign: "right" }}>
                                            <b>{t("common.subTotal")}</b>
                                        </td>
                                        <td>{showCurrency(amount)}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </td>
                    </tr>
                </>
            );
        });

        return (
            <PrintArea>
                <Table className="ra-table-print">
                    <HTMLPrintHeader param={param} />
                    <tbody>
                        {results}
                        {uncategorizedProductResults !== null ? (
                            <tr>
                                <td colSpan={3}>
                                    <Table className="ra-table-detail-print">
                                        <thead>
                                            <tr>
                                                <th className="rowNo">#</th>
                                                <th className="product">{t("common.product")}</th>
                                                <th className="qty">{t("common.balance")} (Q)</th>
                                                <th className="qty">{t("common.cogs")} (Rp)</th>
                                                <th className="qty">{t("common.amount")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {uncategorizedProductResults}
                                            <tr>
                                                <td colSpan={4} style={{ textAlign: "right" }}>
                                                    <b>{t("common.subTotal")}</b>
                                                </td>
                                                <td>{showCurrency(sumUncategorized.toFixed(2))}</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </td>
                            </tr>
                        ) : (
                            <></>
                        )}
                    </tbody>
                </Table>
            </PrintArea>
        );
    }
}

export default withTranslation()(EstimatedInventoryPrint);

import { PureComponent } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Table } from "reactstrap";
import { showCurrency } from "../../../common/function/showValueHelper";

type FinancePositionProps = {
    data: any;
};

type FinancePositionState = {};

class FinancePosition extends PureComponent<WithTranslation & FinancePositionProps, FinancePositionState> {
    constructor(props: any) {
        super(props);
        let stateObj = {};
        this.state = stateObj;
    }

    render() {
        const { data, t } = this.props;
        let result: any[] = [];
        data.listData.forEach((val: any, i: number) => {
            // if (val.coaNo?.value === "") return;
            result.push(
                <tr>
                    <td>{val.date?.value ?? ""}</td>
                    <td>{val.key?.value ?? ""}</td>
                    <td>{val.coaNo?.value ?? ""}</td>
                    <td>{val.description?.value ?? ""}</td>
                    <td>{showCurrency(val.debit?.value)}</td>
                    <td>{showCurrency(val.credit?.value)}</td>
                    <td>{showCurrency(val.balance?.value)}</td>
                </tr>
            );
        });
        return (
            <div>
                <style>
                    {`
                        @page {
                            size: A4 portrait;
                        }

                        .ra-table-detail-print td,
                        .ra-table-detail-print th {
                            border: 1px solid transparent;
                        }
                    `}
                </style>
                <Table className="ra-table-detail-print">
                    <colgroup>
                        <col style={{ width: "12%" }}></col>
                        <col style={{ width: "10%" }}></col>
                        <col style={{ width: "5%" }}></col>
                        <col style={{ width: "28%" }}></col>
                        <col style={{ width: "10%" }}></col>
                        <col style={{ width: "10%" }}></col>
                        <col style={{ width: "15%" }}></col>
                    </colgroup>
                    <thead>
                        <tr>
                            <th>{t("common.date")}</th>
                            <th>{t("common.key")}</th>
                            <th>{t("common.coaNo")}</th>
                            <th>{t("common.description")}</th>
                            <th>{t("common.debit")}</th>
                            <th>{t("common.credit")}</th>
                            <th>{t("common.balance")}</th>
                        </tr>
                    </thead>
                    <tbody>{result}</tbody>
                </Table>
            </div>
        );
    }
}

export default withTranslation()(FinancePosition);

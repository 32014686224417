import changeCase from "change-case";

/**
 * Validating input
 * Make sure input is camel case
 */
export function setCreateEditParam(valueList: any) {
    let param: any = {};
    Object.keys(valueList).map((obj) => {
        if (obj === "key" /*|| obj === "dtCreated"*/) return true; //because they're unused? key must be created by backend
        param[changeCase.camel(obj)] = valueList[obj];
        return true;
    });

    return param;
}

/**
 * Change react-table page to backend page (+1)
 */
export function setFetchWithPaginationParam(param: any) {
    if (param == null) return null;

    param.page = param.page ? param.page + 1 : 1; // Page di API mulai dari 1 bukan 0;
    param.size = param.size ? param.size : 100; //default page-size

    return param;
}

export function setFetchAllParam() {
    return { all: true };
}

export function setFetchOptionParam() {
    return { all: true, orderBy: "id", orderDir: "ASC" };
}

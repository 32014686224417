import React, { useState, PureComponent } from "react";
import ClassNames from "classnames";
import { UncontrolledTooltip, FormGroup, Label, Col } from "reactstrap";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import DatePicker from "react-datepicker";
import { connect } from "react-redux";
import { slcPeriodDateConstraint } from "../../../Setting/redux/periodLock";

// const backendFormat = "YYYY/MM/DD HH:mm:ss"; //format that will be sent to backend

//else dayjs can't parse custom date
dayjs.extend(customParseFormat);

type InputDateType = BaseInputType & {
    dateFormat?: string;
    maxDate?: any;
    minDate?: any;
    excludeDates?: Date[];
    showTimeInput?: boolean;
    showYearPicker?: boolean;
};

/**
 * There are 2 (3) format used here
 * 1. backEndFormat: the date format used by backend (as v0.1.0 we use YYYY/MM/DD) <-- we use this format as state saved by redux-form.
 *    so, we can send the value directly to backend without any conversion.
 * 2. dateFormat: the format displayed by DatePicker component
 * 3. dayjsFormat: converting from state into displayed value (but dayjs need all uppercase, so we convert it)
 */
export const InputDate = ({
    input,
    meta: { touched, error },
    className = "",
    label = "",
    // onChange,
    disabled,
    readOnly,
    horizontalInput,
    isRequired,
    showTimeInput = false,
    showYearPicker = false,
    dateFormat = showTimeInput?"dd/MM/yyyy hh:mm a":"dd/MM/yyyy", //"yyyy/MM/dd", //format shown to user (format of DatePicker) //TO-DO: read from setting
    placeholder = dateFormat.toUpperCase(),
    minDate,
    maxDate,
    excludeDates,
    noInfo,
}: InputDateType) => {
    const { t } = useTranslation();
    const dayjsFormat = showYearPicker?"YYYY":showTimeInput?"DD/MM/YYYY hh:mm A":"DD/MM/YYYY"; //format needed by dayjs
    const [id] = useState(("input" + input.name).replace(/[^\w]+/g, ""));
    //parse date received from backend
    let backendFormat ="YYYY/MM/DD";
    if (input.value?.includes("Z")){
        backendFormat = "YYYY-MM-DDTHH:mm:ss.SSSZ"
    } else if (showTimeInput){
        backendFormat = "YYYY/MM/DD HH:mm:ss"
    } else if (showYearPicker){
        backendFormat = "YYYY"
    }

    return (
        <FormGroup row={horizontalInput} className={className}>
            {label && (
                <Label for={id} /*sm={horizontalInput ? 2 : false}*/>
                    {t(label)} {isRequired ? <small>*</small> : ""}
                </Label>
            )}
            <Col sm={horizontalInput ? 10 : false} style={{ padding: "0" }}>
                <DatePicker
                    {...input}
                    className={ClassNames({ "has-danger": touched && error }, "form-control")}
                    value={
                        input.value
                            ? dayjs(input.value, backendFormat).format(dayjsFormat)
                            : null /*value is the textbox (shown to user)*/
                    }
                    selected={
                        input.value
                            ? dayjs(input.value, backendFormat).toDate()
                            : null /*selected is the highlight in calendar*/
                    }
                    dateFormat={dateFormat} //this and value handle the format shown to user (not the state value)
                    placeholderText={disabled ? "" : t(placeholder)}
                    disabled={disabled}
                    readOnly={readOnly}
                    minDate={
                        minDate
                            ? minDate
                            : dayjs()
                                  .subtract(50, "year")
                                  .toDate()
                    }
                    maxDate={maxDate}
                    excludeDates={excludeDates}
                    showYearPicker={showYearPicker}
                    showMonthDropdown
                    showYearDropdown
                    showTimeInput={showTimeInput}
                    timeInputLabel={`${t("common.time")} :`}
                    dropdownMode="select"
                    onChange={(val) => input.onChange(val ? dayjs(val).format(backendFormat) : null)} //this is the format needed by backend (YYYY/MM/DD) (state value that will be sent by redux-form)
                    onSelect={() => null} //avoid duplicated value change
                    onBlur={() => null} //avoid duplicated value change
                    id={id}
                />
            </Col>
            {noInfo !== true && (
            <UncontrolledTooltip
                className={ClassNames({ "force-hidden": !(touched && error) })}
                placement="right"
                target={id}
                flip={false} //https://github.com/reactstrap/reactstrap/issues/1488
            >
                {t(error)}
            </UncontrolledTooltip>)}
        </FormGroup>
    );
};

type ConstrainedInputDateState = {
    minDate:Date|null;
    excludeDates:Date[];
}

type ConstrainedInputDateProps = {
    periodDateConstraint:CommonReduxState;
} & InputDateType;

class BaseConstrainedInputDate extends PureComponent<ConstrainedInputDateProps, ConstrainedInputDateState> {
    constructor(props:any){
        super(props);

        this.state = {
            minDate:null,
            excludeDates:[]
        }
    }

    componentDidUpdate(prevProps:ConstrainedInputDateProps){
        if (this.props.periodDateConstraint.data!=null && prevProps.periodDateConstraint!==this.props.periodDateConstraint){
            //build excludeDates
            let newExcludeDates:Date[] = [];
            let lockedPeriod = this.props.periodDateConstraint.data.lockedPeriod;
            for (let i=0;i<lockedPeriod?.length;i++){
                let date = dayjs(lockedPeriod[i]);//from backend is last day of a month
                let checkDate = date.set("date", 1);

                //add all within range
                while(!checkDate.isAfter(date)){
                    newExcludeDates.push(checkDate.toDate());

                    checkDate = checkDate.add(1, "day");
                }
            }

            // console.log(this.props.periodDateConstraint.data.minDate, newExcludeDates);

            this.setState({
                //min date based on minDate
                minDate: this.props.periodDateConstraint.data.minDate!=null?dayjs(this.props.periodDateConstraint.data.minDate).toDate():null,
                excludeDates: newExcludeDates
            })
        }
    }


    render() {
        const {
            minDate:ignore,//pick min date to ignore it
            ...props
        } = this.props; //just pass other
        const {excludeDates, minDate} = this.state;

        return <InputDate excludeDates={excludeDates} minDate={minDate} {...props} />;
    }
}


function mapStateToProps(state: any) {
    return {
        periodDateConstraint: slcPeriodDateConstraint(state)
    };
}

export const ConstrainedInputDate = connect(mapStateToProps, {})(BaseConstrainedInputDate);
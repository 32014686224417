/**
 * Filter with limited size
 * 
 * Usage: `Array.from( filter(array, i => i % 2 === 0, 2 ) )`
 */
export function *limitedFilter(array:any[], condition:Function, maxSize:number) {
    if (!maxSize || maxSize > array.length) {
      maxSize = array.length;
    }
    let count = 0;
    let i = 0;
    while ( count< maxSize && i < array.length ) {
      if (condition(array[i])) {
        yield array[i];
        count++;
      }
      i++;
    }
}
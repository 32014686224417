import React, { PureComponent } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Table } from "reactstrap";
import PrintFooter from "../../../common/component/PrintFooter";
import PrintHeader from "../../../common/component/PrintHeader";
import { SafeBig } from "../../../common/function/safeBig";
import { showCurrency, showQty } from "../../../common/function/showValueHelper";

type defaultPOPrintProps = {
    data: any;
    companyInfo: any;
    settingHargaSatuan: boolean;
    colSpan: number;
    showAmount: boolean;
};

class POPrintDefault extends PureComponent<WithTranslation & defaultPOPrintProps> {
    render() {
        const { companyInfo, data, showAmount, t } = this.props;
        if (data == null) return <p>No Data</p>;
        let columns = data.detailList.map((obj: any, index: number) => {
            let pUnitToSmallestUnitCompare = SafeBig(obj.productUnitConversion?.qty ?? 1).div(obj.product.smallestUnitConversion?.qty ?? 1);
            return (
                <tr key={"detail-" + index}>
                    <td className="rowNo">{index + 1}</td>
                    <td>{obj.product.name}</td>
                    <td className="qty">
                        {obj.unit == null ? (
                            <table className="printQtyTable">
                                <tbody>
                                    <tr key={"detail-1-" + index}>
                                        <td>{showQty(obj.qty) + " "}</td>
                                        <td>{obj.product?.unit?.name}</td>
                                    </tr>
                                </tbody>
                            </table>
                        ) : (
                            <table className="printQtyTable">
                                <tbody>
                                    <tr key={"detail-1-" + index}>
                                        { !obj.product.smallestUnit || obj.unit.id === obj.product.smallestUnit?.id ? (
                                            <>
                                                <td>{showQty(obj.qty) + " " + obj.unit?.name} @{ showQty(obj.productUnitConversion.qty) + " " + obj.product?.unit?.name } </td>
                                                <td>{showQty(SafeBig(obj.qty ?? 0).times(obj.productUnitConversion?.qty ?? 1)) + " " + obj.product.unit?.name}</td>
                                            </>
                                        ) : (
                                            <>
                                                <td>{showQty(obj.qty) + " " + obj.unit?.name} @{ showQty(pUnitToSmallestUnitCompare) + " " + obj.product.smallestUnit?.name } </td>
                                                <td>{showQty(SafeBig(obj.qty ?? 0).times(pUnitToSmallestUnitCompare ?? 1)) + " " + obj.product.smallestUnit?.name}</td>
                                            </>
                                        )}
                                    </tr>
                                </tbody>
                            </table>
                        )}
                    </td>
                    { !this.props.settingHargaSatuan && !obj.product.smallestUnitConversion && showAmount ? (
                            <>
                                <td className="price">{showCurrency(obj.price, 2)}</td>
                                <td className="disc">{showCurrency(obj.disc1, 2)}</td>
                            </>
                        ) : ""
                    }
                    { !this.props.settingHargaSatuan && obj.product.smallestUnitConversion  && showAmount ? (
                            <>
                                <td className="price">{showCurrency(obj.price, 2)}</td>
                                <td className="disc">{showCurrency(obj.disc1, 2)}</td>
                            </>
                        ) : ""
                    }
                    { this.props.settingHargaSatuan && !obj.product.smallestUnitConversion && showAmount ? (
                            <>
                                <td className="price">{showCurrency(SafeBig(obj.price ?? 1).div(obj.productUnitConversion?.qty ?? 1).toString(), 2)}</td>
                                <td className="disc">{showCurrency(SafeBig(obj.disc1 ?? 1).div(obj.productUnitConversion?.qty ?? 1).toString(), 2)}</td>
                            </>
                        ) : ""
                    }
                    { this.props.settingHargaSatuan && obj.product.smallestUnitConversion && obj.productUnitConversion.id === obj.product.smallestUnitConversion.id  && showAmount ? (
                            <>
                            <td className="price">{showCurrency(SafeBig(obj.price ?? 1).div(obj.productUnitConversion?.qty ?? 1).toString(), 2)}</td>
                            <td className="disc">{showCurrency(SafeBig(obj.disc1 ?? 1).div(obj.productUnitConversion?.qty ?? 1).toString(), 2)}</td>
                            </>
                        ) : ""
                    }
                    { this.props.settingHargaSatuan && obj.product.smallestUnitConversion && obj.productUnitConversion.id !== obj.product.smallestUnitConversion.id  && showAmount ? (
                            <>
                                <td className="price">{showCurrency(SafeBig(obj.price ?? 1).div(pUnitToSmallestUnitCompare ?? 1).toString(), 2)}</td>
                                <td className="disc1">{showCurrency(SafeBig(obj.disc1 ?? 1).div(pUnitToSmallestUnitCompare ?? 1).toString(), 2)}</td>
                            </>
                        ) : ""
                    }
                    {showAmount && 
                        <td className="subTotal">{showCurrency(obj.subtotal, 2)}</td>
                    }
                </tr>
            );
        });

        let summaryRow = showAmount ? (
            <Table className="ra-summary-table">
                <tbody>
                    <tr>
                        <td colSpan={this.props.colSpan}>
                            <b>{t("common.calculatedPrice")}: </b> <br /> {data.say} {" "} Rupiah
                        </td>
                        <td className="disc" style={{ textAlign: "right" }}>{t("common.discount")}</td>
                        <td className="subTotal">{showCurrency(data.disc2AmountTotal)}</td>
                    </tr>
                    <tr>
                        <td rowSpan={5} colSpan={this.props.colSpan}>
                            {t("common.notes")}: {data.description}
                        </td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: "right" }}>{t("common.taxBase")}</td>
                        <td className="subTotal">{showCurrency(data.subtotal, 2)}</td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: "right" }}>{t("common.tax")}</td>
                        <td className="subTotal">{showCurrency(data.vat, 2)}</td>
                    </tr>
                    {data.modifierCoa!=null &&
                    <tr>
                        <td style={{ textAlign: "right" }}>{data.modifierCoa.name}</td>
                        <td className="subTotal">{showCurrency(data.modifierAmount, 2)}</td>
                    </tr>}
                    <tr>
                        <td style={{ textAlign: "right" }}>{t("common.grandTotal")}</td>
                        <td className="subTotal">{showCurrency(data.grandTotal, 2)}</td>
                    </tr>
                </tbody>
            </Table>
        ) : <Table className="ra-summary-table">
            <tbody>
                <tr>
                    <td rowSpan={5} colSpan={this.props.colSpan}>
                        {t("common.notes")}: {data.description}
                    </td>
                </tr>
            </tbody>
        </Table>


        return (
            //@ts-ignore
            <Table className="ra-table-print">
                <PrintHeader
                    title={t("common.purchaseOrder")}
                    companyInfo={companyInfo}
                    supplier={data.supplier}
                    no={data.key}
                    dtCreated={data.dtCreated}
                />
                <tbody>
                    <tr>
                        <td colSpan={2}>
                            <Table className="ra-table-detail-print">
                                <thead>
                                    <tr>
                                        <th className="rowNo">#</th>
                                        <th>{t("common.product")}</th>
                                        <th className="qty">{t("common.qty")}</th>
                                        {showAmount && 
                                        <>
                                            {this.props.colSpan === 5 ? (<th className="price">{t("common.price@")}</th>) : ""}
                                            <th className="price">{t("common.price")}</th>
                                            <th className="disc">{t("common.discount")}</th>
                                            <th className="subTotal">{t("common.subTotal")}</th>
                                        </>}
                                    </tr>
                                </thead>
                                <tbody>
                                    {columns}
                                </tbody>
                            </Table>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            {summaryRow}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            <PrintFooter footerType="purchaseOrder" status={data.status} />
                        </td>
                    </tr>
                </tbody>
            </Table>
        );
    }
}

export default withTranslation()(POPrintDefault);

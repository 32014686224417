import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withTranslation, WithTranslation } from "react-i18next";
import { DropdownItem, UncontrolledDropdown, DropdownToggle, DropdownMenu, Badge } from "reactstrap";
import { Link } from "react-router-dom";
// import dayjs from "dayjs";

import { actFetchNewNotification, slcNewNotification, actReadNotification } from "../../redux/notification";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type InjectedRedux = {
    newNotification: CommonReduxState;
    actFetchNewNotification: Function;
    actReadNotification: any;
};

type NotificationDropdownProps = {
    // isOpen: boolean;
} & WithTranslation &
    InjectedRedux;

class NotificationDropdown extends PureComponent<NotificationDropdownProps, any> {
    componentDidMount() {
        this.props.actFetchNewNotification();
        setInterval(() => {
            this.props.actFetchNewNotification();
        }, 3600000); //fetch every 1 hour
    }

    renderNotification() {
        const { t } = this.props;
        // console.log(this.props.newNotification);
        if (this.props.newNotification.data.length === 0)
            return (
                <>
                    <DropdownItem className="notification-item" /*onClick={()=>this.props.actFetchNewNotification()}*/>
                        <p style={{ textAlign: "center" }}>{this.props.t("common.noNewNotification")}</p>
                    </DropdownItem>
                    <DropdownItem divider />
                </>
            );

        return this.props.newNotification.data.map((val: any, index: number) => {
            //determining icon
            let title = "--";
            let icon: any = "bell";
            switch (val.foreignTable.toLowerCase()) {
                case "purchase":
                    icon = "shopping-cart";
                    title = "notification.dueDate";
                    break;
                case "sales":
                    icon = "handshake";
                    title = "notification.dueDate";
                    break;
                case "giro-payable":
                case "giro-receivable":
                    icon = "money-bill";
                    title = "notification.dueDate";
                    break;
                default:
                    break;
            }
            if (val.type === "stock-limit") {
                title = "notification.stockLimit";
            }

            //create link
            let link = this.setNotificationLink(val);

            return (
                <div key={index}>
                    <Link to={`/${link}`}>
                        <DropdownItem className="notification-item">
                            <p>
                                <FontAwesomeIcon icon={icon} style={{ marginRight: "5px" }} /> {t(title)}
                            </p>
                            <span className="text-muted float-right">{val.key}</span>
                            {/* <span className="text-muted float-right">{dayjs(val.documentDate).format("DD/MM/YYYY")}</span> */}
                        </DropdownItem>
                    </Link>
                    <DropdownItem divider />
                </div>
            );
        });
    }

    /**
     * We have several notification type, each have custom url for user to view its detail
     */
    setNotificationLink = (data: any) => {
        switch (data.foreignTable) {
            case "giro-payable":
            case "giro-receivable":
                return data.foreignTable.replace(/register-|master-/g, "");
            case "purchase":
            case "sales":
                return data.foreignTable.replace(/register-|master-/g, "") + "/show/" + data.foreignId;
            default:
                return "inventory-warehouse-detail";
        }
    };

    render() {
        const { t, actReadNotification, newNotification } = this.props;

        return (
            <UncontrolledDropdown nav inNavbar onClick={actReadNotification}>
                <DropdownToggle nav>
                    <FontAwesomeIcon icon="bell" />
                    <Badge color="warning" className="navbar-badge">
                        {newNotification.newCount > 0 ? newNotification.newCount : ""}
                    </Badge>
                </DropdownToggle>
                <DropdownMenu right className="dropdown-menu-lg">
                    <DropdownItem header>{t("common.newNotificationList")}</DropdownItem>

                    <DropdownItem divider />
                    <div className="new-notification-container">{this.renderNotification()}</div>
                    <Link to="/notifications">
                        <DropdownItem className="dropdown-footer border-top">
                            {t("common.showAllNotification")}
                        </DropdownItem>
                    </Link>
                </DropdownMenu>
            </UncontrolledDropdown>
        );
    }
}

function mapStateToProps(state: any) {
    return {
        newNotification: slcNewNotification(state),
    };
}

export default connect(
    mapStateToProps,
    { actFetchNewNotification, actReadNotification }
)(withTranslation()(NotificationDropdown));

import { Component } from "react";
import cloneDeep from "clone-deep";
import { InjectedFormProps } from "redux-form";

type BasicFieldLoaderConfig = {
    modifier?: (data: any) => any;
    mapper?: { [source: string]: string | null };
    callback?: () => any;
};

export type scrBasicFieldLoader = (
    currentActiveProps: CommonReduxState,
    prevActiveProps: CommonReduxState,
    config?: BasicFieldLoaderConfig
) => void;

/**
 * Load fields into redux-form using simple key to key.`change`.
 *
 * change(keyInData, keyInFieldName)
 */
export function basicFieldLoader(
    this: Component<InjectedFormProps>,
    currentActiveProps: CommonReduxState,
    prevActiveProps: CommonReduxState,
    config?: BasicFieldLoaderConfig
) {
    if (currentActiveProps !== prevActiveProps && currentActiveProps.data != null) {
        //insert 1st-child fields
        let activeData = cloneDeep(currentActiveProps.data);
        if (config && config.modifier != null) activeData = config.modifier(activeData);

        if ("tempData" in activeData) {
            activeData = { ...activeData, ...activeData.tempData }; //spread
        }

        for (let key in activeData) {
            if (Array.isArray(activeData[key]) && (config && config.mapper != null && key in config.mapper === false))
                continue; //detail data, skip
            let value = null;
            if (activeData[key] && activeData[key].id != null) {
                //if it's composite object, just pick the id
                value = activeData[key].id;
            } else if (Array.isArray(activeData[key])) {
                //if it's multi select (usually used in m-n relation)
                value = activeData[key].map((val: any) => (val.id ? val.id : val)); //[{id:1}] or [1]
            } else {
                value = activeData[key];
            }

            if (config && config.mapper != null && key in config.mapper) {
                // console.log(config.mapper[key], value);
                if (config.mapper[key] != null)
                    //@ts-ignore
                    this.props.change(config.mapper[key], value); //use name alias
            } else {
                // console.log(key, value);
                this.props.change(key, value); //key name = field name
            }
        }

        if (config && config.callback != null) config.callback();
    }
}

type BasicFieldDetailLoaderConfig = {
    modifier?: (mainData: any, detailData: any) => any;
    mapper?: { [source: string]: string | null };
    callback?: () => any;
};

export type scrBasicFieldDetailLoader = (
    currentActiveProps: CommonReduxState,
    prevActiveProps: CommonReduxState,
    formDetailName: string,
    detailDataKeyName: string | null,
    config?: BasicFieldDetailLoaderConfig
) => void;

/**
 * Load detail fields into redux-form using simple key to key.`change`.
 *
 * change(keyInData, keyInFieldName)
 */
export function basicFieldDetailLoader(
    this: Component<InjectedFormProps>,
    currentActiveProps: CommonReduxState,
    prevActiveProps: CommonReduxState,
    formDetailName: string,
    detailDataKeyName: string | null, //null for direct data use (data), add string for the key name (data.detailName)
    config?: BasicFieldDetailLoaderConfig
) {
    if (currentActiveProps !== prevActiveProps && currentActiveProps.data != null) {
        //insert detail fields (used in Register Form)
        let newDetailData: any[] = [];
        let detailData = detailDataKeyName ? currentActiveProps.data[detailDataKeyName] : currentActiveProps.data;

        setTimeout(() => {
            for (let i = 0; i < detailData.length; i++) {
                let tData = cloneDeep(detailData[i]);

                if (config && config.modifier != null) tData = config.modifier(currentActiveProps.data, tData);

                //if null, skip
                if (tData == null) continue;

                let detailState: any = {};

                for (let key in tData) {
                    let value = null;
                    //use `"!!key":"mapper"` to bypass first rule and force full data
                    if (config && config.mapper != null && `!!${key}` in config.mapper) {
                        value = tData[key];
                    } else if (tData[key] && tData[key].id != null) {
                        //if it's composite object, just pick the id
                        value = tData[key].id;
                    } else if (Array.isArray(tData[key])) {
                        //if it's multi select (usually used in m-n relation)
                        value = tData[key].map((val: any) => (val.id ? val.id : val)); //[{id:1}] or [1]
                    } else {
                        value = tData[key];
                    }

                    // console.log(key, this.state.detailFieldLoadMapper[key]);
                    if (config && config.mapper != null && key in config.mapper) {
                        if (config.mapper[key] != null)
                            //@ts-ignore
                            detailState[config.mapper[key]] = value; //use name alias
                    } else {
                        detailState[key] = value; //key name = field name
                    }
                }
                newDetailData.push(detailState);
            }

            this.props.change(formDetailName, newDetailData); //push the values
            //callback
            if (config && config.callback != null) config.callback();
        });
    }
}

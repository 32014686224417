export type HybridReportListProcessor = {
    pivot: string[];
    data: string[];
};

const reportListType = {
    purchaseOrder: {
        pivot: ["supplierKey", "supplierName", "list.key", "list.date", "list.status"],
        data: ["productName", "qty", "productUnit", "price", "disc1Amount", "subtotal"],
    },
    purchase: {
        pivot: ["supplierKey", "supplierName", "list.key", "list.date", "list.status"],
        data: ["productName", "qty", "productUnit", "price", "disc1Amount", "subtotal"],
    },
    receiveReport: {
        pivot: ["supplierKey", "supplierName", "list.key", "list.date"],
        data: ["productName", "qty", "productUnit"],
    },
    sjRetur: {
        pivot: ["supplierKey", "supplierName", "list.key", "list.date"],
        data: ["productName", "qty", "productUnit"],
    },
    debitMemo: {
        pivot: ["supplierKey", "supplierName", "list.key", "list.date", "list.status"],
        data: ["productName", "qty", "productUnit", "price", "subtotal"],
    },
    outstandingPayable: {
        pivot: ["supplierKey", "supplierName"],
        data: ["date", "dueDate", "key", "type", "grandTotal", "payable"],
    },
    purchaseSummary: {
        pivot: [],
        data: ["supplierKey", "supplierName", "customerPhone", "customerAddress", "total"],
    },
    payablePayment: {
        pivot: ["supplierKey", "supplierName"],
        data: ["date", "key", "amount", "paidList"],
    },
    payableMutation: {
        pivot: [],
        data: ["supplierKey", "supplierName", "initial", "purchase", "debitMemo", "payment", "balance"],
    },
    salesOrder: {
        pivot: ["customerKey", "customerName", "list.key", "list.date", "list.status"],
        data: ["productName", "qty", "productUnit", "price", "disc1Amount", "subtotal"],
    },
    sales: {
        pivot: ["customerKey", "customerName", "list.key", "list.date", "list.status"],
        data: ["productName", "qty", "productUnit", "price", "disc1Amount", "subtotal"],
    },
    deliveryOrder: {
        pivot: ["supplierKey", "customerName", "list.key", "list.date"],
        data: ["productName", "qty", "productUnit"],
    },
    deliveryOrderRetur: {
        pivot: ["supplierKey", "customerName", "list.key", "list.date"],
        data: ["productName", "qty", "productUnit"],
    },
    creditMemo: {
        pivot: ["customerKey", "customerName", "list.key", "list.date", "list.status"],
        data: ["productName", "qty", "productUnit", "price", "subtotal"],
    },
    outstandingReceivable: {
        pivot: ["customerKey", "customerName"],
        data: ["date", "dueDate", "key", "type", "grandTotal", "receivable"],
    },
    salesSummary: {
        pivot: [],
        data: ["customerKey", "customerName", "customerPhone", "customerAddress", "total"],
    },
    receivablePayment: {
        pivot: ["customerKey", "customerName"],
        data: ["date", "key", "amount", "paidList"],
    },
    receivableMutation: {
        pivot: [],
        data: ["customerKey", "customerName", "initial", "sales", "creditMemo", "payment", "balance"],
    },
    cashMutation: {
        pivot: [],
        data: ["date", "key", "coaNo", "desc", "amountIn", "amountOut", "balance"],
    },
    bankMutation: {
        pivot: [],
        data: ["date", "key", "coaNo", "autoDesc", "amountIn", "amountOut", "balance"],
    },
};

export { reportListType };

import React, { PureComponent } from "react";
import { Collapse, Nav, NavItem } from "reactstrap";
import { withTranslation, WithTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import classNames from "classnames";
import SimpleBar from "simplebar-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import logoShort from "../../../../asset/img/logo-1.png";
import { boolAuthChecker } from "../../../common/component/AuthCheck";
import reduxStore from "../../../common/reduxHandler/createStore";
import { connect } from "react-redux";
import {
    slcSettingDeliveryOrderDraft,
    slcSettingInventoryAdjustmentDraft,
    slcSettingUnfulfilledSalesOrder,
} from "../../../Setting/redux/setting";

type SidebarProps = {
    location: {
        pathname: string;
    };
    routeArr: RouteDefinitionArray;
} & WithTranslation;

type SidebarState = {
    [key: string]: boolean; //saving state sidebar is opened/not
};

class Sidebar extends PureComponent<SidebarProps, SidebarState> {
    constructor(props: any) {
        super(props);
        this.state = this.getInitialOpenState(props.routeArr);
    }

    getInitialOpenState = (routeArr: RouteDefinitionArray) => {
        let initialState = {};

        // routeArr.map((prop: any) => {
        //     if (prop.collapsible) {
        //         initialState = {
        //             [prop.stateName]: this.recurveGetOpenState(prop.children),
        //         };
        //     }
        //     return null;
        // });

        this.recurveGetOpenState(routeArr, initialState); //recursion with returned value

        return initialState;
    };

    /**
     * Getting initial open state (initial mount)
     *
     * @param {(RouteDefinition | undefined)} routeArr
     */
    recurveGetOpenState(routeArr: RouteDefinitionArray | undefined, state: any) {
        if (!routeArr) return false;

        for (let i = 0; i < routeArr.length; i++) {
            if (routeArr[i].collapsible === true && this.recurveGetOpenState(routeArr[i].children, state)) {
                //parent
                if (routeArr[i].stateName != null) {
                    //if it's collapsible node
                    //@ts-ignore
                    state[routeArr[i].stateName] = true; //set as opened
                }
                return true; //show the parent of opened sidebar if child is active
            } else {
                let pathList = this.props.location.pathname.split("/");
                //@ts-ignore
                const routePath = routeArr[i].path != null ? routeArr[i].path.replace("/", "") : "-1";
                if (pathList.indexOf(routePath) !== -1) {
                    return true; //show them
                }
            }
        }
        return false; //collapse/close them
    }

    /**
     * Flagging sidelink as active/not
     */
    activeRoute = (pathName: string) => {
        // return this.props.location.pathname.indexOf(pathName) > -1 ? true : false;
        let pathList = this.props.location.pathname.split("/");
        //@ts-ignore
        const routePath = pathName.replace("/", "");
        return pathList.indexOf(routePath) !== -1;
    };

    /**
     * Build sidebar links using the module definition file (module/index.js)
     */
    createLink = (routeArr: RouteDefinitionArray | undefined) => {
        if (!routeArr) return null;

        return routeArr.map((prop: RouteDefinition, index) => {
            if (
                prop.authorityModule &&
                !boolAuthChecker(
                    prop.authorityModule,
                    prop.authorityType != null ? prop.authorityType : [],
                    prop.authorityType == null ? true : false
                )
            ) {
                //if it's protected and user don't have access, skip
                return null;
            }

            if (prop.renderFlagByState) {
                if (prop.renderFlagByState.selector(reduxStore.getState()) !== prop.renderFlagByState.value) {
                    //it's conditional render by state and the value did not match
                    return null;
                }
            }

            if (prop.collapsible) {
                return (
                    <NavItem
                        className={classNames({
                            "menu-open": this.state[prop.stateName || ""],
                        })}
                        key={index}
                    >
                        <span
                            className="nav-link"
                            onClick={() =>
                                prop.stateName ? this.setState({ [prop.stateName]: !this.state[prop.stateName] }) : null
                            }
                        >
                            {prop.icon ? (
                                <>
                                    <FontAwesomeIcon className="sidebar-mini-icon" icon={prop.icon} />
                                    <p>{this.props.t(prop.label)}</p>
                                    <FontAwesomeIcon className="right float-right" icon="angle-left" />
                                </>
                            ) : (
                                <>
                                    <span className="sidebar-mini-icon">{prop.shortLabel}</span>
                                    <p>{this.props.t(prop.label)}</p>
                                    <FontAwesomeIcon className="right float-right" icon="angle-left" />
                                </>
                            )}
                        </span>
                        {prop.stateName && (
                            <Collapse isOpen={this.state[prop.stateName]}>
                                <Nav className="nav-sidebar flex-column">{this.createLink(prop.children)}</Nav>
                            </Collapse>
                        )}
                    </NavItem>
                );
            } else {
                //it's leaf node
                if (prop.hideFromMenu) return <span key={index} />;

                return (
                    <NavItem key={index}>
                        <NavLink to={prop.path ? prop.path : ""}>
                            <span
                                className={classNames("nav-link", {
                                    active: this.activeRoute(prop.path ? prop.path : ""),
                                    "no-highlight": prop.addMargin !== true,
                                })}
                            >
                                {prop.icon ? (
                                    <>
                                        <FontAwesomeIcon className="sidebar-mini-icon" icon={prop.icon} />
                                        <p>{this.props.t(prop.label)}</p>
                                    </>
                                ) : (
                                    <>
                                        <span className="sidebar-mini-icon">
                                            {this.props.t(prop.shortLabel ? prop.shortLabel : "")}
                                        </span>
                                        <p>{this.props.t(prop.label)}</p>
                                    </>
                                )}
                            </span>
                        </NavLink>
                    </NavItem>
                );
            }
        });
    };

    render() {
        return (
            <aside className="main-sidebar sidebar-dark-primary elevation-2">
                <div className="brand-link">
                    <img className="brand-image img-circle elevation-2" src={logoShort} alt="logo" />
                    <span className="brand-text">
                        {process.env.REACT_APP_IS_INVENTORY === "true" ? "Refine Inventory" : "Refine Accounting"}
                    </span>
                </div>
                <SimpleBar style={{ height: "100%" }}>
                    <div className="sidebar">
                        <Nav className="mt-2">
                            <Nav pills className="nav-sidebar flex-column" style={{ width: "100%" }}>
                                {this.createLink(this.props.routeArr)}
                                <div style={{ height: "100px" }} />
                            </Nav>
                        </Nav>
                    </div>
                </SimpleBar>
            </aside>
        );
    }
}

function mapStateToProps(state: any) {
    return {
        //need to rerender
        settingDeliveryOrderDraft: slcSettingDeliveryOrderDraft(state),
        settingInventoryAdjustmentDraft: slcSettingInventoryAdjustmentDraft(state),
        settingUnfulfilledSalesOrder: slcSettingUnfulfilledSalesOrder(state),
    };
}

export default connect(
    mapStateToProps,
    {}
)(withTranslation()(Sidebar));

import React, { Component } from "react";
import { Route } from "react-router-dom";
// component
import { AuthCheck } from "../../common/component/AuthCheck";
import CreditMemoPrint from "../../Register/CreditMemo/CreditMemoPrint";
import CreditMemoReceivePrint from "../../Register/CreditMemo/CreditMemoReceivePrint";
import DebitMemoDeliveryPrint from "../../Register/DebitMemo/DebitMemoDeliveryPrint";
import DebitMemoPrint from "../../Register/DebitMemo/DebitMemoPrint";
import DPPurchasePrint from "../../Register/DownPayment/DPPurchasePrint";
import DPSalesPrint from "../../Register/DownPayment/DPSalesPrint";
import JobCompletionPrint from "../../Register/JobCompletion/JobCompletionPrint";
import JobOrderPrint from "../../Register/JobOrder/JobOrderPrint";
import PurchasePrint from "../../Register/Purchase/PurchasePrint";
import ReceiveReportPrint from "../../Register/Purchase/ReceiveReportPrint";
import PurchaseOrderPrint from "../../Register/PurchaseOrder/PurchaseOrderPrint";
import CustomerDepositCardPrint from "../../Register/Report/HtmlReportPrint/CustomerDepositCardPrint";
import CustomerDepositSummaryPrint from "../../Register/Report/HtmlReportPrint/CustomerDepositSummaryPrint";
import EstimatedInventoryPrint from "../../Register/Report/HtmlReportPrint/EstimatedInventoryPrint";
import GrossNetPrint from "../../Register/Report/HtmlReportPrint/GrossNetPrint";
import InventoryMutationPrint from "../../Register/Report/HtmlReportPrint/InventoryMutationPrint";
import OutstandingPayablePrint from "../../Register/Report/HtmlReportPrint/OutstandingPayablePrint";
import PreviewPurchasePrint from "../../Register/Report/HtmlReportPrint/PreviewPurchasePrint";
import PurchaseSummaryPrint from "../../Register/Report/HtmlReportPrint/PurchaseSummaryPrint";
import OutstandingReceivablePrint from "../../Register/Report/HtmlReportPrint/OutstandingReceivablePrint";
import SalesOrderSummaryPrint from "../../Register/Report/HtmlReportPrint/SalesOrderSummaryPrint";
import SalesTempDataPrint from "../../Register/Report/HtmlReportPrint/SalesTempDataPrint";
import SalesTurnoverPrint from "../../Register/Report/HtmlReportPrint/SalesTurnoverPrint";
import SupplierDepositCardPrint from "../../Register/Report/HtmlReportPrint/SupplierDepositCardPrint";
import SupplierDepositSummaryPrint from "../../Register/Report/HtmlReportPrint/SupplierDepositSummaryPrint";
import DeliveryReportPrint from "../../Register/Sales/DeliveryReportPrint";
import SalesPrint from "../../Register/Sales/SalesPrint";
import SalesOrderPrint from "../../Register/SalesOrder/SalesOrderPrint";
import SalesReceiptPrint from "../../Register/SalesReceipt/SalesReceiptPrint";
import TransactionPrint from "../../Register/TransactionMutation/TransactionPrint";
import ReceivableCardPrint from "../../Register/Report/HtmlReportPrint/ReceivableCardPrint";
import PayableCardPrint from "../../Register/Report/HtmlReportPrint/PayableCardPrint";
import CreatedReportPrint from "../../Register/Report/CreatedReportPrint";
import PurchaseOrderSummaryPrint from "../../Register/Report/HtmlReportPrint/PurchaseOrderSummaryPrint";

/**
 * PrintPage uses new window, not inside app, so need to define routing here
 */
class PrintRouter extends Component {
    render() {
        return (
            <>
                <AuthCheck moduleName="purchase" allowedPermission={["read", "readAmount"]}>
                    <Route exact path="/print-page/purchase" component={PurchasePrint} />
                    <Route exact path="/print-page/purchase-summary" component={PurchaseSummaryPrint} />
                </AuthCheck>
                <AuthCheck moduleName="purchase" allowedPermission={["readQty"]}>
                    <Route exact path="/print-page/receive-report" component={ReceiveReportPrint} />
                </AuthCheck>
                <AuthCheck moduleName="purchase-order" allowedPermission={["read", "readAmount"]}>
                    <Route exact path="/print-page/purchase-order" component={PurchaseOrderPrint} />
                    <Route exact path="/print-page/purchase-order-summary" component={PurchaseOrderSummaryPrint} />
                </AuthCheck>

                <AuthCheck moduleName="debit-memo" allowedPermission={["read", "readAmount"]}>
                    <Route exact path="/print-page/debit-memo" component={DebitMemoPrint} />
                </AuthCheck>
                <AuthCheck moduleName="debit-memo" allowedPermission={["readQty"]}>
                    <Route exact path="/print-page/debit-memo-delivery" component={DebitMemoDeliveryPrint} />
                </AuthCheck>

                <AuthCheck moduleName="sales" allowedPermission={["read", "readAmount"]}>
                    <Route exact path="/print-page/sales" component={SalesPrint} />
                </AuthCheck>
                <AuthCheck moduleName="sales" allowedPermission={["readQty"]}>
                    <Route exact path="/print-page/delivery-report" component={DeliveryReportPrint} />
                </AuthCheck>
                <AuthCheck moduleName="sales-order" allowedPermission={["read", "readAmount"]}>
                    <Route exact path="/print-page/sales-order" component={SalesOrderPrint} />
                </AuthCheck>
                <AuthCheck moduleName="sales-receipt" allowedPermission={["read"]}>
                    <Route exact path="/print-page/sales-receipt" component={SalesReceiptPrint} />
                </AuthCheck>

                <AuthCheck moduleName="credit-memo" allowedPermission={["read", "readAmount"]}>
                    <Route exact path="/print-page/credit-memo" component={CreditMemoPrint} />
                </AuthCheck>
                <AuthCheck moduleName="credit-memo" allowedPermission={["readQty"]}>
                    <Route exact path="/print-page/credit-memo-receive" component={CreditMemoReceivePrint} />
                </AuthCheck>
                <AuthCheck
                    moduleName={[
                        "purchase-order",
                        "purchase",
                        "transaction-bank-out",
                        "transaction-cash-out",
                        "giro-payable",
                    ]}
                    allowedPermission={["read", "readAmount"]}
                >
                    <Route exact path="/print-page/purchase-down-payment" component={DPPurchasePrint} />
                </AuthCheck>
                <AuthCheck
                    moduleName={[
                        "sales-order",
                        "sales",
                        "transaction-bank-in",
                        "transaction-cash-in",
                        "giro-receivable",
                    ]}
                    allowedPermission={["read", "readAmount"]}
                >
                    <Route exact path="/print-page/sales-down-payment" component={DPSalesPrint} />
                </AuthCheck>
                <AuthCheck
                    moduleName={["transaction-bank-out", "transaction-cash-out", "giro-payable"]}
                    allowedPermission={["read"]}
                >
                    <Route exact path="/print-page/transaction-out" component={TransactionPrint} />
                    <Route exact path="/print-page/supplier-deposit-summary" component={SupplierDepositSummaryPrint} />
                    <Route exact path="/print-page/supplier-deposit-card" component={SupplierDepositCardPrint} />
                </AuthCheck>
                <AuthCheck
                    moduleName={["transaction-bank-in", "transaction-cash-in", "giro-receivable"]}
                    allowedPermission={["read"]}
                >
                    <Route exact path="/print-page/transaction-in" component={TransactionPrint} />
                    <Route exact path="/print-page/customer-deposit-summary" component={CustomerDepositSummaryPrint} />
                    <Route exact path="/print-page/customer-deposit-card" component={CustomerDepositCardPrint} />
                </AuthCheck>

                {/* HTML report */}
                <AuthCheck moduleName="sales" allowedPermission={["read", "readAmount"]}>
                    <Route exact path="/print-page/gross-net" component={GrossNetPrint} />
                    <Route exact path="/print-page/outstanding-receivable" component={OutstandingReceivablePrint} />
                    <Route exact path="/print-page/sales-turnover" component={SalesTurnoverPrint} />
                    <Route exact path="/print-page/sales-temp-data" component={SalesTempDataPrint} />
                    <Route exact path="/print-page/receivable-card" component={ReceivableCardPrint} />
                </AuthCheck>
                <AuthCheck moduleName="sales-order" allowedPermission={["read", "readAmount"]}>
                    <Route exact path="/print-page/sales-order-summary" component={SalesOrderSummaryPrint} />
                </AuthCheck>

                <AuthCheck moduleName="inventory-flow" allowedPermission={["read", "readQty"]}>
                    <Route exact path="/print-page/estimated-inventory" component={EstimatedInventoryPrint} />
                    <Route exact path="/print-page/inventory-mutation" component={InventoryMutationPrint} />
                </AuthCheck>

                <AuthCheck moduleName="purchase" allowedPermission={["read", "readAmount"]}>
                    <Route exact path="/print-page/outstanding-payable" component={OutstandingPayablePrint} />
                    <Route exact path="/print-page/payable-card" component={PayableCardPrint} />
                    <Route exact path="/print-page/preview-purchase" component={PreviewPurchasePrint} />
                </AuthCheck>

                <AuthCheck moduleName="job-completion" allowedPermission={["read"]}>
                    <Route exact path="/print-page/job-completion" component={JobCompletionPrint} />
                </AuthCheck>

                <AuthCheck moduleName="job-order" allowedPermission={["read"]}>
                    <Route exact path="/print-page/job-order" component={JobOrderPrint} />
                </AuthCheck>

                {/* Any JSON report */}
                <Route exact path="/print-created-report" component={CreatedReportPrint} />
            </>
        );
    }
}

export default PrintRouter;

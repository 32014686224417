import { combineReducers, createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import reducerRegistry from "./reducerRegistry";

/**
 * Load initialState from localStorage if available
 */
const initialState = localStorage.getItem("reduxStub") || {};

// Preserve initial state for not-yet-loaded reducers
const combine = (reducers: any) => {
    const reducerNames = Object.keys(reducers);
    Object.keys(initialState).forEach((item) => {
        if (reducerNames.indexOf(item) === -1) {
            reducers[item] = (state: any = null) => state;
        }
    });
    return combineReducers(reducers);
};

const reducer = combine(reducerRegistry.getReducers());
const store = createStore(reducer, initialState, applyMiddleware(thunk));

// Replace the store's reducer whenever a new reducer is registered.
reducerRegistry.setChangeListener((reducers: any) => {
    store.replaceReducer(combine(reducers));
});

export default store;

import React, { PureComponent } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { formValueSelector, InjectedFormProps, reduxForm } from "redux-form";
import { ConnectedInputSelect } from "../../../common/component/Input";
import jwtTokenReader from "../../../common/function/jwtTokenReader";
import { actOptionAllTenant, slcOptionAllTenant } from "../../../Master/Tenant/redux/tenant";
import { actChangeTenant, slcChangeTenant } from "../../redux/login";

type InjectedRedux = {
    optionAllTenant:any[];
    currentTenant:any;
    changeTenant:CommonReduxState;
    actChangeTenant:Function; 
    actOptionAllTenant:Function;
    selectedTenant:any;
};

type ChangeTenantNavbarProps = {
    isOpen:boolean;
} & WithTranslation &
    InjectedRedux & InjectedFormProps;

let formName = "changeTenantForm";

class ChangeTenantNavbar extends PureComponent<ChangeTenantNavbarProps, any> {
    constructor(props:any){
        super(props);

        this.state = {
            isOpen:false
        }
    }

    componentDidMount() {
        this.props.actOptionAllTenant();
    }

    toggleDropdown = ()=>{
        if (!this.state.isOpen){
            this.props.actOptionAllTenant();
            this.props.change("tenant", this.props.currentTenant.value);
        }
        this.setState({isOpen:!this.state.isOpen});
    }

    onChangeTenant = (value:any)=>{
        setTimeout(() => {
            const {t, currentTenant, selectedTenant} = this.props;
            // let selectedTenant = optionAllTenant.find((val)=>val.value===value);
    
            // console.log(currentTenant, selectedTenant);
            if (currentTenant.value!==selectedTenant.value && window.confirm(`${t("confirm.changeTenant")}: ${selectedTenant.label}`)){
                this.props.actChangeTenant(selectedTenant.uuid);
            }
        });
    }

    render() {
        const { t, currentTenant, optionAllTenant } = this.props;

        return (
            <Dropdown nav inNavbar toggle={this.toggleDropdown} isOpen={this.state.isOpen}>
                <DropdownToggle nav onClick={this.toggleDropdown} >
                    {t("common.currentTenant")}:{" "}
                    {currentTenant?.label}
                </DropdownToggle>
                <DropdownMenu right className="dropdown-menu-lg">
                    <div style={{margin:"10px"}}>
                        <ConnectedInputSelect 
                            label={t("common.changeTenant")} 
                            name="tenant" 
                            options={optionAllTenant}
                            onChange={this.onChangeTenant}
                        />    
                    </div>
                </DropdownMenu>
            </Dropdown>
        );
    }
}

function mapStateToProps(state: any) {
    const selector = formValueSelector(formName);
    let currentTenantUUID = jwtTokenReader()?.tid;
    let optionAllTenant= slcOptionAllTenant(state);
    let currentTenant = optionAllTenant.find((val)=>val.uuid === currentTenantUUID);
    let selectedTenantId = selector(state, "tenant");
    let selectedTenant = optionAllTenant.find((val)=>val.value === selectedTenantId);

    // console.log(currentTenantUUID, optionAllTenant, currentTenant, selectedTenantId, selectedTenant);
    return {
        optionAllTenant,
        currentTenant,
        changeTenant: slcChangeTenant(state),
        selectedTenant
    };
}

export default connect(
    mapStateToProps,
    { actChangeTenant, actOptionAllTenant }
)(reduxForm<any, any>({
    form: formName,
})(withTranslation()(ChangeTenantNavbar)));

import React, { PureComponent } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Table } from "reactstrap";
import HTMLPrintHeader from "../../../common/component/HTMLPrintHeader";
import PrintArea from "../../../common/component/PrintArea";
import { SafeBig } from "../../../common/function/safeBig";
import { showCurrency, showDate, showQty } from "../../../common/function/showValueHelper";

type SalesTurnoverPrintState = {
    data: any;
    param: any;
    sumQty: any;
    grandTotal: any;
    sumTotal: any;
    isFilterBySingleProduct: boolean;
};

class SalesTurnoverPrint extends PureComponent<WithTranslation, SalesTurnoverPrintState> {
    constructor(props: any) {
        super(props);

        //load data from localStorage
        const stringData = localStorage.getItem("sales-turnover-print");
        const jsonData = stringData != null ? JSON.parse(stringData) : null;

        // remove data from localStorage
        // localStorage.removeItem("sales-turnover-print");

        let isFilterBySingleProduct = false;
        if (jsonData?.param && jsonData?.param.productList && jsonData?.param.productList.length === 1) {
            // only query single product
            isFilterBySingleProduct = true;
        }

        let _sumQty: any[] = [];
        if (jsonData && !isFilterBySingleProduct) {
            _sumQty.push(
                jsonData?.data.reduce((sumQty: string, obj: any) => SafeBig(sumQty).add(obj.qty).toString(), "0")
            );
        } else {
            const product = jsonData.optionAllProduct.find(
                (_product: any) => _product.id === jsonData.param.productIdList[0]
            );

            if (product) {
                product.altUnitList.forEach((val: any) => {
                    _sumQty.push({ key: val.name, sum: 0 });
                });
                _sumQty.push({ key: product.unit.name, sum: 0 });
                jsonData.data.forEach((val: any) => {
                    let unit = _sumQty.find((_unit: any) => _unit.key === val.unit);
                    unit.sum = SafeBig(val.qty)
                        .add(unit.sum ?? 0)
                        .toString();
                });
            }
        }
        //calculation
        let _grandTotal = jsonData?.data.reduce((total: any, val: any) => SafeBig(total).add(val.subtotal), "0");
        let _sumTotal = jsonData?.data.reduce((total: any, val: any) => SafeBig(total).add(val.price), "0");

        let stateObj = {
            data: jsonData?.data ?? null,
            param: jsonData?.param ?? {},
            sumQty: _sumQty,
            grandTotal: _grandTotal,
            sumTotal: _sumTotal,
            isFilterBySingleProduct,
        };

        this.state = stateObj;
    }

    render() {
        const { t } = this.props;
        const { param, data } = this.state;
        if (data == null) return <p>No Data</p>;

        let columns = data.map((obj: any, index: number) => {
            return (
                <tr key={"detail-" + index}>
                    <td className="rowNo">{index + 1}</td>
                    <td>{showDate(obj.documentDate)}</td>
                    <td>{obj.key}</td>
                    <td>{obj.customer}</td>
                    <td>{obj.product}</td>
                    <td>
                        {showQty(obj.qty)} {obj.unit}
                    </td>
                    <td>{showCurrency(obj.price)}</td>
                    <td style={{ textAlign: "right" }}>{showCurrency(obj.subtotal, 2)}</td>
                </tr>
            );
        });

        return (
            <PrintArea>
                <Table className="ra-table-print">
                    <HTMLPrintHeader param={param} />
                    <tbody>
                        <tr>
                            <td colSpan={3}>
                                <Table className="ra-table-detail-print">
                                    <thead>
                                        <tr>
                                            <th className="rowNo">#</th>
                                            <th>{t("common.date")}</th>
                                            <th>{t("common.documentNo")}</th>
                                            <th>{t("common.customer")}</th>
                                            <th className="product">{t("common.product")}</th>
                                            <th className="qty">{t("common.qty")}</th>
                                            <th className="price">{t("common.price")}</th>
                                            <th className="price">{t("common.subTotal")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {columns}
                                        <tr>
                                            <td colSpan={5}>
                                                <b>{t("common.total")}: </b>
                                            </td>
                                            <td>
                                                {this.state.sumQty.map((val: any) => {
                                                    if (this.state.isFilterBySingleProduct)
                                                        return `${showQty(val.sum)} ${val.key} `;
                                                    return `${showQty(val)}`;
                                                })}
                                            </td>
                                            <td>{showCurrency(this.state.sumTotal, 2)}</td>
                                            <td style={{ textAlign: "right" }}>
                                                {showCurrency(this.state.grandTotal, 2)}
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </PrintArea>
        );
    }
}

export default withTranslation()(SalesTurnoverPrint);

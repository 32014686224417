import Big from "big.js";

export type PivotRow = {
    [key: string]: any;
};

export type HybridReportOutput = {
    type: "list" | "custom";
    meta: any;
    sourceInfoList: any[];
    listData: any[];
    columnDef?: { header: string; key: string }[];
};

export type PivotedList = {
    pivotedList: PivotedList[];
    pivot: PivotRow[];
    list?: HybridReportOutput[];
} | null;

/**
 * This method helps us change from tabular list into pivoted list
 * will return an array consist pivot and pivotedList
 * @param pivotNames array of strings which represent the key's of pivoted list with max 1 descendants
 * @param tabularList primary list (table) which we want to parse into pivoted list
 * @param options extra parameters for developer only
 * @returns PivotedList[]
 */
let _parseListIntoPivotedList = (pivotNames: string[], tabularList: any): PivotedList[] => {
    let _pivotNames: string[] = pivotNames.filter((val: string) => !val.includes("."));
    let _childPivotNames: string[] = pivotNames.filter((val: string) => val.includes("."));
    _childPivotNames = _childPivotNames.map((val: string) => {
        let splittedStrings = val.split(".");
        return splittedStrings[1];
    });
    let pivots: PivotedList[] = tabularList.reduce((result: any, current: any) => {
        let pivot: any = [];
        // it's only suport 1 key pivot
        // key pivot REQUIRE only in the first index [0]
        // building pivots, merge the value of each pivot name
        _pivotNames.forEach((val: string, i: number) => {
            // set the pivot key value
            if (
                i === 0 &&
                current[val] &&
                !result.find((res: any) => res.pivot[val] && res.pivot[val] === current[val].value)
            ) {
                pivot[val] = current[val].value;
            }
            // set non key pivot value
            if (
                i !== 0 &&
                current[val] &&
                !result.find(
                    (res: any) =>
                        res.pivot[val] &&
                        res.pivot[val] === current[val].value &&
                        res.pivot[_pivotNames[0]] === current[_pivotNames[0]].value
                )
            ) {
                pivot[val] = current[val].value;
            }
        });

        if (Object.keys(pivot).length > 0) {
            result.push({ pivot });
        }

        return result;
    }, []);

    //@ts-ignore
    pivots.forEach((val: any) => {
        let list = tabularList.filter((value: any) => value[_pivotNames[0]]?.value === val.pivot[_pivotNames[0]]);
        if (_childPivotNames && _childPivotNames.length > 0) {
            val.pivotedList = _parseListIntoPivotedList(_childPivotNames, list);
        } else {
            val.list = list;
        }
    });

    return pivots;
};

let _tranformToRegularList = (tabularList: any): PivotedList[] => {
    let _pivots: PivotedList[] = [];
    let pivot: PivotedList = {
        pivot: [],
        pivotedList: [],
        list: tabularList,
    };
    _pivots.push(pivot);
    return _pivots;
};

let parseListIntoPivotedList = (pivotNames: string[], tabularList: any, option?: { debug: boolean }) => {
    let startTime: Big = Big(performance.now()),
        endTime: Big,
        ellapsedTime: string;
    let result: PivotedList[] = [];
    if (pivotNames.length > 0) {
        result = _parseListIntoPivotedList(pivotNames, tabularList);
    } else {
        result = _tranformToRegularList(tabularList);
    }
    if (option && option.debug) {
        endTime = Big(performance.now());
        //@ts-ignore
        ellapsedTime = Math.round((endTime - startTime) / 1000, 2);
        ellapsedTime = endTime.minus(startTime).div(1000).toFixed(2);
        console.log(`Parsing ${tabularList.length} data in ${ellapsedTime} seconds`);
        console.log("Result: ", result);
    }
    return result;
};

export { parseListIntoPivotedList };

import dayjs from "dayjs";
import React from "react";
import { useTranslation } from "react-i18next";
import { getDataFromSingleCache } from "../../function/cache";

type PrintFooterProps = {
    footerType?: string;
    status?: string;
};

const PrintFooter = React.memo(({ footerType, status }: PrintFooterProps) => {
    const { t } = useTranslation();
    const stampData = getDataFromSingleCache("setting/stamp");
    const imgStamp = stampData ? (
        <span>
            <img
                src={stampData}
                alt="Stamp"
                style={{
                    height: "100px",
                }}
            />
            <br />
        </span>
    ) : (
        ""
    );
    let elm;
    switch (footerType) {
        case "deliveryReport":
        case "deliveryReportDebitMemo":
            elm = (
                <tbody>
                    <tr>
                        <td style={{ width: "25%" }}>
                            {t("common.preparedBy")}
                            <br />
                            <br />
                            <br />
                            <br />
                        </td>
                        <td style={{ width: "25%" }}>
                            {t("common.approvedBy")}
                            <br />
                            <br />
                        </td>
                        <td style={{ width: "25%" }}>
                            {t("common.sentBy")}
                            <br />
                            <br />
                        </td>
                        <td style={{ width: "25%" }}>
                            {t("common.receivedBy")}
                            <br />
                            <br />
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={4}>
                            {t("common.printDate")}: {dayjs(new Date()).format("DD-MM-YYYY HH:mm:ss")}
                        </td>
                    </tr>
                </tbody>
            );
            break;
        case "sales":
            elm = (
                <tbody>
                    <tr>
                        <td style={{ width: "30%" }}>
                            {t("common.preparedBy")}
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                        </td>
                        <td style={{ width: "30%" }}>
                            {t("common.receivedBy")}
                            <br />
                            <br />
                        </td>
                        <td style={{ width: "40%" }}>
                            <b>
                                {t("common.status")}: {t(`common.${status}`)}
                            </b>
                            {imgStamp ? (
                                <span>
                                    <br /> <br /> {imgStamp}
                                </span>
                            ) : (
                                ""
                            )}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={4}>
                            {t("common.printDate")}: {dayjs(new Date()).format("DD-MM-YYYY HH:mm:ss")}
                        </td>
                    </tr>
                </tbody>
            );
            break;
        case "salesReceipt":
            elm = (
                <tbody>
                    <tr>
                        <td style={{ width: "25%" }}>
                            {t("common.preparedBy")}
                            <br />
                            <br />
                            <br />
                            <br />
                        </td>
                        <td style={{ width: "25%" }}>
                            {t("common.receivedBy")}
                            <br />
                            <br />
                        </td>
                        <td style={{ width: "50%" }} />
                    </tr>
                    <tr>
                        <td colSpan={4}>
                            {t("common.printDate")}: {dayjs(new Date()).format("DD-MM-YYYY HH:mm:ss")}
                        </td>
                    </tr>
                </tbody>
            );
            break;
        case "purchase":
            elm = (
                <tbody>
                    <tr>
                        <td style={{ width: "25%" }}>
                            {t("common.receivedBy")}
                            <br />
                            <br />
                            <br />
                            <br />
                        </td>
                        <td style={{ width: "25%" }}>
                            {t("common.checkedBy")}
                            <br />
                            <br />
                        </td>
                        <td style={{ width: "50%" }}>
                            <b>
                                {t("common.status")}: {t(`common.${status}`)}
                            </b>
                            {imgStamp ? (
                                <span>
                                    <br /> <br /> {imgStamp}
                                </span>
                            ) : (
                                ""
                            )}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={4}>
                            {t("common.printDate")}: {dayjs(new Date()).format("DD-MM-YYYY HH:mm:ss")}
                        </td>
                    </tr>
                </tbody>
            );
            break;
        case "receiveReport":
            elm = (
                <tbody>
                    <tr>
                        <td style={{ width: "25%" }}>
                            {t("common.receivedBy")}
                            <br />
                            <br />
                            <br />
                            <br />
                        </td>
                        <td style={{ width: "25%" }}>
                            {t("common.checkedBy")}
                            <br />
                            <br />
                        </td>
                        <td style={{ width: "25%" }} />
                        <td style={{ width: "25%" }} />
                    </tr>
                    <tr>
                        <td colSpan={4}>
                            {t("common.printDate")}: {dayjs(new Date()).format("DD-MM-YYYY HH:mm:ss")}
                        </td>
                    </tr>
                </tbody>
            );
            break;
        case "transaction":
        case "jobCompletion":
        case "jobOrder":
            elm = (
                <tbody>
                    <tr>
                        <td>
                            {t("common.printDate")}: {dayjs(new Date()).format("DD-MM-YYYY HH:mm:ss")}
                        </td>
                    </tr>
                </tbody>
            );
            break;

        default:
            elm = (
                <tbody>
                    <tr>
                        <td style={{ width: "25%" }}>
                            {t("common.preparedBy")}
                            <br />
                            <br />
                            <br />
                            <br />
                        </td>
                        <td style={{ width: "25%" }}>{t("common.approvedBy")}</td>
                        <td style={{ width: "50%" }}>
                            <b>
                                {t("common.status")}: {t(`common.${status}`)}
                            </b>
                            {imgStamp ? (
                                <span>
                                    <br /> <br /> {imgStamp}
                                </span>
                            ) : (
                                ""
                            )}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={4}>
                            {t("common.printDate")}: {dayjs(new Date()).format("DD-MM-YYYY HH:mm:ss")}
                        </td>
                    </tr>
                </tbody>
            );
            break;
    }
    return <table className="ra-footer-table">{elm}</table>;
});

export default PrintFooter;

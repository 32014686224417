import reducerRegistry from "../../common/reduxHandler/reducerRegistry";
import { reqPost, reqGet } from "../../common/function/request";
import { setCreateEditParam } from "../../common/function/requestParamHelper";

//initial state
const initialState: CommonReduxState = {
    loading: false,
    data: null,
    error: null,
};

//action
const createActionName = (name: string) => `periodLock/${name}`;
const PERIOD_LOCK = createActionName("CREATE_PERIOD_LOCK");
const PERIOD_LOCK_DONE = createActionName("CREATE_PERIOD_LOCK_DONE");
const PERIOD_LOCK_FAILED = createActionName("CREATE_PERIOD_LOCK_FAILED");
const PERIOD_UNLOCK = createActionName("UPDATE_PERIOD_UNLOCK");
const PERIOD_UNLOCK_DONE = createActionName("UPDATE_PERIOD_UNLOCK_DONE");
const PERIOD_UNLOCK_FAILED = createActionName("UPDATE_PERIOD_UNLOCK_FAILED");
const PERIOD_RELOCK = createActionName("UPDATE_PERIOD_RELOCK");
const PERIOD_RELOCK_DONE = createActionName("UPDATE_PERIOD_RELOCK_DONE");
const PERIOD_RELOCK_FAILED = createActionName("UPDATE_PERIOD_RELOCK_FAILED");
const PERIOD_CLOSE = createActionName("CREATE_PERIOD_CLOSE");
const PERIOD_CLOSE_DONE = createActionName("CREATE_PERIOD_CLOSE_DONE");
const PERIOD_CLOSE_FAILED = createActionName("CREATE_PERIOD_CLOSE_FAILED");
const PERIOD_REOPEN = createActionName("UPDATE_PERIOD_REOPEN");
const PERIOD_REOPEN_DONE = createActionName("UPDATE_PERIOD_REOPEN_DONE");
const PERIOD_REOPEN_FAILED = createActionName("UPDATE_PERIOD_REOPEN_FAILED");
const FETCH_PERIOD_DATE_CONSTRAINT = createActionName("FETCH_PERIOD_DATE_CONSTRAINT");
const FETCH_PERIOD_DATE_CONSTRAINT_DONE = createActionName("FETCH_PERIOD_DATE_CONSTRAINT_DONE");
const FETCH_PERIOD_DATE_CONSTRAINT_FAILED = createActionName("FETCH_PERIOD_DATE_CONSTRAINT_FAILED");

//reducer
function periodLock(state = initialState, action: DispatchedAction<APIBaseDataResponse>) {
    switch (action.type) {
        case PERIOD_LOCK:
            return { ...initialState, loading: true };
        case PERIOD_LOCK_DONE:
            // console.log(action.payload);
            return { ...state, data: action.payload.meta.status, loading: false };
        case PERIOD_LOCK_FAILED:
            return { ...state, error: action.error, loading: false };
        default:
            return state;
    }
}

function periodUnlock(state = initialState, action: DispatchedAction<APIBaseDataResponse>) {
    switch (action.type) {
        case PERIOD_UNLOCK:
            return { ...initialState, loading: true };
        case PERIOD_UNLOCK_DONE:
            return { ...state, data: action.payload.meta.status, loading: false };
        case PERIOD_UNLOCK_FAILED:
            return { ...state, error: action.error, loading: false };
        default:
            return state;
    }
}

function periodRelock(state = initialState, action: DispatchedAction<APIBaseDataResponse>) {
    switch (action.type) {
        case PERIOD_RELOCK:
            return { ...initialState, loading: true };
        case PERIOD_RELOCK_DONE:
            return { ...state, data: action.payload.meta.status, loading: false };
        case PERIOD_RELOCK_FAILED:
            return { ...state, error: action.error, loading: false };
        default:
            return state;
    }
}

function periodClose(state = initialState, action: DispatchedAction<APIBaseDataResponse>) {
    switch (action.type) {
        case PERIOD_CLOSE:
            return { ...initialState, loading: true };
        case PERIOD_CLOSE_DONE:
            return { ...state, data: action.payload.meta.status, loading: false };
        case PERIOD_CLOSE_FAILED:
            return { ...state, error: action.error, loading: false };
        default:
            return state;
    }
}

function periodReopen(state = initialState, action: DispatchedAction<APIBaseDataResponse>) {
    switch (action.type) {
        case PERIOD_REOPEN:
            return { ...initialState, loading: true };
        case PERIOD_REOPEN_DONE:
            return { ...state, data: action.payload.meta.status, loading: false };
        case PERIOD_REOPEN_FAILED:
            return { ...state, error: action.error, loading: false };
        default:
            return state;
    }
}

function periodDateConstraint(state = initialState, action: DispatchedAction<APIBaseDataResponse>) {
    switch (action.type) {
        case FETCH_PERIOD_DATE_CONSTRAINT:
            return { ...initialState, loading: true };
        case FETCH_PERIOD_DATE_CONSTRAINT_DONE:
            return { ...state, data: action.payload.data, loading: false };
        case FETCH_PERIOD_DATE_CONSTRAINT_FAILED:
            return { ...state, error: action.error, loading: false };
        default:
            return state;
    }
}

//register reducer
reducerRegistry.register("periodLock", periodLock);
reducerRegistry.register("periodUnlock", periodUnlock);
reducerRegistry.register("periodRelock", periodRelock);
reducerRegistry.register("periodClose", periodClose);
reducerRegistry.register("periodReopen", periodReopen);
reducerRegistry.register("periodDateConstraint", periodDateConstraint);

//selector
export const slcPeriodLock = (state: any): CommonReduxState => state["periodLock"];
export const slcPeriodUnlock = (state: any): CommonReduxState => state["periodUnlock"];
export const slcPeriodRelock = (state: any): CommonReduxState => state["periodRelock"];
export const slcPeriodClose = (state: any): CommonReduxState => state["periodClose"];
export const slcPeriodReopen = (state: any): CommonReduxState => state["periodReopen"];
export const slcPeriodDateConstraint = (state: any): CommonReduxState => state["periodDateConstraint"];

//action creator

/**
 * Lock period
 */
export function actRunPeriodLock(inputs: any) {
    const params = setCreateEditParam(inputs);
    const request = reqPost(`lock-period`, params);

    return (dispatch: any) => {
        dispatch({
            type: PERIOD_LOCK,
            addToLoading: true,
        });
        request
            .then((payload: any) => {
                dispatch({
                    type: PERIOD_LOCK_DONE,
                    payload: payload.data,
                });
                //update constraint | refine-inventory won't reach here
                actFetchPeriodDateConstraint();
            })
            .catch((error) => {
                dispatch({
                    type: PERIOD_LOCK_FAILED,
                    error,
                });
            });
    };
}

/**
 * Unlock period
 */
export function actRunPeriodUnlock(id: number) {
    const request = reqPost(`unlock-period/${id}`, {});

    return (dispatch: any) => {
        dispatch({
            type: PERIOD_UNLOCK,
            addToLoading: true,
        });
        request
            .then((payload: any) => {
                dispatch({
                    type: PERIOD_UNLOCK_DONE,
                    payload: payload.data,
                });
                //update constraint | refine-inventory won't reach here
                dispatch(actFetchPeriodDateConstraint());
            })
            .catch((error) => {
                dispatch({
                    type: PERIOD_UNLOCK_FAILED,
                    error,
                });
            });
    };
}

/**
 * Relock previously unlocked period
 */
export function actRunPeriodRelock(id: number) {
    const request = reqPost(`relock-period/${id}`, {});

    return (dispatch: any) => {
        dispatch({
            type: PERIOD_RELOCK,
            addToLoading: true,
        });
        request
            .then((payload: any) => {
                dispatch({
                    type: PERIOD_RELOCK_DONE,
                    payload: payload.data,
                });
                //update constraint | refine-inventory won't reach here
                dispatch(actFetchPeriodDateConstraint());
            })
            .catch((error) => {
                dispatch({
                    type: PERIOD_RELOCK_FAILED,
                    error,
                });
            });
    };
}

export function actRunPeriodClose(id: number) {
    const request = reqPost(`close-period/${id}`, {});

    return (dispatch: any) => {
        dispatch({
            type: PERIOD_CLOSE,
            addToLoading: true,
        });
        request
            .then((payload: any) => {
                dispatch({
                    type: PERIOD_CLOSE_DONE,
                    payload: payload.data,
                });
                actFetchPeriodDateConstraint();
            })
            .catch((error) => {
                dispatch({
                    type: PERIOD_CLOSE_FAILED,
                    error,
                });
            });
    };
}

export function actRunReopenPeriod(id: number) {
    const request = reqPost(`reopen-period/${id}`, {});

    return (dispatch: any) => {
        dispatch({
            type: PERIOD_REOPEN,
            addToLoading: true,
        });
        request
            .then((payload: any) => {
                dispatch({
                    type: PERIOD_REOPEN_DONE,
                    payload: payload.data,
                });
                actFetchPeriodDateConstraint();
            })
            .catch((error) => {
                dispatch({
                    type: PERIOD_REOPEN_FAILED,
                    error,
                });
            });
    };
}

/**
 * Get period date constraint
 *
 * Used to constraint InputDateConstrained
 */
export function actFetchPeriodDateConstraint() {
    let param: APIGetParameter;
    param = {
        orderBy: "lockDate",
        orderDir: "DESC",
        all: true,
    };
    const request = reqGet(`setting-period`, param);

    return (dispatch: any) => {
        dispatch({
            type: FETCH_PERIOD_DATE_CONSTRAINT,
        });
        request
            .then((payload: any) => {
                dispatch({
                    type: FETCH_PERIOD_DATE_CONSTRAINT_DONE,
                    payload: payload.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: FETCH_PERIOD_DATE_CONSTRAINT_FAILED,
                    error,
                });
            });
    };
}

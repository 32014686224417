import React, { PureComponent } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Table } from "reactstrap";
import HTMLPrintHeader from "../../../common/component/HTMLPrintHeader";
import PrintArea from "../../../common/component/PrintArea";
import { showCurrency, showDate, showQty } from "../../../common/function/showValueHelper";
import { titleCase } from "change-case";
import Big from "big.js";

type PurchaseOrderSummaryPrintState = {
    data: any;
    param: any;
    // date: any;
};

class PurchaseOrderSummaryPrint extends PureComponent<WithTranslation, PurchaseOrderSummaryPrintState> {
    constructor(props: any) {
        super(props);

        //load data from localStorage
        const stringData = localStorage.getItem("purchase-order-summary-print");
        const jsonData = stringData != null ? JSON.parse(stringData) : null;

        // remove data from localStorage
        // localStorage.removeItem("sales-order-summary-print");

        let stateObj = {
            data: jsonData?.data ?? null,
            param: jsonData?.param ?? {},
            // date
        };

        this.state = stateObj;
    }

    render() {
        const { t } = this.props;
        const { param, data } = this.state;
        if (data == null) return <p>No Data</p>;

        let totalResQty = Big(0);
        let totalOrderedQty = Big(0);
        let totalAmount = Big(0);

        let columns = data.map((obj: any, index: number) => {
            totalResQty = totalResQty.add(obj.remainingQty);
            totalOrderedQty = totalOrderedQty.add(obj.orderQty);
            totalAmount = totalAmount.add(obj.remainingAmount);
            return (
                <tr key={"detail-" + index}>
                    <td className="rowNo">{index + 1}</td>
                    <td>{showDate(obj.documentDate)}</td>
                    <td>{obj.key}</td>
                    <td>{titleCase(obj.status)}</td>
                    <td>{obj.supplier}</td>
                    <td>{obj.product}</td>
                    <td>{showCurrency(obj.priceAfterDiscount)}</td>
                    <td>
                        {showQty(obj.orderQty, 2)} {obj.unit}
                    </td>
                    <td>
                        {showQty(obj.remainingQty, 2)} {obj.unit}
                    </td>
                    <td style={{ textAlign: "right" }}>{showCurrency(obj.remainingAmount, 2)}</td>
                </tr>
            );
        });

        return (
            <PrintArea>
                <Table className="ra-table-print">
                    <HTMLPrintHeader param={param} />
                    <tbody>
                        <tr>
                            <td colSpan={3}>
                                <Table className="ra-table-detail-print">
                                    <colgroup>
                                        <col style={{ width: "2%" }} />
                                        <col style={{ width: "8%" }} />
                                        <col style={{ width: "10%" }} />
                                        <col style={{ width: "5%" }} />
                                        <col style={{ width: "20%" }} />
                                        <col style={{ width: "15%" }} />
                                        <col style={{ width: "10%" }} />
                                        <col style={{ width: "10%" }} />
                                        <col style={{ width: "10%" }} />
                                        <col style={{ width: "10%" }} />
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>{t("common.date")}</th>
                                            <th>{t("common.documentNo")}</th>
                                            <th>{t("common.status")}</th>
                                            <th>{t("common.supplier")}</th>
                                            <th>{t("common.product")}</th>
                                            <th>{t("common.price")}</th>
                                            <th>{t("common.orderQty")}</th>
                                            <th>{t("common.remainingToSend")}</th>
                                            <th>{t("common.remainingAmount")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {columns}
                                        <tr>
                                            <td colSpan={7}>
                                                <b>{t("common.total")}:</b>
                                            </td>
                                            <td>{showQty(totalOrderedQty)}</td>
                                            <td>{showQty(totalResQty)}</td>
                                            <td style={{ textAlign: "right" }}>{showCurrency(totalAmount)}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </PrintArea>
        );
    }
}

export default withTranslation()(PurchaseOrderSummaryPrint);

import React from "react";
import { useTranslation } from "react-i18next";
import changeCase from "change-case";
import { showDate } from "../../function/showValueHelper";
import { getDataFromSingleCache } from "../../function/cache";

export enum TransactionType {
    IN = "IN",
    OUT = "OUT",
}

type PrintHeaderTransactionProps = {
    title: string;
    companyInfo: any;
    supplier?: any;
    customer?: any;
    key?: string;
    no?: string;
    dtCreated: string;
    type: TransactionType;
    description?: string;
};

const PrintHeaderTransaction = React.memo(
    ({ title, companyInfo, supplier, customer, no, dtCreated, type, description }: PrintHeaderTransactionProps) => {
        const { t } = useTranslation();
        const imgData = getDataFromSingleCache("setting/logo");
        let to = supplier != null ? supplier : customer;
        let noSupplierCustomerFlag = supplier == null && customer == null;
        let headerElm = imgData ? (
            <tr>
                <th className="docTitle" style={{ width: "60%" }}>
                    <img src={imgData} height="80px" className="logo" alt="logo" />
                </th>
                <th className="docTitle" style={{ textAlign: "left" }}>
                    <span style={{ verticalAlign: "bottom" }}>{changeCase.upper(title)}</span>
                </th>
            </tr>
        ) : (
            <tr>
                <th colSpan={2} className="docTitle" style={{ verticalAlign: "bottom" }}>
                    {changeCase.upper(title)}
                </th>
            </tr>
        );
        return (
            <thead>
                {headerElm}
                <tr style={{ marginBottom: "10px" }}>
                    <th style={{ width: "60%" }}>
                        <b>
                            {t("common.documentNo")}
                            <br />
                            {no}
                        </b>
                    </th>
                    <th>
                        <b>
                            {t("common.createDate")}
                            <br />
                            {showDate(dtCreated)}
                        </b>
                    </th>
                </tr>
                { noSupplierCustomerFlag === false ? (
                    <>

                        <tr>
                            <th>{type === TransactionType.IN ? t("common.paymentFrom") : t("common.paymentTo")}:</th>
                            <th>{t("common.description")}:</th>
                        </tr>
                        <tr>
                            <th>{to.name}</th>
                            <th rowSpan={4}>{description ?? ""}</th>
                        </tr>
                        <tr>
                            <th colSpan={2}>
                                {to.address}
                                {to.city ? ", " + to.city + "." : ""}
                            </th>
                        </tr>
                        <tr>
                            <th colSpan={2}>
                                {t("common.email")}: {to.email ? to.email : ""}
                            </th>
                        </tr>
                        <tr>
                            <th colSpan={2}>
                                {t("common.telephoneNo")}: {to.phone ? to.phone : ""}
                            </th>
                        </tr>
                    </>
                ) : ""}
            </thead>
        );
    }
);

export default PrintHeaderTransaction;

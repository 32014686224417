import React, { PureComponent } from "react";
import SimpleBox from "../SimpleBox";
import { InputText, InputNumber, InputDate, InputSelect, InputTextArea } from "../Input";
import { Field, reduxForm, InjectedFormProps } from "redux-form";
import { Row, Col, Button, Form, Badge } from "reactstrap";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { InputMonth } from "../Input/InputMonth";
import dayjs from "dayjs";

/** Currently can't use form, because this component may shown multiple times in one page (reduxForm-name problem) */

export enum INPUT_TYPE {
    TEXT,
    TEXTAREA,
    NUMBER,
    SELECT,
    MULTI_SELECT,
    DATE,
    DATE_RANGE,
    MODE,
    MONTH,
}

type PanelSearchFilterProps = {
    formName: string;
    fieldList: SearchFieldProps[];
    doSearch: (values: any, e: any) => void;
    clearSearch: Function;
    colSize?: number;
    filterOn?: boolean;
    disableClearSearch?: boolean;
    disableMode?: boolean;
    sideText?: string;
};

type PanelSearchFilterState = {
    fieldList: SearchFieldProps[];
};

class PanelSearchFilter extends PureComponent<
    PanelSearchFilterProps & WithTranslation & InjectedFormProps,
    PanelSearchFilterState
> {
    constructor(props: any) {
        super(props);

        let fieldList = [];
        // console.log(this.props.fieldList);
        if (this.props.fieldList.length > 1 && !this.props.disableMode) {
            fieldList = [
                { label: "common.mode", name: "mode", type: INPUT_TYPE.MODE }, //add mode
                ...this.props.fieldList,
            ];
        } else {
            fieldList = [...this.props.fieldList];
        }
        this.state = {
            fieldList,
        };
    }

    componentDidUpdate(prevProps: any) {
        //handling option changes for input select
        if (this.props.fieldList !== prevProps.fieldList) {
            let fieldList = [];
            if (this.props.fieldList.length > 1) {
                fieldList = [
                    { label: "common.mode", name: "mode", type: INPUT_TYPE.MODE }, //add mode
                    ...this.props.fieldList,
                ];
            } else {
                fieldList = [...this.props.fieldList];
            }
            this.setState({
                fieldList,
            });
        }
    }

    /**
     * Dynamically render search fields
     */
    buildFieldComponent() {
        const { t } = this.props;

        let renderedField = [];
        for (let i = 0; i < this.state.fieldList.length; i++) {
            let fieldProps: SearchFieldProps = this.state.fieldList[i];
            let child = null;
            if (fieldProps.type === INPUT_TYPE.TEXT) {
                child = <Field placeholder="" name={fieldProps.name} label={fieldProps.label} component={InputText} />;
            }
            if (fieldProps.type === INPUT_TYPE.TEXTAREA) {
                child = (
                    <Field placeholder="" name={fieldProps.name} label={fieldProps.label} component={InputTextArea} />
                );
            } else if (fieldProps.type === INPUT_TYPE.NUMBER) {
                child = (
                    <Field placeholder="" name={fieldProps.name} label={fieldProps.label} component={InputNumber} />
                );
            } else if (fieldProps.type === INPUT_TYPE.DATE) {
                child = <Field placeholder="" name={fieldProps.name} label={fieldProps.label} component={InputDate} />;
            } else if (fieldProps.type === INPUT_TYPE.DATE_RANGE) {
                child = (
                    <Row>
                        <Col xs="12">
                            <label>{t(fieldProps.label)}</label>
                            {/* <small className="help-block">{t("common.filterUsingDateRange")}</small> */}
                        </Col>
                        <Col xs="12" sm="6">
                            <Field
                                placeholder="common.startDate"
                                name={fieldProps.name + "@start"}
                                component={InputDate}
                            />
                        </Col>
                        <Col xs="12" sm="6">
                            <Field placeholder="common.endDate" name={fieldProps.name + "@end"} component={InputDate} />
                        </Col>
                    </Row>
                );
            } else if (fieldProps.type === INPUT_TYPE.MONTH) {
                child = <Field placeholder="" name={fieldProps.name} label={fieldProps.label} component={InputMonth} />;
            } else if (fieldProps.type === INPUT_TYPE.SELECT) {
                child = (
                    <Field
                        placeholder=""
                        name={fieldProps.name}
                        label={fieldProps.label}
                        component={InputSelect}
                        options={fieldProps.options}
                        isClearable={true}
                    />
                );
            } else if (fieldProps.type === INPUT_TYPE.MULTI_SELECT) {
                child = (
                    <Field
                        placeholder=""
                        name={fieldProps.name}
                        label={fieldProps.label}
                        component={InputSelect}
                        options={fieldProps.options}
                        isClearable={true}
                        multi={true}
                    />
                );
            } else if (fieldProps.type === INPUT_TYPE.MODE) {
                child = (
                    <Field
                        placeholder=""
                        name={fieldProps.name}
                        label={fieldProps.label}
                        component={InputSelect}
                        options={[{ value: "and", label: t("common.and") }, { value: "or", label: t("common.or") }]}
                    />
                );
            } else if (fieldProps.type === INPUT_TYPE.TEXTAREA) {
                child = (
                    <Field placeholder="" name={fieldProps.name} label={fieldProps.label} component={InputTextArea} />
                );
            }

            if (child != null) {
                renderedField.push(
                    <Col key={i} xs="12" sm={this.props.colSize ? this.props.colSize : "6"}>
                        {child}
                    </Col>
                );
            }
        }

        return renderedField;
    }

    clearSearch = () => {
        this.props.reset(); //react-table already updated, no need to reset that table (because select have reset bug)
        this.props.clearSearch();

        //manually remove initialvalue from inputField
        for (let field of this.props.fieldList) {
            if (field.initialValue != null) {
                if (field.name.includes("Date")) {
                    this.props.change(`${field.name}@start`, null);
                    this.props.change(`${field.name}@end`, null);
                } else {
                    this.props.change(field.name, null);
                }
            }
        }
    };

    render() {
        const { t, handleSubmit, filterOn, disableClearSearch, sideText } = this.props;
        return (
            <SimpleBox
                title="common.searchAndFilter"
                rightTitle={filterOn ? "common.filterOn" : null}
                icon="search"
                color="success"
                initialCollapse={true}
                headerTag="h6"
                collapsible
                sideComponent={
                    filterOn && sideText != null ? (
                        <Badge color="primary" style={{ marginLeft: "5px" }}>
                            {sideText}
                        </Badge>
                    ) : null
                }
            >
                <Form onSubmit={handleSubmit(this.props.doSearch)}>
                    <Row>{this.buildFieldComponent()}</Row>
                    <Row>
                        <Col xs="6" sm="3">
                            <Button
                                color="primary"
                                onClick={handleSubmit(this.props.doSearch)}
                                /*style={{marginRight:"10px"}}*/ block
                            >
                                {t("common.search")}
                            </Button>
                        </Col>

                        {!disableClearSearch && (
                            <Col xs="6" sm="3">
                                <Button color="danger" onClick={this.clearSearch} block>
                                    {t("common.clearSearch")}
                                </Button>
                            </Col>
                        )}
                    </Row>
                </Form>
            </SimpleBox>
        );
    }
}

function mapStateToProps(state: any, ownProps: PanelSearchFilterProps) {
    const initialFieldValue: any = {};
    for (let i = 0; i < ownProps.fieldList.length; i++) {
        const val = ownProps.fieldList[i];

        if (val.initialValue != null) {
            //special case -> need to separate value for date
            if (val.name.includes("Date") && val.avoidSplitDate !== true) {
                let split = val.initialValue.split("-");
                // if (!split[0].includes(":")) split[0] = split[0] + " 00:00:00"; //strange infinite loop error
                initialFieldValue[`${val.name}@start`] = dayjs(split[0], "DD/MM/YYYY").format("YYYY/MM/DD");
                initialFieldValue[`${val.name}@end`] = dayjs(split[1], "DD/MM/YYYY").format("YYYY/MM/DD");
            } else {
                initialFieldValue[val.name] = val.initialValue;
            }
        }
    }

    return {
        form: ownProps.formName, //dynamically set redux-form name key
        initialValues: {
            mode: "and",
            ...initialFieldValue,
        },
    };
}

export default connect(
    mapStateToProps,
    null
)(
    reduxForm<any, any>({ destroyOnUnmount: false, enableReinitialize: true })(
        //set options if any
        withTranslation()(PanelSearchFilter)
    )
);

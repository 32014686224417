import { PureComponent } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Table } from "reactstrap";
import { showCurrency } from "../../../common/function/showValueHelper";

type FinancePositionProps = {
    data: any;
};

type FinancePositionState = {};

class FinancePosition extends PureComponent<WithTranslation & FinancePositionProps, FinancePositionState> {
    constructor(props: any) {
        super(props);
        let stateObj = {};
        this.state = stateObj;
    }

    render() {
        const { data } = this.props;
        // split left col = assets, right col = liability & equity
        let rightIndex = data.listData.findIndex((elm: any) => elm.description?.value === "KEWAJIBAN LANCAR");
        let assetsData = data.listData.slice(0, rightIndex - 1);
        let liabilitiesData = data.listData.slice(rightIndex);
        console.log(liabilitiesData);
        let assetsResult = assetsData.map((val: any, i: number) => {
            let keys = Object.keys(val);
            if (i < 3 || keys.length === 0) return <></>;
            if (keys.length === 1)
                return (
                    <tr key={`financePos-${i}`}>
                        <th colSpan={2}>{val.description?.value ?? ""}</th>
                    </tr>
                );
            return (
                <tr key={`financeAssetsPos-${i}`}>
                    <td>{val.description?.value ?? ""}</td>
                    <td>{showCurrency(val.debit?.value)}</td>
                </tr>
            );
        });
        let liabilitiesEqResult = liabilitiesData.map((val: any, i: number) => {
            let keys = Object.keys(val);
            if (keys.length === 0) return <></>;
            if (keys.length === 1)
                return (
                    <tr key={`financePos-${i}`}>
                        <th colSpan={2}>{val.description?.value ?? ""}</th>
                    </tr>
                );
            return (
                <tr key={`financeLiaEqPos-${i}`}>
                    <td>{val.description?.value ?? ""}</td>
                    <td>{showCurrency(val.debit?.value)}</td>
                </tr>
            );
        });
        return (
            <div>
                <style>
                    {`
                        @page {
                            size: A4 landscape;
                        }

                        @media print {
                            table.ra-finance-pos-print th, table.ra-finance-pos-print td {
                                font-size: 5pt !important;
                            }
                        }
                        
                        table th, table td {
                            padding: 0 5px !important;
                            border: 1px solid transparent !important;
                            border-top: none !important;
                        }
                    `}
                </style>
                <Table>
                    <colgroup>
                        <col style={{ width: "50%" }}></col>
                        <col style={{ width: "50%" }}></col>
                    </colgroup>
                    <tbody>
                        <tr>
                            <td>
                                <Table className="ra-finance-pos-print">
                                    <colgroup>
                                        <col style={{ width: "70%" }} />
                                        <col style={{ width: "30%" }} />
                                    </colgroup>
                                    <tbody>{assetsResult}</tbody>
                                </Table>
                            </td>
                            <td>
                                <Table className="ra-finance-pos-print">
                                    <colgroup>
                                        <col style={{ width: "70%" }} />
                                        <col style={{ width: "30%" }} />
                                    </colgroup>
                                    <tbody>{liabilitiesEqResult}</tbody>
                                </Table>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </div>
        );
    }
}

export default withTranslation()(FinancePosition);

import React, { PureComponent } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Table } from "reactstrap";
import PrintArea from "../../../common/component/PrintArea";
import PrintHeader from "../../../common/component/PrintHeader";
import { SafeBig } from "../../../common/function/safeBig";
import { showCurrency, showQty } from "../../../common/function/showValueHelper";

type DebitMemoPrintState = {
    data: any;
    companyInfo: any;
    colSpan: number;
    settingHargaSatuan: boolean;
};

class DebitMemoPrint extends PureComponent<WithTranslation, DebitMemoPrintState> {
    constructor(props: any) {
        super(props);

        //load data from localStorage
        const stringData = localStorage.getItem("debitMemo-print");
        const jsonData = stringData != null ? JSON.parse(stringData) : null;

        // remove data from localStorage
        localStorage.removeItem("debitMemo-print");

        // console.log(jsonData);

        let stateObj = {
            data: jsonData && jsonData.data ? jsonData.data : null,
            companyInfo: jsonData && jsonData.companyInfo ? jsonData.companyInfo : null,
            colSpan: 3,
            settingHargaSatuan: jsonData.settingHargaSatuan ?? false
        };

        this.state = stateObj;
    }

    render() {
        const { t } = this.props;
        const { companyInfo, data } = this.state;
        if (data == null) return <p>No Data</p>;
        let columns = data.detailList.map((obj: any, index: number) => {
            let pUnitToSmallestUnitCompare = SafeBig(obj.productUnitConversion?.qty ?? 1).div(obj.product.smallestUnitConversion?.qty ?? 1);
            return (
                <tr key={"detail-" + index}>
                    <td className="rowNo">{index + 1}</td>
                    <td>{obj.product.name}</td>
                    <td className="qty">
                        {obj.unit == null ? (
                            <table className="printQtyTable">
                                <tbody>
                                    <tr key={"detail-1-" + index}>
                                        <td>{showQty(obj.qty) + " "}</td>
                                        <td>{obj.product?.unit?.name}</td>
                                    </tr>
                                </tbody>
                            </table>
                        ) : (
                            <table className="printQtyTable">
                                <tbody>
                                    { !obj.product.smallestUnit || obj.unit.id === obj.product.smallestUnit?.id ? (
                                        <tr>
                                            <td>{showQty(obj.qty) + " " + obj.unit?.name} @{ showQty(obj.productUnitConversion.qty) + " " + obj.product?.unit?.name } </td>
                                            <td>{showQty(SafeBig(obj.qty ?? 0).times(obj.productUnitConversion?.qty ?? 1)) + " " + obj.product.unit?.name}</td>
                                        </tr>
                                    ) : (
                                        <tr>
                                            <td>{showQty(obj.qty) + " " + obj.unit?.name} @{ showQty(pUnitToSmallestUnitCompare.toString()) + " " + obj.product.smallestUnit?.name } </td>
                                            <td>{showQty(SafeBig(obj.qty ?? 0).times(pUnitToSmallestUnitCompare ?? 1)) + " " + obj.product.smallestUnit?.name}</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        )}
                    </td>
                    <td className="price">{showCurrency(SafeBig(obj.price).div(pUnitToSmallestUnitCompare ?? 1), 2)}</td>
                    <td className="subTotal">{showCurrency(obj.subtotal, 2)}</td>
                </tr>
            );
        });

        return (
            <PrintArea>
                <Table className="ra-table-print">
                    <PrintHeader
                        title={t("common.debitMemo")}
                        companyInfo={companyInfo}
                        supplier={data.supplier}
                        no={data.key}
                        dtCreated={data.dtCreated}
                    />
                    <tbody>
                        <tr>
                            <td colSpan={2}>
                                <Table className="ra-table-detail-print">
                                    <thead>
                                        <tr>
                                            <th className="rowNo">#</th>
                                            <th>{t("common.product")}</th>
                                            <th className="qty">{t("common.qty")}</th>
                                            <th className="price">{t("common.price")}</th>
                                            <th className="subtotal">{t("common.subTotal")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {columns}
                                        <tr>
                                            <td colSpan={this.state.colSpan}>
                                                <b>{t("common.calculatedPrice")}: </b> <br />
                                                {data.say} {" "} Rupiah
                                            </td>
                                            <td style={{ textAlign: "right" }}>{t("common.taxBase")}</td>
                                            <td className="subTotal">{showCurrency(data.subtotal, 2)}</td>
                                        </tr>
                                        <tr>
                                            <td rowSpan={3} colSpan={this.state.colSpan}>
                                                {t("common.notes")}: {data.description}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ textAlign: "right" }}>{t("common.tax")}</td>
                                            <td className="subTotal">{showCurrency(data.vat, 2)}</td>
                                        </tr>
                                        {data.modifierCoa!=null &&
                                        <tr>
                                            <td style={{ textAlign: "right" }}>{data.modifierCoa.name}</td>
                                            <td className="subTotal">{showCurrency(data.modifierAmount, 2)}</td>
                                        </tr>}
                                        <tr>
                                            <td style={{ textAlign: "right" }}>{t("common.grandTotal")}</td>
                                            <td className="subTotal">{showCurrency(data.grandTotal, 2)}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </PrintArea>
        );
    }
}

export default withTranslation()(DebitMemoPrint);

import { getDataFromSingleCache, saveIntoSingleCache } from "../../common/function/cache";
import { reqDelete, reqGet, reqPost, reqPut } from "../../common/function/request";
import { setCreateEditParam } from "../../common/function/requestParamHelper";
import reducerRegistry from "../../common/reduxHandler/reducerRegistry";
import { actReloadInitConfig } from "./setting";

/**
 * All setting and their selector (for frontend functionality) are handled by setting.ts
 *
 * This redux handle view-list/editing setting config (similar to master-related redux)
 */

//initial state
const initialState: CommonReduxState = {
    loading: false,
    data: null,
    error: null,
};

//action
const createActionName = (name: string) => `settingDB/${name}`;
const FETCH_SETTING_DEFAULT_COA_LIST = createActionName("FETCH_SETTING_DEFAULT_COA_LIST");
const FETCH_SETTING_DEFAULT_COA_LIST_DONE = createActionName("FETCH_SETTING_DEFAULT_COA_LIST_DONE");
const FETCH_SETTING_DEFAULT_COA_LIST_FAILED = createActionName("FETCH_SETTING_DEFAULT_COA_LIST_FAILED");
const FETCH_SETTING_COMPANY_INFO_LIST = createActionName("FETCH_SETTING_COMPANY_INFO_LIST");
const FETCH_SETTING_COMPANY_INFO_LIST_DONE = createActionName("FETCH_SETTING_COMPANY_INFO_LIST_DONE");
const FETCH_SETTING_COMPANY_INFO_LIST_FAILED = createActionName("FETCH_SETTING_COMPANY_INFO_LIST_FAILED");
const FETCH_SETTING_APPLICATION_LIST = createActionName("FETCH_SETTING_APPLICATION_LIST");
const FETCH_SETTING_APPLICATION_LIST_DONE = createActionName("FETCH_SETTING_APPLICATION_LIST_DONE");
const FETCH_SETTING_APPLICATION_LIST_FAILED = createActionName("FETCH_SETTING_APPLICATION_LIST_FAILED");
const FETCH_SETTING_ACCOUNTING_LIST = createActionName("FETCH_SETTING_ACCOUNTING_LIST");
const FETCH_SETTING_ACCOUNTING_LIST_DONE = createActionName("FETCH_SETTING_ACCOUNTING_LIST_DONE");
const FETCH_SETTING_ACCOUNTING_LIST_FAILED = createActionName("FETCH_SETTING_ACCOUNTING_LIST_FAILED");
const FETCH_SETTING_ETC_LIST = createActionName("FETCH_SETTING_ETC_LIST");
const FETCH_SETTING_ETC_LIST_DONE = createActionName("FETCH_SETTING_ETC_LIST_DONE");
const FETCH_SETTING_ETC_LIST_FAILED = createActionName("FETCH_SETTING_ETC_LIST_FAILED");
const FETCH_SETTING_WEBVIEW_LIST = createActionName("FETCH_SETTING_WEBVIEW_LIST");
const FETCH_SETTING_WEBVIEW_LIST_DONE = createActionName("FETCH_SETTING_WEBVIEW_LIST_DONE");
const FETCH_SETTING_WEBVIEW_LIST_FAILED = createActionName("FETCH_SETTING_WEBVIEW_LIST_FAILED");
const FETCH_SETTING = createActionName("FETCH_SETTING");
const FETCH_SETTING_DONE = createActionName("FETCH_SETTING_DONE");
const FETCH_SETTING_FAILED = createActionName("FETCH_SETTING_FAILED");
const UPDATE_SETTING = createActionName("UPDATE_SETTING");
const UPDATE_SETTING_DONE = createActionName("UPDATE_SETTING_DONE");
const UPDATE_SETTING_FAILED = createActionName("UPDATE_SETTING_FAILED");
const FETCH_SETTING_LOGO = createActionName("FETCH_SETTING_LOGO");
const FETCH_SETTING_LOGO_DONE = createActionName("FETCH_SETTING_LOGO_DONE");
const FETCH_SETTING_LOGO_FAILED = createActionName("FETCH_SETTING_LOGO_FAILED");
const FETCH_SETTING_STAMP = createActionName("FETCH_SETTING_STAMP");
const FETCH_SETTING_STAMP_DONE = createActionName("FETCH_SETTING_STAMP_DONE");
const FETCH_SETTING_STAMP_FAILED = createActionName("FETCH_SETTING_STAMP_FAILED");

//reducer
function settingDefaultCoaList(state = initialState, action: DispatchedAction<APIListResponse>) {
    switch (action.type) {
        case FETCH_SETTING_DEFAULT_COA_LIST:
            return { ...initialState, loading: true };
        case FETCH_SETTING_DEFAULT_COA_LIST_DONE:
            return {
                ...state,
                data: action.payload.data.list,
                page: action.payload.data.paginator?.page,
                totalPage: action.payload.data.paginator?.totalPage,
                total: action.payload.data.paginator?.total,
                loading: false,
            };
        case FETCH_SETTING_DEFAULT_COA_LIST_FAILED:
            return { ...state, error: action.error, loading: false };
        default:
            return state;
    }
}

function settingCompanyInfoList(state = initialState, action: DispatchedAction<APIListResponse>) {
    switch (action.type) {
        case FETCH_SETTING_COMPANY_INFO_LIST:
            return { ...initialState, loading: true };
        case FETCH_SETTING_COMPANY_INFO_LIST_DONE:
            return {
                ...state,
                data: action.payload.data.list,
                page: action.payload.data.paginator?.page,
                totalPage: action.payload.data.paginator?.totalPage,
                total: action.payload.data.paginator?.total,
                loading: false,
            };
        case FETCH_SETTING_COMPANY_INFO_LIST_FAILED:
            return { ...state, error: action.error, loading: false };
        default:
            return state;
    }
}

function settingApplicationList(state = initialState, action: DispatchedAction<APIListResponse>) {
    switch (action.type) {
        case FETCH_SETTING_APPLICATION_LIST:
            return { ...initialState, loading: true };
        case FETCH_SETTING_APPLICATION_LIST_DONE:
            return {
                ...state,
                data: action.payload.data.list,
                page: action.payload.data.paginator?.page,
                totalPage: action.payload.data.paginator?.totalPage,
                total: action.payload.data.paginator?.total,
                loading: false,
            };
        case FETCH_SETTING_APPLICATION_LIST_FAILED:
            return { ...state, error: action.error, loading: false };
        default:
            return state;
    }
}

function settingAccountingList(state = initialState, action: DispatchedAction<APIListResponse>) {
    switch (action.type) {
        case FETCH_SETTING_ACCOUNTING_LIST:
            return { ...initialState, loading: true };
        case FETCH_SETTING_ACCOUNTING_LIST_DONE:
            return {
                ...state,
                data: action.payload.data.list,
                page: action.payload.data.paginator?.page,
                totalPage: action.payload.data.paginator?.totalPage,
                total: action.payload.data.paginator?.total,
                loading: false,
            };
        case FETCH_SETTING_ACCOUNTING_LIST_FAILED:
            return { ...state, error: action.error, loading: false };
        default:
            return state;
    }
}

function settingEtcList(state = initialState, action: DispatchedAction<APIListResponse>) {
    switch (action.type) {
        case FETCH_SETTING_ETC_LIST:
            return { ...initialState, loading: true };
        case FETCH_SETTING_ETC_LIST_DONE:
            return {
                ...state,
                data: action.payload.data.list,
                page: action.payload.data.paginator?.page,
                totalPage: action.payload.data.paginator?.totalPage,
                total: action.payload.data.paginator?.total,
                loading: false,
            };
        case FETCH_SETTING_ETC_LIST_FAILED:
            return { ...state, error: action.error, loading: false };
        default:
            return state;
    }
}

function settingWebviewList(state = initialState, action: DispatchedAction<APIListResponse>) {
    switch (action.type) {
        case FETCH_SETTING_WEBVIEW_LIST:
            return { ...initialState, loading: true };
        case FETCH_SETTING_WEBVIEW_LIST_DONE:
            return {
                ...state,
                data: action.payload.data.list,
                page: action.payload.data.paginator?.page,
                totalPage: action.payload.data.paginator?.totalPage,
                total: action.payload.data.paginator?.total,
                loading: false,
            };
        case FETCH_SETTING_WEBVIEW_LIST_FAILED:
            return { ...state, error: action.error, loading: false };
        default:
            return state;
    }
}

function activeSetting(state = initialState, action: DispatchedAction<APIBaseDataResponse>) {
    switch (action.type) {
        case FETCH_SETTING:
            return { ...initialState, loading: true };
        case FETCH_SETTING_DONE:
            return { ...state, data: action.payload.data, loading: false };
        case FETCH_SETTING_FAILED:
            return { ...state, error: action.error, loading: false };
        default:
            return state;
    }
}

function updateSetting(state = initialState, action: DispatchedAction<APIBaseDataResponse>) {
    switch (action.type) {
        case UPDATE_SETTING:
            return { ...initialState, loading: true };
        case UPDATE_SETTING_DONE:
            return { ...state, data: action.payload.data, loading: false };
        case UPDATE_SETTING_FAILED:
            return { ...state, error: action.error, loading: false };
        default:
            return state;
    }
}

function settingLogo(state = initialState, action: DispatchedAction<APIBaseDataResponse>) {
    switch (action.type) {
        case FETCH_SETTING_LOGO:
            return { ...initialState, loading: true };
        case FETCH_SETTING_LOGO_DONE:
            return { ...state, data: action.payload.data, loading: false };
        case FETCH_SETTING_LOGO_FAILED:
            return { ...state, error: action.error, loading: false };
        default:
            return state;
    }
}

function settingStamp(state = initialState, action: DispatchedAction<APIBaseDataResponse>) {
    switch (action.type) {
        case FETCH_SETTING_STAMP:
            return { ...initialState, loading: true };
        case FETCH_SETTING_STAMP_DONE:
            return { ...state, data: action.payload.data, loading: false };
        case FETCH_SETTING_STAMP_FAILED:
            return { ...state, error: action.error, loading: false };
        default:
            return state;
    }
}

//register reducer
reducerRegistry.register("settingDefaultCoaList", settingDefaultCoaList);
reducerRegistry.register("settingCompanyInfoList", settingCompanyInfoList);
reducerRegistry.register("settingApplicationList", settingApplicationList);
reducerRegistry.register("settingAccountingList", settingAccountingList);
reducerRegistry.register("settingEtcList", settingEtcList);
reducerRegistry.register("settingWebviewList", settingWebviewList);
reducerRegistry.register("activeSetting", activeSetting);
reducerRegistry.register("updateSetting", updateSetting);
reducerRegistry.register("settingStamp", settingStamp);
reducerRegistry.register("settingLogo", settingLogo);

//selector
export const slcSettingDefaultCoaList = (state: any): CommonReduxState => state["settingDefaultCoaList"];
export const slcSettingCompanyInfoList = (state: any): CommonReduxState => state["settingCompanyInfoList"];
export const slcSettingApplicationList = (state: any): CommonReduxState => state["settingApplicationList"];
export const slcSettingAccountingList = (state: any): CommonReduxState => state["settingAccountingList"];
export const slcSettingEtcList = (state: any): CommonReduxState => state["settingEtcList"];
export const slcSettingWebviewList = (state: any): CommonReduxState => state["settingWebviewList"];
export const slcFetchActiveSetting = (state: any): CommonReduxState => state["activeSetting"];
export const slcUpdateSetting = (state: any): CommonReduxState => state["updateSetting"];
export const slcSettingLogo = (state: any): CommonReduxState => state["settingLogo"];
export const slcSettingStamp = (state: any): CommonReduxState => state["settingStamp"];

//action creator
const urlPath = "setting";

function createSettingParam(categoryEnum: string, inputs: any = {}) {
    let data = {
        q: [categoryEnum],
        searchKey: ["category"],
        operator: ["="],
        mode: "and",
        all: true,
    };
    if (inputs.q?.length > 0) {
        data.q.push(...inputs.q);
    }
    if (inputs.searchKey?.length > 0) {
        data.searchKey.push(...inputs.searchKey);
    }
    if (inputs.operator?.length > 0) {
        data.operator.push(...inputs.operator);
    }
    return data;
}

/**
 * COA Setting (category 1)
 */
export function actFetchSettingDefaultCoaList(inputs: any = {}) {
    const param = createSettingParam("default-coa", inputs);

    const request = reqGet(urlPath, param);

    return (dispatch: any) => {
        dispatch({
            type: FETCH_SETTING_DEFAULT_COA_LIST,
        });
        request
            .then((payload) => {
                dispatch({
                    type: FETCH_SETTING_DEFAULT_COA_LIST_DONE,
                    payload: payload.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: FETCH_SETTING_DEFAULT_COA_LIST_FAILED,
                    error,
                });
            });
    };
}

/**
 * CompanyInfo Setting (category 2)
 */
export function actFetchSettingCompanyInfoList(inputs: any = {}) {
    const param = createSettingParam("informasi-perusahaan", inputs);

    const request = reqGet(urlPath, param);

    return (dispatch: any) => {
        dispatch({
            type: FETCH_SETTING_COMPANY_INFO_LIST,
        });
        request
            .then((payload) => {
                dispatch({
                    type: FETCH_SETTING_COMPANY_INFO_LIST_DONE,
                    payload: payload.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: FETCH_SETTING_COMPANY_INFO_LIST_FAILED,
                    error,
                });
            });
    };
}

/**
 * Application Setting (category 3)
 */
export function actFetchSettingApplicationList(inputs: any = {}) {
    const param = createSettingParam("aplikasi", inputs);

    const request = reqGet(urlPath, param);

    return (dispatch: any) => {
        dispatch({
            type: FETCH_SETTING_APPLICATION_LIST,
        });
        request
            .then((payload) => {
                dispatch({
                    type: FETCH_SETTING_APPLICATION_LIST_DONE,
                    payload: payload.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: FETCH_SETTING_APPLICATION_LIST_FAILED,
                    error,
                });
            });
    };
}

/**
 * Accounting Setting (category 4)
 */
export function actFetchSettingAccountingList(inputs: any = {}) {
    const param = createSettingParam("akuntansi", inputs);

    const request = reqGet(urlPath, param);

    return (dispatch: any) => {
        dispatch({
            type: FETCH_SETTING_ACCOUNTING_LIST,
        });
        request
            .then((payload) => {
                dispatch({
                    type: FETCH_SETTING_ACCOUNTING_LIST_DONE,
                    payload: payload.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: FETCH_SETTING_ACCOUNTING_LIST_FAILED,
                    error,
                });
            });
    };
}

/**
 * Etc Setting (category 5)
 */
export function actFetchSettingEtcList(inputs: any = {}) {
    const param = createSettingParam("lain-lain", inputs);

    const request = reqGet(urlPath, param);

    return (dispatch: any) => {
        dispatch({
            type: FETCH_SETTING_ETC_LIST,
        });
        request
            .then((payload) => {
                dispatch({
                    type: FETCH_SETTING_ETC_LIST_DONE,
                    payload: payload.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: FETCH_SETTING_ETC_LIST_FAILED,
                    error,
                });
            });
    };
}


/**
 * Etc Setting (category 6)
 */
 export function actFetchSettingWebviewList(inputs: any = {}) {
    const param = createSettingParam("webview", inputs);

    const request = reqGet(urlPath, param);

    return (dispatch: any) => {
        dispatch({
            type: FETCH_SETTING_WEBVIEW_LIST,
        });
        request
            .then((payload) => {
                dispatch({
                    type: FETCH_SETTING_WEBVIEW_LIST_DONE,
                    payload: payload.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: FETCH_SETTING_WEBVIEW_LIST_FAILED,
                    error,
                });
            });
    };
}

/**
 * Get a Setting data. Used mainly for view/edit
 */
export function actFetchActiveSetting(id: number) {
    const request = reqGet(`${urlPath}/${id}`, null);

    return (dispatch: any) => {
        dispatch({
            type: FETCH_SETTING,
            addToLoading: true,
        });
        request
            .then((payload) => {
                dispatch({
                    type: FETCH_SETTING_DONE,
                    payload: payload.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: FETCH_SETTING_FAILED,
                    error,
                });
            });
    };
}

/**
 * Edit setting data
 */
export function actEditSetting(id: number, valueList: any) {
    let param = setCreateEditParam(valueList);

    const request = reqPut(`${urlPath}/${id}`, param);

    return (dispatch: any) => {
        dispatch({
            type: UPDATE_SETTING,
        });
        request
            .then((payload) => {
                dispatch({
                    type: UPDATE_SETTING_DONE,
                    payload: payload.data,
                });
                //reload config
                dispatch(actReloadInitConfig());
            })
            .catch((error) => {
                dispatch({
                    type: UPDATE_SETTING_FAILED,
                    error,
                });
            });
    };
}


/**
 * Import file into setting `logo` or `stamp`
 */
 export function actEditSettingFile(path: string, formData: any) {
    const request = reqPost(`${urlPath}/${path}`, formData, {responseType: "blob"}, true); //non-json data

    return (dispatch: any) => {
        dispatch({
            type: UPDATE_SETTING,
            addToLoading: true,
        });
        request
            .then((payload) => {
                dispatch({
                    type: UPDATE_SETTING_DONE,
                    payload: {data:true},
                });
                
                let reader = new window.FileReader();
                reader.readAsDataURL(payload.data); 
                reader.onload = function() {
                    let imageDataUrl = reader.result;
                    dispatch({
                        type: path === "logo" ? FETCH_SETTING_LOGO_DONE : FETCH_SETTING_STAMP_DONE,
                        payload: { data: imageDataUrl },
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: UPDATE_SETTING_FAILED,
                    error,
                });
            });
    };
}

export function actFetchSettingLogo() {
    let localStorageData = getDataFromSingleCache(urlPath + "/logo");
    if (localStorageData) {
        return (dispatch: any) => {
            dispatch({
                type: FETCH_SETTING_LOGO_DONE,
                payload: { data: localStorageData }
            })
        }
    }

    // else get from backend
    const request = reqGet(`${urlPath}/logo`, null, { responseType: "arraybuffer" });

    return (dispatch: any) => {
        dispatch({
            type: FETCH_SETTING_LOGO,
            addToLoading: true,
        });
        request
            .then((payload: any) => {
                if (payload.headers["content-type"].includes("application/json")) {
                    dispatch({
                        type: FETCH_SETTING_LOGO_DONE,
                        payload: { data:null },
                    });
                    return;
                }

                // data:image/png -> doesn't effect if image is jpg
                let img = "data:image/png;base64, " + Buffer.from(payload.data, 'binary').toString('base64');

                // save to localstorage
                saveIntoSingleCache(urlPath + "/logo", img);
                dispatch({
                    type: FETCH_SETTING_LOGO_DONE,
                    payload: { data: img }
                })
            })
            .catch((error) => {
                dispatch({
                    type: FETCH_SETTING_LOGO_FAILED,
                    error,
                });
            });
    };
}

export function actFetchSettingStamp() {
    let localStorageData = getDataFromSingleCache(urlPath + "/stamp");
    if (localStorageData) {
        return (dispatch: any) => {
            dispatch({
                type: FETCH_SETTING_STAMP_DONE,
                payload: { data: localStorageData }
            })
        }
    }

    // else get from backend
    const request = reqGet(`${urlPath}/stamp`, null, { responseType: "arraybuffer" });

    return (dispatch: any) => {
        dispatch({
            type: FETCH_SETTING_STAMP,
            addToLoading: true,
        });
        request
            .then((payload: any) => {
                if (payload.headers["content-type"].includes("application/json")) {
                    dispatch({
                        type: FETCH_SETTING_STAMP_DONE,
                        payload: { data: null },
                    });
                    return;
                }
                
                // data:image/png -> doesn't effect if image is jpg
                let img = "data:image/png;base64, " + Buffer.from(payload.data, 'binary').toString('base64');

                // save to localstorage
                saveIntoSingleCache(urlPath + "/stamp", img);
                dispatch({
                    type: FETCH_SETTING_STAMP_DONE,
                    payload: { data: img }
                })
            })
            .catch((error) => {
                dispatch({
                    type: FETCH_SETTING_STAMP_FAILED,
                    error,
                });
            });
    };
}

/**
 * Delete setting `logo` or `stamp`
 */
 export function actDeleteSettingFile(path: string) {
    const request = reqDelete(`${urlPath}/${path}`);

    return (dispatch: any) => {
        dispatch({
            type: UPDATE_SETTING,
            addToLoading: true,
        });
        request
            .then((payload) => {
                dispatch({
                    type: UPDATE_SETTING_DONE,
                    payload: {data:true},
                });

                // remove local storage file
                localStorage.removeItem(`setting/${path}`);
                
                dispatch({
                    type: path==="logo"? FETCH_SETTING_LOGO_DONE:FETCH_SETTING_STAMP_DONE,
                    payload: {data:null},
                });
            })
            .catch((error) => {
                dispatch({
                    type: UPDATE_SETTING_FAILED,
                    error,
                });
            });
    };
}
import React, { Component } from "react";
import { Route } from "react-router-dom";
/**
 * Index page should only handle Routing. Without any styling/other components.
 */
// component
import UpdateInfoForm from "./UpdateInfoForm";

class MasterCoaIndex extends Component {
    render() {
        return (
            <>
                <Route exact path="/update-info/:module/:key" component={UpdateInfoForm} />
            </>
        );
    }
}

export default MasterCoaIndex;

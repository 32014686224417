import React, { PureComponent } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import PrintArea from "../../../common/component/PrintArea";
import PurchasePrintTemplate from "../PurchasePrintTemplate/tplDefault";
import PurchasePrintAnj from "../PurchasePrintTemplate/tplAnj";
import PurchasePrintBerkat from "../PurchasePrintTemplate/tplBerkat";

type PurchasePrintState = {
    data: any;
    companyInfo: any;
    colSpan: number;
    settingHargaSatuan: boolean;
    printTemplateSetting: any;
};

class PurchasePrint extends PureComponent<WithTranslation, PurchasePrintState> {
    constructor(props: any) {
        super(props);

        //load data from localStorage
        const stringData = localStorage.getItem("purchase-print");
        const jsonData = stringData != null ? JSON.parse(stringData) : null;

        // remove data from localStorage
        // localStorage.removeItem("purchase-print");

        // console.log(jsonData);

        let stateObj = {
            data: jsonData && jsonData.data ? jsonData.data : null,
            colSpan: 4,
            companyInfo: jsonData?.companyInfo ? jsonData.companyInfo : null,
            settingHargaSatuan: jsonData.setting?.settingHargaSatuan ? jsonData.setting.settingHargaSatuan : null,
            printTemplateSetting: jsonData.setting?.printTemplateSetting ?? "DEFAULT",
        }

        this.state = stateObj;
    }

    render() {
        const { companyInfo, data } = this.state;
        if (data == null) return <p>No Data</p>;

        let elmPrint = null;
        switch (this.state.printTemplateSetting) {
            case "BERKAT":
                elmPrint = <PurchasePrintBerkat data={data} companyInfo={companyInfo} />
                break;
                
            case "ANJ":
                elmPrint = <PurchasePrintAnj data={data} companyInfo={companyInfo} />
                break;
                
            default:
                elmPrint = <PurchasePrintTemplate 
                                data={data} 
                                companyInfo={companyInfo} 
                                settingHargaSatuan={this.state.settingHargaSatuan}
                                colSpan={this.state.colSpan} />
                break;
        }

        return (
            <PrintArea>
                { elmPrint }
            </PrintArea>
        );
    }
}

export default withTranslation()(PurchasePrint);

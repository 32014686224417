import Big from "big.js";

/**
 * Big() wrapper with some check to avoid crash
 * 
 * Should be safe from:
 * - Non-number
 * - "-" without number behind it
 * 
 * We also no longer needed to use `Big(val ?? 0)` as it's already handled inside
 * But we still need to use `SafeBig(val).add(val2 ?? 0)` because SafeBig still returns Big
 */
export function SafeBig(number:any):Big{
    // if (isNaN(number)) return Big(0);

    try{
        return Big(number);
    } catch (err){
        return Big(0);
    }
}
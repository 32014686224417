import React, { useState } from "react";
import ClassNames from "classnames";
import { UncontrolledTooltip, Input, FormGroup, Label, Col } from "reactstrap";
import { useTranslation } from "react-i18next";

type InputRadioType = BaseInputType & {
    options: any[];
    selected: any;
    name: string;
};

/**
 * Renders multiple radio options directly
 */
export const InputRadio = ({
    input,
    meta: { touched, error },
    className = "",
    label = "",
    disabled,
    isRequired,
    horizontalInput,
    options,
    noInfo,
}: InputRadioType) => {
    const { t } = useTranslation();
    const [id] = useState(("input" + input.name).replace(/[^\w]+/g, ""));

    return (
        <FormGroup tag="fieldset" row={horizontalInput} className={className}>
            {label && (
                <Label /*for={id} /*sm={horizontalInput ? 2 : false}*/>
                    {t(label)} {isRequired ? <small>*</small> : ""}
                </Label>
            )}
            <Col sm={horizontalInput ? 10 : false} style={{ padding: "0" }}>
                {options.map((row, index) => (
                    <FormGroup check key={index} className="radio">
                        <Label check>
                            <Input
                                type="radio"
                                {...input}
                                className={ClassNames({ "has-danger": touched && error })}
                                disabled={disabled}
                                value={row.value}
                                checked={input.value === row.value}
                                id={id}
                            />
                            {t(row.label)}
                        </Label>
                    </FormGroup>
                ))}
            </Col>
            {noInfo !== true && (
                <UncontrolledTooltip
                    className={ClassNames({ "force-hidden": !(touched && error) })}
                    placement="right"
                    target={id}
                    flip={false} //https://github.com/reactstrap/reactstrap/issues/1488
                >
                    {t(error)}
                </UncontrolledTooltip>
            )}
        </FormGroup>
    );
};

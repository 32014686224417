import React, { PureComponent } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Table } from "reactstrap";
import HTMLPrintHeader from "../../../common/component/HTMLPrintHeader";
import PrintArea from "../../../common/component/PrintArea";
import { showCurrency, showDate, showQty } from "../../../common/function/showValueHelper";
import { SafeBig } from "../../../common/function/safeBig";

type PurchaseSummaryPrintState = {
    data: any;
    param: any;
    // date: any;
};

class PurchaseSummaryPrint extends PureComponent<WithTranslation, PurchaseSummaryPrintState> {
    constructor(props: any) {
        super(props);

        //load data from localStorage
        const stringData = localStorage.getItem("purchase-summary-print");
        const jsonData = stringData != null ? JSON.parse(stringData) : null;

        // remove data from localStorage
        // localStorage.removeItem("purchase-summary-print");

        let stateObj = {
            data: jsonData?.data ?? null,
            param: jsonData?.param ?? {},
            // date
        }

        this.state = stateObj;
    }

    render() {
        const { t } = this.props;
        const { param, data } = this.state;
        if (data == null) return <p>No Data</p>;

        let total = SafeBig(0);
        let lastKey:string;
        let columns = data.map((obj: any, index: number) => {
            total = total.add(obj.subtotal);

            if (lastKey===obj.key){
                return (
                    <tr key={"detail-" + index}>
                        <td className="rowNo">{index + 1}</td>
                        <td colSpan={3}></td>
                        <td>{obj.product}</td>
                        <td>{showQty(obj.qty, 2)} {obj.unit}</td>
                        <td style={{ textAlign: "right" }}>{showCurrency(obj.subtotal, 2)}</td>
                    </tr>
                );
            }
            
            lastKey = obj.key;
            return (
                <tr key={"detail-" + index}>
                    <td className="rowNo">{index + 1}</td>
                    <td>{showDate(obj.documentDate)}</td>
                    <td>{obj.key}</td>
                    <td>{obj.supplier}</td>
                    <td>{obj.product}</td>
                    <td>{showQty(obj.qty, 2)} {obj.unit}</td>
                    <td style={{ textAlign: "right" }}>{showCurrency(obj.subtotal, 2)}</td>
                </tr>
            );
        });

        return (
            <PrintArea>
                <Table className="ra-table-print">
                    <HTMLPrintHeader
                        param={param}
                    />
                    <tbody>
                        <tr>
                            <td colSpan={3}>
                                <Table className="ra-table-detail-print">
                                    <thead>
                                        <tr>
                                            <th className="rowNo">#</th>
                                            <th>{t("common.date")}</th>
                                            <th>{t("common.documentNo")}</th>
                                            <th>{t("common.supplier")}</th>
                                            <th>{t("common.product")}</th>
                                            <th className="qty">{t("common.qty")}</th>
                                            <th className="amount">{t("common.subtotal")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {columns}
                                        <tr>
                                            <td colSpan={6}>
                                                <b>{t("common.total")}:</b>
                                            </td>
                                            <td className="amount"  style={{textAlign:"right"}}>
                                                {showCurrency(total)}
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </PrintArea>
        );
    }
}

export default withTranslation()(PurchaseSummaryPrint);

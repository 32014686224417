import React, { PureComponent } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Table } from "reactstrap";
import PrintFooter from "../../../common/component/PrintFooter";
import SimpleHeader from "../../../common/component/PrintHeader/SimpleHeader";
import { SafeBig } from "../../../common/function/safeBig";
import { showCurrency, showQty } from "../../../common/function/showValueHelper";

type InjectedProps = {
    data: any;
    companyInfo: any;
    settingHargaSatuan: boolean;
};

class Simple extends PureComponent<WithTranslation & InjectedProps> {
    render() {
        const { t, data, companyInfo } = this.props;
        if (data == null) return <p>No Data</p>;
        let columns = data.detailList.map((obj: any, index: number) => {
            return (
                <tr key={"detail-" + index}>
                    <td className="rowNo">{index + 1}</td>
                    <td>{obj.productName ?? obj.product.name}</td>
                    <td className="qty">
                        {obj.unit == null ? (
                            <table className="printQtyTable">
                                <tbody>
                                    <tr key={"detail-1-" + index}>
                                        <td>{showQty(obj.qty) + " "}</td>
                                        <td>{obj.product?.unit?.name}</td>
                                    </tr>
                                </tbody>
                            </table>
                        ) : (
                            <table className="printQtyTable">
                                <tbody>
                                    <tr key={"detail-1-" + index}>
                                        <td>{showQty(obj.qty) + " " + obj.unit?.name} @{ showQty(obj.productUnitConversion.qty) + " " + obj.product?.unit?.name } </td>
                                        <td>{showQty(SafeBig(obj.qty ?? 0).times(obj.productUnitConversion?.qty ?? 1)) + " " + obj.product.unit?.name}</td>
                                    </tr>
                                </tbody>
                            </table>
                        )}
                    </td>
                    { this.props.settingHargaSatuan ? 
                        (<td className="price">{showCurrency(SafeBig(obj.price ?? 1).div(obj.productUnitConversion?.qty ?? 1).toString(), 2)}</td>) : 
                        (<td className="price">{showCurrency(obj.price, 2)}</td>)
                    }
                    <td className="disc">{showCurrency(obj.disc1, 2)}</td>
                    <td className="subTotal">{showCurrency(obj.subtotal, 2)}</td>
                </tr>
            );
        });

        let badanUsaha = companyInfo.find((val: any) => {
            return val.key === "status-badan-usaha";
        });

        let companyPaymentInfo = companyInfo.find((val: any) => {
            return val.key === "company-payment-info"
        });

        // show dp if
        // - has dp usage
        // - status != paid
        let hasDP = false; let balance = "";
        if (data.status === "pending" && data.totalDpUsage && SafeBig(data.totalDpUsage).gt(0)) {
            balance = SafeBig(data.grandTotal).minus(data.totalDpUsage).toString();
            hasDP = true;
        }

        let summaryRow;
        if (badanUsaha.value === "PKP") {
            // if PKP
            summaryRow = (
                <Table className="ra-summary-table">
                    <tbody>
                        <tr>
                            <td colSpan={4}>
                                <b>{t("common.calculatedPrice")}: </b> <br /> {data.say} {" "} Rupiah
                            </td>
                            <td className="disc" style={{ textAlign: "right" }}>{t("common.discount")}</td>
                            <td className="subTotal">{showCurrency(data.disc2AmountTotal)}</td>
                        </tr>
                        <tr>
                            <td rowSpan={data.dpUsage ? 4 : 5} colSpan={4}>
                                {t("common.notes")}: <br />{data.description}
                            </td>
                        </tr>
                        <tr>
                            <td style={{ textAlign: "right" }}>{t("common.taxBase")}</td>
                            <td className="subTotal">{showCurrency(data.subtotal, 2)}</td>
                        </tr>
                        <tr>
                            <td style={{ textAlign: "right" }}>{t("common.tax")}</td>
                            <td className="subTotal">{showCurrency(data.vat, 2)}</td>
                        </tr>
                        <tr>
                            <td style={{ textAlign: "right" }}>{t("common.grandTotal")}</td>
                            <td className="subTotal">{showCurrency(data.grandTotal, 2)}</td>
                        </tr>   
                        {hasDP ? (
                            <>
                                <tr>
                                    <td style={{ textAlign: "right" }}>{t("common.downPayment")}</td>
                                    <td className="subTotal">{showCurrency(data.totalDpUsage, 2)}</td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: "right" }}>{t("common.remainingAmount")}</td>
                                    <td className="subTotal">{showCurrency(balance, 2)}</td>
                                </tr>
                            </>
                        ) : null}
                    </tbody>
                </Table>
            )
        } else {
            // if not PKP
            summaryRow = (
                <Table className="ra-summary-table">
                    <tbody>
                        <tr>
                            <td colSpan={4}>
                                <b>{t("common.calculatedPrice")}: </b> <br /> {data.say} {" "} Rupiah
                            </td>
                            <td className="disc" style={{ textAlign: "right" }}>{t("common.discount")}</td>
                            <td className="subTotal">{showCurrency(data.disc2AmountTotal)}</td>
                        </tr>
                        <tr>
                            <td rowSpan={data.dpUsage ? 3 : 2} colSpan={4}>
                                {t("common.notes")}: <br />{data.description}
                            </td>
                            <td style={{ textAlign: "right" }}>{t("common.taxBase")}</td>
                            <td className="subTotal">{showCurrency(data.subtotal, 2)}</td>
                        </tr>
                        <tr>
                            <td style={{ textAlign: "right" }}>{t("common.grandTotal")}</td>
                            <td className="subTotal">{showCurrency(data.grandTotal, 2)}</td>
                        </tr>
                        {hasDP ? (
                                <>
                                    <tr>
                                        <td style={{ textAlign: "right" }}>{t("common.downPayment")}</td>
                                        <td className="subTotal">{showCurrency(data.totalDpUsage, 2)}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: "right" }}>{t("common.remainingAmount")}</td>
                                        <td className="subTotal">{showCurrency(balance, 2)}</td>
                                    </tr>
                                </>
                            ) : null}
                    </tbody>
                </Table>
            )
        }

        return (
            <Table className="ra-table-print">
                <SimpleHeader
                    title={t("common.sales")}
                    companyInfo={companyInfo}
                    customer={data.customer}
                    customAddress={data.customAddress}
                    no={data.key}
                    dtCreated={data.dtCreated}
                />
                <tbody>
                    <tr>
                        <td colSpan={2}>
                            <Table className="ra-table-detail-print">
                                <thead>
                                    <tr>
                                        <th className="rowNo">#</th>
                                        <th>{t("common.product")}</th>
                                        <th style={{ width: "15%"}}>{t("common.qty")}</th>
                                        <th style={{ width: "15%" }}>{t("common.price")}</th>
                                        <th style={{ width: "15%" }}>{t("common.discount")}</th>
                                        <th style={{ width: "20%" }}>{t("common.subTotal")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {columns}
                                </tbody>
                            </Table>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            {summaryRow}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            {companyPaymentInfo.value !== "" ? (
                                <>
                                    <strong>{t("common.paymentInfo")}</strong>
                                    <p>{companyPaymentInfo.value}</p>
                                </>
                            ) : ""}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            <PrintFooter footerType="sales" status={data.status} />
                        </td>
                    </tr>
                </tbody>
            </Table>
        );
    }
}

export default withTranslation()(Simple);

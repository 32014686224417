import React, { PureComponent } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import PrintArea from "../../../common/component/PrintArea";
import Simple from "../SalesPrintTemplate/tplSimple";
import Standard from "../SalesPrintTemplate/tplStandard";
import TenantAnjPrint from "../SalesPrintTemplate/tplAnj";
import TenantBerkatPrint from "../SalesPrintTemplate/tplBerkat";
import DeliveryReportPrintAnj from "../DeliveryReportPrintTemplate/tplAnj";
import cloneDeep from "clone-deep";
import { slcFetchMyAccount, actFetchMyAccount } from "../../../Core/redux/myAccount";
import { connect } from "react-redux";

type InjectedProps = {
    myAccount: any;
    actFetchMyAccount: Function;
}

type SalesPrintState = {
    data: any;
    companyInfo: any;
    colSpan: number;
    settingHargaSatuan: boolean;
    printTemplateSetting: string;
    settingCetakSuratJalan: boolean;
};

class SalesPrint extends PureComponent<WithTranslation & InjectedProps, SalesPrintState> {
    constructor(props: any) {
        super(props);

        //load data from localStorage
        const stringData = localStorage.getItem("sales-print");
        const jsonData = stringData != null ? JSON.parse(stringData) : null;

        // remove data from localStorage
        // localStorage.removeItem("sales-print");

        let stateObj = {
            data: jsonData && jsonData.data ? jsonData.data : null,
            colSpan: 4,
            companyInfo: jsonData && jsonData.companyInfo ? jsonData.companyInfo : null,
            settingHargaSatuan: jsonData?.setting?.settingHargaSatuan ? jsonData.setting.settingHargaSatuan : null,
            printTemplateSetting: jsonData?.setting?.printTemplateSetting ?? "STANDARD",
            settingCetakSuratJalan: jsonData?.setting?.settingCetakSuratJalan ?? false,
        }

        this.state = stateObj;
    }

    componentDidMount(): void {
        this.props.actFetchMyAccount();
    }

    render() {
        const { myAccount } = this.props;
        const { companyInfo, data, printTemplateSetting } = this.state;
        let printTmpl = null; let sjPrintTmpl = null;
        switch (printTemplateSetting) {
            case "SIMPLE":
                printTmpl = <Simple data={data} companyInfo={companyInfo} settingHargaSatuan={this.state.settingHargaSatuan} />
                break;

            case "BERKAT":
                printTmpl = <TenantBerkatPrint data={data} companyInfo={companyInfo} settingHargaSatuan={this.state.settingHargaSatuan} />
                break;

            case "ANJ":
                printTmpl = <TenantAnjPrint data={data} companyInfo={companyInfo} settingHargaSatuan={this.state.settingHargaSatuan} accountInfo={myAccount.data} />
                break;
                
            default:
                printTmpl = <Standard data={data} companyInfo={companyInfo} colSpan={this.state.colSpan} settingHargaSatuan={this.state.settingHargaSatuan} />
                break;
        }

        if (this.state.settingCetakSuratJalan) {
            const sjData = cloneDeep(data);
            sjData.key = sjData.deliveryOrderNo;
            sjPrintTmpl = (
                <>
                    <div style={{ pageBreakAfter: "always" }}></div>
                    <DeliveryReportPrintAnj data={sjData} companyInfo={companyInfo} />
                </>
            )
        }
        return (
            <PrintArea>
                { printTmpl }
                { sjPrintTmpl }
            </PrintArea>
        );
    }
}

function mapStateToProps(state: any) {
    return {
        myAccount: slcFetchMyAccount(state),
    };
}

export default connect(mapStateToProps, {
    actFetchMyAccount
})(withTranslation()(SalesPrint));

import React, { PureComponent } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Table } from "reactstrap";
import { showCurrency, showDate, showQty } from "../../../common/function/showValueHelper";
import { SafeBig } from "../../../common/function/safeBig";
import dayjs from "dayjs";

type InjectedProps = {
    data: any;
    companyInfo: any;
    settingHargaSatuan: boolean;
    accountInfo: any;
};

function getData(companyInfo: any, key: string) {
    let obj = companyInfo.find((val: any) => val.key === key);
    return obj ? obj.value : "";
}

class AnjPrint extends PureComponent<WithTranslation & InjectedProps> {
    render() {
        const { t, data, companyInfo, accountInfo } = this.props;
        if (data == null) return <p>No Data</p>;
        let columns = data.detailList.map((obj: any, index: number) => {
            let price = obj.price;
            let pUnitToSmallestUnitCompare = SafeBig(obj.productUnitConversion?.qty ?? 1).div(
                obj.product.smallestUnitConversion?.qty ?? 1
            );
            if (obj.productUnitConversion && obj.product.smallestUnitConversion != null) {
                price = SafeBig(price)
                    .div(SafeBig(obj.productUnitConversion.qty).div(obj.product.smallestUnitConversion.qty ?? 1))
                    .toString();
            } else if (obj.productUnitConversion && obj.product.smallestUnitConversion == null) {
                price = SafeBig(price).div(obj.productUnitConversion.qty).toString();
            }
            return (
                <tr key={"detail-" + index}>
                    <td className="qty">
                        {obj.unit == null ? (
                            <table className="printQtyTable">
                                <colgroup>
                                    <col style={{ width: "70%" }}></col>
                                    <col style={{ width: "30%" }}></col>
                                </colgroup>
                                <tbody>
                                    <tr key={"detail-1-" + index}>
                                        <td>{showQty(obj.qty) + " "}</td>
                                        <td>{obj.product?.unit?.name}</td>
                                    </tr>
                                </tbody>
                            </table>
                        ) : (
                            <table className="printQtyTable">
                                <colgroup>
                                    <col style={{ width: "70%" }}></col>
                                    <col style={{ width: "30%" }}></col>
                                </colgroup>
                                <tbody>
                                    <tr key={"detail-1-" + index}>
                                        {!obj.product.smallestUnit || obj.unit.id === obj.product.smallestUnit?.id ? (
                                            <>
                                                <td>
                                                    {showQty(obj.qty) + " " + obj.unit?.name} @
                                                    {showQty(obj.productUnitConversion.qty) +
                                                        " " +
                                                        obj.product?.unit?.name}{" "}
                                                </td>
                                                <td>
                                                    {showQty(
                                                        SafeBig(obj.qty ?? 0).times(obj.productUnitConversion?.qty ?? 1)
                                                    ) +
                                                        " " +
                                                        obj.product.unit?.name}
                                                </td>
                                            </>
                                        ) : (
                                            <>
                                                <td>
                                                    {showQty(obj.qty) + " " + obj.unit?.name} @
                                                    {showQty(pUnitToSmallestUnitCompare) +
                                                        " " +
                                                        obj.product.smallestUnit?.name}{" "}
                                                </td>
                                                <td>
                                                    {showQty(
                                                        SafeBig(obj.qty ?? 0).times(pUnitToSmallestUnitCompare ?? 1)
                                                    ) +
                                                        " " +
                                                        obj.product.smallestUnit?.name}
                                                </td>
                                            </>
                                        )}
                                    </tr>
                                </tbody>
                            </table>
                        )}
                    </td>
                    <td>{obj.productName ?? obj.product.name}</td>
                    <td>{showCurrency(price, 2)}</td>
                    <td>{showCurrency(obj.subtotal, 2)}</td>
                </tr>
            );
        });

        let summaryRow = (
            <Table className="ra-summary-table">
                <colgroup>
                    <col style={{ width: "67%" }}></col>
                    <col style={{ width: "15%" }}></col>
                    <col style={{ width: "18%" }}></col>
                </colgroup>
                <tbody>
                    <tr>
                        <td>
                            <b>{t("common.calculatedPrice")}: </b> <br /> {data.say} Rupiah
                        </td>
                        <td style={{ textAlign: "right" }}>{t("common.taxBase")}</td>
                        <td className="subtotal">{showCurrency(data.subtotal, 2)}</td>
                    </tr>
                    <tr>
                        <td rowSpan={data.dpUsage ? 3 : 2}>
                            {t("common.notes")}: <br />
                            {data.description}
                        </td>
                        <td style={{ textAlign: "right" }}>{t("common.grandTotal")}</td>
                        <td className="subtotal">{showCurrency(data.grandTotal, 2)}</td>
                    </tr>
                </tbody>
            </Table>
        );

        return (
            <>
                <style>
                    {` 
                    .ra-table-print > thead th {
                        font-size: 12pt;
                    }

                    .ra-table-detail-print th,
                    .ra-table-detail-print td,
                    .ra-summary-table td {
                        font-size: 10pt !important;
                        letter-spacing: .5pt;
                    }

                    table td {
                        font-size: 10pt;
                    }

                    .ra-table-print > thead {
                        border-bottom: .5cm solid transparent;
                    }

                    @page {
                        margin: 1cm 0cm;
                    }

                    @media print {
                        .ra-table-print > thead th {
                            font-size: 14pt;
                        }    

                        .ra-table-detail-print td,
                        .ra-table-detail-print th {
                            padding: 3px !important;
                        }

                        .ra-table-detail-print td,
                        .ra-table-detail-print th,
                        .ra-summary-table td,
                        .ra-summary-table th {
                            border: .5px solid black;
                        }

                        .ra-table-detail-print th,
                        .ra-table-detail-print td,
                        .ra-summary-table td {
                            font-size: 12pt !important;
                            letter-spacing: .5pt;
                        }

                        .ra-table-detail-print td {
                            white-space: pre-wrap;
                            height: inherit;
                        }

                        .ra-table-detail-print tr {
                            height: 1px;
                        }

                        .printQtyTable {
                            height: 100%;
                        }
                    }
                    `}
                </style>
                <Table className="ra-table-print">
                    <thead>
                        <tr style={{ marginBottom: "10px" }}>
                            <th style={{ width: "60%" }}>
                                <b>{data.key}</b>
                            </th>
                            <th>
                                <b>
                                    {t("common.date")}: {showDate(data.documentDate)}
                                </b>
                            </th>
                        </tr>
                        <tr>
                            <th>
                                {getData(companyInfo, "nama-perusahaan")} <br />
                                {getData(companyInfo, "alamat-perusahaan")} <br />
                                {data.salesPerson.name} ({accountInfo?.name ?? "--"})
                            </th>
                            <th>
                                {data.customer.name} <br /> {data.customAddress ?? data.customer.address}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colSpan={2}>
                                <Table className="ra-table-detail-print">
                                    <colgroup>
                                        <col style={{ width: "30%" }}></col>
                                        <col style={{ width: "37%" }}></col>
                                        <col style={{ width: "15%" }}></col>
                                        <col style={{ width: "18%" }}></col>
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th>{t("common.qty")}</th>
                                            <th>{t("common.product")}</th>
                                            <th>{t("common.price")}</th>
                                            <th>{t("common.subTotal")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>{columns}</tbody>
                                </Table>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2}>{summaryRow}</td>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                <table className="ra-footer-table">
                                    <tbody>
                                        <tr>
                                            <td colSpan={3}>
                                                {t("common.printDate")}:{" "}
                                                {dayjs(new Date()).format("DD-MM-YYYY HH:mm:ss")}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </>
        );
    }
}

export default withTranslation()(AnjPrint);

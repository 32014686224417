import { titleCase } from "change-case";
import React, { PureComponent } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Table } from "reactstrap";
import HTMLPrintHeader from "../../../common/component/HTMLPrintHeader";
import PrintArea from "../../../common/component/PrintArea";
import { showCurrency, showDate, showQty } from "../../../common/function/showValueHelper";

type SalesTempDataPrintState = {
    data: any;
    param: any;
    tempKey: string[];
};

class SalesTempDataPrint extends PureComponent<WithTranslation, SalesTempDataPrintState> {
    constructor(props: any) {
        super(props);

        //load data from localStorage
        const stringData = localStorage.getItem("sales-temp-data-print");
        const jsonData = stringData != null ? JSON.parse(stringData) : null;

        // remove data from localStorage
        localStorage.removeItem("sales-temp-data-print");
        
        let stateObj = {
            data: jsonData?.data?.list ?? null,
            tempKey: jsonData?.data?.tempKey ?? [],
            param: jsonData?.param ?? {},
        }

        this.state = stateObj;
    }

    render() {
        const { t } = this.props;
        const { param, data, tempKey } = this.state;
        if (data == null) return <p>No Data</p>;

        
        let columns = data.map((obj: any, index: number) => {
            return (
                <tr key={"detail-" + index}>
                    <td className="rowNo">{index + 1}</td>
                    <td>{showDate(obj.documentDate)}</td>
                    <td>{obj.key}</td>
                    <td>{obj.customer}</td>
                    <td>{showQty(obj.qty)}</td>
                    <td style={{ textAlign: "right" }}>{showCurrency(obj.grandTotal, 2)}</td>
                    {tempKey?.map((val:any)=>
                        <td>{obj.tempData[val] ?? ""}</td>
                    )}
                </tr>
            );
        });

        return (
            <PrintArea>
                <Table className="ra-table-print">
                    <HTMLPrintHeader
                        param={param}
                    />
                    <tbody>
                        <tr>
                            <td colSpan={3}>
                                <Table className="ra-table-detail-print">
                                    <thead>
                                        <tr>
                                            <th className="rowNo">#</th>
                                            <th>{t("common.date")}</th>
                                            <th>{t("common.documentNo")}</th>
                                            <th>{t("common.customer")}</th>
                                            <th className="qty">{t("common.totalQty")}</th>
                                            <th className="price">{t("common.grandTotal")}</th>
                                            {tempKey.map((val:string)=><th>{titleCase(val)}</th>)}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {columns}
                                    </tbody>
                                </Table>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </PrintArea>
        );
    }
}

export default withTranslation()(SalesTempDataPrint);

import ReactGA from "react-ga";

/**
 * NOTES
 * 
 * If GA is not working locally, make sure you turn of your tracker-blocker/ad-blocker in your test-browser.
 */

/**
 * Call this first time app load
 */
export function initializeGA() {
    //initialize google-analytics
    if (process.env.REACT_APP_USE_ANALYTICS === "true") {
        if (process.env.REACT_APP_IS_INVENTORY === "true") {
            ReactGA.initialize("UA-153522852-1"); //refine inventory
        } else {
            ReactGA.initialize("UA-153522852-2"); //refine accounting
        }
    }
}

let previousPage = "";

/**
 * Sent pageview analytic
 * @param page location pathname
 */
export const trackPage = (page: any) => {
    if (process.env.REACT_APP_USE_ANALYTICS === "true") {
        if (previousPage !== page) {
            // console.log("Track " + page);
            previousPage = page;
            //set current page
            ReactGA.set({ page });
            //set new pageview
            ReactGA.pageview(page);
        }
    }
};

import React, { Component } from "react";
import ClassNames from "classnames";
import { UncontrolledTooltip, Input, FormGroup, Label, Col, InputGroup, InputGroupAddon } from "reactstrap";
import { withTranslation, WithTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type InputTextType = BaseInputType & { forcePlaceholder: boolean } & WithTranslation;

class BaseInputText extends Component<InputTextType, any> {
    constructor(props: any) {
        super(props);

        this.state = {
            id: ("input" + props.input.name).replace(/[^\w]+/g, ""),
        };
    }

    /**
     * Helps solve lagging 'a bit'
     */
    shouldComponentUpdate(nextProps: any, nextState: any) {
        if (this.props.input.value === nextProps.input.value && this.props.input.meta !== nextProps.input.meta) {
            return false;
        }

        return true;
    }

    render() {
        const {
            input,
            meta: { touched, error },
            className = "",
            label = "",
            placeholder = label,
            forcePlaceholder = false,
            /*onKeyPress,*/ disabled,
            readOnly,
            horizontalInput,
            isRequired,
            logo,
            noInfo,
            t,
        } = this.props;

        let ProcessedInput = (
            <Input
                type="text"
                {...input}
                className={ClassNames({ "has-danger": touched && error })}
                placeholder={!forcePlaceholder && disabled ? "" : t(placeholder)}
                // onKeyPress={onKeyPress}
                disabled={disabled}
                readOnly={readOnly}
                id={this.state.id}
            />
        );

        if (logo) {
            ProcessedInput = (
                <InputGroup>
                    <InputGroupAddon addonType="prepend">
                        <span className="input-group-text">
                            <FontAwesomeIcon icon={logo} />
                        </span>
                    </InputGroupAddon>
                    {ProcessedInput}
                </InputGroup>
            );
        }

        return (
            <FormGroup row={horizontalInput} className={className}>
                {label && (
                    <Label for={this.state.id} /*sm={horizontalInput ? 2 : false}*/>
                        {t(label)} {isRequired ? <small>*</small> : ""}
                    </Label>
                )}
                <Col sm={horizontalInput ? 10 : false} style={{ padding: "0" }}>
                    {ProcessedInput}
                </Col>
                {noInfo !== true && (
                    <UncontrolledTooltip
                        className={ClassNames({ "force-hidden": !(touched && error) })}
                        placement="right"
                        target={this.state.id}
                        flip={false} //https://github.com/reactstrap/reactstrap/issues/1488
                    >
                        {t(error)}
                    </UncontrolledTooltip>
                )}
            </FormGroup>
        );
    }
}

// class BaseDebouncedInputText extends Component<InputTextType, any> {
//     lastPropValue: any;
//     debouncedOnChange: any;
//     handleChange: any;
//     constructor(props: any) {
//         super(props);

//         //get initial value
//         this.state = {
//             value: props.input.value,
//             id: ("input" + props.input.name).replace(/[^\w]+/g, ""),
//         };
//         //set lastValue as initial value
//         this.lastPropValue = props.input.value;

//         this.debouncedOnChange = debounce((event: any) => {
//             props.input.onChange(event.target.value);
//         }, 250);

//         //On change, debounce it
//         this.handleChange = (event: any) => {
//             event.persist();
//             this.setState({ value: event.target.value });
//             this.debouncedOnChange(event);
//         };
//     }

//     /**
//      * Helps solve lagging 'a bit'
//      */
//     shouldComponentUpdate(nextProps: any, nextState: any) {
//         if (
//             this.props.input.value === nextProps.input.value &&
//             this.props.input.meta !== nextProps.input.meta
//         ) {
//             return false;
//         }

//         return true;
//     }

//     componentDidUpdate(prevProps: any) {
//         //if changed programatically, override all
//         if (this.props.input.value !== prevProps.input.value) {
//             this.lastPropValue = this.props.input.value;
//             this.setState({ value: this.props.input.value });
//         }
//     }

//     /**
//      * Get value to be shown. Which is newest value or debounced value
//      */
//     getValue() {
//         const value =
//             this.props.input.value !== this.lastPropValue ? this.props.input.value : this.state.value;

//         this.lastPropValue = this.props.input.value;

//         return value;
//     }

//     render() {
//         const {
//             input,
//             meta: { touched, error },
//             className = "",
//             label = "",
//             placeholder = label,
//             forcePlaceholder = false,
//             /*onKeyPress,*/ disabled,
//             readOnly,
//             horizontalInput,
//             isRequired,
//             logo,
//             t,
//         } = this.props;

//         let ProcessedInput = (
//             <Input
//                 type="text"
//                 {...input}
//                 className={ClassNames({ "has-danger": touched && error })}
//                 placeholder={!forcePlaceholder && disabled ? "" : t(placeholder)}
//                 // onKeyPress={onKeyPress}
//                 disabled={disabled}
//                 readOnly={readOnly}
//                 id={this.state.id}
//                 value={this.getValue()}
//                 onChange={this.handleChange}
//             />
//         );

//         if (logo) {
//             ProcessedInput = (
//                 <InputGroup>
//                     <InputGroupAddon addonType="prepend">
//                         <span className={logo + " input-group-text"} />
//                     </InputGroupAddon>
//                     {ProcessedInput}
//                 </InputGroup>
//             );
//         }

//         return (
//             <FormGroup row={horizontalInput} className={className}>
//                 {label && (
//                     <Label for={this.state.id} /*sm={horizontalInput ? 2 : false}*/>
//                         {t(label)} {isRequired ? <small>*</small> : ""}
//                     </Label>
//                 )}
//                 <Col sm={horizontalInput ? 10 : false} style={{ padding: "0" }}>
//                     {ProcessedInput}
//                 </Col>
//                 <UncontrolledTooltip
//                     className={ClassNames({ "force-hidden": !(touched && error) })}
//                     placement="right"
//                     target={this.state.id}
//                     flip={false} //https://github.com/reactstrap/reactstrap/issues/1488
//                 >
//                     {t(error)}
//                 </UncontrolledTooltip>
//             </FormGroup>
//         );
//     }
// }

export const InputText = withTranslation()(BaseInputText);
// export const DebouncedInputText = withTranslation()(BaseDebouncedInputText);

import i18n from "i18next";
import { initReactI18next } from "react-i18next";

//load translation file
import translationID from "./translation/indonesia.json";
import translationEN from "./translation/english.json";

//i18n config
let resources = {
    id: {
        translation: translationID,
    },
    en: {
        translation: translationEN,
    },
};

//language list (for options)
export const languageList = [{ key: "id", label: "Indonesia" }, { key: "en", label: "English" }];

const choosenLanguage = localStorage.getItem("language");

i18n.use(initReactI18next).init({
    resources,
    lng: choosenLanguage ? choosenLanguage : "id",

    interpolation: {
        escapeValue: false, //react already handle this
    },
});

export default i18n;

import changeCase from "change-case";
import reducerRegistry from "../../common/reduxHandler/reducerRegistry";
import i18n from "../../../i18n";
// no specific actions
// reducer

/**
 * ALL state that added to globalLoading MUST have accompanying successNotification or Non-null payload.
 *
 * So this reducer can add and delete progress accordingly.
 *
 * Any dispatched action that contains addToLoading will be added into the array of loading.
 * When any action finished/have success notification, the type fill be searched in the array.
 * If found, the action will be deleted from the array.
 *
 * Currently the 'key' is only the type. So the action is non-unique
 */
function globalLoading(state: string[] = [], action: DispatchedAction<any>) {
    if (
        action.addToLoading === true ||
        (((action.type.indexOf("CREATE") > -1 && action.type.indexOf("DONE") === -1 && action.type.indexOf("FAILED") === -1) ||
            (action.type.indexOf("UPDATE") > -1 && action.type.indexOf("DONE") === -1 && action.type.indexOf("FAILED") === -1) ||
            (action.type.indexOf("DELETE") > -1 && action.type.indexOf("DONE") === -1 && action.type.indexOf("FAILED") === -1)) &&
            action.type.indexOf("@@redux") === -1) //any create/update/delete that's not redux-form action, add to loading
    ) {
        return [...state, changeCase.title(action.type)];
    }

    if (
        action.payload != null ||
        action.successNotification ||
        (action.error!=null && action.type.indexOf("@@redux") === -1)
    ) {
        //new success action or error (assume success always return payload)
        //delete the _DONE or _FAILED from the type
        const lastUnderscore = action.type.lastIndexOf("_");
        const typeName = changeCase.title(action.type.slice(0, lastUnderscore));
        const index = state.indexOf(typeName);
        
        if (index > -1) {
            let newState = [...state];
            newState.splice(index, 1);
            return newState;
        }
    }

    return state;
}

/**
 * Adding success notification (green) for all create/update/delete.
 *
 * Can also add success notification for other action by adding "successNotification" in the dispatched action.
 */
function globalSuccess(state = {}, action: DispatchedAction<any>) {
    if (action.successNotification) {
        //priority
        return { source: changeCase.title(action.type), message: action.successNotification };
    } else if (action.type.indexOf("CREATE") > -1 && action.type.indexOf("CREATED")===-1 && action.type.indexOf("DONE") > -1) {
        //all Create
        return { source: changeCase.title(action.type), message: i18n.t("notification.create") };
    } else if (action.type.indexOf("UPDATE") > -1 && action.type.indexOf("DONE") > -1) {
        //all Update
        return { source: changeCase.title(action.type), message: i18n.t("notification.update") };
    } else if (action.type.indexOf("DELETE") > -1 && action.type.indexOf("DONE") > -1) {
        //all Delete
        return { source: changeCase.title(action.type), message: i18n.t("notification.delete") };
    }

    return state;
}

function globalError(state = {}, action: DispatchedAction<any>) {
    if (action.error && action.type.indexOf("@@redux") === -1) {
        let message = "An Error has Occured";

        try {
            message = action.error?.response?.data?.meta?.message ?? action.error.message ?? "";
        } catch (err){}

        return {
            source: changeCase.title(action.type),
            message
        };
    }
    return state;
}

//register
reducerRegistry.register("globalLoading", globalLoading);
reducerRegistry.register("globalSuccess", globalSuccess);
reducerRegistry.register("globalError", globalError);

// selectors
export const slcGlobalLoading = (state: any) => state["globalLoading"];
export const slcGlobalSuccess = (state: any) => state["globalSuccess"];
export const slcGlobalError = (state: any) => state["globalError"];

//action creator
export function triggerGlobalSuccess(source: string, message: string) {
    return {
        type: changeCase.title(source),
        successNotification: message,
    };
}

export function triggerGlobalError(source: string, message: string) {
    return {
        type: changeCase.title(source),
        error: { message },
    };
}

import React from "react";
import { useTranslation } from "react-i18next";
import changeCase from "change-case";
import { showDate } from "../../function/showValueHelper";
import { getDataFromSingleCache } from "../../function/cache";

type PrintHeaderProps = {
    title: string;
    companyInfo: any;
    supplier?: any;
    customer?: any;
    customAddress?: string;
    no: string;
    dtCreated: string;
};

function getData(companyInfo: any, key: string) {
    let obj = companyInfo.find((val: any) => val.key === key);
    return obj ? obj.value : "";
}

const SimpleHeader = React.memo(
    ({ title, companyInfo, supplier, customer, no, dtCreated, customAddress }: PrintHeaderProps) => {
        const { t } = useTranslation();
        const imgData = getDataFromSingleCache("setting/logo");
        let headerElm = imgData ? (
            <tr>
                <th className="docTitle" style={{ width: "60%" }}>
                    <img src={imgData} height="80px" className="logo" alt="logo" />
                </th>
                <th className="docTitle" style={{ textAlign: "left" }}>
                    <span style={{ verticalAlign: "bottom" }}>{changeCase.upper(title)}</span>
                </th>
            </tr>
        ) : (
            <tr>
                <th colSpan={2} className="docTitle" style={{ verticalAlign: "bottom" }}>
                    {changeCase.upper(title)}
                </th>
            </tr>
        );
        let to = supplier != null ? supplier : customer;

        return (
            <thead>
                {headerElm}
                <tr style={{ marginBottom: "10px" }}>
                    <th style={{ width: "60%" }}>
                        <b>{no}</b>
                    </th>
                    <th>
                        <b>
                            {t("common.date")}: {showDate(dtCreated)}
                        </b>
                    </th>
                </tr>
                <tr>
                    <th>
                        {getData(companyInfo, "nama-perusahaan")} <br />
                        {getData(companyInfo, "alamat-perusahaan")}
                        {getData(companyInfo, "kota") ? ", " + getData(companyInfo, "kota") + "." : ""}
                        {getData(companyInfo, "no-npwp") ? (
                            <>
                                <br />
                                No. NPWP: {getData(companyInfo, "no-npwp")}
                            </>
                        ) : (
                            ""
                        )}
                    </th>
                    <th>
                        {t("common.recipient")}: <br />
                        {to.name} <br />
                        {customAddress != null
                            ? customAddress
                            : to.address + "\n" + (to.city ? ", " + to.city + "." : "")}
                        {/* {to.address}
                    {to.city ? ", " + to.city + "." : ""} <br /> */}
                        {to.taxIdentificationNo !== "" ? `No. NPWP: ${to.taxIdentificationNo}` : ""}
                    </th>
                </tr>
            </thead>
        );
    }
);

export default SimpleHeader;

import { camelCase } from "change-case";
import { PureComponent } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Table } from "reactstrap";
import PrintArea from "../../../common/component/PrintArea";
import { showCurrency, showQty } from "../../../common/function/showValueHelper";
import {
    HybridReportOutput,
    parseListIntoPivotedList,
    PivotedList,
    PivotRow,
} from "../../../common/function/tabularToPivotedList";
import { asyncGetCreatedReport } from "../redux/hybridReport";
import { HybridReportListProcessor, reportListType } from "./reportListType";
import {
    SalesGrossProfit,
    ReceivableCard,
    InventoryCardAcc,
    PayableCard,
    PurchaseSummary,
    InventoryCardLimited,
    ProfitDeficit,
    FinancePosition,
    InventoryMutationLimited,
    InventoryMutationAcc,
    Ledger,
} from "../HybridReportPrint";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type CreatedReportPrintState = {
    data: PivotedList[] | null;
    activeReportListType: HybridReportListProcessor | null;
    meta: any;
    sourceInfoList: any;
    loading: boolean;
    reportType: "list" | "custom" | string | null;
};

class CreatedReportPrint extends PureComponent<WithTranslation, CreatedReportPrintState> {
    constructor(props: any) {
        super(props);

        let stateObj = {
            data: null,
            activeReportListType: null,
            meta: null,
            sourceInfoList: null,
            reportType: null,
            loading: true,
        };

        this.state = stateObj;
    }

    componentDidMount(): void {
        //get the data
        this.processGetData();
    }

    processGetData = async () => {
        //load data from localStorage
        const passedId = localStorage.getItem("print-created-report");

        if (passedId != null) {
            const idToGet = Number(passedId);
            let jsonData = await asyncGetCreatedReport(idToGet);
            this.setState({ loading: false });
            let parsedData = jsonData.listData;
            let activeReportListType = reportListType[jsonData.meta.report];
            if (jsonData.type === "list" && activeReportListType != null) {
                parsedData = parseListIntoPivotedList(activeReportListType.pivot, jsonData.listData, { debug: true });
            } else {
                parsedData = jsonData;
            }

            this.setState({
                data: parsedData,
                activeReportListType,
                meta: jsonData.meta,
                sourceInfoList: jsonData.sourceInfoList,
                reportType: jsonData.type,
            });
        }

        // remove data from localStorage
        // localStorage.removeItem("CreatedReport-print");
    };

    renderMeta = () => {
        const { meta, sourceInfoList } = this.state;
        const { t } = this.props;
        let filter = null;
        if (sourceInfoList.length > 0) {
            filter = sourceInfoList?.map((val: any, i: number) => (
                <tr key={i}>
                    <th>{t(`common.${camelCase(val.label)}`)}</th>
                    <td>{val.value}</td>
                </tr>
            ));
        }
        return (
            <>
                <Table className="ra-report-meta-print">
                    <tbody>
                        <tr>
                            <th colSpan={2}>{t(`common.${meta.report ?? "--"}`)}</th>
                        </tr>
                        <tr>
                            <th style={{ width: "20%" }}>{t("common.date")}</th>
                            <td>: {`${meta.periodStartDate} - ${meta.periodEndDate}`}</td>
                        </tr>
                        <tr>
                            <th>{t("common.createDate")}</th>
                            <td>: {meta.exportDate}</td>
                        </tr>
                        {filter ? (
                            <>
                                <tr>
                                    <th colSpan={2}>{t("Filter")}</th>
                                </tr>
                                {filter}
                            </>
                        ) : (
                            <></>
                        )}
                    </tbody>
                </Table>
            </>
        );
    };

    renderSimpleTable = (data: any) => {
        let headerData = data?.columnDef;
        if (data == null || headerData == null) return <span />;

        let header: any[] = [];

        for (let i = 0; i < headerData.length; i++) {
            let def = headerData[i];
            header.push(<th key={i}>{def.header}</th>);
        }

        let output: any[] = [];
        let excelData = data.listData;
        for (let i = 0; i < excelData?.length; i++) {
            let row = excelData[i];
            let rowOutput: any[] = [];

            for (let j = 0; j < headerData.length; j++) {
                let cell = row[headerData[j].key];

                if (!cell) {
                    rowOutput.push(<td key={j}></td>);
                    continue;
                } // make sure cell has value

                if (cell.formula != null) {
                    rowOutput.push(<td key={j}>--</td>);
                } else if (cell.format === "qty") {
                    rowOutput.push(<td key={j}>{showQty(cell.value)}</td>);
                } else if (cell.format !== "text" && !isNaN(cell.value) && cell.value?.toString().split()[0] !== "0") {
                    rowOutput.push(<td key={j}>{showCurrency(cell.value)}</td>);
                } else {
                    //if (cell.format === "percent") and string
                    rowOutput.push(<td key={j}>{cell.value}</td>);
                }
            }

            output.push(<tr key={i}>{rowOutput}</tr>);
        }

        return (
            <>
                <Table>
                    <thead>
                        <tr>{header}</tr>
                    </thead>
                    <tbody>{output}</tbody>
                </Table>
            </>
        );
    };

    theData = (data: string | number) => {
        switch (typeof data) {
            case "number":
                return showCurrency(data);
            default:
                return data;
        }
    };

    mapPivotedListIntoColumn = (data: HybridReportOutput, renderedKeys: string[] = []) => {
        let dataKeys = Object.keys(data);
        let res: any[] = [];
        renderedKeys.forEach((val) => {
            if (dataKeys.includes(val)) {
                res.push(<td key={val}>{this.theData(data[val].value)}</td>);
            }
        });
        return res;
    };

    renderPivot = (pivot: PivotRow) => {
        const { t } = this.props;
        let result: any = [];
        Object.keys(pivot).forEach((val) => {
            result.push(
                <tr key={`${val}-${pivot[val]}`}>
                    <th style={{ width: "25%" }}>{`${t(`common.${val}`)}`}</th>
                    <th style={{ width: "75%" }}>: {`${pivot[val]}`}</th>
                </tr>
            );
        });
        return result;
    };

    renderData = (pivotedList: PivotedList[] | null, keys: string[] = []) => {
        switch (this.state.meta.report) {
            case "salesGrossProfit":
                return <SalesGrossProfit data={this.state.data} />;

            case "receivableCard":
                return <ReceivableCard data={this.state.data} />;

            case "inventoryCard":
                return <InventoryCardAcc data={this.state.data} />;

            case "limitedInventoryCard":
                return <InventoryCardLimited data={this.state.data} />;

            case "limitedInventoryMutation":
                return <InventoryMutationLimited data={this.state.data} />;

            case "inventoryMutation":
                return <InventoryMutationAcc data={this.state.data} />;

            case "payableCard":
                return <PayableCard data={this.state.data} />;

            case "purchaseSummary":
                return <PurchaseSummary data={this.state.data} />;

            case "profitDeficit":
                return <ProfitDeficit data={this.state.data} />;

            case "financePosition":
                return <FinancePosition data={this.state.data} />;

            case "ledger":
                return <Ledger data={this.state.data} />;

            default:
                // default is report with type list
                return pivotedList?.map((row, i) => {
                    if (!row) return <></>;
                    if (!row.list) {
                        // must have children and pivotedList
                        return (
                            <Table className="ra-report-pivot" key={`table-pivot-${i}`}>
                                <thead>{this.renderPivot(row.pivot)}</thead>
                                <tbody>
                                    <tr>
                                        <td colSpan={2}>{this.renderData(row.pivotedList ?? [], keys)}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        );
                    } else {
                        return (
                            <Table className="ra-report-pivot" key={`table-pivot-${i}`}>
                                <thead>{this.renderPivot(row.pivot)}</thead>
                                <tbody>
                                    <tr>
                                        <td colSpan={2} className={"ra-report-wrapper"}>
                                            <Table className="ra-report">
                                                <thead>
                                                    <tr>
                                                        {keys.map((val) => (
                                                            <th key={val}>{this.props.t(`common.${val}`)}</th>
                                                        ))}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {row.list?.map((val, i) => (
                                                        <tr key={i}>{this.mapPivotedListIntoColumn(val, keys)}</tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        );
                    }
                });
        }
    };

    render() {
        const { data } = this.state;
        const { t } = this.props;
        if (data == null && this.state.loading)
            return (
                <p style={{ textAlign: "center" }}>
                    <FontAwesomeIcon icon={"spinner"} spin /> {`${t("common.loading")}`}
                </p>
            );
        else if (data == null && !this.state.loading) return <p>No Data</p>;

        return (
            //@ts-ignore
            <PrintArea>
                {this.renderMeta()}
                {this.renderData(data, this.state.activeReportListType?.data)}
            </PrintArea>
        );
    }
}

export default withTranslation()(CreatedReportPrint);

import { PureComponent } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Table } from "reactstrap";
import { showCurrency } from "../../../common/function/showValueHelper";

type ProfitDeficitProps = {
    data: any;
};

type ProfitDeficitState = {};

class ProfitDeficit extends PureComponent<WithTranslation & ProfitDeficitProps, ProfitDeficitState> {
    constructor(props: any) {
        super(props);
        let stateObj = {};
        this.state = stateObj;
    }

    render() {
        const { data } = this.props;
        let result = data.listData.map((val: any, i: number) => {
            let keys = Object.keys(val);
            if (i < 4 || keys.length === 0) return <></>;
            if (keys.length === 1)
                return (
                    <tr>
                        <th colSpan={4}>{val.description?.value ?? ""}</th>
                    </tr>
                );
            return (
                <tr>
                    <td>{val.description?.value ?? ""}</td>
                    <td>{showCurrency(val.debit?.value)}</td>
                    <td>{showCurrency(val.credit?.value)}</td>
                    <td>{showCurrency(val.balance?.value)}</td>
                </tr>
            );
        });
        return (
            <div>
                <style>
                    {`
                        @page {
                            size: A4 portrait;
                        }

                        .ra-table-detail-print td,
                        .ra-table-detail-print th {
                            border: 1px solid transparent;
                        }
                    `}
                </style>
                <Table className="ra-table-detail-print">
                    <colgroup>
                        <col style={{ width: "40%" }}></col>
                        <col style={{ width: "20%" }}></col>
                        <col style={{ width: "20%" }}></col>
                        <col style={{ width: "20%" }}></col>
                    </colgroup>
                    <tbody>{result}</tbody>
                </Table>
            </div>
        );
    }
}

export default withTranslation()(ProfitDeficit);

import React, { useState } from "react";
import ClassNames from "classnames";
import { UncontrolledTooltip, Input, FormGroup, Label, Col } from "reactstrap";
import { useTranslation } from "react-i18next";

type InputEmailType = BaseInputType & {};

export const InputEmail = ({
    input,
    meta: { touched, error },
    className = "",
    label = "",
    placeholder = label,
    /*onKeyPress,*/ disabled,
    readOnly,
    horizontalInput,
    isRequired,
    noInfo,
}: InputEmailType) => {
    const { t } = useTranslation();
    const [id] = useState(("input" + input.name).replace(/[^\w]+/g, ""));
    return (
        <FormGroup row={horizontalInput} className={className}>
            {label && (
                <Label for={id} /*sm={horizontalInput ? 2 : false}*/>
                    {t(label)} {isRequired ? <small>*</small> : ""}
                </Label>
            )}
            <Col sm={horizontalInput ? 10 : false} style={{ padding: "0" }}>
                <Input
                    type="email"
                    {...input}
                    className={ClassNames({ "has-danger": touched && error })}
                    placeholder={disabled ? "" : t(placeholder)}
                    // onKeyPress={onKeyPress}
                    disabled={disabled}
                    readOnly={readOnly}
                    id={id}
                />
            </Col>
            {noInfo !== true && (
                <UncontrolledTooltip
                    className={ClassNames({ "force-hidden": !(touched && error) })}
                    placement="right"
                    target={id}
                    flip={false} //https://github.com/reactstrap/reactstrap/issues/1488
                >
                    {t(error)}
                </UncontrolledTooltip>
            )}
        </FormGroup>
    );
};

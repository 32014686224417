import React, { PureComponent } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Table } from "reactstrap";
import HTMLPrintHeader from "../../../common/component/HTMLPrintHeader";
import PrintArea from "../../../common/component/PrintArea";
import { SafeBig } from "../../../common/function/safeBig";
import { showCurrency, showDate } from "../../../common/function/showValueHelper";

type PayableCardPrintState = {
    data: any;
    param: any;
};

class PayableCardPrint extends PureComponent<WithTranslation, PayableCardPrintState> {
    constructor(props: any) {
        super(props);

        //load data from localStorage
        const stringData = localStorage.getItem("payable-card-print");
        const jsonData = stringData != null ? JSON.parse(stringData) : null;

        //remove data from localStorage
        localStorage.removeItem("payable-card-print");
        

        let stateObj = {
            data: jsonData?.data ?? null,
            param: jsonData?.param ?? {},
        }

        this.state = stateObj;
    }

    render() {
        const { t } = this.props;
        const { param, data } = this.state;
        if (data == null) return <p>No Data</p>;

        let columns:any[] = []; 
        
        // for(let list of customerList)
        let grandTotal = SafeBig(0);
        for(let grouped of data){

            // Render supplier
            columns.push(<tr>
                <td><b>{`${t("common.supplier")}`}</b></td>
                <td colSpan={2}><b>{`: ${grouped.supplier.name}`}</b></td>
            </tr>)
            
            let details: any[] = []; let net = SafeBig(grouped.initAmount);
            details.push(
                <tr key={"initial-amount"}>
                    <td colSpan={5}>{t("common.beginningBalance")}</td>
                    <td style={{ textAlign: "right" }}>{showCurrency(grouped.initAmount, 2)}</td>
                </tr>);

            for(let i = 0; i< grouped.rowList.length;i++){
                let obj = grouped.rowList[i];
                net = net.add(obj.increase ?? 0).minus(obj.decrease ?? 0);
                details.push(
                    <tr key={"detail-" + i}>
                        <td className="rowNo">{i + 1}</td>
                        <td>{showDate(obj.date)}</td>
                        <td>{obj.key}</td>
                        <td style={{ textAlign: "right" }}>{showCurrency(obj.increase, 2)}</td>
                        <td style={{ textAlign: "right" }}>{showCurrency(obj.decrease, 2)}</td>
                        <td style={{ textAlign: "right" }}>{showCurrency(net, 2)}</td>
                    </tr>
                );
            }

            //summary
            details.push(<tr>
                <td colSpan={5} style={{ textAlign: "right" }}><b>{t("common.subTotal")}</b></td>
                <td style={{ textAlign: "right" }}>{showCurrency(net, 2)}</td>
            </tr>)

            columns.push(
                <tr>
                    <td colSpan={3} style={{ padding: "0 !important" }}>
                        <Table className="ra-table-detail-print">
                            <thead>
                                <tr>
                                    <th className="rowNo">#</th>
                                    <th>{t("common.date")}</th>
                                    <th>{t("common.documentNo")}</th>
                                    <th className="subTotal">{t("common.increase")}</th>
                                    <th className="subTotal">{t("common.decrease")}</th>
                                    <th className="subTotal">{t("common.amount")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {details}
                            </tbody>
                        </Table>
                    </td>
                </tr>);
            grandTotal = grandTotal.plus(net);
        }

        return (
            <PrintArea>
                <Table className="ra-table-print">
                    <HTMLPrintHeader
                        param={param}
                    />
                    <tbody>
                        {columns}
                        <tr>
                            <td colSpan={3}>
                                <Table>
                                    <tfoot>
                                        <tr>
                                            <td style={{ textAlign: "right", width: "80%" }}><b>{t("common.grandTotal")}</b></td>
                                            <td style={{ textAlign: "right" }}>{showCurrency(grandTotal, 2)}</td>
                                        </tr>
                                    </tfoot>
                                </Table>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </PrintArea>
        );
    }
}

export default withTranslation()(PayableCardPrint);

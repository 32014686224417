import { Component } from "react";

export type scrCheckIsEditIsShow = (path:string)=>{isEdit:boolean, isShow:boolean, paramKey:string};

/**
 * Check whether current path is edit/show form. Also returns parameter key if available.
 */
export function checkIsEditIsShow(this:Component<ReactRouterProps>, path:string){
    let isEdit = false,
        isShow = false;
    if (this.props.match.path === `${path}/edit/:key` && this.props.match.params.key != null)
        isEdit = true;
    if (this.props.match.path === `${path}/show/:key` && this.props.match.params.key != null)
        isShow = true;

    let paramKey = null;
    if (this.props.match.params!=null){
        paramKey = this.props.match.params.key;
    }

    return {isEdit, isShow, paramKey};
}
import axios from "axios";
import { getDataFromCachedCollection, saveIntoCacheCollection } from "./cache";
import { CACHE_MODE, filterParam, getAuthHeader } from "./request";

const ROOT_URL = process.env.NODE_ENV === "development" ? "/admin-api/v1" : process.env.REACT_APP_ADMIN_URL;

/**
 * POST request
 * @param urlPath Path URL to the request (include any :var if any, without ROOT_URL)
 * @param data JSON data (body)
 * @param config Appended axios config
 */
export function reqAdminPost(urlPath: string, data: any, config = {}, skipFilter: boolean = false) {
    let filteredData = skipFilter ? data : filterParam(data);

    return axios.post(`${ROOT_URL}/${urlPath}`, filteredData, {
        headers: getAuthHeader(),
        withCredentials: true,
        ...config,
    });
}

/**
 * GET request
 *
 * Will be used to cache requests (e.g. {all:true} as params)
 *
 * @param urlPath Path URL to the request (include any :var if any, without ROOT_URL)
 * @param params GET parameter (obj) that will be processed by lib to (?var1=val1&&var2=val2)
 * @param useCache Enum CACHE_MODE: Use LocalStorage caching or not
 * @param config Appended axios config
 */
export function reqAdminGet(
    urlPath: string,
    params: any,
    config: any = {},
    useCache = CACHE_MODE.NO_LOAD_NO_SAVE,
    customCacheDuration?: number
) {
    if (useCache === CACHE_MODE.WITH_LOAD_WITH_SAVE) {
        let key = `@admin/${urlPath}`; //storage key
        const cachedResult = getDataFromCachedCollection(key, params);
        if (cachedResult != null) {
            //if any cached data, return as Promise (simulate payload object)
            return new Promise<any>((res, rej) =>
                res({
                    data: cachedResult,
                })
            );
        }
    }

    let filteredParam = filterParam(params);

    //for request without cache
    let result = axios.get(`${ROOT_URL}/${urlPath}`, {
        params: filteredParam,
        headers: getAuthHeader(),
        withCredentials: true,
        ...config,
    });

    //cache the result to localStorage if allowed
    if (useCache === CACHE_MODE.WITH_LOAD_WITH_SAVE || useCache === CACHE_MODE.NO_LOAD_WITH_SAVE) {
        let key = `@admin/${urlPath}`; //storage key
        result.then((payload: any) => saveIntoCacheCollection(key, params, payload.data, customCacheDuration));
    }

    return result;
}

/**
 * PUT request
 * @param urlPath Path URL to the request (include any :var if any, without ROOT_URL)
 * @param data JSON data (body)
 * @param config Appended axios config
 */
export function reqAdminPut(urlPath: string, data: any, config = {}) {
    let filteredData = filterParam(data);

    return axios.put(`${ROOT_URL}/${urlPath}`, filteredData, {
        headers: getAuthHeader(),
        withCredentials: true,
        ...config,
    });
}

/**
 * DELETE request
 * @param urlPath Path URL to the request (include any :var if any, without ROOT_URL)
 * @param config Appended axios config
 */
export function reqAdminDelete(urlPath: string, config = {}) {
    return axios.delete(`${ROOT_URL}/${urlPath}`, {
        headers: getAuthHeader(),
        withCredentials: true,
        ...config,
    });
}

import { camelCase, pascalCase } from "change-case";
import dayjs from "dayjs";
import React from "react";
import { useTranslation } from "react-i18next";
import { isArray } from "util";

type HTMLPrintHeaderProps = {
    param: any;
};

const HTMLPrintHeader = React.memo(({ param }: HTMLPrintHeaderProps) => {
    const { t } = useTranslation();

    const { startDate, endDate, name } = param;
    let date = t("common.all");
    if (startDate != null && endDate != null) {
        //time always 000000 & 235959, so we can use this logic
        let startString = dayjs(startDate).format("DD/MM/YYYY");
        let endString = dayjs(endDate).format("DD/MM/YYYY");
        if (startString === endString) {
            date = startString;
        } else {
            date = `${startString} - ${endString}`;
        }
    }

    let queryList: any[] = [];
    for (let key in param) {
        if (!key.includes("Id") && !["startDate", "endDate", "name", "moduleName"].includes(key)) {
            queryList.push(
                <tr key={key}>
                    <td>{pascalCase(key)}</td>
                    <td>: {isArray(param[key]) ? param[key].join(", ") : param[key]}</td>
                </tr>
            );
        }
    }

    return (
        <thead>
            <tr>
                <th style={{ width: "200px" }}>
                    {t("common.reportName")} <br />
                    {t("common.date")} <br />
                    {t("common.printDate")}
                </th>
                <th>
                    {t(`common.${camelCase(param.moduleName)}`)} <br />
                    {date} <br />
                    {dayjs().format("DD/MM/YYYY HH:mm")} {` ${t("common.by")} ${name}`} <br />
                </th>
                <th>
                    {queryList.length > 0 ? (
                        <table className="table-param-info">
                            <tbody>{queryList}</tbody>
                        </table>
                    ) : (
                        <></>
                    )}
                </th>
            </tr>
            {/* <tr>
                    <th></th>
                    <th></th>
                </tr>
                <tr>
                    <th></th>
                    <th>
                        
                    </th>
                </tr> */}
        </thead>
    );
});

export default HTMLPrintHeader;

import React, { useState } from "react";
import ClassNames from "classnames";
import { UncontrolledTooltip, FormGroup, Label, Col } from "reactstrap";
import { useTranslation } from "react-i18next";

type InputFileType = {
    input: any;
    meta: { touched: boolean; error: string };
    className?: string;
    label?: string;
    onChange?: Function;
    onBlur?: Function;
    disabled?: boolean;
    horizontalInput?: boolean;
    isRequired?: boolean;
    accept?: string; //extension limitation
};

const handleChange = (handler: any) => ({ target: { files } }: any) => {
    handler(files.length ? { file: files[0], name: files[0].name } : {});
};

export const InputFile = ({
    input: { onChange, onBlur, name, value: omitValue, ...inputProps },
    meta: { touched, error },
    className = "",
    label = "",
    disabled,
    horizontalInput,
    isRequired,
    accept,
}: InputFileType) => {
    const { t } = useTranslation();
    const [id] = useState(("input" + name).replace(/[^\w]+/g, ""));
    return (
        <FormGroup row={horizontalInput} className={className}>
            {label && (
                <Label for={id} /*sm={horizontalInput ? 2 : false}*/>
                    {t(label)} {isRequired ? <small>*</small> : ""}
                </Label>
            )}
            <Col sm={horizontalInput ? 10 : false} style={{ padding: "0" }}>
                {/* <input
                    type="file"
                    {...input}
                    className={ClassNames({ "has-danger": touched && error })}
                    // onChange={adaptFileEventToValue(input.onChange)}
                    // onBlur={adaptFileEventToValue(input.onBlur)}
                    disabled={disabled}
                    id={id}
                /> */}
                <input
                    type="file"
                    onChange={handleChange(onChange)}
                    onBlur={handleChange(onBlur)}
                    {...inputProps}
                    accept={accept}
                    disabled={disabled}
                    id={id}
                />
            </Col>
            <UncontrolledTooltip
                className={ClassNames({ "force-hidden": !(touched && error) })}
                placement="right"
                target={id}
                flip={false} //https://github.com/reactstrap/reactstrap/issues/1488
            >
                {t(error)}
            </UncontrolledTooltip>
        </FormGroup>
    );
};

import i18n from "../../../i18n";
import { reqAdminGet, reqAdminPut } from "../../common/function/adminRequest";
import { deleteCacheCollection } from "../../common/function/cache";
import { setCreateEditParam } from "../../common/function/requestParamHelper";
import reducerRegistry from "../../common/reduxHandler/reducerRegistry";

//initial state
const initialState: CommonReduxState = {
    loading: false,
    token: null,
    data: null,
    error: null,
};

//action
const createActionName = (name: string) => `myAccount/${name}`;
const FETCH_MY_ACCOUNT = createActionName("FETCH_MY_ACCOUNT");
const FETCH_MY_ACCOUNT_DONE = createActionName("FETCH_MY_ACCOUNT_DONE");
const FETCH_MY_ACCOUNT_FAILED = createActionName("FETCH_MY_ACCOUNT_FAILED");
const UPDATE_MY_ACCOUNT = createActionName("UPDATE_MY_ACCOUNT");
const UPDATE_MY_ACCOUNT_DONE = createActionName("UPDATE_MY_ACCOUNT_DONE");
const UPDATE_MY_ACCOUNT_FAILED = createActionName("UPDATE_MY_ACCOUNT_FAILED");
const CHANGE_LANGUAGE = createActionName("CHANGE_LANGUAGE"); //simple action

//reducer
function fetchMyAccount(state = initialState, action: DispatchedAction<APIBaseDataResponse>) {
    switch (action.type) {
        case FETCH_MY_ACCOUNT:
            return { ...initialState, loading: true };
        case FETCH_MY_ACCOUNT_DONE:
            localStorage.setItem("language", action.payload.data.languageCode);
            return { ...state, data: action.payload.data, loading: false };
        case FETCH_MY_ACCOUNT_FAILED:
            return { ...state, error: action.error, loading: false };
        default:
            return state;
    }
}

function updateMyAccount(state = initialState, action: DispatchedAction<APIBaseDataResponse>) {
    switch (action.type) {
        case UPDATE_MY_ACCOUNT:
            return { ...initialState, loading: true };
        case UPDATE_MY_ACCOUNT_DONE:
            return { ...state, data: action.payload.data, loading: false };
        case UPDATE_MY_ACCOUNT_FAILED:
            return { ...state, error: action.error, loading: false };
        default:
            return state;
    }
}

function currentLanguage(state = localStorage.getItem("language"), action: any) {
    if (action.type === CHANGE_LANGUAGE) {
        localStorage.setItem("language", action.payload);
        return action.payload;
    }

    return state;
}

//register reducer
reducerRegistry.register("fetchMyAccount", fetchMyAccount);
reducerRegistry.register("updateMyAccount", updateMyAccount);
reducerRegistry.register("currentLanguage", currentLanguage);

//selector
export const slcFetchMyAccount = (state: any): CommonReduxState => state["fetchMyAccount"];
export const slcUpdateMyAccount = (state: any): CommonReduxState => state["updateMyAccount"];
export const slcCurrentLanguage = (state: any): CommonReduxState => state["currentLanguage"];

//action creator
/**
 * fetch current user's account
 *
 */
export function actFetchMyAccount() {
    const request = reqAdminGet(`my-account`, null);

    return (dispatch: any) => {
        dispatch({
            type: FETCH_MY_ACCOUNT,
        });
        request
            .then((payload) => {
                dispatch({
                    type: FETCH_MY_ACCOUNT_DONE,
                    payload: payload.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: FETCH_MY_ACCOUNT_FAILED,
                    error,
                });
            });
    };
}

/**
 * Edit current user's account
 *
 * @param integer id
 * @param object valueList
 */
export function actEditMyAccount(valueList: any) {
    let param = setCreateEditParam(valueList);

    const request = reqAdminPut(`my-account`, param);

    return (dispatch: any) => {
        dispatch({
            type: UPDATE_MY_ACCOUNT,
        });
        request
            .then((payload) => {
                deleteCacheCollection("my-account");
                actFetchMyAccount();
                dispatch({
                    type: UPDATE_MY_ACCOUNT_DONE,
                    payload: payload.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: UPDATE_MY_ACCOUNT_FAILED,
                    error,
                });
            });
    };
}

export function actEditLanguage(lang: string) {
    //call i18n instance
    i18n.changeLanguage(lang);

    return {
        type: CHANGE_LANGUAGE,
        payload: lang,
    };
}

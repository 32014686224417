import React, { PureComponent } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Table } from "reactstrap";
import HTMLPrintHeader from "../../../common/component/HTMLPrintHeader";
import PrintArea from "../../../common/component/PrintArea";
import { SafeBig } from "../../../common/function/safeBig";
import { showCurrency, showDate } from "../../../common/function/showValueHelper";

type PreviewPurchasePrintState = {
    data: any;
    param: any;
};

class PreviewPurchasePrint extends PureComponent<WithTranslation, PreviewPurchasePrintState> {
    constructor(props: any) {
        super(props);

        //load data from localStorage
        const stringData = localStorage.getItem("preview-purchase-print");
        const jsonData = stringData != null ? JSON.parse(stringData) : null;

        // remove data from localStorage
        // localStorage.removeItem("preview-purchase-print");
        
        console.log(jsonData?.data);

        let stateObj = {
            data: jsonData?.data ?? null,
            param: jsonData?.param ?? {},
        }

        this.state = stateObj;
    }

    render() {
        const { t } = this.props;
        const { param, data } = this.state;
        if (data == null) return <p>No Data</p>;

        let columns:any[] = [];

        for (let {supplier, purchaseList} of data) {
            columns.push(<tr>
                <td colSpan={3}><h2>{`${supplier.key} - ${supplier.name}`}</h2></td>
            </tr>)

            let purchaseSums = SafeBig(0);
            purchaseList.sort((a: any, b: any) => a.documentDate > b.documentDate ? -1 : 1);
            purchaseList.forEach((purchase: any, i: number) => {
                purchaseSums = purchaseSums.add(purchase.grandTotal);
                columns.push(<tr>
                    <td colSpan={3}><h3>{`${purchase.key} | ${showDate(purchase.documentDate)}`}</h3></td>
                </tr>)

                let purchaseCols: any[] = [];
                purchase.detailList?.forEach((detail: any, i: number) => {
                    purchaseCols.push(<tr>
                        <td className="rowNo">{i+1}</td>
                        <td>{`${detail.product.name}`}</td>
                        <td>{showCurrency(detail.qty)} {detail?.unit?.name ?? detail.product.unit?.name}</td>
                        <td>{showCurrency(detail.priceAfterDiscount)}</td>
                        <td>{showCurrency(detail.subtotal)}</td>
                    </tr>)
                })

                columns.push(<tr>
                    <td colSpan={3}>
                        <Table className="ra-table-detail-print">
                            <thead>
                                <tr>
                                    <th className="rowNo">#</th>
                                    <th className="product">{t("common.product")}</th>
                                    <th className="qty">{t("common.qty")}</th>
                                    <th className="price">{t("common.price")}</th>
                                    <th className="price">{t("common.subTotal")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {purchaseCols}
                                <tr>
                                    <td colSpan={4}>{t("common.total")}</td>
                                    <td>{showCurrency(purchase.grandTotal)}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </td>
                </tr>)
            })

            columns.push(<tr>
                <td style={{ textAlign: "right", fontWeight: "bold"}} colSpan={3}>{t("common.grandTotal")}: {showCurrency(purchaseSums)}</td>
            </tr>);

            // add line break;
            columns.push(<tr>
                <td>&nbsp;</td>
                <td colSpan={2}></td>
            </tr>);
        }

        return (
            <PrintArea>
                <Table className="ra-table-print">
                    <HTMLPrintHeader
                        param={param}
                    />
                    <tbody>
                        {columns}
                    </tbody>
                </Table>
            </PrintArea>
        );
    }
}

export default withTranslation()(PreviewPurchasePrint);

import { lazy } from "react";

/**
 * https://raphael-leger.medium.com/react-webpack-chunkloaderror-loading-chunk-x-failed-ac385bd110e0
 *
 * Experimental method to refresh-webpage when lazy-loaded component failed to load
 *
 * With localstorage to avoid infinite loop
 *
 * Warning: Might have problems with multi-tab
 */
const lazyWithRetry = (componentImport: any) =>
    lazy(async () => {
        const pageHasAlreadyBeenForceRefreshed = JSON.parse(
            window.localStorage.getItem("is-force-refreshed") || "false"
        );

        try {
            const component = await componentImport();

            window.localStorage.setItem("is-force-refreshed", "false");

            return component;
        } catch (error) {
            if (!pageHasAlreadyBeenForceRefreshed) {
                // Assuming that the user is not on the latest version of the application.
                // Let's refresh the page immediately.
                window.localStorage.setItem("is-force-refreshed", "true");
                return window.location.reload();
            }

            // The page has already been reloaded
            // Assuming that user is already using the latest version of the application.
            // Let's let the application crash and raise the error.
            throw error;
        }
    });

export default lazyWithRetry;

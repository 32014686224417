import React, { PureComponent } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Table } from "reactstrap";
import PrintFooter from "../../../common/component/PrintFooter";
import { SafeBig } from "../../../common/function/safeBig";
import { showCurrency, showDate, showQty } from "../../../common/function/showValueHelper";

type ReceiveReportPrintAnjProps = {
    data: any;
    companyInfo: any;
};

function getData(companyInfo: any, key: string) {
    let obj = companyInfo.find((val: any) => val.key === key);
    return obj ? obj.value : "";
}

class ReceiveReportPrintAnj extends PureComponent<WithTranslation & ReceiveReportPrintAnjProps> {
    
    render() {
        const { t } = this.props;
        const { data, companyInfo } = this.props;
        if (data == null) return <p>No Data</p>;
        let totalQty = SafeBig(0);
        let columns = data.detailList.map((obj: any, index: number) => {
            totalQty = totalQty.plus(SafeBig(obj.qty));
            let pUnitToSmallestUnitCompare = SafeBig(obj.productUnitConversion?.qty ?? 1).div(obj.product.smallestUnitConversion?.qty ?? 1);
            return (
                <tr key={"detail-" + index}>
                    <td className="rowNo">{index + 1}</td>
                    <td>{obj.product.name}</td>
                    <td className="qty">
                        {obj.unit == null ? (
                            <table className="printQtyTable">
                                <tbody>
                                    <tr key={"detail-1-" + index}>
                                        <td>{showQty(obj.qty) + " "}</td>
                                        <td>{obj.product?.unit?.name}</td>
                                    </tr>
                                </tbody>
                            </table>
                        ) : (
                            <table className="printQtyTable">
                                <tbody>
                                    <tr key={"detail-1-" + index}>
                                        { !obj.product.smallestUnit || obj.unit.id === obj.product.smallestUnit?.id ? (
                                            <>
                                                <td>{showQty(obj.qty) + " " + obj.unit?.name} @{ showQty(obj.productUnitConversion.qty) + " " + obj.product?.unit?.name } </td>
                                                <td>{showQty(SafeBig(obj.qty ?? 0).times(obj.productUnitConversion?.qty ?? 1)) + " " + obj.product.unit?.name}</td>
                                            </>
                                        ) : (
                                            <>
                                                <td>{showQty(obj.qty) + " " + obj.unit?.name} @{ showQty(pUnitToSmallestUnitCompare) + " " + obj.product.smallestUnit?.name } </td>
                                                <td>{showQty(SafeBig(obj.qty ?? 0).times(pUnitToSmallestUnitCompare ?? 1)) + " " + obj.product.smallestUnit?.name}</td>
                                            </>
                                        )}
                                    </tr>
                                </tbody>
                            </table>
                        )}
                    </td>
                </tr>
            );
        });

        return (
            <>
                <style>
                    {`
                    .ra-table-detail-print td.qty, 
                    .ra-table-detail-print th.qty {
                        width: 30% !important;
                    }

                    table > th {
                        font-size: 12pt;
                    }

                    table td {
                        font-size: 9pt;
                    }

                    .ra-table-print > thead {
                        border-bottom: none;
                    }

                    @media print {
                        table td, table th {
                            font-size: 10pt !important;
                        }
                    }

                    @media print {
                        .ra-table-detail-print td,
                        .ra-table-detail-print th {
                            padding: 3px !important;
                        }

                        table th {
                            font-size: 10pt !important;
                        }

                        table td {
                            font-size: 10pt !important;
                        }
                    }
                    `}
                </style>            
                <Table className="ra-table-print">
                    <thead>
                        <tr>
                            <th>
                                <b>{data.key}</b>
                            </th>
                            <th>
                                <b>
                                    {t("common.date")}: {showDate(data.documentDate)}
                                </b>
                            </th>
                        </tr>
                        <tr>
                            <th>
                                <p>{getData(companyInfo, "nama-perusahaan")} <br />
                                {getData(companyInfo, "alamat-perusahaan")}</p>
                            </th>
                            <th>
                                {t("common.recipient")}: <br />
                                {data.supplier.name}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colSpan={2}>
                                <Table className="ra-table-detail-print">
                                    <thead>
                                        <tr>
                                            <th className="rowNo">#</th>
                                            <th>{t("common.product")}</th>
                                            <th className="qty">{t("common.qty")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {columns}
                                        <tr>
                                            <td colSpan={2} style={{ textAlign: "right" }}>{t("common.totalQty")}</td>
                                            <td>{showCurrency(totalQty.toString(), 2)}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                <PrintFooter footerType="receiveReport" />
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </>
        );
    }
}

export default withTranslation()(ReceiveReportPrintAnj);

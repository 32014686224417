import React, { PureComponent } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Table } from "reactstrap";
import { showCurrency, showDate, showQty } from "../../../common/function/showValueHelper";
import changeCase from "change-case";
import dayjs from "dayjs";

type InjectedProps = {
    data: any;
    companyInfo: any;
    settingHargaSatuan: boolean;
};

function getData(companyInfo: any, key: string) {
    let obj = companyInfo.find((val: any) => val.key === key);
    return obj ? obj.value : "";
}

class AnjPrint extends PureComponent<WithTranslation & InjectedProps> {
    render() {
        const { t, data, companyInfo } = this.props;
        if (data == null) return <p>No Data</p>;
        let columns = data.detailList.map((obj: any, index: number) => {
            return (
                <tr key={"detail-" + index}>
                    <td className="rowNo">{index + 1}</td>
                    <td>{obj.productName ?? obj.product.name}</td>
                    <td className="qty">
                        {obj.unit == null ? (
                            <table className="printQtyTable">
                                <tbody>
                                    <tr key={"detail-1-" + index}>
                                        <td>{showQty(obj.qty) + " "}</td>
                                        <td>{obj.product?.unit?.name}</td>
                                    </tr>
                                </tbody>
                            </table>
                        ) : (
                            <table className="printQtyTable">
                                <tbody>
                                    <tr key={"detail-1-" + index}>
                                        <td>{showQty(obj.qty) + " "}</td>
                                        <td>{obj.unit.name}</td>
                                    </tr>
                                </tbody>
                            </table>
                        )}
                    </td>
                    <td className="price">{showCurrency(obj.price, 2)}</td>
                    <td className="subTotal">{showCurrency(obj.subtotal, 2)}</td>
                </tr>
            );
        });

        let badanUsaha = companyInfo.find((val: any) => {
            return val.key === "status-badan-usaha";
        });

        let companyPaymentInfo = companyInfo.find((val: any) => {
            return val.key === "company-payment-info"
        });

        let summaryRow;
        if (badanUsaha.value === "PKP") {
            // if PKP
            summaryRow = (
                <Table className="ra-summary-table">
                    <tbody>
                        <tr>
                            <td colSpan={4}>
                                <b>{t("common.calculatedPrice")}: </b> <br /> {data.say} {" "} Rupiah
                            </td>
                            <td style={{ textAlign: "right" }}>{t("common.taxBase")}</td>
                            <td className="subTotal">{showCurrency(data.subtotal, 2)}</td>
                        </tr>
                        <tr>
                            <td rowSpan={4} colSpan={4}>
                                {t("common.notes")}: <br />{data.description}
                            </td>
                            <td style={{ textAlign: "right" }}>{t("common.tax")}</td>
                            <td className="subTotal">{showCurrency(data.vat, 2)}</td>
                        </tr>
                        <tr>
                            <td style={{ textAlign: "right" }}>{t("common.grandTotal")}</td>
                            <td className="subTotal">{showCurrency(data.grandTotal, 2)}</td>
                        </tr>
                    </tbody>
                </Table>
            )
        } else {
            // if not PKP
            summaryRow = (
                <Table className="ra-summary-table">
                    <tbody>
                        <tr>
                            <td colSpan={4}>
                                <b>{t("common.calculatedPrice")}: </b> <br /> {data.say} {" "} Rupiah
                            </td>
                            <td style={{ textAlign: "right", width: "15%" }}>{t("common.taxBase")}</td>
                            <td className="subTotal">{showCurrency(data.subtotal, 2)}</td>
                        </tr>
                        <tr>
                            <td rowSpan={data.dpUsage ? 3 : 2} colSpan={4}>
                                {t("common.notes")}: <br />{data.description}
                            </td>
                            <td style={{ textAlign: "right" }}>{t("common.grandTotal")}</td>
                            <td className="subTotal">{showCurrency(data.grandTotal, 2)}</td>
                        </tr>
                    </tbody>
                </Table>
            )
        }

        return (
            <Table className="ra-table-print">
                <thead>
                    <tr style={{ marginBottom: "10px" }}>
                        <th style={{ width: "60%" }}>
                            <b>{changeCase.upper(t("common.sales"))}</b>
                        </th>
                        <th>
                            <b>
                                {t("common.date")}: {showDate(data.documentDate)}
                            </b>
                        </th>
                    </tr>
                    <tr>
                        <th>
                            <b>{data.key}</b> <br />
                            <p>{getData(companyInfo, "nama-perusahaan")} <br />
                            {getData(companyInfo, "alamat-perusahaan")}</p>
                        </th>
                        <th>
                            {t("common.recipient")}: <br />
                            {data.customer.name} <br/> {data.customAddress}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td colSpan={2}>
                            <Table className="ra-table-detail-print">
                                <thead>
                                    <tr>
                                        <th className="rowNo">#</th>
                                        <th>{t("common.product")}</th>
                                        <th style={{ width: "15%"}}>{t("common.qty")}</th>
                                        <th style={{ width: "15%" }}>{t("common.price")}</th>
                                        <th style={{ width: "20%" }}>{t("common.subTotal")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {columns}
                                </tbody>
                            </Table>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            {summaryRow}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            <table className="ra-footer-table">
                                <tbody>
                                    <tr>
                                        <td style={{ width: "50%" }}>
                                            {companyPaymentInfo.value !== "" ? (
                                                <>
                                                    <strong>{t("common.paymentInfo")}</strong>
                                                    <p>{companyPaymentInfo.value}</p>
                                                </>
                                            ) : ""}
                                        </td>
                                        <td style={{ width: "15%" }}>
                                            {t("common.preparedBy")}
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                        </td>
                                        <td style={{ width: "15%" }}>{t("common.approvedBy")}</td>
                                        <td style={{ width: "30%" }}>
                                            <b>
                                                {t("common.status")}: {t(`common.${data.status}`)}
                                            </b>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={4}>
                                            {t("common.printDate")}: {dayjs(new Date()).format("DD-MM-YYYY HH:mm:ss")}
                                        </td>
                                    </tr>
                                </tbody>    
                            </table>
                        </td>
                    </tr>
                </tbody>
            </Table>
        );
    }
}

export default withTranslation()(AnjPrint);

import React, { PureComponent } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Table } from "reactstrap";
import PrintFooter from "../../../common/component/PrintFooter";
import { SafeBig } from "../../../common/function/safeBig";
import { showCurrency, showDate, showQty } from "../../../common/function/showValueHelper";

type PurchasePrintAnjProps = {
    data: any;
    companyInfo: any;
};

function getData(companyInfo: any, key: string) {
    let obj = companyInfo.find((val: any) => val.key === key);
    return obj ? obj.value : "";
}

class PurchasePrintAnj extends PureComponent<WithTranslation & PurchasePrintAnjProps> {
    render() {
        const { t } = this.props;
        const { data, companyInfo } = this.props;
        if (data == null) return <p>No Data</p>;
        let columns = data.detailList.map((obj: any, index: number) => {
            let price = obj.price;
            let pUnitToSmallestUnitCompare = SafeBig(obj.productUnitConversion?.qty ?? 1).div(obj.product.smallestUnitConversion?.qty ?? 1);
            if (obj.productUnitConversion && obj.product.smallestUnitConversion != null) {
                price = SafeBig(price).div(SafeBig(obj.productUnitConversion.qty).div(obj.product.smallestUnitConversion.qty ?? 1)).toString();
            } else if (obj.productUnitConversion && obj.product.smallestUnitConversion == null) {
                price = SafeBig(price).div(obj.productUnitConversion.qty).toString();
            }
            return (
                <tr key={"detail-" + index}>
                    <td className="rowNo">{index + 1}</td>
                    <td>{obj.product.name}</td>
                    <td className="qty">
                        {obj.unit == null ? (
                            <table className="printQtyTable">
                                <tbody>
                                    <tr key={"detail-1-" + index}>
                                        <td>{showQty(obj.qty) + " "}</td>
                                        <td>{obj.product?.unit?.name}</td>
                                    </tr>
                                </tbody>
                            </table>
                        ) : (
                            <table className="printQtyTable">
                                <tbody>
                                    <tr key={"detail-1-" + index}>
                                        { !obj.product.smallestUnit || obj.unit.id === obj.product.smallestUnit?.id ? (
                                            <>
                                                <td>{showQty(obj.qty) + " " + obj.unit?.name} @{ showQty(obj.productUnitConversion.qty) + " " + obj.product?.unit?.name } </td>
                                                <td>{showQty(SafeBig(obj.qty ?? 0).times(obj.productUnitConversion?.qty ?? 1)) + " " + obj.product.unit?.name}</td>
                                            </>
                                        ) : (
                                            <>
                                                <td>{showQty(obj.qty) + " " + obj.unit?.name} @{ showQty(pUnitToSmallestUnitCompare) + " " + obj.product.smallestUnit?.name } </td>
                                                <td>{showQty(SafeBig(obj.qty ?? 0).times(pUnitToSmallestUnitCompare ?? 1)) + " " + obj.product.smallestUnit?.name}</td>
                                            </>
                                        )}
                                    </tr>
                                </tbody>
                            </table>
                        )}
                    </td>
                    <td className="price">{showCurrency(price, 2)}</td>
                    <td className="subTotal">{showCurrency(obj.subtotal, 2)}</td>
                </tr>
            );
        });

        let summaryRow = (
                <Table className="ra-summary-table">
                    <colgroup>
                        <col style={{ width: "67%"}}></col>
                        <col style={{ width: "15%"}}></col>
                        <col style={{ width: "18%"}}></col>
                    </colgroup>
                    <tbody>
                        <tr>
                            <td>
                                <b>{t("common.calculatedPrice")}: </b> <br /> {data.say} {" "} Rupiah
                            </td>
                            <td className="disc" style={{ textAlign: "right" }}>{t("common.discount")}</td>
                            <td className="subtotal">{showCurrency(data.disc2AmountTotal)}</td>
                        </tr>
                        <tr>
                            <td rowSpan={4}>
                                {t("common.notes")}: {data.description}
                            </td>
                        </tr>
                        <tr>
                            <td style={{ textAlign: "right" }}>{t("common.taxBase")}</td>
                            <td className="subtotal">{showCurrency(data.subtotal, 2)}</td>
                        </tr>
                        <tr>
                            <td style={{ textAlign: "right" }}>{t("common.grandTotal")}</td>
                            <td className="subtotal">{showCurrency(data.grandTotal, 2)}</td>
                        </tr>
                    </tbody>
                </Table>
            );


        return (
            <>
                <style>
                    {` 
                    table > th {
                        font-size: 12pt;
                    }

                    table td {
                        font-size: 9pt;
                    }

                    .ra-table-print > thead {
                        border-bottom: none;
                    }

                    @media print {
                        .ra-table-detail-print th.product {
                            width: 40% !important;
                        }

                        .ra-table-detail-print td,
                        .ra-table-detail-print th {
                            padding: 3px !important;
                        }

                        table th {
                            font-size: 10pt !important;
                        }

                        table td {
                            font-size: 10pt !important;
                        }

                        .ra-table-detail-print th.product {
                            width: 40%;
                        }
    
                        .ra-table-detail-print th.subtotal {
                            width: 15%;
                        }
                    }
                    `}
                </style>
                <Table className="ra-table-print">
                    <thead>
                        <tr style={{ marginBottom: "10px", marginTop: "10px" }}>
                            <th>
                                <b>{data.key}</b>
                            </th>
                            <th>
                                <b>
                                    {t("common.date")}: {showDate(data.documentDate)}
                                </b>
                            </th>
                        </tr>
                        <tr>
                            <th>
                                <p>{getData(companyInfo, "nama-perusahaan")} <br />
                                {getData(companyInfo, "alamat-perusahaan")}</p>
                            </th>
                            <th>
                                {t("common.recipient")}: <br />
                                {data.supplier.name}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colSpan={2}>
                                <Table className="ra-table-detail-print">
                                    <colgroup>
                                        <col style={{ width: "2%"}}></col>
                                        <col style={{ width: "40%"}}></col>
                                        <col style={{ width: "25%"}}></col>
                                        <col style={{ width: "15%"}}></col>
                                        <col style={{ width: "18%"}}></col>
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>{t("common.product")}</th>
                                            <th>{t("common.qty")}</th>
                                            <th>{t("common.price")}</th>
                                            <th>{t("common.subTotal")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {columns}
                                    </tbody>
                                </Table>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                {summaryRow}
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                <PrintFooter footerType="transaction" status={data.status} />
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </>
        );
    }
}

export default withTranslation()(PurchasePrintAnj);

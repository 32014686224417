import React, { PureComponent } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Table } from "reactstrap";
import PrintArea from "../../../common/component/PrintArea";
import PrintHeader from "../../../common/component/PrintHeader";
import { showCurrency, showDate, showQty } from "../../../common/function/showValueHelper";

type DPPurchasePrintState = {
    data: any;
    companyInfo: any;
};

class DPPurchasePrint extends PureComponent<WithTranslation, DPPurchasePrintState> {
    constructor(props: any) {
        super(props);

        //load data from localStorage
        const stringData = localStorage.getItem("dp-purchase-print");
        const jsonData = stringData != null ? JSON.parse(stringData) : null;

        // console.log(jsonData);
        // remove data from localStorage
        localStorage.removeItem("dp-purchase-print");

        this.state = {
            data: jsonData && jsonData.data ? jsonData.data : null,
            companyInfo: jsonData && jsonData.companyInfo ? jsonData.companyInfo : null,
        };
    }

    render() {
        const { t } = this.props;
        const { companyInfo, data } = this.state;
        if (data == null) return <p>No Data</p>;
        //PO detail
        let columns = data.poDetails.map((obj: any, index: number) => {
            return (
                <tr key={"detail-" + index}>
                    <td>{index + 1}</td>
                    <td>{obj.product.name}</td>
                    <td>
                        {showQty(obj.qty)} {obj.unit?.name ?? obj.product.unit.name}
                    </td>
                    <td>{showCurrency(obj.price, 2)}</td>
                    <td>{showCurrency(obj.disc1, 2)}</td>
                    <td>{showCurrency(obj.subtotal, 2)}</td>
                </tr>
            );
        });

        return (
            <PrintArea>
                <Table className="ra-table-print">
                    <PrintHeader
                        title={t("common.dpPurchase")}
                        companyInfo={companyInfo}
                        supplier={data.supplier}
                        no={data.key}
                        dtCreated={data.dtCreated}
                    />
                    <tbody>
                        <tr>
                            <th colSpan={2}>
                                {t("common.purchaseOrderInfo")}
                                <br />
                                {t("common.purchaseOrder")} No: {data.poData.key}
                                <br />
                                {t("common.poDate")}: {showDate(data.poData.dtCreated)}
                            </th>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                <Table className="ra-table-detail-print">
                                    <thead>
                                        <tr>
                                            <th className="rowNo">#</th>
                                            <th>{t("common.product")}</th>
                                            <th>{t("common.qty")}</th>
                                            <th>{t("common.price")}</th>
                                            <th>{t("common.discount")}</th>
                                            <th>{t("common.subTotal")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {columns}
                                        <tr>
                                            <td colSpan={4} rowSpan={6} />
                                            <td>{t("common.subTotal")}</td>
                                            <td>{showCurrency(data.poData.amountTotal)}</td>
                                        </tr>
                                        <tr>
                                            <td>{t("common.tax")}</td>
                                            <td>{showCurrency(data.poData.tax)}</td>
                                        </tr>
                                        <tr>
                                            <td>{t("common.fobTotal")}</td>
                                            <td>{showCurrency(data.poData.fobtotal)}</td>
                                        </tr>
                                        <tr>
                                            <td>{t("common.grandTotal")}</td>
                                            <td>{showCurrency(data.poData.grandTotal)}</td>
                                        </tr>
                                        <tr>
                                            <td>{t("common.downPayment")}</td>
                                            <td>{showCurrency(data.amount)}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2}>
                                                <b>{t("common.calculatedDownPayment")}: </b> <br />
                                                {data.say} {" "} Rupiah
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </PrintArea>
        );
    }
}

export default withTranslation()(DPPurchasePrint);

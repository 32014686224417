import React, { PureComponent } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Table } from "reactstrap";
import HTMLPrintHeader from "../../../common/component/HTMLPrintHeader";
import PrintArea from "../../../common/component/PrintArea";
import { showCurrency, showDate } from "../../../common/function/showValueHelper";

type CustomerDepositCardPrintState = {
    data: any;
    param: any;
};

class CustomerDepositCardPrint extends PureComponent<WithTranslation, CustomerDepositCardPrintState> {
    constructor(props: any) {
        super(props);

        //load data from localStorage
        const stringData = localStorage.getItem("customer-deposit-card-print");
        const jsonData = stringData != null ? JSON.parse(stringData) : null;

        // remove data from localStorage
        localStorage.removeItem("customer-deposit-card-print");
        
        console.log(jsonData?.data);

        let stateObj = {
            data: jsonData?.data ?? null,
            param: jsonData?.param ?? {},
        }

        this.state = stateObj;
    }

    render() {
        const { t } = this.props;
        const { param, data } = this.state;
        if (data == null) return <p>No Data</p>;

        let columns:any[] = [];
        
        // for(let list of customerList)
        for(let grouped of data){
            let groupedTable:any[] = [];
            for(let i = 0; i< grouped.transactionList.length;i++){
                let obj = grouped.transactionList[i];

                groupedTable.push(
                    <tr key={"detail-" + i}>
                        <td className="rowNo">{i + 1}</td>
                        <td>{showDate(obj.documentDate)}</td>
                        <td>{obj.key}</td>
                        <td style={{ textAlign: "right" }}>{showCurrency(obj.amount, 2)}</td>
                        <td>{obj.parentDescription}</td>
                        <td>{obj.detailDescription}</td>
                    </tr>
                );
            }

            //customer-specific table
            columns.push(
                <>
                        <tr>
                            <td>{t("common.customer")}</td>
                            <td colSpan={5}>{grouped.customer.key}<br/>{grouped.customer.name}</td>
                        </tr>
                        <tr>
                            <td colSpan={5}>{t("common.startingBalance")}</td>
                            <td>{showCurrency(grouped.startBalance)}</td>
                        </tr>
                        <tr>
                            <td className="rowNo">#</td>
                            <td>{t("common.date")}</td>
                            <td>{t("common.documentNo")}</td>
                            <td className="price">{t("common.amount")}</td>
                            <td>{t("common.parentDescription")}</td>
                            <td>{t("common.detailDescription")}</td>
                        </tr>
                        {groupedTable}
                        <tr>
                            <td colSpan={5}>{t("common.endingBalance")}</td>
                            <td>{showCurrency(grouped.endBalance)}</td>
                        </tr>
                        
                        <tr>
                        <td colSpan={6} style={{background:"lightgrey", height:"20px"}}>{" "}</td>
                        </tr>
                </>
            );
        }

        return (
            <PrintArea>
                <Table className="ra-table-print">
                    <HTMLPrintHeader
                        param={param}
                    />
                    <tbody>
                        <tr>
                            <td colSpan={3}>
                            <Table className="ra-table-detail-print" style={{marginBottom:"40px"}}>
                    <tbody>
                                {columns}
                    </tbody>
                </Table>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </PrintArea>
        );
    }
}

export default withTranslation()(CustomerDepositCardPrint);

import React, { PureComponent } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Table } from "reactstrap";
import PrintArea from "../../../common/component/PrintArea";
import PrintFooter from "../../../common/component/PrintFooter";
import PrintHeaderTransaction, { TransactionType } from "../../../common/component/PrintHeaderTransaction";
import { showCurrency } from "../../../common/function/showValueHelper";

type TransactionPrintState = {
    data: any;
    companyInfo: any;
    type: string;
    title: string;
};

class TransactionPrint extends PureComponent<WithTranslation, TransactionPrintState> {
    constructor(props: any) {
        super(props);

        //load data from localStorage
        const stringData = localStorage.getItem("transaction-print");
        const jsonData = stringData != null ? JSON.parse(stringData) : null;

        // remove data from localStorage
        // localStorage.removeItem("transaction-print");

        let stateObj = {
            data: jsonData && jsonData.data ? jsonData.data : null,
            companyInfo: jsonData && jsonData.companyInfo ? jsonData.companyInfo : null,
            type: jsonData.type,
            title: `transaction${jsonData.data?.bank ? "Bank" : "Cash"}${(jsonData.type?.toUpperCase() === "IN") ? "In" : "Out"}`
        };

        this.state = stateObj;
    }

    render() {
        const { t } = this.props;
        const { companyInfo, data, type, title } = this.state;
        if (data == null) return <p>No Data</p>;
        let columns = data.detailList.map((obj: any, index: number) => {
            return (
                <tr key={"detail-" + index}>
                    <td className="rowNo">{index + 1}</td>
                    <td style={{ width: "25%" }}>
                        { `${obj.coa.coaNo} - ${obj.coa.name}`}
                    </td>
                    <td style={{ width: "25%" }}>
                        { obj.sales ? (
                            <span>{ obj.sales.key }</span>
                        ) : ""}
                        { obj.creditMemo ? (
                            <span>{ obj.creditMemo.key }</span>
                        ) : ""}
                        { obj.dpSales ? (
                            <span>{ obj.dpSales.key }</span>
                        ) : ""}
                        { obj.purchase ? (
                            <span>{ obj.purchase.key }</span>
                        ) : ""}
                        { obj.debitMemo ? (
                            <span>{ obj.debitMemo.key }</span>
                        ) : ""}
                        { obj.dpPurchase ? (
                            <span>{ obj.dpPurchase.key }</span>
                        ) : ""}
                    </td>
                    <td>{ obj.description }</td>
                    <td className="subTotal">{ showCurrency(obj.amount) }</td>
                </tr>
            );
        });

        return (
            <PrintArea>
                <Table className="ra-table-print">
                    <PrintHeaderTransaction
                        title={t(`common.${title}`)} // need to check transaction type
                        companyInfo={companyInfo}
                        supplier={data?.supplier} // payment from customer or supplier
                        customer={data?.customer}
                        no={data.key}
                        dtCreated={data.documentDate}
                        type={type === "IN" ? TransactionType.IN : TransactionType.OUT}
                    />
                    <tbody>
                        <tr>
                            <td colSpan={2}>
                                <Table className="ra-table-detail-print">
                                    <thead>
                                        <tr>
                                            <th className="rowNo">#</th>
                                            <th>{t("common.coa")}</th>
                                            {/** Sales, Purchase, DP Sales, DP Purchase, COA Lain */}
                                            <th>{t("common.documentNo")}</th> {/** No Referensi */}
                                            <th>{t("common.description")}</th>
                                            <th>{t("common.amount")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {columns}
                                    </tbody>
                                </Table>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                <Table className="ra-summary-table">
                                    <tbody>
                                        <tr>
                                            <td colSpan={4} style={{ textAlign: "right" }}>{t("common.grandTotal")}</td>
                                            <td className="subTotal">{showCurrency(data.amount, 2)}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                <PrintFooter footerType="transaction" />
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </PrintArea>
        );
    }
}

export default withTranslation()(TransactionPrint);

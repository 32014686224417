/**
 * Old name: actionConfig & actionSetting
 *
 * Used to get all setting in general (without param)
 *
 * Usually used as first-open call
 *
 * Reducers are finance-related setting that affect how the app handles calculation
 */

import reducerRegistry from "../../common/reduxHandler/reducerRegistry";
import { reqGet, CACHE_MODE } from "../../common/function/request";
import { setFetchAllParam } from "../../common/function/requestParamHelper";
import { createSelector } from "reselect";
import { calculateTaxRatio } from "../../common/function/showValueHelper";
import dayjs from "dayjs";

//initial state
const initialState: CommonReduxState = {
    loading: false,
    data: null,
    error: null,
};

//action
const createActionName = (name: string) => `setting/${name}`;
const INITIALIZE_CONFIG_SETTING = createActionName("INITIALIZE_CONFIG_SETTING");
const INITIALIZE_CONFIG_SETTING_DONE = createActionName("INITIALIZE_CONFIG_SETTING_DONE");
const INITIALIZE_CONFIG_SETTING_FAILED = createActionName("INITIALIZE_CONFIG_SETTING_FAILED");

//reducer
function initializeConfigSetting(state = initialState, action: DispatchedAction<APIListResponse>) {
    switch (action.type) {
        case INITIALIZE_CONFIG_SETTING:
            return { ...initialState, loading: true };
        case INITIALIZE_CONFIG_SETTING_DONE:
            return {
                ...state,
                loading: false,
                data: action.payload.data.list,
            };
        case INITIALIZE_CONFIG_SETTING_FAILED:
            return { ...state, error: action.error, loading: false };
        default:
            return state;
    }
}

//register reducer
reducerRegistry.register("initializeConfigSetting", initializeConfigSetting);

//selector
export const slcInitialConfigSetting = (state: any): CommonReduxState => state["initializeConfigSetting"];

const getInitialConfigSetting = (state: any): CommonReduxState =>
    state["initializeConfigSetting"].data ? state["initializeConfigSetting"].data : [];

function processDynamicTax(data:any, documentDate:Date){
    if (data == null) {
        console.log("No Data");
        return 10;
    }

    //new
    let settingTaxList: SettingTax[] = JSON.parse(data.value);

    //check date (already sorted when saved)
    for (let settingTax of settingTaxList) {
        //2000-01-02 00:00:00 to 2000-01-01 23:59:59 because we use isBefore
        let startDate = dayjs(settingTax.startDate ?? new Date("1900-01-01"))
            .startOf("day")
            .subtract(1, "ms");
        let endDate = dayjs(settingTax.endDate ?? new Date("9999-01-01"))
            .endOf("day")
            .add(1, "ms");

        //startDate <= documentDate <= endDate
        if (dayjs(startDate).isBefore(documentDate) && dayjs(documentDate).isBefore(endDate)) {
            // console.log(startDate.toISOString(), endDate.toISOString())
            return Number(settingTax.tax);
        }
    }

    console.log("Unknown tax setting");
    //falloff
    return 10;
}

// export const slcSettingOutputVAT_Value = createSelector(
//     getInitialConfigSetting, //basic selector
//     (initConfig): number => {
//         const data = initConfig.find((val: any) => val.key === "pajak-keluaran");
//         return data ? Number(data.value) : 0;
//     }
// );


/**
 * https://stackoverflow.com/questions/40291084/use-reselect-selector-with-parameters
 */
 export const slcSettingOutputVAT_DynamicValue = createSelector(
    [
        getInitialConfigSetting, //basic selector
        (state:any, documentDate:any) => documentDate //2nd param
    ],
    (initConfig, documentDate) => {
        const data = initConfig.find((val: any) => val.key === "pajak-keluaran");

        //legacy
        if (!isNaN(data)){
            return data ? Number(data.value) : 0;
        }

        //new
        let tax = processDynamicTax(data, documentDate);
        return Number(tax);
    }
);

// export const slcSettingInputVAT_Value = createSelector(
//     getInitialConfigSetting, //basic selector
//     (initConfig): number => {
//         const data = initConfig.find((val: any) => val.key === "pajak-masukan");
//         return data ? Number(data.value) : 0;
//     }
// );


/**
 * https://stackoverflow.com/questions/40291084/use-reselect-selector-with-parameters
 */
 export const slcSettingInputVAT_DynamicValue = createSelector(
    [
        getInitialConfigSetting, //basic selector
        (state:any, documentDate:any) => documentDate //2nd param
    ],
    (initConfig, documentDate) => {
        const data = initConfig.find((val: any) => val.key === "pajak-masukan");

        //legacy
        if (!isNaN(data)){
            return data ? Number(data.value) : 0;
        }

        //new
        let tax = processDynamicTax(data, documentDate);
        return Number(tax);
    }
);

/**
 * https://stackoverflow.com/questions/40291084/use-reselect-selector-with-parameters
 */
export const slcSettingOutputVAT_DynamicRatio = createSelector(
    [
        getInitialConfigSetting, //basic selector
        (state:any, documentDate:any) => documentDate //2nd param
    ],
    (initConfig, documentDate) => {
        const data = initConfig.find((val: any) => val.key === "pajak-keluaran");

        //legacy
        if (!isNaN(data)){
            return data ? Number(calculateTaxRatio(data.value)) : 0;
        }
        
        //new
        let tax = processDynamicTax(data, documentDate);
        return Number(calculateTaxRatio(tax));
    }
);

// export const slcSettingOutputVAT_Ratio = createSelector(
//     getInitialConfigSetting, //basic selector
//     (initConfig): number => {
//         const data = initConfig.find((val: any) => val.key === "pajak-keluaran");
//         return data ? Number(calculateTaxRatio(data.value)) : 0;
//     }
// );


/**
 * https://stackoverflow.com/questions/40291084/use-reselect-selector-with-parameters
 */
 export const slcSettingInputVAT_DynamicRatio = createSelector(
    [
        getInitialConfigSetting, //basic selector
        (state:any, documentDate:any) => documentDate //2nd param
    ],
    (initConfig, documentDate) => {
        const data = initConfig.find((val: any) => val.key === "pajak-masukan");

        //legacy
        if (!isNaN(data)){
            return data ? Number(calculateTaxRatio(data.value)) : 0;
        }

        //new
        let tax = processDynamicTax(data, documentDate);
        return Number(calculateTaxRatio(tax));
    }
);

// export const slcSettingInputVAT_Ratio = createSelector(
//     getInitialConfigSetting, //basic selector
//     (initConfig): number => {
//         const data = initConfig.find((val: any) => val.key === "pajak-masukan");
//         return data ? Number(calculateTaxRatio(data.value)) : 0;
//     }
// );

/**
 * Returns several tax-related keys.
 *
 * data: The tax value
 * isTaxable: PKP company
 * isNonTaxable: NON-PKP company
 */
export const slcSettingCompanyTaxStatus = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): { data: any; isTaxable: boolean; isNonTaxable: boolean } => {
        const data = initConfig.find((val: any) => val.key === "status-badan-usaha");
        return {
            data: data ? data.value : "",
            isTaxable: data ? data.value === "PKP" : false,
            isNonTaxable: data ? data.value === "NON-PKP" : false,
        };
    }
);

/**
 * Returns Array of Object containing key:string and value:any
 */
export const slcSettingCompanyInfo_Value = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): { key: string; value: any }[] => {
        // info perusahaan config
        let infoPerusahaan: any = [];
        for (let i = 0; i < initConfig.length; i++) {
            const row = initConfig[i];
            if (
                row.key === "nama-perusahaan" ||
                row.key === "bidang-usaha" ||
                row.key === "alamat-perusahaan" ||
                row.key === "kecamatan" ||
                row.key === "kelurahan" ||
                row.key === "kota" ||
                row.key === "provinsi" ||
                row.key === "kode-pos" ||
                row.key === "negara" ||
                row.key === "no-telepon" ||
                row.key === "email" ||
                row.key === "status-badan-usaha" ||
                row.key === "no-npwp" ||
                row.key === "company-payment-info"
            )
                infoPerusahaan.push(row);
        }

        return infoPerusahaan;
    }
);

/**
 * Check whether this app may use inclusive tax or not
 */
export const slcSettingInclusiveTax_Value = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): boolean => {
        const data = initConfig.find((val: any) => val.key === "fitur-inclusive-tax");
        return data ? data.value === "1" || data.value === 1 || data.value === true : false;
    }
);

/**
 * Check whether money calculations require rounding or not
 */
export const slcSettingRoundFeature_Value = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): boolean => {
        const data = initConfig.find((val: any) => val.key === "fitur-pembulatan");
        return data ? data.value === "1" || data.value === 1 || data.value === true : false;
    }
);

/**
 * Allow/Disallow duplciated product-id in DynamicTables
 */
export const slcSettingAllowDuplicateProduct_Value = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): boolean => {
        const data = initConfig.find((val: any) => val.key === "fitur-produk-kembar-dalam-satu-nota");
        return data ? data.value === "1" || data.value === 1 || data.value === true : false;
    }
);

export const slcSettingIsInstalled_Value = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): boolean => {
        const data = initConfig.find((val: any) => val.key === "fase-instalasi");
        return data ? data.value === "1" || data.value === 1 || data.value === true : false;
    }
);

export const slcSettingStepInstallation_Value = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): number | null => {
        const data = initConfig.find((val: any) => val.key === "step-instalasi-terakhir");
        return data ? parseInt(data.value) : null;
    }
);

/**
 * Check whether SO/Sales price modifiable or not
 */
export const slcSettingInventoryTypeAVG = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): boolean => {
        const data = initConfig.find((val: any) => val.key === "sistem-persediaan");
        return data.value === "AVG" ? true : false;
    }
);

/**
 * Check whether SO/Sales price modifiable or not
 */
export const slcSettingInventoryTypeFIFO = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): boolean => {
        const data = initConfig.find((val: any) => val.key === "sistem-persediaan");
        return data.value === "FIFO" ? true : false;
    }
);

/**
 * Check whether SO/Sales price modifiable or not
 */
export const slcSettingFixSalePrice_Value = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): boolean => {
        const data = initConfig.find((val: any) => val.key === "fitur-kunci-harga-jual");
        return data ? data.value === "1" || data.value === 1 || data.value === true : false;
    }
);

/**
 * Get default sales-deposit coa
 */
export const slcSettingDefaultSalesDepositCoa = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): number => {
        const data = initConfig.find((val: any) => val.key === "default-coa-uang-muka-penjualan");
        return data ? Number(data.value) : 62;
    }
);

/**
 * Get default saldo awal ekuitas coa
 */
export const slcSettingDefaultBeginningBalanceCoa = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): number => {
        const data = initConfig.find((val: any) => val.key === "default-coa-saldo-awal-ekuitas");
        return data ? Number(data.value) : 86;
    }
);

/**
 * Get default purchase-deposit coa
 */
export const slcSettingDefaultPurchaseDepositCoa = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): number => {
        const data = initConfig.find((val: any) => val.key === "default-coa-uang-muka-pembelian");
        return data ? Number(data.value) : 25;
    }
);

/**
 * Allow change product-name in Sales/SJ or not
 */
export const slcSettingCustomProductName_Value = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): boolean => {
        const data = initConfig.find((val: any) => val.key === "fitur-custom-product-name");
        return data ? data.value === "1" || data.value === 1 || data.value === true : false;
    }
);

/**
 * Allow change product-name in Sales/SJ or not
 */
export const slcSettingCheckCustomerLimit = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): boolean => {
        const data = initConfig.find((val: any) => val.key === "batasi-penjualan-berdasarkan-limit");
        return data ? data.value === "1" || data.value === 1 || data.value === true : false;
    }
);

/**
 * Setting limit customer by unpaid receivable
 */
export const slcSettingCheckCustomerLimitUnpaidReceivable = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): boolean => {
        const data = initConfig.find((val: any) => val.key === "batasi-penjualan-berdasarkan-durasi-hutang");
        return data ? data.value === "1" || data.value === 1 || data.value === true : false;
    }
);

/**
 * Check to show (true) / hide (false) Dot-Matrix Print Menu
 */
export const slcSettingTurnOnDotMatrixPrint_Value = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): boolean => {
        const data = initConfig.find((val: any) => val.key === "fitur-print-menggunakan-dot-matrix");
        return data ? data.value === "1" || data.value === 1 || data.value === true : false;
    }
);

/**
 * Check to change harga satuan behaviour
 */
export const slcSettingKonversiSatuanDanHargaSatuan_Value = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): boolean => {
        const data = initConfig.find((val: any) => val.key === "fitur-konversi-satuan-dan-harga-satuan");
        return data ? data.value === "1" || data.value === 1 || data.value === true : false;
    }
);

/**
 * Setting fitur history harga jual & hpp
 */
export const slcSettingHistoryHargaJualDanHPP_Value = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): boolean => {
        const data = initConfig.find((val: any) => val.key === "fitur-tampilkan-history-dan-hpp-di-penjualan");
        return data ? data.value === "1" || data.value === 1 || data.value === true : false;
    }
);

/**
 * Check whether this Dot-Matrix print used as default or not (if not, set HTML as default)
 *
 * Determine which print triggered in Action's DropdownButton
 */
export const slcSettingUseDotMatrixAsDefault_Value = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): boolean => {
        const data = initConfig.find((val: any) => val.key === "set-dot-matrix-sebagai-print-default");
        return data ? data.value === "1" || data.value === 1 || data.value === true : false;
    }
);

/**
 * Check whether this Dot-Matrix print used as default or not (if not, set HTML as default)
 *
 * Determine which print triggered in Action's DropdownButton
 */
export const slcSettingShowDecimalFeature_Value = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): boolean => {
        const data = initConfig.find((val: any) => val.key === "fitur-tampilkan-angka-dibelakang-koma");
        return data ? data.value === "1" || data.value === 1 || data.value === true : false;
    }
);

export const slcSettingFeaturePrintPaperType_Value = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): boolean => {
        const data = initConfig.find((val: any) => val.key === "fitur-cetak-opsi-ukuran-kertas");
        return data ? data.value : "A4 portrait";
    }
);

export const slcSettingFeaturePrintTemplate_Value = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): boolean => {
        const data = initConfig.find((val: any) => val.key === "fitur-cetak-template-nota");
        return data ? data.value : "STANDARD";
    }
);

export const slcSettingFeaturePrintFontType_Value = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): boolean => {
        const data = initConfig.find((val: any) => val.key === "fitur-cetak-font-family");
        return data ? data.value : "Arial 'sans-serif'";
    }
);

export const slcSettingFeaturePrintSalesAndDeliveryReport_Value = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): boolean => {
        const data = initConfig.find((val: any) => val.key === "fitur-print-faktur-penjualan-dan-surat-jalan");
        return data ? data.value === "1" : false; // default is false
    }
);

/**
 * Sales Other Reference No
 */
export const slcSettingSalesOtherReferenceNo_Value = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): number => {
        const data = initConfig.find((val: any) => val.key === "fitur-no-referensi-penjualan-lainnya");

        return Number(data?.value ?? 0);
    }
);

/**
 * Check toast duration
 */
export const slcSettingToastDuration = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): boolean => {
        const data = initConfig.find((val: any) => val.key === "toast-duration");
        return data;
    }
);

/**
 * Webview turned on or not
 */
export const slcSettingWebviewFeature = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): boolean => {
        const data = initConfig.find((val: any) => val.key === "fitur-webview");
        return data ? data.value === "1" || data.value === 1 || data.value === true : false;
    }
);

/**
 * Webview turned on or not
 */
export const slcSettingExpandSubrow = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): boolean => {
        const data = initConfig.find((val: any) => val.key === "default-expand-subrow");
        return data ? data.value === "1" || data.value === 1 || data.value === true : false;
    }
);

/**
 * Show updateDate in listTable or not (2nd row from the end)
 */
export const slcSettingShowUpdateDateInTable = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): boolean => {
        const data = initConfig.find((val: any) => val.key === "fitur-tampilkan-update-date-di-table");
        return data ? data.value === "1" || data.value === 1 || data.value === true : false;
    }
);

/**
 * Webview turned on or not
 */
export const slcSettingSmallestUnitConversionFeature = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): boolean => {
        const data = initConfig.find((val: any) => val.key === "fitur-unit-konversi-terkecil");
        return data ? data.value === "1" || data.value === 1 || data.value === true : false;
    }
);

/**
 * Get default filter by date (for ListTable)
 */
export const slcSettingDefaultFilterByDate = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): number => {
        const data = initConfig.find((val: any) => val.key === "fitur-default-filter-harian");

        return Number(data?.value ?? 0);
    }
);


/**
 * Show deliveryOrder draft menu or not
 */
 export const slcSettingDeliveryOrderDraft = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): boolean => {
        const data = initConfig.find((val: any) => val.key === "fitur-draft-surat-jalan");
        return data ? data.value === "1" || data.value === 1 || data.value === true : false;
    }
);

/**
 * Print SJ after create or not
 */
 export const slcSettingPrintDeliveryReportAfterCreate = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): boolean => {
        const data = initConfig.find((val: any) => val.key === "fitur-cetak-surat-jalan-setelah-dibuat");
        return data ? data.value === "1" || data.value === 1 || data.value === true : false;
    }
);

/**
 * Show UnfulfilledSalesOrder menu or not
 */
 export const slcSettingUnfulfilledSalesOrder = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): boolean => {
        const data = initConfig.find((val: any) => val.key === "fitur-pesanan-penjualan-belum-selesai");
        return data ? data.value === "1" || data.value === 1 || data.value === true : false;
    }
);

/**
 * Show invAdjustment draft menu or not
 */
 export const slcSettingInventoryAdjustmentDraft = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): boolean => {
        const data = initConfig.find((val: any) => val.key === "fitur-draft-inventory-adjustment");
        return data ? data.value === "1" || data.value === 1 || data.value === true : false;
    }
);

/**
 * Show sales receipt menu or not
 */
 export const slcSettingSalesReceiptFeature = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): boolean => {
        const data = initConfig.find((val: any) => val.key === "fitur-tanda-terima-penjualan");
        return data ? data.value === "1" || data.value === 1 || data.value === true : false;
    }
);

/**
 * Show customAddress in sales & SJ or not
 */
 export const slcSettingSalesCustomAddress = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): boolean => {
        const data = initConfig.find((val: any) => val.key === "fitur-sales-custom-address");
        return data ? data.value === "1" || data.value === 1 || data.value === true : false;
    }
);

/**
 * Show modifierCoa in table+form or not
 */
 export const slcSettingModifierCoa = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): boolean => {
        const data = initConfig.find((val: any) => val.key === "fitur-modifier-coa");
        return data ? data.value === "1" || data.value === 1 || data.value === true : false;
    }
);

/**
 * Show modifierCoa in table+form or not
 */
 export const slcSettingShowParentInTransactionMutation = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): boolean => {
        const data = initConfig.find((val: any) => val.key === "fitur-show-parent-in-transaction-mutation");
        return data ? data.value === "1" || data.value === 1 || data.value === true : false;
    }
);

/**
 * TempDataColumn
 */
 export const slcSettingTempDataForPurchaseOrder = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): string[] => {
        const data = initConfig.find((val: any) => val.key === "temp-data-for-purchase-order");

        return processTempDataToOption(data);
    }
);


/**
 * TempDataColumn
 */
 export const slcSettingTempDataForPurchase = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): string[] => {
        const data = initConfig.find((val: any) => val.key === "temp-data-for-purchase");

        return processTempDataToOption(data);
    }
);

/**
 * TempDataColumn
 */
 export const slcSettingTempDataForDebitMemo = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): string[] => {
        const data = initConfig.find((val: any) => val.key === "temp-data-for-debit-memo");

        return processTempDataToOption(data);
    }
);

/**
 * TempDataColumn
 */
 export const slcSettingTempDataForSalesOrder = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): string[] => {
        const data = initConfig.find((val: any) => val.key === "temp-data-for-sales-order");

        return processTempDataToOption(data);
    }
);

/**
 * TempDataColumn
 */
 export const slcSettingTempDataForSales = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): string[] => {
        const data = initConfig.find((val: any) => val.key === "temp-data-for-sales");

        return processTempDataToOption(data, ["salesReceiptCounter"]);
    }
);

/**
 * TempDataColumn
 */
 export const slcSettingTempDataForCreditMemo = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): string[] => {
        const data = initConfig.find((val: any) => val.key === "temp-data-for-credit-memo");

        return processTempDataToOption(data, ["salesReceiptCounter"]);
    }
);

/**
 * TempDataColumn
 */
export const slcSettingTempDataForBillOfMaterial = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): string[] => {
        const data = initConfig.find((val: any) => val.key === "temp-data-for-bill-of-material");

        return processTempDataToOption(data);
    }
);

/**
 * TempDataColumn
 */
 export const slcSettingTempDataForJobOrder = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): string[] => {
        const data = initConfig.find((val: any) => val.key === "temp-data-for-job-order");

        return processTempDataToOption(data);
    }
);

/**
 * TempDataColumn
 */
 export const slcSettingTempDataForJobCompletion = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): string[] => {
        const data = initConfig.find((val: any) => val.key === "temp-data-for-job-completion");

        
        return processTempDataToOption(data);
    }
);

export const slcSettingListColumnForPurchaseOrder = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): boolean => {
        return processCustomListColumn(initConfig, "list-column-for-purchase-order");
    }
);

export const slcSettingListColumnForPurchase = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): boolean => {
        return processCustomListColumn(initConfig, "list-column-for-purchase");
    }
);

export const slcSettingListColumnForReceiveReport = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): boolean => {
        return processCustomListColumn(initConfig, "list-column-for-receive-report")
    }
);

export const slcSettingListColumnForDebitMemo = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): boolean => {
        return processCustomListColumn(initConfig, "list-column-for-debit-memo")
    }
);

export const slcSettingListColumnForDebitMemoDelivery = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): boolean => {
        return processCustomListColumn(initConfig, "list-column-for-debit-memo-delivery")
    }
);

export const slcSettingListColumnForDpPurchase = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): boolean => {
        return processCustomListColumn(initConfig, "list-column-for-dp-purchase")
    }
);

export const slcSettingListColumnForDpPurchaseUsage = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): boolean => {
        return processCustomListColumn(initConfig, "list-column-for-dp-purchase-usage")
    }
);

export const slcSettingListColumnForSalesOrder = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): boolean => {
        return processCustomListColumn(initConfig, "list-column-for-sales-order")
    }
);

export const slcSettingListColumnForSales = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): boolean => {
        return processCustomListColumn(initConfig, "list-column-for-sales")
    }
);

export const slcSettingListColumnForDeliveryReport = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): boolean => {
        return processCustomListColumn(initConfig, "list-column-for-delivery-report")
    }
);

export const slcSettingListColumnForCreditMemo = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): boolean => {
        return processCustomListColumn(initConfig, "list-column-for-credit-memo")
    }
);

export const slcSettingListColumnForCreditMemoReceive = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): boolean => {
        return processCustomListColumn(initConfig, "list-column-for-credit-memo-receive")
    }
);

export const slcSettingListColumnForDpSales = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): boolean => {
        return processCustomListColumn(initConfig, "list-column-for-dp-sales")
    }
);

export const slcSettingListColumnForDpSalesUsage = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): boolean => {
        return processCustomListColumn(initConfig, "list-column-for-dp-sales-usage")
    }
);

export const slcSettingListColumnForRecurringInvoice = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): boolean => {
        return processCustomListColumn(initConfig, "list-column-for-recurring-invoice")
    }
);

export const slcSettingListColumnForBankMutation = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): boolean => {
        return processCustomListColumn(initConfig, "list-column-for-bank-mutation")
    }
);

export const slcSettingListColumnForCashMutation = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): boolean => {
        return processCustomListColumn(initConfig, "list-column-for-cash-mutation")
    }
);

export const slcSettingListColumnForGiroPayable = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): boolean => {
        return processCustomListColumn(initConfig, "list-column-for-giro-payable")
    }
);

export const slcSettingListColumnForGiroReceivable = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): boolean => {
        return processCustomListColumn(initConfig, "list-column-for-giro-receivable")
    }
);

export const slcSettingListColumnForAsset = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): boolean => {
        return processCustomListColumn(initConfig, "list-column-for-asset")
    }
);

export const slcSettingListColumnForBillOfMaterial = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): boolean => {
        return processCustomListColumn(initConfig, "list-column-for-bill-of-material")
    }
);

export const slcSettingListColumnForJobOrder = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): boolean => {
        return processCustomListColumn(initConfig, "list-column-for-job-order")
    }
);

export const slcSettingListColumnForJobCompletion = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): boolean => {
        return processCustomListColumn(initConfig, "list-column-for-job-completion")
    }
);

export const slcSettingSummaryForBankMutation = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): boolean => {
        return processCustomListColumn(initConfig, "summary-for-bank-mutation")
    }
);

export const slcSettingSummaryForCashMutation = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): boolean => {
        return processCustomListColumn(initConfig, "summary-for-cash-mutation")
    }
);


/**
 * Process comma-separated column setting
 */
function processCustomListColumn(initConfig:any, key:string){
    const data = initConfig.find((val: any) => val.key === key);
    let value = data?.value ?? "";

    let split = value.split(",").map((val:any)=>val.trim());
    if (split.length===1 && split[0]==="") return [];
    
    return split;
}

/**
 * Show deleted data or not
 */
 export const slcSettingShowDeletedData = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): boolean => {
        const data = initConfig.find((val: any) => val.key === "fitur-tampilkan-data-yang-telah-dihapus");
        return data ? data.value === "1" || data.value === 1 || data.value === true : false;
    }
);

function processTempDataToOption(data:any, skippedKey:string[] = []){
    let value = data?.value;

    let split = value?.split(",") ?? [];
    let list:any[] = [];
    for(let key of split){
        let trim = key?.trim();
        if (trim!=null && trim!=="" && !skippedKey.includes(trim)){
            list.push(trim);
        }
    }
    
    return list.sort((a:any, b:any) => a.localeCompare(b))
}

//action creator
const urlPath = "setting";

/**
 * Init config that's called on first App page load
 */
export function actInitConfig() {
    const request = reqGet(urlPath, setFetchAllParam(), null, CACHE_MODE.WITH_LOAD_WITH_SAVE, 86400); //setting rarely changed

    return (dispatch: any) => {
        dispatch({
            type: INITIALIZE_CONFIG_SETTING,
        });

        request
            .then((payload) => {
                dispatch({
                    type: INITIALIZE_CONFIG_SETTING_DONE,
                    payload: payload.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: INITIALIZE_CONFIG_SETTING_FAILED,
                    error,
                });
            });
    };
}

/**
 * Reinit config. used when there's any change to config setting. Must not use cache for loading
 */
export function actReloadInitConfig() {
    const request = reqGet(urlPath, setFetchAllParam(), null, CACHE_MODE.NO_LOAD_WITH_SAVE, 86400);

    return (dispatch: any) => {
        dispatch({
            type: INITIALIZE_CONFIG_SETTING,
        });

        request
            .then((payload) => {
                dispatch({
                    type: INITIALIZE_CONFIG_SETTING_DONE,
                    payload: payload.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: INITIALIZE_CONFIG_SETTING_FAILED,
                    error,
                });
            });
    };
}

/**
 * Update 20190807. Isn't all setting already retrieved above? Just use them.
 *
 * Fetch Default COA saved inside Setting.
 * e.g. default customer deposit COA
 */
// export function actFetchSettingDefaultCoa(inputs:any = {}) {
//     const param = {
//         q: "1" + (inputs.q ? "," + inputs.q : ""),
//         searchKey: "categoryId" + (inputs.searchKey ? "," + inputs.searchKey : ""),
//         operator: "=" + (inputs.operator ? "," + inputs.operator : ""),
//         mode: "and",
//         all: true
//     };

//     const request = reqGet(urlPath, param, null, CACHE_MODE.WITH_LOAD_WITH_SAVE, 86400); //setting rarely changed

//     return (dispatch: any) => {
//         dispatch({
//             type: FETCH_SETTING_DEFAULT_COA,
//         });

//         request
//             .then((payload) => {
//                 dispatch({
//                     type: FETCH_SETTING_DEFAULT_COA_DONE,
//                     payload: payload.data,
//                 });
//             })
//             .catch((error) => {
//                 dispatch({
//                     type: FETCH_SETTING_DEFAULT_COA_FAILED,
//                     error,
//                 });
//             });
//     };
// }

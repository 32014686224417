import React, { PureComponent } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Table } from "reactstrap";
import PrintArea from "../../../common/component/PrintArea";
import PrintFooter from "../../../common/component/PrintFooter";
import PrintHeader from "../../../common/component/PrintHeader";
import { showCurrency, showDate } from "../../../common/function/showValueHelper";

type SalesReceiptPrintState = {
    data: any;
    companyInfo: any;
    colSpan: number;
    settingHargaSatuan: boolean;
};

class SalesReceiptPrint extends PureComponent<WithTranslation, SalesReceiptPrintState> {
    constructor(props: any) {
        super(props);

        //load data from localStorage
        const stringData = localStorage.getItem("salesReceipt-print");
        const jsonData = stringData != null ? JSON.parse(stringData) : null;

        // remove data from localStorage
        // localStorage.removeItem("salesReceipt-print");

        console.log(jsonData);

        let stateObj = {
            data: jsonData && jsonData.data ? jsonData.data : null,
            colSpan: 4,
            companyInfo: jsonData && jsonData.companyInfo ? jsonData.companyInfo : null,
            settingHargaSatuan: jsonData.settingHargaSatuan ?? false
        }

        this.state = stateObj;
    }

    render() {
        const { t } = this.props;
        const { companyInfo, data } = this.state;
        if (data == null) return <p>No Data</p>;
        let columns = data.detailList.map((obj: any, index: number) => {
            return (
                <tr key={"detail-" + index}>
                    <td className="rowNo">{index + 1}</td>
                    <td>{showDate(obj.sales ? obj.sales.documentDate : obj.creditMemo.documentDate)}</td>
                    <td>{obj.sales ? obj.sales.key : obj.creditMemo.key}</td>
                    <td>
                        {obj.sales ? t("common.sales") : t("common.creditMemo")}
                    </td>
                    <td className="subTotal">{showCurrency(obj.subtotal, 2)}</td>
                </tr>
            );
        });

        let companyPaymentInfo = companyInfo.find((val: any) => {
            return val.key === "company-payment-info"
        });

        return (
            <PrintArea>
                <Table className="ra-table-print">
                    <PrintHeader
                        title={t("common.salesReceipt")}
                        companyInfo={companyInfo}
                        customer={data.customer}
                        no={data.key}
                        dtCreated={data.dtCreated}
                    />
                    <tbody>
                        <tr>
                            <td colSpan={2}>
                                <Table className="ra-table-detail-print">
                                    <thead>
                                        <tr>
                                            <th className="rowNo">#</th>
                                            <th>{t("common.date")}</th>
                                            <th>{t("common.documentNo")}</th>
                                            <th>{t("common.type")}</th>
                                            <th className="subTotal">{t("common.subTotal")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {columns}
                                    </tbody>
                                </Table>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                <Table className="ra-summary-table">
                                    <tbody>
                                        <tr>
                                            <td colSpan={3} style={{ textAlign: "right" }}>{t("common.grandTotal")}</td>
                                            <td className="subTotal">{showCurrency(data.grandTotal, 2)}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                {companyPaymentInfo.value !== "" ? (
                                    <>
                                        <strong>{t("common.paymentInfo")}</strong>
                                        <p>{companyPaymentInfo.value}</p>
                                    </>
                                ) : ""}
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                <PrintFooter footerType={"salesReceipt"} status={data.status} />
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </PrintArea>
        );
    }
}

export default withTranslation()(SalesReceiptPrint);

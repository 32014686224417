import { titleCase } from "change-case";
import { createSelector } from "reselect";
import { CACHE_MODE, reqGet, reqPut } from "../../../common/function/request";
import { setCreateEditParam } from "../../../common/function/requestParamHelper";
import reducerRegistry from "../../../common/reduxHandler/reducerRegistry";
import { deleteCacheCollection } from "../../../common/function/cache";

//initial state
const initialState: CommonReduxState = {
    loading: false,
    data: null,
    error: null,
};

//action
const createActionName = (name: string) => `updateInfo/${name}`;
const UPDATE_INFO = createActionName("UPDATE_INFO");
const UPDATE_INFO_DONE = createActionName("UPDATE_INFO_DONE");
const UPDATE_INFO_FAILED = createActionName("UPDATE_INFO_FAILED");
const FETCH_INFO = createActionName("FETCH_INFO");
const FETCH_INFO_DONE = createActionName("FETCH_INFO_DONE");
const FETCH_INFO_FAILED = createActionName("FETCH_INFO_FAILED");
const OPTION_INFO_TAG = createActionName("OPTION_INFO_TAG");
const OPTION_INFO_TAG_DONE = createActionName("OPTION_INFO_TAG_DONE");
const OPTION_INFO_TAG_FAILED = createActionName("OPTION_INFO_TAG_FAILED");

//reducer
function getActiveInfo(state = initialState, action: DispatchedAction<APIBaseDataResponse>) {
    switch (action.type) {
        case FETCH_INFO:
            return { ...initialState, loading: true };
        case FETCH_INFO_DONE:
            return { ...state, data: action.payload.data, loading: false };
        case FETCH_INFO_FAILED:
            return { ...state, error: action.error, loading: false };
        default:
            return state;
    }
}

function updateInfo(state = initialState, action: DispatchedAction<APIBaseDataResponse>) {
    switch (action.type) {
        case UPDATE_INFO:
            return { ...initialState, loading: true };
        case UPDATE_INFO_DONE:
            return { ...state, data: action.payload.data, loading: false };
        case UPDATE_INFO_FAILED:
            return { ...state, error: action.error, loading: false };
        default:
            return state;
    }
}

function optionInfoTag(state = initialState, action: DispatchedAction<APIBaseDataResponse>) {
    switch (action.type) {
        case OPTION_INFO_TAG:
            return { ...initialState, loading: true };
        case OPTION_INFO_TAG_DONE:
            return { ...state, data: action.payload.data, loading: false };
        case OPTION_INFO_TAG_FAILED:
            return { ...state, error: action.error, loading: false };
        default:
            return state;
    }
}
//register reducer
reducerRegistry.register("getActiveInfo", getActiveInfo);
reducerRegistry.register("updateInfo", updateInfo);
reducerRegistry.register("optionInfoTag", optionInfoTag);

//selector
export const slcFetchActiveInfo = (state: any): CommonReduxState => state["getActiveInfo"];
export const slcUpdateInfo = (state: any): CommonReduxState => state["updateInfo"];
const getInfoTag = (state: any): any[] => (state["optionInfoTag"].data ? state["optionInfoTag"].data : []);

/**
 * Returns options directly, not redux-state
 */
export const slcOptionInfoTag = createSelector(
    getInfoTag, //basic selector
    (optionInfoTag): { value: any; label: string }[] =>
        optionInfoTag.map((val: any) => ({ value: val, label: titleCase(val) }))
);

//action creator
const urlPath = "info";

/**
 * Edit info data
 *
 * @param integer id
 * @param object valueList
 */
export function actEditInfo(module: string, id: number, valueList: any) {
    let param = setCreateEditParam(valueList);

    const request = reqPut(`${module}/${urlPath}/${id}`, param);

    return (dispatch: any) => {
        dispatch({
            type: UPDATE_INFO,
        });
        request
            .then((payload) => {
                dispatch({
                    type: UPDATE_INFO_DONE,
                    payload: payload.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: UPDATE_INFO_FAILED,
                    error,
                });
            });
    };
}

/**
 * Get register info data
 *
 * Currently use standard GET API
 */
export function actFetchActiveInfo(module: string, id: number) {
    const request = reqGet(`${module}/${id}`, null);

    return (dispatch: any) => {
        dispatch({
            type: FETCH_INFO,
            addToLoading: true,
        });
        request
            .then((payload) => {
                dispatch({
                    type: FETCH_INFO_DONE,
                    payload: payload.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: FETCH_INFO_FAILED,
                    error,
                });
            });
    };
}

/**
 * Get a Info tag
 */
export function actOptionInfoTag(module: string) {
    const request = reqGet(`${module}/tag`, null, null, CACHE_MODE.WITH_LOAD_WITH_SAVE);

    return (dispatch: any) => {
        dispatch({
            type: OPTION_INFO_TAG,
            addToLoading: true,
        });
        request
            .then((payload) => {
                deleteCacheCollection(`${module}/tag`);
                dispatch({
                    type: OPTION_INFO_TAG_DONE,
                    payload: payload.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: OPTION_INFO_TAG_FAILED,
                    error,
                });
            });
    };
}

import React, { PureComponent } from "react";
import { Button, Form } from "reactstrap";
import { Link, Prompt } from "react-router-dom";
import { withTranslation, WithTranslation } from "react-i18next";

import SimpleBox from "../SimpleBox";
import { connect } from "react-redux";
import { getFormValues } from "redux-form";
import { isArray } from "util";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type SimpleFormBoxProps = {
    title: string;
    titleComponent?: any;
    disableSubmit: boolean;
    disableBack?: boolean;
    onSubmit: any;
    submitting: boolean;
    path?: string;
    returnPath?: string; //if back button returns to different path
    children: JSX.Element | React.ReactNode;
    skipClosePrompt?: boolean;
    match: any; //react-router-match
    formName: string;
    // isDirty?: boolean;
    formValues?: any;
} & WithTranslation; // & RouteComponentProps;

type SimpleFormBoxState = {
    isShow: boolean;
    // isEdit:boolean;
};

/**
 * SimpleBox inside Form component
 *
 * With extra prompt on window/tab close or react-router link.
 */
class SimpleFormBox extends PureComponent<SimpleFormBoxProps, SimpleFormBoxState> {
    constructor(props: any) {
        super(props);
        this.state = {
            isShow: false,
            // isEdit:false
        };
    }

    componentDidMount() {
        const { match, path, skipClosePrompt, t } = this.props;
        let //isEdit = false,
            isShow = false;

        // if (match.path === `${path}/edit/:key` && match.params.key != null) {
        //     isEdit = true;
        // } else
        if (match != null && match.path === `${path}/show/:key` && match.params.key != null) {
            isShow = true;
        }

        this.setState({ isShow });

        //add close confirmation
        if (!isShow && !skipClosePrompt) {
            window.onbeforeunload = (e: any) => {
                if (this.isFormDirty())
                    //direct get
                    return t("confirm.closeForm"); //string ignored in firefox

                return null;
            };
        }
    }

    componentWillUnmount() {
        window.onbeforeunload = null; //remove
    }

    /**
     * Brute-force detect values in form
     *
     * Skips default-values often used in registers
     */
    isFormDirty = () => {
        if (this.state.isShow) return false; //if show, skip

        const ignoredKey = [
            "customerId",
            "dueDate",
            "supplierId",
            "key",
            "documentDate",
            "whId",
            "isInclusiveTax",
            "supplierDocumentDate",
            "dueDate",
            "termOfPaymentId",
            "coa",
        ];
        let keyList = this.props.formValues != null ? Object.keys(this.props.formValues) : [];
        // console.log(keyList);
        for (let i = 0; i < keyList.length; i++) {
            if (ignoredKey.includes(keyList[i])) continue;

            if (
                this.props.formValues[keyList[i]] != null &&
                this.props.formValues[keyList[i]] !== "" &&
                this.props.formValues[keyList[i]] !== 0
            ) {
                // console.log(keyList[i], this.props.formValues[keyList[i]]);
                if (isArray(this.props.formValues[keyList[i]])) {
                    //if array but 0 child
                    if (this.props.formValues[keyList[i]].length === 0) continue;

                    //if array with {} as child (unedited detail)
                    //only consider array with 1 child (after reset), if more than 1 child, user already click add
                    if (typeof this.props.formValues[keyList[i]][0] === "object") {
                        if (Object.keys(this.props.formValues[keyList[i]][0]).length === 0) continue;

                        let detailKeyList = Object.keys(this.props.formValues[keyList[i]][0]);

                        //check all detail values
                        for (let j = 0; j < detailKeyList.length; j++) {
                            let val = this.props.formValues[keyList[i]][0][detailKeyList[j]];
                            if (val != null && val !== "" && val !== 0 && val !== -1) {
                                return true;
                            }
                        }

                        //else flag not dirty
                        continue;
                    }
                }

                return true;
            }
        }
        return false;
    };

    render() {
        const {
            title,
            onSubmit,
            disableSubmit,
            disableBack = false,
            submitting,
            path = "",
            returnPath,
            children,
            skipClosePrompt,
            titleComponent,
            t,
        } = this.props;
        const { isShow } = this.state;

        // console.log(isDirty, this.isFormDirty());

        return (
            <Form onSubmit={onSubmit}>
                <Prompt when={!isShow && !skipClosePrompt && this.isFormDirty()} message={t("confirm.closeForm")} />
                <SimpleBox
                    title={title}
                    titleComponent={titleComponent}
                    footerComponent={
                        <>
                            {!disableSubmit && (
                                <Button
                                    type="submit"
                                    color="primary"
                                    disabled={submitting}
                                    style={{ marginRight: "8px" }}
                                >
                                    <FontAwesomeIcon icon="check" style={{ marginRight: "8px" }} />
                                    {t("common.save")}
                                </Button>
                            )}
                            {!disableBack && (
                                <Link to={returnPath != null ? returnPath : path}>
                                    <Button color="danger">
                                        <FontAwesomeIcon icon="caret-left" style={{ marginRight: "8px" }} />
                                        {t("common.back")}
                                    </Button>
                                </Link>
                            )}
                        </>
                    }
                >
                    {children}
                </SimpleBox>
            </Form>
        );
    }
}

function mapStateToProps(state: any, ownProps: any) {
    return {
        // isDirty: isDirty(ownProps.formName)(state),
        formValues: getFormValues(ownProps.formName)(state),
    };
}

export default connect(
    mapStateToProps,
    null
)(withTranslation()(SimpleFormBox));

import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
    faAngleLeft,
    faTachometerAlt,
    faBook,
    faIndustry,
    faCube,
    faMoneyBill,
    faShoppingCart,
    faHandshake,
    faCubes,
    faCalculator,
    faChartPie,
    faUsers,
    faSlidersH,
    faCogs,
    faCog,
    faLock,
    faCheck,
    faCaretLeft,
    faTrash,
    faBars,
    faUser,
    faBell,
    faChevronLeft,
    faChevronRight,
    faInfoCircle,
    faHandHoldingUsd,
    faCommentDollar,
    faSearch,
    faCoins,
    faUndo,
    faFileInvoiceDollar,
    faClock,
    faReceipt,
    faExchangeAlt,
    faMoneyCheck,
    faBalanceScale,
    faWallet,
    faRedo,
    faRecycle,
    faLaptopHouse,
    faExclamationCircle,
    faFileExport,
    faWarehouse,
    faTag,
    faLink,
    faSpinner,
} from "@fortawesome/free-solid-svg-icons";

import reduxStore from "./module/common/reduxHandler/createStore";

//import translation engine (so it will be called once)
import "./i18n";

//import css
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.min.css";
import "react-table/react-table.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import "simplebar/dist/simplebar.min.css";
import "react-virtualized/styles.css";
import "./asset/scss/main.scss";

import Core from "./module/Core";
import { initializeGA } from "./module/common/function/googleAnalytics";

//google analytics
initializeGA();

//font awesome icons
library.add(
    faAngleLeft,
    faTachometerAlt,
    faBook,
    faIndustry,
    faCube,
    faMoneyBill,
    faShoppingCart,
    faHandshake,
    faCubes,
    faCalculator,
    faChartPie,
    faUsers,
    faSlidersH,
    faCogs,
    faCog,
    faLock,
    faCheck,
    faCaretLeft,
    faTrash,
    faBars,
    faUser,
    faBell,
    faChevronLeft,
    faChevronRight,
    faInfoCircle,
    faHandHoldingUsd,
    faCommentDollar,
    faSearch,
    faLock,
    faCommentDollar,
    // faHandHoldingUsd,
    faCoins,
    faUndo,
    faFileInvoiceDollar,
    faClock,
    faReceipt,
    faExchangeAlt,
    faMoneyCheck,
    faBalanceScale,
    faWallet,
    faRedo,
    faRecycle,
    faLaptopHouse,
    faExclamationCircle,
    faFileExport,
    faWarehouse,
    faTag,
    faLink,
    faSpinner
);

// Sentry logging only for production environment
// if (process.env.NODE_ENV === "production") {
//     Sentry.init({
//         dsn: process.env.REACT_APP_SENTRY_API,
//     });
// }

ReactDOM.render(
    <Provider store={reduxStore}>
        <BrowserRouter>
            <Core />
        </BrowserRouter>
    </Provider>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

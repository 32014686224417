import React, { PureComponent } from "react";
import { connect } from "react-redux";
// import {Route} from 'react-router-dom';
import reduxStore from "../../reduxHandler/createStore";
import { slcUserLogin } from "../../../Core/redux/login";

const allPermissionType = ["create", "read", "readQty", "readAmount", "update", "delete", "authorize"]; //all type of permission

/**
 * Check whether current user-logged access have authority to access a 'module' with certain 'allowedPermission' type
 *
 * @param userLoginProps
 * @param moduleName
 * @param allowedPermission
 */
function checkAuthority(userLoginProps: any, moduleName: string | string[], allowedPermission: string[]) {
    if (userLoginProps.access) {
        let moduleList: string[] = [];
        if (typeof moduleName === "string") {
            moduleList.push(moduleName);
        } else if (Array.isArray(moduleName)) {
            moduleList = moduleName;
        }

        //v0.3.0 uses map
        for (let i = 0; i < moduleList.length; i++) {
            let accessPermission = userLoginProps.access[moduleList[i]];
            if (accessPermission) {
                for (let j = 0; j < allowedPermission.length; j++) {
                    if (accessPermission[allowedPermission[j]] && accessPermission[allowedPermission[j]] === true) {
                        return true;
                    }
                }
            }
        }
    }
    return false;
}

type AuthCheckProps = {
    allowAllType?: boolean;
    allowedPermission?: string[];
    userLogin?: CommonReduxState;
    moduleName: string | string[];
    children?: any;
};

type AuthCheckState = {
    allowRender: boolean;
    allowedPermission: string[];
};

/**
 * Authorization Wrapper that decides render/not using redux-check.
 * Props: moduleName & (allowedPermission or allowAllType)
 * Usage:
 * <AuthCheck moduleName="string"-OR-{["string1","string2"]} allowedPermission={["create","read","readQty","readAmount","update","delete","authorize"]}/>
 *     <Child />
 * </AuthCheck>
 */
class BaseAuthCheck extends PureComponent<AuthCheckProps, AuthCheckState> {
    constructor(props: any) {
        super(props);

        let allowedPermission: any = [];
        //check props
        if (this.props.allowAllType === true) {
            allowedPermission = allPermissionType;
        } else {
            allowedPermission = this.props.allowedPermission;
        }

        this.state = {
            allowRender: false,
            allowedPermission,
        };
    }

    /**
     * This combination of cDM and cWRP is the reason React removes cWRP into gDSFP...
     * The cWRP won't be called the first time it's rendered, for some unknown reason...
     * That's why currently it's mitigated by cDM
     */
    componentDidMount() {
        this.checkAllowRender(this.props);
    }

    componentDidUpdate(prevprops: any) {
        if (this.props.userLogin && this.props.userLogin.access && prevprops.userLogin !== this.props.userLogin) {
            this.checkAllowRender(this.props);
        }
    }

    checkAllowRender = (usedProps: any) => {
        const { userLogin } = usedProps;
        const moduleName = this.props.moduleName;
        const allowedPermissionKeyList = this.state.allowedPermission;

        this.setState({ allowRender: checkAuthority(userLogin, moduleName, allowedPermissionKeyList) });
    };

    render() {
        if (this.state.allowRender === true) {
            return this.props.children;
        } else {
            return null;
        }
    }
}

function mapStateToProps(state: any) {
    return { userLogin: slcUserLogin(state) };
}

export const AuthCheck = connect(mapStateToProps)(BaseAuthCheck);

/**
 * HOC function that creates the AuthCheck HOC wrapper.
 * Use this ONLY IF there's duplicated AuthCheck with same props.
 * This way the code will be a lot cleaner.
 *
 * @export
 * @param {string|string[]} moduleName
 * @param {string[]} allowedPermission
 */
export function authCheckFactory(moduleName: string | string[], allowedPermission: string[], allowAllType = false) {
    return (props: any) => (
        <AuthCheck moduleName={moduleName} allowedPermission={allowedPermission} allowAllType={allowAllType}>
            {props.children}
        </AuthCheck>
    );
}

/**
 * Independent function that can be used to return true/false for the Authorization Checking.
 * Used inside PureComponent's method to determine a value.
 *
 * Will get the userLoginState by itself.
 *
 * @param {string|string[]} moduleName - Module name to check
 * @param {string[]} allowedPermission
 * @param {boolean} [allowAllType=false]
 * @returns Bool - allowed or not
 */
export function boolAuthChecker(
    moduleName: string | string[] | undefined,
    allowedPermission: string[],
    allowAllType = false
) {
    if (moduleName == null) return false;

    let allowedPermissionKeyList: string[] = [];
    //check params
    if (allowAllType === true) {
        allowedPermissionKeyList = allPermissionType;
    } else {
        allowedPermissionKeyList = allowedPermission;
    }

    //get current redux-store state
    const userLoginProps = slcUserLogin(reduxStore.getState());

    return checkAuthority(userLoginProps, moduleName, allowedPermissionKeyList);
}

/**
 * Copy-paste everything inside AuthCheck
 * As it's exactly the same, EXCEPT render()
 *
 * This PureComponent only renders <Route>
 */
// class BaseAuthRoute extends PureComponent {
//     constructor(props){
//         super(props);

//         let allowedPermission=[];
//         //check props
//         if (this.props.allowAllType==true){
//             allowedPermission = allPermissionType;
//         } else {
//             allowedPermission = this.props.allowedPermission;
//         }

//         this.state = {
//             allowRender:false,
//             allowedPermission
//         };
//     }

//     componentDidMount(){
//         this.checkAllowRender(this.props);
//     }

//     componentWillReceiveProps(nextProps){
//         if (nextProps.userLogin && nextProps.userLogin.access && nextProps.userLogin!==this.props.userLogin){
//             this.checkAllowRender(nextProps);
//         }
//     }

//     checkAllowRender = (usedProps) => {
//         const { userLogin } = usedProps;
//         const moduleName = this.props.moduleName;
//         const allowedPermissionKeyList = this.state.allowedPermission;
//         let allowRender=false;
//         if (userLogin.access){
//             let moduleList = [];
//             if (typeof moduleName === 'string'){
//                 moduleList.push(moduleName);
//             } else if (Array.isArray(moduleName)){
//                 moduleList = moduleName;
//             }

//             for (let i = 0; i < moduleList.length; i++){
//                 let modulePermission = userLogin.access[moduleList[i]];
//                 if (modulePermission){
//                     let accessPermission = modulePermission.access_permission;
//                     for(let i = 0; i < allowedPermissionKeyList.length; i++){
//                         if (accessPermission[allowedPermissionKeyList[i]] && accessPermission[allowedPermissionKeyList[i]] === true){
//                             //it's allowed
//                             allowRender = true;
//                             break;
//                         }
//                     }
//                 }

//                 if (allowRender)
//                     break;
//             }
//         }
//         this.setState({allowRender});
//     }

//     render(){
//         if (this.state.allowRender===true){
//             return <Route path={this.props.path} PureComponent = {this.props.PureComponent}/>
//         } else {
//             return null;
//         }
//     }
// };
// export const AuthRoute = connect(mapStateToProps)(BaseAuthRoute);

import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation, WithTranslation } from "react-i18next";
import { Button, UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { AuthCheck } from "../AuthCheck";
import {
    slcSettingTurnOnDotMatrixPrint_Value,
    slcSettingUseDotMatrixAsDefault_Value,
} from "../../../Setting/redux/setting";

type TableActionProps = {
    onClickDelete?: Function;
    onClickDeleteWithData?: Function;
    onActionApproved?: Function;
    onActionUnapproved?: Function;
    onActionClosed?: Function;
    onActionCreateInvoice?: Function;
    onEdit?: any; //override Link
    printDocument?: any;
    disableEdit?: boolean;
    disableEditInfo?: boolean;
    disableDelete?: boolean;
    disablePreview?: boolean;
    enableDuplicate?: boolean;
    actionApproved?: boolean | null;
    actionUnapproved?: boolean | null;
    actionClosed?: boolean | null;
    actionCreateInvoice?: boolean | null;
    actionTransactionIn?: string | null;
    actionTransactionOut?: string | null;
    moduleName: string | string[];
    originalData: any;
    url?: string;
    editUrl?: string;
    previewUrl?: string;
    duplicateUrl?: string;
    params?: any;
    useDotMatrix?: boolean;
    defaultPrint?: "dot-matrix" | "html";
    /**
     * Array of url-path for custom action.
     *
     * Can't be used for custom function call, create external component instead
     */
    customAction?:
        | {
              label: string;
              to: any;
              color?: string;
          }[]
        | null;
    /**
     * Array for custom show/edit link
     *
     * Currently used for non-stock sales from recurringInvoice
     */
    customLink?: { to: string; condition: boolean }[];
} & WithTranslation;

/**
 * Set of Action Buttons for ListTable
 */
class TableAction extends PureComponent<TableActionProps, any> {
    onClickDelete = () => {
        if (this.props.onClickDelete != null && window.confirm(this.props.t("confirm.delete"))) {
            this.props.onClickDelete(this.props.originalData.id);
        } else if (this.props.onClickDeleteWithData != null && window.confirm(this.props.t("confirm.delete"))) {
            this.props.onClickDeleteWithData(this.props.originalData);
        }
    };

    onClickCreateInvoice = () => {
        if (this.props.onActionCreateInvoice != null) this.props.onActionCreateInvoice(this.props.originalData);
    };

    onClickApproved = () => {
        if (this.props.onActionApproved != null) this.props.onActionApproved(this.props.originalData);
    };

    onClickUnapproved = () => {
        if (this.props.onActionUnapproved != null) this.props.onActionUnapproved(this.props.originalData);
    };

    onClickClosed = () => {
        if (this.props.onActionClosed != null && window.confirm(this.props.t("confirm.closeOrder"))) {
            this.props.onActionClosed(this.props.originalData);
        }
    };

    render() {
        const {
            t,
            children,
            moduleName,
            disableEdit,
            disableEditInfo,
            onEdit,
            disableDelete,
            disablePreview,
            enableDuplicate,
            originalData,
            printDocument,
            useDotMatrix,
            defaultPrint,
            editUrl,
            previewUrl,
            duplicateUrl,
            url,
            params,
            customAction,
            actionApproved,
            actionUnapproved,
            actionClosed,
            actionCreateInvoice,
            customLink,
            actionTransactionIn,
            actionTransactionOut,
        } = this.props;

        if (originalData.isDeleted === true)
            return <label style={{ textAlign: "center", display: "block" }}>{t("common.deleted")}</label>; //if it's trashed, no action allowed

        let customElm =
            customAction != null && customAction.length > 0
                ? customAction.map((val: any, index: number) => (
                      <Link to={val.to} key={index}>
                          <Button color={val.color ? val.color : "secondary"} className="btn-xs" block>
                              {t(val.label)}
                          </Button>
                      </Link>
                  ))
                : null;

        //if there's no info whether the data is action-allowed or not (null), consider it allowed
        let actionApprovedElm = actionApproved ? (
            <Button color="primary" className="btn-xs" block onClick={this.onClickApproved}>
                {t("common.actionApproved")}
            </Button>
        ) : (
            <></>
        );

        let actionUnapprovedElm = actionUnapproved ? (
            <Button color="primary" className="btn-xs" block onClick={this.onClickUnapproved}>
                {t("common.actionUnapproved")}
            </Button>
        ) : (
            <></>
        );

        let actionClosedElm = actionClosed ? (
            <Button color="warning" className="btn-xs" block onClick={this.onClickClosed}>
                {t("common.actionClosed")}
            </Button>
        ) : (
            <></>
        );

        let actionCreateInvoiceElm = actionCreateInvoice ? (
            <Button color="secondary" className="btn-xs" block onClick={this.onClickCreateInvoice}>
                {t("common.actionCreateInvoice")}
            </Button>
        ) : (
            <></>
        );

        let finalUrl = url;
        if (customLink != null && customLink.length > 0) {
            finalUrl = customLink.find((val) => val.condition === true)?.to ?? url;
        }

        let editElm =
            originalData.isUpdateAllowed !== false && !disableEdit ? (
                onEdit != null ? (
                    <Button color="primary" className="btn-xs" block onClick={onEdit}>
                        {t("common.edit")}
                    </Button>
                ) : (
                    <Link
                        to={
                            editUrl
                                ? editUrl
                                : `${finalUrl}/edit/${originalData ? originalData.id : ""}${params ? "?" + params : ""}`
                        }
                    >
                        <Button color="primary" className="btn-xs" block>
                            {t("common.edit")}
                        </Button>
                    </Link>
                )
            ) : null;

        //only allow if normal edit is not allowed
        let editInfoElm =
            (originalData.isUpdateAllowed === false || disableEdit) &&
            originalData.isUpdateInfoAllowed === true &&
            !disableEditInfo ? (
                <Link
                    to={`/update-info${finalUrl}/${originalData ? originalData.id : ""}${params ? "?" + params : ""}`}
                >
                    <Button color="default" className="btn-xs" block>
                        {t("common.editInfo")}
                    </Button>
                </Link>
            ) : null;

        let previewFinalUrl = previewUrl
            ? `${previewUrl}${params ? "?" + params : ""}`
            : `${finalUrl}/show/${originalData ? originalData.id : ""}${params ? "?" + params : ""}`;

        let previewElm = !disablePreview ? (
            <Link to={previewFinalUrl}>
                <Button color="success" className="btn-xs" block>
                    {t("common.view")}
                </Button>
            </Link>
        ) : null;

        let deleteElm =
            originalData.isDeleteAllowed !== false && !disableDelete ? (
                <Button color="danger" className="btn-xs" block onClick={this.onClickDelete}>
                    {t("common.delete")}
                </Button>
            ) : null;

        let printElm = null;
        if (printDocument != null) {
            if (!useDotMatrix) {
                printElm = (
                    <Button color="info" className="btn-xs" onClick={() => printDocument(originalData, true)} block>
                        Print
                    </Button>
                );
            } else {
                let defDot = defaultPrint === "dot-matrix";
                printElm = (
                    <UncontrolledButtonDropdown style={{ width: "100%" }}>
                        <Button
                            color="info"
                            className="btn-xs"
                            style={{ width: "100%" }}
                            onClick={() => (defDot ? printDocument(originalData) : printDocument(originalData, true))}
                        >
                            {defDot ? t("common.printDot") : t("common.printHTML")}
                        </Button>
                        <DropdownToggle caret className="btn-xs" color="info" />
                        <DropdownMenu>
                            <DropdownItem
                                onClick={() =>
                                    !defDot ? printDocument(originalData) : printDocument(originalData, true)
                                }
                            >
                                {!defDot ? t("common.printDot") : t("common.printHTML")}
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                );
            }
        }

        let duplicateElm = !!enableDuplicate ? (
            <Link
                to={
                    duplicateUrl
                        ? duplicateUrl
                        : `${finalUrl}/new/${originalData ? originalData.id : ""}${params ? "?" + params : ""}`
                }
            >
                <Button className="btn-xs" block>
                    {t("common.duplicate")}
                </Button>
            </Link>
        ) : null;

        let transactionInElm =
            actionTransactionIn != null ? (
                <Link
                    target="_blank"
                    to={`/transaction-in/new/${actionTransactionIn}/${originalData?.id}/${
                        originalData?.customer?.id ?? ""
                    }`}
                >
                    <Button className="btn-xs btn-pay" block>
                        {t("common.pay")}
                    </Button>
                </Link>
            ) : null;

        let transactionOutElm =
            actionTransactionOut != null ? (
                <Link
                    target="_blank"
                    to={`/transaction-out/new/${actionTransactionOut}/${originalData?.id}/${
                        originalData?.supplier?.id ?? ""
                    }`}
                >
                    <Button className="btn-xs btn-pay" block>
                        {t("common.pay")}
                    </Button>
                </Link>
            ) : null;

        if (originalData.isDeleted) return <span />;

        return (
            <>
                {customElm}
                {actionCreateInvoiceElm}
                <AuthCheck moduleName={moduleName} allowedPermission={["update"]}>
                    {actionApprovedElm}
                    {actionUnapprovedElm}
                    {actionClosedElm}
                    {editElm}
                    {editInfoElm}
                </AuthCheck>
                <AuthCheck moduleName={moduleName} allowedPermission={["read"]}>
                    {previewElm}
                </AuthCheck>
                <AuthCheck moduleName={moduleName} allowedPermission={["create"]}>
                    {duplicateElm}
                </AuthCheck>
                <AuthCheck moduleName={moduleName} allowedPermission={["delete"]}>
                    {deleteElm}
                </AuthCheck>

                {printElm}

                {transactionInElm}
                {transactionOutElm}

                {children}
            </>
        );
    }
}

function mapStateToProps(state: any) {
    return {
        useDotMatrix: slcSettingTurnOnDotMatrixPrint_Value(state),
        defaultPrint: slcSettingUseDotMatrixAsDefault_Value(state) === true ? "dot-matrix" : "html",
    };
}

export default connect<any>(mapStateToProps, {})(withTranslation()(TableAction));

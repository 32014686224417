import React, { PureComponent } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Button } from "reactstrap";
import PrintArea from "../../../common/component/PrintArea";
import POPrintDefault from "../PurchaseOrderPrintTemplate/tplDefault";
import POPrintAnj from "../PurchaseOrderPrintTemplate/tplAnj";
import POPrintBerkat from "../PurchaseOrderPrintTemplate/tplBerkat";

type PurchaseOrderPrintState = {
    data: any;
    companyInfo: any;
    settingHargaSatuan: boolean;
    colSpan: number;
    showAmount: boolean;
    printTemplateSetting: any;
};

class PurchaseOrderPrint extends PureComponent<WithTranslation, PurchaseOrderPrintState> {
    constructor(props: any) {
        super(props);

        //load data from localStorage
        const stringData = localStorage.getItem("purchaseOrder-print");
        const jsonData = stringData != null ? JSON.parse(stringData) : null;

        // remove data from localStorage
        // localStorage.removeItem("purchaseOrder-print");

        let stateObj = {
            data: jsonData && jsonData.data ? jsonData.data : null,
            colSpan: 4,
            companyInfo: jsonData?.companyInfo ? jsonData.companyInfo : null,
            settingHargaSatuan: jsonData.setting?.settingHargaSatuan ? jsonData.setting.settingHargaSatuan : null,
            printTemplateSetting: jsonData.setting?.printTemplateSetting ?? "STANDARD",
            showAmount: true,
        };

        this.state = stateObj;
    }

    onToggleAmount = () => {
        this.setState({ showAmount: !this.state.showAmount });
    };

    render() {
        const { t } = this.props;
        const { companyInfo, data } = this.state;
        if (data == null) return <p>No Data</p>;

        let elmPrint = null;
        switch (this.state.printTemplateSetting) {
            case "ANJ":
                elmPrint = <POPrintAnj data={data} companyInfo={companyInfo} />;
                break;

            case "BERKAT":
                elmPrint = (
                    <POPrintBerkat
                        data={data}
                        colSpan={this.state.colSpan}
                        showAmount={this.state.showAmount}
                        companyInfo={companyInfo}
                    />
                );
                break;

            default:
                elmPrint = (
                    <POPrintDefault
                        data={data}
                        companyInfo={companyInfo}
                        settingHargaSatuan={this.state.settingHargaSatuan}
                        colSpan={this.state.colSpan}
                        showAmount={this.state.showAmount}
                    />
                );
                break;
        }

        return (
            //@ts-ignore
            <PrintArea
                customComponent={
                    <>
                        <Button onClick={this.onToggleAmount}>{t("common.showHideAmount")}</Button>
                    </>
                }
            >
                {elmPrint}
            </PrintArea>
        );
    }
}

export default withTranslation()(PurchaseOrderPrint);

import React, { PureComponent } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Table } from "reactstrap";
import HTMLPrintHeader from "../../../common/component/HTMLPrintHeader";
import PrintArea from "../../../common/component/PrintArea";
import { SafeBig } from "../../../common/function/safeBig";
import { showCurrency, showDate } from "../../../common/function/showValueHelper";

type OutstandingPayablePrintState = {
    data: any;
    param: any;
};

class OutstandingPayablePrint extends PureComponent<WithTranslation, OutstandingPayablePrintState> {
    constructor(props: any) {
        super(props);

        //load data from localStorage
        const stringData = localStorage.getItem("outstanding-payable-print");
        const jsonData = stringData != null ? JSON.parse(stringData) : null;

        // remove data from localStorage
        // localStorage.removeItem("payable-print");
        // console.log(jsonData?.data);

        let stateObj = {
            data: jsonData?.data ?? null,
            param: jsonData?.param ?? {},
        }

        this.state = stateObj;
    }

    render() {
        const { t } = this.props;
        const { param, data } = this.state;
        if (data == null) return <p>No Data</p>;

        let columns:any[] = [];
        
        // for(let list of supplierList)
        let grandTotal = SafeBig(0);
        for(let grouped of data){

            // Render supplier
            columns.push(<tr>
                <td><b>{`${t("common.customer")}`}</b></td>
                <td colSpan={2}><b>{`: ${grouped.supplier}`}</b></td>
            </tr>)

            let details: any[] = [];
            for(let i = 0; i< grouped.payableList.length;i++){
                let obj = grouped.payableList[i];

                details.push(
                    <tr key={"detail-" + i}>
                    <td className="rowNo">{i + 1}</td>
                    <td>{showDate(obj.documentDate)}</td>
                    <td>{obj.key}</td>
                    <td style={{ textAlign: "right" }}>{showCurrency(obj.grandTotal, 2)}</td>
                    <td style={{ textAlign: "right" }}>{showCurrency(obj.payment, 2)}</td>
                    <td style={{ textAlign: "right" }}>{showCurrency(obj.net, 2)}</td>
                    <td>{obj.description}</td>
                </tr>
                );
            }

            //summary
            details.push(<tr>
                <td colSpan={5} style={{ textAlign: "right" }}><b>{t("common.subTotal")}</b></td>
                <td style={{ textAlign: "right" }}>{showCurrency(grouped.subtotal, 2)}</td>
                <td />
            </tr>)

            columns.push(
                <tr>
                    <td colSpan={2} style={{ padding: "0 !important" }}>
                        <Table className="ra-table-detail-print">
                            <thead>
                                <tr>
                                    <th className="rowNo">#</th>
                                    <th>{t("common.date")}</th>
                                    <th>{t("common.documentNo")}</th>
                                    <th className="price">{t("common.grandTotal")}</th>
                                    <th className="price">{t("common.received")}</th>
                                    <th className="price">{t("common.net")}</th>
                                    <th>{t("common.description")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {details}
                            </tbody>
                        </Table>
                    </td>
                </tr>);
            grandTotal = grandTotal.plus(grouped.subtotal);
        }

        return (
            <PrintArea>
                <Table className="ra-table-print">
                    <HTMLPrintHeader
                        param={param}
                    />
                    <tbody>
                        {columns}
                        <tr>
                            <td colSpan={3}>
                                <Table>
                                    <tfoot>
                                        <tr>
                                            <td style={{ textAlign: "right", width: "80%" }}><b>{t("common.grandTotal")}</b></td>
                                            <td style={{ textAlign: "right" }}>{showCurrency(grandTotal, 2)}</td>
                                        </tr>
                                    </tfoot>
                                </Table>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </PrintArea>
        );
    }
}

export default withTranslation()(OutstandingPayablePrint);

type TokenData = {
    exp: number;
    iat: number;
    uid: string;
    tid: string;
    ver: number;
} | null;

/**
 * Function to read JWT data retrieved after login.
 *
 * Returns payload data
 */
export default function jwtTokenReader(): TokenData {
    const hpToken = localStorage.getItem("hpT");
    if (!hpToken) return null;

    const payloadToken = hpToken.split(".")[1];
    return JSON.parse(window.atob(payloadToken));
}

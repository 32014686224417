import React from "react";
import { useTranslation } from "react-i18next";
import { Table } from "reactstrap";
import PrintFooter from "../../../common/component/PrintFooter";
import PrintHeader from "../../../common/component/PrintHeader";
import SimpleHeader from "../../../common/component/PrintHeader/SimpleHeader";
import { SafeBig } from "../../../common/function/safeBig";
import { showCurrency, showQty } from "../../../common/function/showValueHelper";

type DeliveryReportPrintDefaultProps = {
    data: any;
    companyInfo: any;
    printTemplateSetting: string;
};

const DeliveryReportPrintDefault = React.memo(
    ({ data, companyInfo, printTemplateSetting }: DeliveryReportPrintDefaultProps) => {
        const { t } = useTranslation();
        if (data == null) return <p>No Data</p>;
        let totalQty = SafeBig(0);
        let columns = data.detailList.map((obj: any, index: number) => {
            totalQty = totalQty.plus(SafeBig(obj.qty ?? 0));
            return (
                <tr key={"detail-" + index}>
                    <td className="rowNo">{index + 1}</td>
                    <td>{obj.productName ?? obj.product.name}</td>
                    <td className="qty">
                        {obj.unit == null ? (
                            <table className="printQtyTable">
                                <tbody>
                                    <tr key={"detail-1-" + index}>
                                        <td>{showQty(obj.qty) + " "}</td>
                                        <td>{obj.product?.unit?.name}</td>
                                    </tr>
                                </tbody>
                            </table>
                        ) : (
                            <table className="printQtyTable">
                                <tbody>
                                    <tr key={"detail-1-" + index}>
                                        { !obj.product.smallestUnit || obj.unit.id === obj.product.smallestUnit?.id ? (
                                            <>
                                                <td>{showQty(obj.qty) + " " + obj.unit?.name} @{ showQty(obj.productUnitConversion.qty) + " " + obj.product?.unit?.name } </td>
                                                <td>{showQty(SafeBig(obj.qty ?? 0).times(obj.productUnitConversion?.qty ?? 1)) + " " + obj.product.unit?.name}</td>
                                            </>
                                        ) : (
                                            <>
                                                <td>{showQty(obj.qty) + " " + obj.unit?.name} @{ showQty(obj.product.smallestUnitConversion.qty) + " " + obj.product.smallestUnit?.name } </td>
                                                <td>{showQty(SafeBig(obj.qty ?? 0).times(obj.product.smallestUnitConversion?.qty ?? 1)) + " " + obj.product.smallestUnit?.name}</td>
                                            </>
                                        )}
                                    </tr>
                                </tbody>
                            </table>
                        )}
                    </td>
                </tr>
            );
        });
        console.log(data);
        return (
            <Table className="ra-table-print">
                { printTemplateSetting === "STANDARD" ? (
                    <PrintHeader
                        title={t("common.deliveryReport")}
                        companyInfo={companyInfo}
                        customer={data.customer}
                        no={data.key}
                        customAddress={data.customAddress}
                        dtCreated={data.dtCreated}
                    />
                ) : (
                    <SimpleHeader
                        title={t("common.deliveryReport")}
                        companyInfo={companyInfo}
                        customer={data.customer}
                        no={data.key}
                        customAddress={data.customAddress}
                        dtCreated={data.dtCreated}
                    />
                )}
                <tbody>
                    <tr>
                        <td colSpan={2}>
                            <Table className="ra-table-detail-print">
                                <thead>
                                    <tr>
                                        <th className="rowNo">#</th>
                                        <th>{t("common.product")}</th>
                                        <th className="qty">{t("common.qty")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {columns}
                                    <tr>
                                        <td colSpan={2} style={{ textAlign: "right" }}>
                                            {t("common.totalQty")}
                                        </td>
                                        <td>{showCurrency(totalQty.toString(), 2)}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            <PrintFooter footerType="deliveryReport" />
                        </td>
                    </tr>
                </tbody>
            </Table>
        );
    }
);

export default DeliveryReportPrintDefault;

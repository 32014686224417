import { reducer as formReducer } from "redux-form";

/**
 * A class too contains all reducer, with dynamic reducer capability
 */
export class ReducerRegistry {
    _emitChange: any;
    _reducers: any;

    constructor() {
        this._emitChange = null;
        this._reducers = {
            //set initial reducer
            form: formReducer,
        };
    }

    getReducers() {
        return { ...this._reducers };
    }

    /**
     * Add new reducer to the registry
     * @param name key of the reducer
     * @param reducer reducer function
     */
    register(name: string, reducer: Function) {
        this._reducers = { ...this._reducers, [name]: reducer };
        if (this._emitChange) {
            this._emitChange(this.getReducers());
        }
    }

    setChangeListener(listener: any) {
        this._emitChange = listener;
    }
}

const reducerRegistry = new ReducerRegistry();
export default reducerRegistry;

/**
 * Tutorial/documentation modules
 */

let tutorialConfig: TutorialDefinitionMap = {};

if (process.env.REACT_APP_IS_INVENTORY === "false") {
    //lazy load modules
    //usage: component: ()=><MasterBank/>, //lazy is an object, we need to simulate call here

    tutorialConfig = {
        install: {
            title: "common.install",
            url: "https://docs.refineaccounting.com/how-to-start/#saldo-awal",
        },
        //master
        coa: {
            title: "common.coa",
            url: "https://docs.refineaccounting.com/how-to-start/#coa",
        },
        bank: {
            title: "common.bank",
            url: "https://docs.refineaccounting.com/how-to-start/#kas-&-bank",
        },
        cash: {
            title: "common.cash",
            url: "https://docs.refineaccounting.com/how-to-start/#kas-&-bank",
        },
        supplier: {
            title: "common.supplier",
            url: "https://docs.refineaccounting.com/how-to-start/#supplier",
        },
        "supplier-category": {
            title: "common.supplierCategory",
            url: "https://docs.refineaccounting.com/how-to-start/#kategori-supplier",
        },
        customer: {
            title: "common.customer",
            url: "https://docs.refineaccounting.com/how-to-start/#pelanggan",
        },
        "customer-category": {
            title: "common.customerCategory",
            url: "https://docs.refineaccounting.com/how-to-start/#kategori-pelanggan",
        },
        warehouse: {
            title: "common.warehouse",
            url: "https://docs.refineaccounting.com/how-to-start/#gudang",
        },
        product: {
            title: "common.product",
            url: "https://docs.refineaccounting.com/how-to-start/#produk",
        },
        "product-unit": {
            title: "common.productUnit",
            url: "https://docs.refineaccounting.com/how-to-start/#satuan-produk",
        },
        "product-category": {
            title: "common.productCategory",
            url: "https://docs.refineaccounting.com/how-to-start/#kategori-produk",
        },
        "product-unit-conversion": {
            title: "common.productUnitConversion",
            url: "https://docs.refineaccounting.com/how-to-start/#konversi-satuan-produk",
        },
        "product-group": {
            title: "common.productGroup",
            url: "https://docs.refineaccounting.com/how-to-start/#grup-produk--parcel",
        },
        "product-special-price": {
            title: "common.productSpecialPrice",
            url: "https://docs.refineaccounting.com/how-to-start/#harga-spesial",
        },
        "sales-person": {
            title: "common.salesPerson",
            url: "https://docs.refineaccounting.com/how-to-start/#tenaga-penjual",
        },
        "term-of-payment": {
            title: "common.termOfPayment",
            url: "https://docs.refineaccounting.com/how-to-start/#metode-pembayaran",
        },

        //purchase
        "purchase-order": {
            title: "common.purchaseOrder",
            url: "https://docs.refineaccounting.com/pembelian/#pesanan-pembelian",
        },
        "receive-report": {
            title: "common.receiveReport",
            url: "https://docs.refineaccounting.com/pembelian/#laporan-penerimaan-barang",
        },
        purchase: {
            title: "common.purchase",
            url: "https://docs.refineaccounting.com/pembelian/#faktur-pembelian",
        },
        "debit-memo-delivery": {
            title: "common.debitMemoDelivery",
            url: "https://docs.refineaccounting.com/pembelian/#surat-jalan-retur-pembelian",
        },
        "debit-memo": {
            title: "common.debitMemo",
            url: "https://docs.refineaccounting.com/pembelian/#retur-pembelian",
        },
        "account-payable-report": {
            title: "common.accountPayableReport",
            url: "https://docs.refineaccounting.com/pembelian/#hutang-usaha",
        },
        "purchase-down-payment": {
            title: "common.purchaseDownPayment",
            url: "https://docs.refineaccounting.com/pembelian/#uang-muka-pembelian",
        },
        "purchase-dp-usage": {
            title: "common.purchaseDpUsage",
            url: "https://docs.refineaccounting.com/pembelian/#uang-muka-pembelian",
        },
        "deposit-supplier": {
            title: "common.depositSupplier",
            url: "https://docs.refineaccounting.com/pembelian/#deposit-supplier",
        },

        //sales
        "sales-order": {
            title: "common.salesOrder",
            url: "https://docs.refineaccounting.com/penjualan/#pesanan-penjualan",
        },
        "delivery-report": {
            title: "common.deliveryReport",
            url: "https://docs.refineaccounting.com/penjualan/#surat-jalan",
        },
        sales: {
            title: "common.sales",
            url: "https://docs.refineaccounting.com/penjualan/#faktur-penjualan",
        },
        "credit-memo-delivery": {
            title: "common.creditMemoDelivery",
            url: "https://docs.refineaccounting.com/penjualan/#penerimaan-barang-retur",
        },
        "credit-memo": {
            title: "common.creditMemo",
            url: "https://docs.refineaccounting.com/penjualan/#retur-penjualan",
        },
        "account-receivable-report": {
            title: "common.accountReceivableReport",
            url: "https://docs.refineaccounting.com/penjualan/#piutang-usaha",
        },
        "sales-down-payment": {
            title: "common.salesDownPayment",
            url: "https://docs.refineaccounting.com/penjualan/#uang-muka-penjualan",
        },
        "sales-dp-usage": {
            title: "common.salesDpUsage",
            url: "https://docs.refineaccounting.com/penjualan/#uang-muka-penjualan",
        },
        "deposit-customer": {
            title: "common.depositCustomer",
            url: "https://docs.refineaccounting.com/pembelian/#deposit-customer",
        },

        //inventory
        "inventory-warehouse-detail": {
            title: "common.inventoryList",
            url: "https://docs.refineaccounting.com/persediaan/#daftar-persediaan",
        },
        "inventory-report": {
            title: "common.inventoryReport",
            url: "https://docs.refineaccounting.com/persediaan/#mutasi-persediaan",
        },
        "stock-card": {
            title: "common.stockCard",
            url: "https://docs.refineaccounting.com/persediaan/#kartu-persediaan",
        },
        "stock-opname": {
            title: "common.inventoryAdjustment",
            url: "https://docs.refineaccounting.com/persediaan/#koreksi-persediaan",
        },
        "stock-transfer": {
            title: "common.inventoryTransfer",
            url: "https://docs.refineaccounting.com/persediaan/#transfer-persediaan",
        },

        //transaction
        "transaction-in": {
            title: "common.transactionIn",
            url: "https://docs.refineaccounting.com/keuangan/#transaksi-masuk",
        },
        "transaction-out": {
            title: "common.transactionOut",
            url: "https://docs.refineaccounting.com/keuangan/#transaksi-keluar",
        },
        "transfer-cash-bank": {
            title: "common.bookEntry",
            url: "https://docs.refineaccounting.com/keuangan/#pemindahbukuan",
        },
        "giro-payable": {
            title: "common.payableCheck",
            url: "https://docs.refineaccounting.com/keuangan/#giro-dibuka",
        },
        "giro-receivable": {
            title: "common.receivableCheck",
            url: "https://docs.refineaccounting.com/keuangan/#giro-diterima",
        },
        "cash-mutation": {
            title: "common.cashMutation",
            url: "https://docs.refineaccounting.com/keuangan/#mutasi-kas",
        },
        "bank-mutation": {
            title: "common.bankMutation",
            url: "https://docs.refineaccounting.com/keuangan/#mutasi-bank",
        },

        //report
        "periodical-report": {
            title: "common.periodicalReport",
            url: "https://docs.refineaccounting.com/laporan/",
        },
    };
} else {
    //ELSE IF INVENTORY
    //lazy load modules
    //usage: component: ()=><MasterBank/>, //lazy is an object, we need to simulate call here
    // const Dashboard = lazy(() => import("./Dashboard"));
    // tutorialConfig = {
    // };
}

export default tutorialConfig;

// "purchase-order/list": {
//     title: "common.purchaseOrder",
//     url: "https://refineaccounting.com/fitur-kami/",
//     // videoId: "xZCxav3v7QA",
//     // component:  <PurchaseOrderListTutorial/>
// },

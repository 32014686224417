import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withTranslation, WithTranslation } from "react-i18next";
import { Table } from "reactstrap";
import HTMLPrintHeader from "../../../common/component/HTMLPrintHeader";
import PrintArea from "../../../common/component/PrintArea";
import { SafeBig } from "../../../common/function/safeBig";
import { showCurrency, showQty } from "../../../common/function/showValueHelper";
import { slcSettingKonversiSatuanDanHargaSatuan_Value } from "../../../Setting/redux/setting";

type GrossNetPrintState = {
    data: any;
    param: any;
};

type InjectedProps = {
    settingHargaSatuan: boolean;
};


class GrossNetPrint extends PureComponent<InjectedProps & WithTranslation, GrossNetPrintState> {
    constructor(props: any) {
        super(props);

        //load data from localStorage
        const stringData = localStorage.getItem("gross-net-print");
        const jsonData = stringData != null ? JSON.parse(stringData) : null;

        // remove data from localStorage
        // localStorage.removeItem("gross-net-print");
        
        let stateObj = {
            data: jsonData?.data ?? null,
            param: jsonData?.param ?? {},
        }
        this.state = stateObj;
    }

    render() {
        const { t, settingHargaSatuan } = this.props;
        const { param, data } = this.state;
        if (data == null) return <p>No Data</p>;

        let columns = data.map((obj: any, index: number) => {
            let price = obj.price;
            if (settingHargaSatuan) {
                price = SafeBig(price).div(obj.conversion ?? 1).toFixed();
            }
            return (
                <tr key={"detail-" + index}>
                    <td>{index + 1}</td>
                    <td>{obj.key}</td>
                    <td>{obj.customer}</td>
                    <td>{obj.product}</td>
                    <td style={{ textAlign: "right" }} className="qty">
                        {obj.unit == null ? (
                            <table className="printQtyTable">
                                <tbody>
                                    <tr key={"detail-1-" + index}>
                                        <td>{showQty(obj.qty) + " "}</td>
                                        <td>{obj.baseUnit}</td>
                                    </tr>
                                </tbody>
                            </table>
                        ) : (
                            <table className="printQtyTable">
                                <tbody>
                                    <tr key={"detail-1-" + index}>
                                        <td>{showQty(obj.qty) + " " + obj.unit} @{ showQty(obj.conversion) + " " + obj.baseUnit } </td>
                                        <td>{showQty(obj.baseQty) + " " + obj.baseUnit}</td>
                                    </tr>
                                </tbody>
                            </table>
                        )}
                    </td>
                    <td style={{ textAlign: "right" }}>{showCurrency(price, 2)}</td>
                    <td style={{ textAlign: "right" }}>{showCurrency(obj.totalPrice, 2)}</td>
                    <td style={{ textAlign: "right" }}>{showCurrency(obj.cogs, 2)}</td>
                    <td style={{ textAlign: "right" }}>{showCurrency(obj.totalCogs, 2)}</td>
                    <td style={{ textAlign: "right" }}>{showCurrency(obj.totalNet, 2)}</td>
                    <td style={{ textAlign: "right" }}>{showCurrency(obj.netPercentage, 2)}</td>
                    {/* <td style={{ textAlign: "right" }}>{showCurrency(obj.netEachQty, 2)}</td> */}
                </tr>
            );
        });

        //calculation
        let totalQty = SafeBig(data.reduce((total:any, val:any)=>SafeBig(total).add(val.qty), "0"));
        let totalPrice = SafeBig(data.reduce((total:any, val:any)=>SafeBig(total).add(val.totalPrice), "0"));
        let totalCost = SafeBig(data.reduce((total:any, val:any)=>SafeBig(total).add(val.totalCogs), "0"));
        let totalNet = totalPrice.sub(totalCost);
        let totalPercent = !totalPrice.eq(0) ? totalNet.div(totalPrice).times(100) : "0";

        return (
            <PrintArea>
                <style>
                    {`
                        @page {
                            size: A4 landscape;
                            margin: .5cm .5cm;
                        }
                    `}
                </style>
                <Table className="ra-table-print" id="gross-net-report">
                    <HTMLPrintHeader
                        param={param}
                    />
                    <tbody>
                        <tr>
                            <td colSpan={3}>
                                <Table className="gross-net-detail">
                                    <thead>
                                        <tr>
                                            <th className="rowNo">#</th>
                                            <th className="docNo">{t("common.documentNo")}</th>
                                            <th className="customer">{t("common.customer")}</th>
                                            <th className="product">{t("common.product")}</th>
                                            <th className="qty">{t("common.qty")}</th>
                                            <th className="price">{t("common.price@")}</th>
                                            <th className="price">{t("common.totalPrice")}</th>
                                            <th className="price">{t("common.cogs@")}</th>
                                            <th className="price">{t("common.totalCost")}</th>
                                            <th className="price">{t("common.p/l")}</th>
                                            <th className="plpercentage">%</th>
                                            {/* <th>{t("common.p/l/qty")}</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {columns}
                                        <tr>
                                            <td colSpan={4}>
                                                <b>{t("common.total")}: </b>
                                            </td>
                                            <td style={{ textAlign: "right" }}>{showCurrency(totalQty, 2)}</td>
                                            <td></td>
                                            <td style={{ textAlign: "right" }}>{showCurrency(totalPrice, 2)}</td>
                                            <td></td>
                                            <td style={{ textAlign: "right" }}>{showCurrency(totalCost, 2)}</td>
                                            <td style={{ textAlign: "right" }}>{showCurrency(totalNet, 2)}</td>
                                            <td style={{ textAlign: "right" }}>{showCurrency(totalPercent, 2)}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </PrintArea>
        );
    }
}

function mapStateToProps(state: any) {
    return {
        settingHargaSatuan: slcSettingKonversiSatuanDanHargaSatuan_Value(state),
    };
}

export default connect<any>(mapStateToProps, {})(withTranslation()(GrossNetPrint));

import React, { PureComponent } from "react";
import ReactTable from "react-table";
import { withTranslation, WithTranslation } from "react-i18next";
import ClassNames from "classnames";

type ServerSideTableProps = {
    data: any[];
    totalPage: number;
    loading: boolean;
    fetchData: any; //(state: any, instance: any) => void;
    columns: any;
    filtered?: any;
    sorted?: any;
    defaultSorted?: any;
    // defaultPageSize?: number;
    maxHeight?: number | "auto";
    ref?: any;
    subComponent?: any;
    passedRef?: any;
    showPagination?: boolean;
    overridePage?: number;
    pageSize?: number;
    showPageJump?: boolean;
    expandAllSubrow?: boolean;
    className?: string;
    onPageChange?: (page: number) => void;
};

type ServerSideTableState = {
    tableData: any[]; //using this to avoid blinking after every create/edit/delete
    expandedRow: any;
};

class ServerSideTable extends PureComponent<ServerSideTableProps & WithTranslation, ServerSideTableState> {
    internalRef: any;
    constructor(props: any) {
        super(props);

        this.state = {
            tableData: [],
            expandedRow: {},
        };
    }

    componentDidUpdate(prevProps: ServerSideTableProps) {
        if (this.props.data !== prevProps.data && this.props.data != null) {
            //only change if not null (when loading, data is null)
            this.setState({
                tableData: this.props.data,
                expandedRow: this.props.expandAllSubrow
                    ? this.props.data?.map((element: any, index: number) => {
                          return { [index]: true };
                      })
                    : {},
            });
        }

        //in 20200331 suddenly there's a bug that cause table not refreshing on changed filtered
        if (this.props.filtered !== prevProps.filtered) {
            setTimeout(() => {
                this.internalRef.fireFetchData();
            });
        }
    }

    onExpandChange = (newExpanded: any, index: number) => {
        this.setState({ expandedRow: { ...this.state.expandedRow, ...newExpanded } });
    };

    render() {
        //call data here to avoid entering ...props
        const {
            data,
            passedRef,
            totalPage,
            fetchData,
            subComponent,
            maxHeight = "auto",
            overridePage,
            t,
            expandAllSubrow,
            showPageJump,
            onPageChange,
            ...props
        } = this.props;

        return (
            //@ts-ignore
            <ReactTable
                data={this.state.tableData}
                pages={totalPage}
                // loading={props.loading}
                onFetchData={fetchData}
                // columns={props.columns}
                // filtered={props.filtered}
                // sorted={props.sorted}
                ref={(instance: any) => {
                    this.internalRef = instance;
                    if (passedRef != null) {
                        passedRef(instance);
                    }
                }}
                {...props}
                manual
                style={{ maxHeight: maxHeight === "auto" ? null : maxHeight }} //moved to css
                //sub + expand
                SubComponent={subComponent}
                expanded={this.state.expandedRow}
                onExpandedChange={this.onExpandChange}
                // defaultPageSize={this.state.tableData?this.state.tableData.length:20}
                showPageJump={showPageJump ?? true}
                page={overridePage}
                onPageChange={onPageChange}
                pageText={t("common.page")}
                rowsText={t("common.row")}
                ofText={t("common.table_of")}
                previousText={t("common.previousPage")}
                nextText={t("common.nextPage")}
                className={ClassNames(`-striped -highlight -no-overflow ${this.props.className}`, {
                    "fixed-table": maxHeight === "auto",
                })}
            />
        );
    }
}

export default withTranslation()(ServerSideTable);

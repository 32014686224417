import { PureComponent } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Table } from "reactstrap";
import { showCurrency, showQty } from "../../../common/function/showValueHelper";

type InventoryCardAccountingProps = {
    data: any;
};

type InventoryCardAccountingState = {};

class InventoryCardAcc extends PureComponent<
    WithTranslation & InventoryCardAccountingProps,
    InventoryCardAccountingState
> {
    constructor(props: any) {
        super(props);
        let stateObj = {};
        this.state = stateObj;
    }

    render() {
        const { data, t } = this.props;
        let result = data.listData.map((val: any, i: number) => {
            let txElements = (
                <Table className="ra-table-detail-print" key={`invcard-${i}`}>
                    <colgroup>
                        <col style={{ width: "5%" }}></col>
                        <col style={{ width: "10%" }}></col>
                        <col style={{ width: "10%" }}></col>
                        <col style={{ width: "5%" }}></col>
                        <col style={{ width: "7%" }}></col>
                        <col style={{ width: "5%" }}></col>
                        <col style={{ width: "7%" }}></col>
                        <col style={{ width: "5%" }}></col>
                        <col style={{ width: "7%" }}></col>
                        <col style={{ width: "5%" }}></col>
                    </colgroup>
                    <thead>
                        <tr>
                            <th>{t("common.date")}</th>
                            <th>{t("common.key")}</th>
                            <th>{t("common.description")}</th>
                            <th>{t("common.qtyIn")}</th>
                            <th>{t("common.amountIn")}</th>
                            <th>{t("common.qtyOut")}</th>
                            <th>{t("common.amountOut")}</th>
                            <th>{t("common.qtyFinal")}</th>
                            <th>{t("common.balance")}</th>
                            <th>{t("common.avg")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colSpan={2}></td>
                            <td>{t("common.beginningBalance")}</td>
                            <td colSpan={4}></td>
                            <td>{showQty(val.initialBalance.qBalance)}</td>
                            <td>{showCurrency(val.initialBalance.amountBalance)}</td>
                            <td>{showCurrency(val.initialBalance.cogs)}</td>
                        </tr>
                        {val.list.map((transaction: any, i: number) => {
                            return (
                                <tr key={`invcard-tx-${i}`}>
                                    <td>{transaction.date}</td>
                                    <td>{transaction.key}</td>
                                    <td>{transaction.description}</td>
                                    <td>{showQty(transaction.qIn)}</td>
                                    <td>{showCurrency(transaction.amountIn)}</td>
                                    <td>{showQty(transaction.qOut)}</td>
                                    <td>{showCurrency(transaction.amountOut)}</td>
                                    <td>{showQty(transaction.qBalance)}</td>
                                    <td>{showCurrency(transaction.amountBalance)}</td>
                                    <td>{showCurrency(transaction.cogs)}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            );
            return (
                <>
                    <tr>
                        <td>{t("common.product")}</td>
                        <td>: {`${val.product.key} - ${val.product.name}`}</td>
                    </tr>
                    <tr>
                        <td>{t("common.category")}</td>
                        <td>: {`${val.product.category}`}</td>
                    </tr>
                    <tr>
                        <td colSpan={2}>{txElements}</td>
                    </tr>
                </>
            );
        });
        return (
            <div>
                <style>
                    {`
                        @page {
                            size: A4 landscape;
                        }

                        .ra-table-detail-print {
                            margin-bottom: 1em !important;
                        }
                    `}
                </style>
                <Table className="ra-table-print">
                    <colgroup>
                        <col style={{ width: "20%" }}></col>
                        <col style={{ width: "70%" }}></col>
                    </colgroup>
                    <tbody>{result}</tbody>
                </Table>
            </div>
        );
    }
}

export default withTranslation()(InventoryCardAcc);

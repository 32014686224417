import React, { PureComponent } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import PrintArea from "../../../common/component/PrintArea";
import DeliveryReportPrintTable from "../DeliveryReportPrintTemplate/tplDefault";
import DeliveryReportPrintAnj from "../DeliveryReportPrintTemplate/tplAnj";
import DeliveryReportPrintBerkat from "../DeliveryReportPrintTemplate/tplBerkat";

type DeliveryReportPrintState = {
    data: any;
    companyInfo: any;
    printTemplateSetting: any;
};

class DeliveryReportPrint extends PureComponent<WithTranslation, DeliveryReportPrintState> {
    constructor(props: any) {
        super(props);

        //load data from localStorage
        const stringData = localStorage.getItem("deliveryReport-print");
        const jsonData = stringData != null ? JSON.parse(stringData) : null;

        // remove data from localStorage
        localStorage.removeItem("deliveryReport-print");

        // console.log(jsonData);

        this.state = {
            data: jsonData && jsonData.data ? jsonData.data : null,
            companyInfo: jsonData && jsonData.companyInfo ? jsonData.companyInfo : null,
            printTemplateSetting: jsonData?.setting?.printTemplateSetting ?? "STANDARD",
        };
    }

    render() {
        const { data, companyInfo, printTemplateSetting } = this.state;
        let printTmpl = null;
        switch (printTemplateSetting) {
            case "BERKAT": 
                printTmpl = <DeliveryReportPrintBerkat data={data} companyInfo={companyInfo} />
                break;

            case "ANJ":
                printTmpl = <DeliveryReportPrintAnj data={data} companyInfo={companyInfo} />
                break;
                
            default:
                printTmpl = <DeliveryReportPrintTable data={data} companyInfo={companyInfo} printTemplateSetting={printTemplateSetting} />
                break;
        }
        return (
            <PrintArea>
                { printTmpl }
            </PrintArea>
        );
    }
}

export default withTranslation()(DeliveryReportPrint);

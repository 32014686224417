import React from "react";
import { useTranslation } from "react-i18next";
import { Table } from "reactstrap";
import PrintFooter from "../../../common/component/PrintFooter";
import { SafeBig } from "../../../common/function/safeBig";
import { showDate, showQty } from "../../../common/function/showValueHelper";

type DeliveryReportPrintAnjProps = {
    data: any;
    companyInfo: any;
};

function getData(companyInfo: any, key: string) {
    let obj = companyInfo.find((val: any) => val.key === key);
    return obj ? obj.value : "";
}

const DeliveryReportPrintAnj = React.memo(({ data, companyInfo }: DeliveryReportPrintAnjProps) => {
    const { t } = useTranslation();
    if (data == null) return <p>No Data</p>;
    let totalQty = SafeBig(0);
    let columns = data.detailList.map((obj: any, index: number) => {
        totalQty = totalQty.plus(SafeBig(obj.qty ?? 0));
        let pUnitToSmallestUnitCompare = SafeBig(obj.productUnitConversion?.qty ?? 1).div(
            obj.product.smallestUnitConversion?.qty ?? 1
        );
        return (
            <tr key={"detail-" + index}>
                <td className="qty">
                    {obj.unit == null ? (
                        <table className="printQtyTable">
                            <colgroup>
                                <col style={{ width: "65%" }}></col>
                                <col style={{ width: "35%" }}></col>
                            </colgroup>
                            <tbody>
                                <tr key={"detail-1-" + index}>
                                    <td>{showQty(obj.qty) + " "}</td>
                                    <td>{obj.product?.unit?.name}</td>
                                </tr>
                            </tbody>
                        </table>
                    ) : (
                        <table className="printQtyTable">
                            <colgroup>
                                <col style={{ width: "65%" }}></col>
                                <col style={{ width: "35%" }}></col>
                            </colgroup>
                            <tbody>
                                <tr key={"detail-1-" + index}>
                                    {!obj.product.smallestUnit || obj.unit.id === obj.product.smallestUnit?.id ? (
                                        <>
                                            <td>
                                                {showQty(obj.qty) + " " + obj.unit?.name} @
                                                {showQty(obj.productUnitConversion.qty) + " " + obj.product?.unit?.name}{" "}
                                            </td>
                                            <td>
                                                {showQty(
                                                    SafeBig(obj.qty ?? 0).times(obj.productUnitConversion?.qty ?? 1)
                                                ) +
                                                    " " +
                                                    obj.product.unit?.name}
                                            </td>
                                        </>
                                    ) : (
                                        <>
                                            <td>
                                                {showQty(obj.qty) + " " + obj.unit?.name} @
                                                {showQty(pUnitToSmallestUnitCompare) +
                                                    " " +
                                                    obj.product.smallestUnit?.name}{" "}
                                            </td>
                                            <td>
                                                {showQty(SafeBig(obj.qty ?? 0).times(pUnitToSmallestUnitCompare ?? 1)) +
                                                    " " +
                                                    obj.product.smallestUnit?.name}
                                            </td>
                                        </>
                                    )}
                                </tr>
                            </tbody>
                        </table>
                    )}
                </td>
                <td>{obj.productName ?? obj.product.name}</td>
            </tr>
        );
    });
    return (
        <>
            <style>
                {`
                    .ra-table-print > thead th {
                        font-size: 12pt;
                    }

                    .ra-table-detail-print th,
                    .ra-table-detail-print td,
                    .ra-summary-table td {
                        font-size: 10pt !important;
                        letter-spacing: .5pt;
                    }

                    table td {
                        font-size: 10pt;
                    }

                    .ra-table-print > thead {
                        border-bottom: .5cm solid transparent;
                    }

                    @page {
                        margin: 1cm 0cm;
                    }

                    @media print {
                        .ra-table-print > thead th {
                            font-size: 14pt;
                        }    

                        .ra-table-detail-print td,
                        .ra-table-detail-print th {
                            padding: 3px !important;
                        }

                        .ra-table-detail-print td,
                        .ra-table-detail-print th,
                        .ra-summary-table td,
                        .ra-summary-table th {
                            border: .5px solid black;
                        }

                        .ra-table-detail-print th,
                        .ra-table-detail-print td,
                        .ra-summary-table td {
                            font-size: 12pt !important;
                            letter-spacing: .5pt;
                        }

                        .ra-table-detail-print td {
                            white-space: pre-wrap;
                            height: inherit;
                        }

                        .ra-table-detail-print tr {
                            height: 1px;
                        }

                        .printQtyTable {
                            height: 100%;
                        }
                    }
                    `}
            </style>
            <Table className="ra-table-print">
                <thead>
                    <tr>
                        <th>
                            <b>{data.key}</b>
                        </th>
                        <th>
                            <b>
                                {t("common.date")}: {showDate(data.documentDate)}
                            </b>
                        </th>
                    </tr>
                    <tr>
                        <th style={{ width: "60%" }}>
                            {getData(companyInfo, "nama-perusahaan")} <br />
                            {getData(companyInfo, "alamat-perusahaan")}
                        </th>
                        <th>
                            {data.customer.name} <br /> {data.customAddress ?? data.customer.address}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td colSpan={2}>
                            <Table className="ra-table-detail-print">
                                <colgroup>
                                    <col style={{ width: "30%" }}></col>
                                    <col style={{ width: "62%" }}></col>
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th className="qty">{t("common.qty")}</th>
                                        <th>{t("common.product")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {columns}
                                    <tr className="detailSummary">
                                        <td className="qty">
                                            <Table className="printQtyTable">
                                                <colgroup>
                                                    <col style={{ width: "65%" }}></col>
                                                    <col style={{ width: "35%" }}></col>
                                                </colgroup>
                                                <tbody>
                                                    <tr>
                                                        <td style={{ textAlign: "right" }}>{t("common.totalQty")}</td>
                                                        <td>{showQty(totalQty.toString(), 2)} COLLY</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </Table>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            <PrintFooter footerType="deliveryReport" />
                        </td>
                    </tr>
                </tbody>
            </Table>
        </>
    );
});

export default DeliveryReportPrintAnj;

import React, { lazy } from "react";
import lazyWithRetry from "./common/function/lazyWithRetry";
import Common from "./Register/Common";
import {
    slcSettingInventoryAdjustmentDraft,
    slcSettingSalesReceiptFeature,
    slcSettingUnfulfilledSalesOrder,
} from "./Setting/redux/setting";

/**
 * ALL Module index/sidebar/lazy MUST BE DEFINED HERE.
 * The SidebarLinks and <Route> components are handled by EnsureLoginPage.
 *
 * However, sub-pages of the Module need to be defined in their own index file (as we're not using lazy-load there).
 *
 * Paths should avoid substring to avoid sidebar-active bug
 *
 * **NOTES**
 * There are 2 moduleConfig here, refineAccounting and refineInventory.
 * Set them both if there's any change.
 *
 * By separating them like this, build output is smaller for refineInventory (as the number of import is smaller).
 */

let moduleConfig: RouteDefinitionArray = [];

if (process.env.REACT_APP_IS_INVENTORY === "false") {
    //lazy load modules
    //usage: component: ()=><MasterBank/>, //lazy is an object, we need to simulate call here
    const Dashboard = lazyWithRetry(() => import("./Dashboard"));
    const MasterBank = lazyWithRetry(() => import("./Master/Bank"));
    const MasterCash = lazyWithRetry(() => import("./Master/Cash"));
    const MasterCoa = lazyWithRetry(() => import("./Master/Coa"));
    const MasterCustomer = lazyWithRetry(() => import("./Master/Customer"));
    const MasterSupplier = lazyWithRetry(() => import("./Master/Supplier"));
    const MasterSalesPerson = lazyWithRetry(() => import("./Master/SalesPerson"));
    const MasterWarehouse = lazyWithRetry(() => import("./Master/Warehouse"));
    const MasterProduct = lazyWithRetry(() => import("./Master/Product"));
    const MasterTermOfPayment = lazyWithRetry(() => import("./Master/TermOfPayment"));
    const MasterAdmin = lazyWithRetry(() => import("./Master/Admin"));
    const MasterTenant = lazyWithRetry(() => import("./Master/Tenant"));
    const TransactionIn = lazyWithRetry(() => import("./Register/TransactionIn"));
    const TransactionOut = lazyWithRetry(() => import("./Register/TransactionOut"));
    const DownPayment = lazyWithRetry(() => import("./Register/DownPayment"));
    const TransactionMutation = lazyWithRetry(() => import("./Register/TransactionMutation"));
    const PurchaseOrder = lazyWithRetry(() => import("./Register/PurchaseOrder"));
    const Purchase = lazyWithRetry(() => import("./Register/Purchase"));
    const DebitMemo = lazyWithRetry(() => import("./Register/DebitMemo"));
    const SalesOrder = lazyWithRetry(() => import("./Register/SalesOrder"));
    const Sales = lazyWithRetry(() => import("./Register/Sales"));
    const CreditMemo = lazyWithRetry(() => import("./Register/CreditMemo"));
    const SalesReceipt = lazyWithRetry(() => import("./Register/SalesReceipt"));
    const InventoryFlow = lazyWithRetry(() => import("./Register/InventoryFlow"));
    const Asset = lazyWithRetry(() => import("./Register/Asset"));
    const BillOfMaterial = lazyWithRetry(() => import("./Register/BillOfMaterial"));
    const JobOrder = lazyWithRetry(() => import("./Register/JobOrder"));
    const JobCompletion = lazyWithRetry(() => import("./Register/JobCompletion"));
    const Accounting = lazyWithRetry(() => import("./Register/Accounting"));
    const Report = lazyWithRetry(() => import("./Register/Report"));
    const Setting = lazyWithRetry(() => import("./Setting"));
    const Installation = lazyWithRetry(() => import("./Installation"));
    const RecurringInvoice = lazyWithRetry(() => import("./Register/RecurringInvoice"));
    // const Test = lazy(() => import("./Test"));
    moduleConfig = [
        {
            path: "/home",
            label: "common.dashboard",
            icon: "tachometer-alt",
            addMargin: true,
            component: () => <Dashboard />,
        },
        {
            hideFromMenu: true,
            path: "/update-info",
            label: "common.updateInfo",
            shortLabel: "short.updateInfo",
            component: () => <Common />,
        },
        {
            authorityModule: ["coa", "bank", "cash", "product", "warehouse", "supplier", "customer", "term-of-payment"],
            authorityType: ["read"],
            label: "common.master",
            icon: "book",
            collapsible: true,
            stateName: "master",
            children: [
                {
                    authorityModule: "coa",
                    path: "/coa",
                    label: "common.coa",
                    shortLabel: "short.coa",
                    component: () => <MasterCoa />,
                },
                {
                    authorityModule: "bank",
                    path: "/bank",
                    label: "common.bank",
                    shortLabel: "short.bank",
                    component: () => <MasterBank />,
                },
                {
                    authorityModule: "cash",
                    path: "/cash",
                    label: "common.cash",
                    shortLabel: "short.cash",
                    component: () => <MasterCash />,
                },
                {
                    authorityModule: "warehouse",
                    path: "/warehouse",
                    label: "common.warehouse",
                    shortLabel: "short.warehouse",
                    component: () => <MasterWarehouse />,
                },
                {
                    authorityModule: "sales-person",
                    path: "/sales-person",
                    label: "common.salesPerson",
                    shortLabel: "short.salesPerson",
                    component: () => <MasterSalesPerson />,
                },
                {
                    authorityModule: ["customer", "customer-category"],
                    label: "common.customer",
                    icon: "users",
                    collapsible: true,
                    stateName: "masterCustomer",
                    children: [
                        {
                            authorityModule: "customer",
                            path: "/customer",
                            label: "common.customer",
                            shortLabel: "short.customer",
                            component: () => <MasterCustomer />,
                        },
                        {
                            authorityModule: "customer-category",
                            path: "/customer-category",
                            label: "common.customerCategory",
                            shortLabel: "short.customerCategory",
                            component: () => <MasterCustomer />, //customerCategory belongs inside masterCustomer
                        },
                    ],
                },
                {
                    authorityModule: ["supplier", "supplier-category"],
                    label: "common.supplier",
                    icon: "industry",
                    collapsible: true,
                    stateName: "masterSupplier",
                    children: [
                        {
                            authorityModule: "supplier",
                            path: "/supplier",
                            label: "common.supplier",
                            shortLabel: "short.supplier",
                            component: () => <MasterSupplier />,
                        },
                        {
                            authorityModule: "supplier-category",
                            path: "/supplier-category",
                            label: "common.supplierCategory",
                            shortLabel: "short.supplierCategory",
                            component: () => <MasterSupplier />,
                        },
                    ],
                },
                {
                    authorityModule: [
                        "product",
                        "product-category",
                        "product-special-price",
                        "product-type",
                        "product-unit",
                        "product-unit-conversion",
                    ],
                    label: "common.product",
                    icon: "cube",
                    collapsible: true,
                    stateName: "masterProduct",
                    children: [
                        {
                            authorityModule: "product",
                            path: "/product",
                            label: "common.product",
                            shortLabel: "short.product",
                            component: () => <MasterProduct />,
                        },
                        {
                            authorityModule: "product-special-price",
                            path: "/product-special-price",
                            label: "common.productSpecialPrice",
                            shortLabel: "short.productSpecialPrice",
                            component: () => <MasterProduct />,
                        },
                        {
                            authorityModule: "product-category",
                            path: "/product-category",
                            label: "common.productCategory",
                            shortLabel: "short.productCategory",
                            component: () => <MasterProduct />,
                        },
                        // { //currently hidden
                        //     authorityModule: "product-type", //missing authority
                        //     path: "/product-types",
                        //     label: "common.masterProductType",
                        //     shortLabel: "short.productType",
                        //     component: () => <MasterProduct />,
                        // },
                        {
                            authorityModule: "product-unit",
                            path: "/product-unit",
                            label: "common.productUnit",
                            shortLabel: "short.productUnit",
                            component: () => <MasterProduct />,
                        },
                        {
                            authorityModule: "product-unit-conversion",
                            path: "/product-unit-conversion",
                            label: "common.productUnitConversion",
                            shortLabel: "short.productUnitConversion",
                            component: () => <MasterProduct />,
                        },
                    ],
                },
                {
                    authorityModule: "term-of-payment",
                    path: "/term-of-payment",
                    label: "common.termOfPayment",
                    shortLabel: "short.termOfPayment",
                    component: () => <MasterTermOfPayment />,
                },
            ],
        },
        {
            authorityModule: [
                "transaction-bank-in",
                "transaction-cash-in",
                "transaction-bank-out",
                "transaction-cash-out",
                "giro-payable",
                "giro-receivable",
            ],
            authorityType: ["read"],
            label: "common.financeModule",
            icon: "money-bill",
            collapsible: true,
            stateName: "financeModule",
            children: [
                {
                    authorityModule: ["transaction-bank-in", "transaction-cash-in"],
                    authorityType: ["create"],
                    path: "/transaction-in",
                    label: "common.transactionIn",
                    shortLabel: "short.transactionIn",
                    component: () => <TransactionIn />,
                },
                {
                    authorityModule: ["transaction-bank-out", "transaction-cash-out"],
                    authorityType: ["create"],
                    path: "/transaction-out",
                    label: "common.transactionOut",
                    shortLabel: "short.transactionOut",
                    component: () => <TransactionOut />,
                },
                {
                    authorityModule: [
                        "transaction-bank-out",
                        "transaction-bank-in",
                        "transaction-cash-in",
                        "transaction-cash-out",
                    ],
                    path: "/transfer-cash-bank",
                    label: "common.bookEntry",
                    shortLabel: "short.bookEntry",
                    component: () => <TransactionMutation />,
                },
                {
                    authorityModule: "giro-payable",
                    path: "/giro-payable",
                    label: "common.giroPayable",
                    shortLabel: "short.giroPayable",
                    component: () => <TransactionMutation />,
                },
                {
                    authorityModule: "giro-receivable",
                    path: "/giro-receivable",
                    label: "common.giroReceivable",
                    shortLabel: "short.giroReceivable",
                    component: () => <TransactionMutation />,
                },
                {
                    authorityModule: "cash",
                    path: "/cash-mutation",
                    label: "common.cashMutation",
                    shortLabel: "short.cashMutation",
                    component: () => <TransactionMutation />,
                },
                {
                    authorityModule: "bank",
                    path: "/bank-mutation",
                    label: "common.bankMutation",
                    shortLabel: "short.bankMutation",
                    component: () => <TransactionMutation />,
                },
                {
                    authorityModule: ["giro-receivable", "giro-payable"],
                    path: "/giro-detail",
                    label: "common.giroDetail",
                    shortLabel: "short.giroDetail",
                    component: () => <TransactionMutation />,
                    hideFromMenu: true,
                },
            ],
        },
        {
            authorityModule: ["purchase-order", "purchase", "debit-memo", "accounting-report"],
            authorityType: ["read", "readQty", "readAmount"],
            label: "common.purchaseModule",
            icon: "shopping-cart",
            collapsible: true,
            stateName: "purchaseModule",
            children: [
                {
                    authorityModule: "purchase-order",
                    authorityType: ["readAmount"],
                    path: "/purchase-order",
                    label: "common.purchaseOrder",
                    shortLabel: "short.purchaseOrder",
                    component: () => <PurchaseOrder />,
                },
                {
                    authorityModule: "purchase",
                    authorityType: ["readQty"],
                    path: "/receive-report",
                    label: "common.receiveReport",
                    shortLabel: "short.receiveReport",
                    component: () => <Purchase />,
                },
                {
                    authorityModule: "purchase",
                    authorityType: ["readAmount"],
                    path: "/purchase",
                    label: "common.purchase",
                    shortLabel: "short.purchase",
                    component: () => <Purchase />,
                },
                {
                    authorityModule: "debit-memo",
                    authorityType: ["readQty"],
                    path: "/debit-memo-delivery",
                    label: "common.debitMemoDelivery",
                    shortLabel: "short.debitMemoDelivery",
                    component: () => <DebitMemo />,
                },
                {
                    authorityModule: "debit-memo",
                    authorityType: ["readAmount"],
                    path: "/debit-memo",
                    label: "common.debitMemo",
                    shortLabel: "short.debitMemo",
                    component: () => <DebitMemo />,
                },
                {
                    authorityModule: "purchase",
                    authorityType: ["readAmount"],
                    path: "/account-payable-report",
                    label: "common.accountPayable",
                    shortLabel: "short.accountPayable",
                    component: () => <Purchase />,
                },
                {
                    authorityModule: ["dp-purchase"],
                    path: "/purchase-down-payment",
                    label: "common.dpPurchase",
                    shortLabel: "short.dpPurchase",
                    component: () => <DownPayment />,
                },
                {
                    authorityModule: ["dp-purchase"],
                    path: "/purchase-dp-usage",
                    label: "common.dpPurchaseUsage",
                    shortLabel: "short.dpPurchaseUsage",
                    component: () => <DownPayment />,
                },
                {
                    authorityModule: ["transaction-bank-out", "transaction-cash-out"],
                    authorityType: ["read"],
                    path: "/deposit-supplier",
                    label: "common.depositSupplier",
                    shortLabel: "short.depositSupplier",
                    component: () => <Purchase />,
                },
            ],
        },
        {
            authorityModule: ["sales-order", "sales", "sales-receipt", "credit-memo"],
            authorityType: ["read", "readQty", "readAmount"],
            label: "common.salesModule",
            icon: "handshake",
            collapsible: true,
            stateName: "salesModule",
            children: [
                {
                    authorityModule: "sales-order",
                    authorityType: ["readAmount"],
                    path: "/sales-order",
                    label: "common.salesOrder",
                    shortLabel: "short.salesOrder",
                    component: () => <SalesOrder />,
                },
                {
                    authorityModule: "sales-order",
                    authorityType: ["readQty"],
                    path: "/unfulfilled-sales-order",
                    label: "common.unfulfilledSalesOrder",
                    shortLabel: "short.unfulfilledSalesOrder",
                    renderFlagByState: { selector: slcSettingUnfulfilledSalesOrder, value: true },
                    component: () => <SalesOrder />,
                },
                {
                    authorityModule: "sales",
                    authorityType: ["readQty"],
                    path: "/delivery-report",
                    label: "common.deliveryReport",
                    shortLabel: "short.deliveryReport",
                    component: () => <Sales />,
                },
                {
                    authorityModule: "sales",
                    authorityType: ["readAmount"],
                    path: "/sales",
                    label: "common.sales",
                    shortLabel: "short.sales",
                    component: () => <Sales />,
                },
                {
                    hideFromMenu: true,
                    authorityModule: "sales",
                    authorityType: ["readAmount"],
                    path: "/nonstock-invoice",
                    label: "common.sales",
                    shortLabel: "short.sales",
                    component: () => <Sales />,
                },
                {
                    authorityModule: "credit-memo",
                    authorityType: ["readQty"],
                    path: "/credit-memo-receive",
                    label: "common.creditMemoReceive",
                    shortLabel: "short.creditMemoReceive",
                    component: () => <CreditMemo />,
                },
                {
                    authorityModule: "credit-memo",
                    authorityType: ["readAmount"],
                    path: "/credit-memo",
                    label: "common.creditMemo",
                    shortLabel: "short.creditMemo",
                    component: () => <CreditMemo />,
                },
                {
                    authorityModule: "sales-receipt",
                    authorityType: ["read"],
                    path: "/sales-receipt",
                    label: "common.salesReceipt",
                    shortLabel: "short.salesReceipt",
                    renderFlagByState: { selector: slcSettingSalesReceiptFeature, value: true },
                    component: () => <SalesReceipt />,
                },
                {
                    authorityModule: "sales",
                    authorityType: ["readAmount"],
                    path: "/account-receivable-report",
                    label: "common.accountReceivable",
                    shortLabel: "short.accountReceivable",
                    component: () => <Sales />,
                },
                {
                    authorityModule: ["dp-sales"],
                    path: "/sales-down-payment",
                    label: "common.dpSales",
                    shortLabel: "short.dpSales",
                    component: () => <DownPayment />,
                },
                {
                    authorityModule: ["dp-sales"],
                    path: "/sales-dp-usage",
                    label: "common.dpSalesUsage",
                    shortLabel: "short.dpSalesUsage",
                    component: () => <DownPayment />,
                },
                {
                    authorityModule: ["transaction-bank-in", "transaction-cash-in"],
                    authorityType: ["read"],
                    path: "/deposit-customer",
                    label: "common.depositCustomer",
                    shortLabel: "short.depositCustomer",
                    component: () => <Sales />,
                },
            ],
        },
        {
            authorityModule: ["recurring-invoice", "bill"],
            authorityType: ["read", "readQty", "readAmount"],
            label: "common.recurringModule",
            icon: "recycle",
            collapsible: true,
            stateName: "recurringModule",
            children: [
                {
                    authorityModule: "recurring-invoice",
                    authorityType: ["readAmount"],
                    path: "/recurring-invoice",
                    label: "common.recurringInvoice",
                    shortLabel: "short.recurringInvoice",
                    component: () => <RecurringInvoice />,
                },
            ],
        },
        {
            authorityModule: ["inventory-flow", "inventory-adjustment", "inventory-transfer", "inventory-draft"],
            label: "common.inventoryModule",
            icon: "cubes",
            collapsible: true,
            stateName: "inventoryModule",
            children: [
                {
                    authorityModule: "inventory-flow",
                    path: "/inventory-warehouse-detail",
                    label: "common.inventoryWarehouseTable",
                    shortLabel: "short.inventoryWarehouseTable",
                    component: () => <InventoryFlow />,
                },
                {
                    authorityModule: "inventory-flow",
                    path: "/inventory-report",
                    label: "common.inventoryReport",
                    shortLabel: "short.inventoryReport",
                    component: () => <InventoryFlow />,
                },
                {
                    authorityModule: "inventory-flow",
                    path: "/stock-card",
                    label: "common.stockCard",
                    shortLabel: "short.stockCard",
                    component: () => <InventoryFlow />,
                },
                {
                    authorityModule: "inventory-draft",
                    path: "/inventory-draft",
                    label: "common.inventoryDraft",
                    shortLabel: "short.inventoryDraft",
                    renderFlagByState: { selector: slcSettingInventoryAdjustmentDraft, value: true },
                    component: () => <InventoryFlow />,
                },
                {
                    authorityModule: "inventory-adjustment",
                    path: "/stock-opname",
                    label: "common.inventoryAdjustment",
                    shortLabel: "short.inventoryAdjustment",
                    component: () => <InventoryFlow />,
                },
                // {
                //     authorityModule: "inventory-adjustment",
                //     authorityType: ["readAmount"],
                //     path: "/stock-opname-cogs",
                //     label: "common.cogsAdjustment",
                //     shortLabel: "short.cogsAdjustment",
                //     component: () => <p>Will be available in the future</p>,
                // },
                {
                    authorityModule: "inventory-transfer",
                    path: "/stock-transfer",
                    label: "common.inventoryTransfer",
                    shortLabel: "short.inventoryTransfer",
                    component: () => <InventoryFlow />,
                },
            ],
        },
        {
            authorityModule: ["asset"],
            authorityType: ["read"],
            label: "common.assetModule",
            icon: "laptop-house",
            collapsible: true,
            stateName: "assetModule",
            children: [
                {
                    authorityModule: "asset",
                    authorityType: ["read"],
                    path: "/asset",
                    label: "common.asset",
                    shortLabel: "short.asset",
                    component: () => <Asset />,
                },
            ],
        },
        {
            authorityModule: ["bill-of-material", "job-order", "job-completion"],
            authorityType: ["read"],
            label: "common.productionModule",
            icon: "warehouse",
            collapsible: true,
            stateName: "productionModule",
            children: [
                {
                    authorityModule: "bill-of-material",
                    authorityType: ["read"],
                    path: "/bill-of-material",
                    label: "common.billOfMaterial",
                    shortLabel: "short.billOfMaterial",
                    component: () => <BillOfMaterial />,
                },
                {
                    authorityModule: "job-order",
                    authorityType: ["read"],
                    path: "/job-order",
                    label: "common.jobOrder",
                    shortLabel: "short.jobOrder",
                    component: () => <JobOrder />,
                },
                {
                    authorityModule: "job-completion",
                    authorityType: ["read"],
                    path: "/job-completion",
                    label: "common.jobCompletion",
                    shortLabel: "short.jobCompletion",
                    component: () => <JobCompletion />,
                },
                {
                    authorityModule: "job-completion",
                    authorityType: ["read"],
                    path: "/production-summary",
                    label: "common.productionSummary",
                    shortLabel: "short.productionSummary",
                    component: () => <JobCompletion />,
                },
            ],
        },
        {
            authorityModule: ["acc-general-journal", "acc-journal-memorial"],
            label: "common.accountingModule",
            icon: "calculator",
            collapsible: true,
            stateName: "accountingModule",
            children: [
                {
                    authorityModule: "acc-general-journal",
                    path: "/account-preview",
                    label: "common.accountPreview",
                    shortLabel: "short.accountPreview",
                    component: () => <Accounting />,
                },
                {
                    authorityModule: "acc-general-journal",
                    path: "/general-ledger-preview",
                    label: "common.generalLedgerPreview",
                    shortLabel: "short.generalLedgerPreview",
                    component: () => <Accounting />,
                },
                {
                    authorityModule: "acc-journal-memorial",
                    path: "/memorial-journal",
                    label: "common.memorialJournal",
                    shortLabel: "short.memorialJournal",
                    component: () => <Accounting />,
                },
            ],
        },
        {
            authorityModule: ["accounting-report"],
            label: "common.reportModule",
            icon: "chart-pie",
            stateName: "reportModule",
            collapsible: true,
            children: [
                {
                    authorityModule: "accounting-report",
                    path: "/created-report",
                    label: "common.createdReport",
                    shortLabel: "short.createdReport",
                    component: () => <Report />,
                },
                {
                    authorityModule: "accounting-report",
                    path: "/hybrid-report",
                    label: "common.hybridReport",
                    shortLabel: "short.hybridReport",
                    component: () => <Report />,
                },
                // {
                //     authorityModule: "accounting-report",
                //     path: "/periodical-report",
                //     label: "common.periodicalReport",
                //     shortLabel: "short.periodicalReport",
                //     component: () => <Report />,
                // },
                {
                    authorityModule: "accounting-report",
                    path: "/html-report",
                    label: "common.htmlReport",
                    shortLabel: "short.htmlReport",
                    component: () => <Report />,
                },
                // {
                //     authorityModule: "accounting-report",
                //     path: "/general-journal",
                //     label: "common.generalJournal",
                //     shortLabel: "short.generalJournal",
                //     component: () => <p>Will be available in the future</p>,
                // },
                // {
                //     authorityModule: "accounting-report",
                //     path: "/general-ledgers",
                //     label: "common.ledger",
                //     shortLabel: "short.ledger",
                //     component: () => <p>Will be available in the future</p>,
                // },
                // {
                //     authorityModule: "accounting-report",
                //     path: "/trial-balances",
                //     label: "common.trialBalance",
                //     shortLabel: "short.trialBalance",
                //     component: () => <p>Will be available in the future</p>,
                // },
            ],
        },
        {
            authorityModule: ["admin", "admin-role", "tenant"],
            label: "common.accountModule",
            icon: "users",
            collapsible: true,
            stateName: "accountModule",
            children: [
                {
                    authorityModule: "tenant",
                    path: "/tenant",
                    label: "common.tenant",
                    shortLabel: "short.tenant",
                    component: () => <MasterTenant />,
                },
                {
                    authorityModule: "admin",
                    path: "/admin",
                    label: "common.admin",
                    shortLabel: "short.admin",
                    component: () => <MasterAdmin />,
                },
                {
                    authorityModule: "role",
                    path: "/role",
                    label: "common.role",
                    shortLabel: "short.role",
                    component: () => <MasterAdmin />,
                },
                {
                    authorityModule: "tenant",
                    path: "/role-admin",
                    label: "common.roleAdmin",
                    shortLabel: "short.roleAdmin",
                    component: () => <MasterAdmin />,
                },
            ],
        },
        {
            authorityModule: ["setting"],
            authorityType: ["create", "read", "update"],
            label: "common.settingModule",
            icon: "sliders-h",
            collapsible: true,
            stateName: "settingModule",
            children: [
                {
                    authorityModule: "setting",
                    path: "/setting-default-coa",
                    label: "common.defaultCoa",
                    shortLabel: "short.defaultCoa",
                    component: () => <Setting />,
                },
                {
                    authorityModule: "setting",
                    path: "/setting-info",
                    label: "common.companyInfo",
                    shortLabel: "short.companyInfo",
                    component: () => <Setting />,
                },
                {
                    authorityModule: "setting",
                    path: "/setting-application",
                    label: "common.application",
                    shortLabel: "short.application",
                    component: () => <Setting />,
                },
                {
                    authorityModule: "setting",
                    path: "/setting-accounting",
                    label: "common.accounting",
                    shortLabel: "short.accounting",
                    component: () => <Setting />,
                },
                {
                    authorityModule: "setting",
                    path: "/setting-etc",
                    label: "common.etc",
                    shortLabel: "short.etc",
                    component: () => <Setting />,
                },
                {
                    authorityModule: "setting",
                    authorityType: ["create"],
                    path: "/setting-webview",
                    label: "common.webview",
                    shortLabel: "short.webview",
                    component: () => <Setting />,
                },
                {
                    authorityModule: "setting",
                    authorityType: ["create"],
                    path: "/setting-database",
                    label: "common.databaseManagement",
                    shortLabel: "short.databaseManagement",
                    component: () => <Setting />,
                },
                // {
                //     authorityModule: "setting",
                //     path: "/setting-print",
                //     label: "common.print",
                //     shortLabel: "short.print",
                //     component: () => <p>Will be available in the future</p>,
                // },
                // {
                //     authorityModule: "admin",
                //     path: "/update",
                //     label: "common.update",
                //     shortLabel: "short.update",
                //     component: () => <Setting />,
                // },
            ],
        },
        {
            authorityModule: "setting",
            authorityType: ["create"],
            icon: "cogs",
            path: "/queue-result",
            label: "common.queueResult",
            addMargin: true,
            component: () => <Setting />,
        },
        {
            authorityModule: "setting",
            authorityType: ["update"],
            icon: "cog",
            path: "/install",
            label: "common.installation",
            addMargin: true,
            component: () => <Installation />,
        },
        {
            authorityModule: "setting-period",
            authorityType: ["create", "update"],
            icon: "lock",
            path: "/lock-period",
            label: "common.lockPeriod",
            addMargin: true,
            component: () => <Setting />,
        },
    ];

    if (process.env.REACT_APP_ENABLE_SQL_MANAGER === "true") {
        moduleConfig.push({
            authorityModule: "admin-role",
            authorityType: ["create"],
            icon: "file-export",
            path: "/setting-sql-manager",
            label: "common.sqlManager",
            addMargin: true,
            component: () => <Setting />,
        });
    }
} else {
    //ELSE IF INVENTORY
    //lazy load modules
    const Dashboard = lazy(() => import("./Dashboard"));
    const MasterBank = lazy(() => import("./Master/Bank"));
    const MasterCash = lazy(() => import("./Master/Cash"));
    const MasterCoa = lazy(() => import("./Master/Coa"));
    const MasterCustomer = lazy(() => import("./Master/Customer"));
    const MasterSupplier = lazy(() => import("./Master/Supplier"));
    const MasterWarehouse = lazy(() => import("./Master/Warehouse"));
    const MasterProduct = lazy(() => import("./Master/Product"));
    const MasterTermOfPayment = lazy(() => import("./Master/TermOfPayment"));
    const MasterAdmin = lazy(() => import("./Master/Admin"));
    const MasterTenant = lazy(() => import("./Master/Tenant"));
    const InventoryFlow = lazy(() => import("./Register/InventoryFlow"));

    moduleConfig = [
        {
            path: "/home",
            label: "common.dashboard",
            icon: "tachometer-alt",
            addMargin: true,
            component: () => <Dashboard />,
        },
        {
            authorityModule: ["coa", "bank", "cash", "product", "warehouse", "supplier", "customer", "term-of-payment"],
            authorityType: ["read"],
            label: "common.master",
            icon: "book",
            collapsible: true,
            stateName: "master",
            children: [
                {
                    authorityModule: "coa",
                    path: "/coa",
                    label: "common.coa",
                    shortLabel: "short.coa",
                    component: () => <MasterCoa />,
                },
                {
                    authorityModule: "bank",
                    path: "/bank",
                    label: "common.bank",
                    shortLabel: "short.bank",
                    component: () => <MasterBank />,
                },
                {
                    authorityModule: "cash",
                    path: "/cash",
                    label: "common.cash",
                    shortLabel: "short.cash",
                    component: () => <MasterCash />,
                },
                {
                    authorityModule: "warehouse",
                    path: "/warehouse",
                    label: "common.warehouse",
                    shortLabel: "short.warehouse",
                    component: () => <MasterWarehouse />,
                },
                {
                    authorityModule: ["customer", "customer-category"],
                    label: "common.customer",
                    icon: "users",
                    collapsible: true,
                    stateName: "masterCustomer",
                    children: [
                        {
                            authorityModule: "customer",
                            path: "/customer",
                            label: "common.customer",
                            shortLabel: "short.customer",
                            component: () => <MasterCustomer />,
                        },
                        {
                            authorityModule: "customer-category",
                            path: "/customer-category",
                            label: "common.customerCategory",
                            shortLabel: "short.customerCategory",
                            component: () => <MasterCustomer />, //customerCategory belongs inside masterCustomer
                        },
                    ],
                },
                {
                    authorityModule: ["supplier", "supplier-category"],
                    label: "common.supplier",
                    icon: "industry",
                    collapsible: true,
                    stateName: "masterSupplier",
                    children: [
                        {
                            authorityModule: "supplier",
                            path: "/supplier",
                            label: "common.supplier",
                            shortLabel: "short.supplier",
                            component: () => <MasterSupplier />,
                        },
                        {
                            authorityModule: "supplier-category",
                            path: "/supplier-category",
                            label: "common.supplierCategory",
                            shortLabel: "short.supplierCategory",
                            component: () => <MasterSupplier />,
                        },
                    ],
                },
                {
                    authorityModule: ["product", "product-category", "product-type", "product-unit"],
                    label: "common.product",
                    icon: "cube",
                    collapsible: true,
                    stateName: "masterProduct",
                    children: [
                        {
                            authorityModule: "product",
                            path: "/product",
                            label: "common.product",
                            shortLabel: "short.product",
                            component: () => <MasterProduct />,
                        },
                        {
                            authorityModule: "product-special-price",
                            path: "/product-special-price",
                            label: "common.productSpecialPrice",
                            shortLabel: "short.productSpecialPrice",
                            component: () => <MasterProduct />,
                        },
                        {
                            authorityModule: "product-category",
                            path: "/product-category",
                            label: "common.productCategory",
                            shortLabel: "short.productCategory",
                            component: () => <MasterProduct />,
                        },
                        // { //currently hidden
                        //     authorityModule: "product-type", //missing authority
                        //     path: "/product-types",
                        //     label: "common.masterProductType",
                        //     shortLabel: "short.productType",
                        //     component: () => <MasterProduct />,
                        // },
                        {
                            authorityModule: "product-unit",
                            path: "/product-unit",
                            label: "common.productUnit",
                            shortLabel: "short.productUnit",
                            component: () => <MasterProduct />,
                        },
                    ],
                },
                {
                    authorityModule: "term-of-payment",
                    path: "/term-of-payment",
                    label: "common.termOfPayment",
                    shortLabel: "short.termOfPayment",
                    component: () => <MasterTermOfPayment />,
                },
            ],
        },
        {
            authorityModule: ["inventory-flow", "inventory-adjustment", "inventory-transfer"],
            label: "common.inventoryModule",
            icon: "cubes",
            collapsible: true,
            stateName: "inventoryModule",
            children: [
                {
                    authorityModule: "inventory-flow",
                    path: "/inventory-warehouse-detail",
                    label: "common.inventoryWarehouseTable",
                    shortLabel: "short.inventoryWarehouseTable",
                    component: () => <InventoryFlow />,
                },
                {
                    authorityModule: "inventory-flow",
                    path: "/inventory-report",
                    label: "common.inventoryReport",
                    shortLabel: "short.inventoryReport",
                    component: () => <InventoryFlow />,
                },
                {
                    authorityModule: "inventory-flow",
                    path: "/stock-card",
                    label: "common.stockCard",
                    shortLabel: "short.stockCard",
                    component: () => <InventoryFlow />,
                },
                {
                    authorityModule: "inventory-adjustment",
                    path: "/stock-opname",
                    label: "common.inventoryAdjustment",
                    shortLabel: "short.inventoryAdjustment",
                    component: () => <InventoryFlow />,
                },
                // {
                //     authorityModule: "inventory-adjustment",
                //     authorityType: ["readAmount"],
                //     path: "/stock-opname-cogs",
                //     label: "common.cogsAdjustment",
                //     shortLabel: "short.cogsAdjustment",
                //     component: () => <p>Will be available in the future</p>,
                // },
                {
                    authorityModule: "inventory-transfer",
                    path: "/stock-transfer",
                    label: "common.inventoryTransfer",
                    shortLabel: "short.inventoryTransfer",
                    component: () => <InventoryFlow />,
                },
            ],
        },
        {
            authorityModule: ["admin", "admin-role", "tenant"],
            label: "common.accountModule",
            icon: "users",
            collapsible: true,
            stateName: "accountModule",
            children: [
                {
                    authorityModule: "tenant",
                    path: "/tenant",
                    label: "common.tenant",
                    shortLabel: "short.tenant",
                    component: () => <MasterTenant />,
                },
                {
                    authorityModule: "admin",
                    path: "/admin",
                    label: "common.admin",
                    shortLabel: "short.admin",
                    component: () => <MasterAdmin />,
                },
                {
                    authorityModule: "role",
                    path: "/role",
                    label: "common.role",
                    shortLabel: "short.role",
                    component: () => <MasterAdmin />,
                },
                {
                    authorityModule: "tenant", //special protection
                    path: "/role-admin",
                    label: "common.roleAdmin",
                    shortLabel: "short.roleAdmin",
                    component: () => <MasterAdmin />,
                },
            ],
        },
    ];
}

export default moduleConfig;

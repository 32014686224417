import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Switch, Route } from "react-router-dom";

import EnsureLoginPage from "./page/EnsureLoginPage";
import LoginPage from "./page/LoginPage";

import { actValidateUserLogin, actResetUserLogin, slcReAuthNeeded } from "./redux/login";
import PrintRouter from "./page/PrintRouter";

// type CoreProps = {
//     actValidateUserLogin: Function;
//     actResetUserLogin: Function;
//     reAuthNeeded: boolean;
//     language: string;
// };

class Core extends PureComponent<any, any> {
    componentDidMount() {
        this.props.actValidateUserLogin();
    }

    componentDidUpdate(prevProps: any) {
        if (this.props.reAuthNeeded === true) {
            this.props.actResetUserLogin();
        }

        // if (this.props.language!=null && prevProps.language!==this.props.language){
        //     i18n.changeLanguage(this.props.language);
        // }
    }

    render() {
        return (
            <div>
                <Switch>
                    <Route path="/login" component={LoginPage} />
                    <Route path="/print-page" component={PrintRouter} />
                    <Route path="/print-created-report" component={PrintRouter} />
                    <Route path="*" component={EnsureLoginPage} />
                </Switch>
            </div>
        );
    }
}

function mapStateToProps(state: any) {
    return {
        reAuthNeeded: slcReAuthNeeded(state),
        // language:
    };
}

export default connect(
    mapStateToProps,
    { actValidateUserLogin, actResetUserLogin }
)(Core);

import React, { PureComponent } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Table } from "reactstrap";
import PrintFooter from "../../../common/component/PrintFooter";
import { SafeBig } from "../../../common/function/safeBig";
import { showCurrency, showDate, showQty } from "../../../common/function/showValueHelper";
import changeCase from "change-case";

type POPrintAnjState = {
    data: any;
    companyInfo: any;
};

function getData(companyInfo: any, key: string) {
    let obj = companyInfo.find((val: any) => val.key === key);
    return obj ? obj.value : "";
}


class POPrintAnj extends PureComponent<WithTranslation & POPrintAnjState> {
    render() {
        const { t } = this.props;
        const { data, companyInfo } = this.props;
        if (data == null) return <p>No Data</p>;
        let columns = data.detailList.map((obj: any, index: number) => {
            return (
                <tr key={"detail-" + index}>
                    <td className="rowNo">{index + 1}</td>
                    <td>{obj.product.name}</td>
                    <td className="qty">
                        {obj.unit == null ? (
                            <table className="printQtyTable">
                                <tbody>
                                    <tr key={"detail-1-" + index}>
                                        <td>{showQty(obj.qty) + " "}</td>
                                        <td>{obj.product?.unit?.name}</td>
                                    </tr>
                                </tbody>
                            </table>
                        ) : (
                            <table className="printQtyTable">
                                <tbody>
                                    <tr key={"detail-1-" + index}>
                                        <td>{showQty(obj.qty) + " "}</td>
                                        <td>{obj.unit?.name}</td>
                                    </tr>
                                </tbody>
                            </table>
                        )}
                    </td>
                    <td className="price">{showCurrency(obj.price, 2)}</td>
                    <td className="price">{showCurrency(obj.disc1Amount, 2)}</td>
                    <td className="subTotal">{showCurrency(obj.subtotal, 2)}</td>
                </tr>
            );
        });

        let summaryRow;
        if (SafeBig(data.vat).gt(0)) {
            summaryRow = (
                <Table className="ra-summary-table">
                    <tbody>
                        <tr>
                            <td colSpan={4}>
                                <b>{t("common.calculatedPrice")}: </b> <br /> {data.say} {" "} Rupiah
                            </td>
                            <td className="disc" style={{ textAlign: "right" }}>{t("common.discount")}</td>
                            <td className="subTotal">{showCurrency(data.disc2AmountTotal)}</td>
                        </tr>
                        <tr>
                            <td rowSpan={5} colSpan={4}>
                                {t("common.notes")}: {data.description}
                            </td>
                        </tr>
                        <tr>
                            <td style={{ textAlign: "right" }}>{t("common.taxBase")}</td>
                            <td className="subTotal">{showCurrency(data.subtotal, 2)}</td>
                        </tr>
                        <tr>
                            <td style={{ textAlign: "right" }}>{t("common.tax")}</td>
                            <td className="subTotal">{showCurrency(data.vat, 2)}</td>
                        </tr>
                        <tr>
                            <td style={{ textAlign: "right" }}>{t("common.grandTotal")}</td>
                            <td className="subTotal">{showCurrency(data.grandTotal, 2)}</td>
                        </tr>
                    </tbody>
                </Table>
            );
        } else {
            summaryRow = (
                <Table className="ra-summary-table">
                    <tbody>
                        <tr>
                            <td colSpan={4}>
                                <b>{t("common.calculatedPrice")}: </b> <br /> {data.say} {" "} Rupiah
                            </td>
                            <td className="disc" style={{ textAlign: "right" }}>{t("common.discount")}</td>
                            <td className="subTotal">{showCurrency(data.disc2AmountTotal)}</td>
                        </tr>
                        <tr>
                            <td rowSpan={4} colSpan={4}>
                                {t("common.notes")}: {data.description}
                            </td>
                        </tr>
                        <tr>
                            <td style={{ textAlign: "right" }}>{t("common.taxBase")}</td>
                            <td className="subTotal">{showCurrency(data.subtotal, 2)}</td>
                        </tr>
                        <tr>
                            <td style={{ textAlign: "right" }}>{t("common.grandTotal")}</td>
                            <td className="subTotal">{showCurrency(data.grandTotal, 2)}</td>
                        </tr>
                    </tbody>
                </Table>
            );
        }


        return (
            <Table className="ra-table-print">
                <thead>
                    <tr style={{ marginBottom: "10px", marginTop: "10px" }}>
                        <th>
                            <b>{changeCase.upper(t("common.purchaseOrder"))}</b>
                        </th>
                        <th>
                            <b>
                                {t("common.date")}: {showDate(data.documentDate)}
                            </b>
                        </th>
                    </tr>
                    <tr>
                        <th>
                            <b>{data.key}</b> <br />
                            <p>{getData(companyInfo, "nama-perusahaan")} <br />
                            {getData(companyInfo, "alamat-perusahaan")}</p>
                        </th>
                        <th>
                            {t("common.recipient")}: <br /> {data.supplier.name}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td colSpan={2}>
                            <Table className="ra-table-detail-print">
                                <thead>
                                    <tr>
                                        <th className="rowNo">#</th>
                                        <th>{t("common.product")}</th>
                                        <th className="qty">{t("common.qty")}</th>
                                        <th className="price">{t("common.price")}</th>
                                        <th className="disc">{t("common.discount")}</th>
                                        <th className="subTotal">{t("common.subTotal")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {columns}
                                </tbody>
                            </Table>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            {summaryRow}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            <PrintFooter footerType="transactionO" status={data.status} />
                        </td>
                    </tr>
                </tbody>
            </Table>
        );
    }
}

export default withTranslation()(POPrintAnj);

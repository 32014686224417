import { PureComponent } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Table } from "reactstrap";
import { showQty } from "../../../common/function/showValueHelper";
import { SafeBig } from "../../../common/function/safeBig";

type InventoryMutationLimitedProps = {
    data: any;
};

type InventoryMutationLimitedState = {};

class InventoryMutationLimited extends PureComponent<
    WithTranslation & InventoryMutationLimitedProps,
    InventoryMutationLimitedState
> {
    constructor(props: any) {
        super(props);
        let stateObj = {};
        this.state = stateObj;
    }

    render() {
        const { data, t } = this.props;
        let sumInitialQty = SafeBig(0);
        let sumPurchaseQty = SafeBig(0);
        let sumDebitQty = SafeBig(0);
        let sumTransferInQty = SafeBig(0);
        let sumSalesQty = SafeBig(0);
        let sumCreditQty = SafeBig(0);
        let sumTransferOutQty = SafeBig(0);
        let sumAdjustmentQty = SafeBig(0);
        let sumEndingQty = SafeBig(0);

        let result = data.listData.map((val: any, i: number) => {
            if (i === 0) return <></>;
            sumInitialQty = sumInitialQty.add(val.initialQty.value);
            sumPurchaseQty = sumPurchaseQty.add(val.purchaseQty.value);
            sumDebitQty = sumDebitQty.add(val.debitMemoQty.value);
            sumTransferInQty = sumTransferInQty.add(val.inventoryTransferInQty.value);
            sumSalesQty = sumSalesQty.add(val.salesQty.value);
            sumCreditQty = sumCreditQty.add(val.creditMemoQty.value);
            sumTransferOutQty = sumTransferOutQty.add(val.inventoryTransferOutQty.value);
            sumAdjustmentQty = sumAdjustmentQty.add(val.inventoryAdjustmentQty.value);
            sumEndingQty = sumEndingQty.add(val.endingQty.value);

            return (
                <tr key={`lim-inv-mutation=${i}`}>
                    <td>{`${val.productKey.value} - ${val.productName.value}`}</td>
                    <td>{showQty(val.initialQty.value)}</td>
                    <td>{showQty(val.purchaseQty.value)}</td>
                    <td>{showQty(val.debitMemoQty.value)}</td>
                    <td>{showQty(val.inventoryTransferInQty.value)}</td>
                    <td>{showQty(val.salesQty.value)}</td>
                    <td>{showQty(val.creditMemoQty.value)}</td>
                    <td>{showQty(val.inventoryTransferOutQty.value)}</td>
                    <td>{showQty(val.inventoryAdjustmentQty.value)}</td>
                    <td>{showQty(val.endingQty.value)}</td>
                    <td>{showQty(val.productDescription.value)}</td>
                </tr>
            );
        });
        result.push(
            <tr key={`sum-inv-limited`}>
                <td>{t("common.total")}</td>
                <td>{showQty(sumInitialQty)}</td>
                <td>{showQty(sumPurchaseQty)}</td>
                <td>{showQty(sumDebitQty)}</td>
                <td>{showQty(sumTransferInQty)}</td>
                <td>{showQty(sumSalesQty)}</td>
                <td>{showQty(sumCreditQty)}</td>
                <td>{showQty(sumTransferOutQty)}</td>
                <td>{showQty(sumAdjustmentQty)}</td>
                <td>{showQty(sumEndingQty)}</td>
                <td></td>
            </tr>
        );
        return (
            <div>
                <style>
                    {`
                        @page {
                            size: A4 landscape;
                        }

                        .ra-table-detail-print {
                            margin-bottom: 1em !important;
                        }
                    `}
                </style>
                <Table className="ra-table-detail-print">
                    <colgroup>
                        <col style={{ width: "20%" }} />
                        <col style={{ width: "7.5%" }} />
                        <col style={{ width: "7.5%" }} />
                        <col style={{ width: "7.5%" }} />
                        <col style={{ width: "7.5%" }} />
                        <col style={{ width: "7.5%" }} />
                        <col style={{ width: "7.5%" }} />
                        <col style={{ width: "7.5%" }} />
                        <col style={{ width: "7.5%" }} />
                        <col style={{ width: "7.5%" }} />
                        <col style={{ width: "5%" }} />
                    </colgroup>
                    <thead>
                        <tr>
                            <td>{t("common.product")}</td>
                            <td>{t("common.beginningBalance")}</td>
                            <td>{t("common.purchase")}</td>
                            <td>{t("common.debitMemo")}</td>
                            <td>{t("common.transferIn")}</td>
                            <td>{t("common.sales")}</td>
                            <td>{t("common.creditMemo")}</td>
                            <td>{t("common.transferOut")}</td>
                            <td>{t("common.inventoryAdjustment")}</td>
                            <td>{t("common.balance")}</td>
                            <td>{t("common.description")}</td>
                        </tr>
                    </thead>
                    <tbody>{result}</tbody>
                </Table>
            </div>
        );
    }
}

export default withTranslation()(InventoryMutationLimited);
